import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd'
import HubspotInitScreen from './HubspotInitScreen'
import HubspotNewDeal from './HubspotNewDeal'
import HubspotUpdateDeal from './HubspotUpdateDeal'
import HubspotExportContacts from './HubspotExportContacts'
import HubspotExportCompanies from './HubspotExportCompanies'
import { get } from 'common/api/axios'
import { useGetAlreadyTackedCreditsMutation } from 'features/contacts/state/api/ContactsApi'
import { useAppSelector } from 'state'
import { useLazyGetCreditsQuery, useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'
// import { useLazyGetPointsQuery } from 'features/settings/state/api/SettingsApi'

interface HubspotModalProps {
  open: boolean
  onClose: () => void
  selectedContacts: any
  isCompany: boolean
  selectedCompanies: any
}

const HubspotModal: React.FC<HubspotModalProps> = ({
  open,
  onClose,
  selectedContacts,
  isCompany,
  selectedCompanies,
}) => {
  const [activeComponent, setActiveComponent] = useState<string | null>('init')
  const [owners, setOwners] = useState<any>([])
  const [pipeline, setPipeline] = useState<any>([])
  const [deals] = useState<any>([])
  const userIds = selectedContacts?.map((contact: any) => contact?.index)
  const companyIds = selectedContacts
    ?.map((contact: any) => contact?.company_id)
    .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)
  const [refetchTackedCredits] = useGetAlreadyTackedCreditsMutation()
  const [telegramCredits, setTelegramCredits] = useState([])
  const [emailCredits, setEmailCredits] = useState([])
  const { selected_companies } = useAppSelector((state) => state?.companies)
  const [userCredits, setUserCredits] = useState<any>()
  const [userPoints, setUserPoints] = useState<any>()

  const [refetchGetCredits] = useLazyGetCreditsQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  const [errorFetchedChecker, setErrorFetchedChecker] = useState(false)
  const errorFetchedCheckerCount = useRef(0)
  const hubspot_access_token = localStorage.getItem('hubspot_access_token')

  //const [errorDealsChecker, setErrorDealsChecker] = useState(false)
  //const errorDealsCheckerCount = useRef(0)

  useEffect(() => {
    const fetchData = async () => {
      const creditsResponse = await refetchGetCredits()
      setUserCredits(creditsResponse?.data?.response?.credits)
      // setUserPoints(creditsResponse?.data?.response?.points)

      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [open])

  useEffect(() => {
    const fetchData = async () => {
      const result = await refetchTackedCredits({
        contacts: userIds,
      })
      if (result) {
        setTelegramCredits(result?.data?.response?.telegram)
        setEmailCredits(result?.data?.response?.email)
      }
    }

    !isCompany && fetchData().catch(console.error)
  }, [open])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedOwners = await get('/outreach/getDealOwner')
        setOwners(fetchedOwners.data.response)

        const fetchedPipeline = await get('/outreach/getHubspotPipelineStages')
        setPipeline(fetchedPipeline.data.response)
      } catch (error) {
        console.error('Error fetching data: ', error)
        setErrorFetchedChecker((c) => !c)
      }
    }
    if (hubspot_access_token !== 'null' && errorFetchedCheckerCount.current < 10) {
      fetchData()
    }
    errorFetchedCheckerCount.current++
  }, [errorFetchedChecker])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const fetchedDeals = await get('/outreach/getHubspotAllDeals')
  //       setDeals(fetchedDeals.data.response)
  //     } catch (error) {
  //       console.error('Error fetching deals: ', error)
  //       setErrorDealsChecker((c) => !c)
  //     }
  //   }
  //   if (errorDealsCheckerCount.current < 10) {
  //      fetchData()
  //   }
  //   errorDealsCheckerCount.current++
  // }, [errorDealsChecker])

  const handleOpenComponent = (componentName: string) => {
    setActiveComponent(componentName)
  }

  const goBackToInitScreen = () => {
    setActiveComponent('init')
  }

  const minWidth =
    activeComponent === 'init' || activeComponent === 'ExportContacts' || activeComponent === 'ExportCompanies'
      ? 600
      : 850
  const maxWidth = activeComponent === 'ExportContacts' ? 650 : 800

  const onCloseModal = () => {
    onClose()
    setActiveComponent('init')
  }

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          setActiveComponent('init')
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: minWidth, maxWidth: maxWidth }}>
        {activeComponent === 'init' ? (
          <HubspotInitScreen onOpenComponent={handleOpenComponent} isCompany={isCompany} />
        ) : (
          <>
            {activeComponent === 'NewDeal' && (
              <HubspotNewDeal
                onCloseModal={onCloseModal}
                onBack={goBackToInitScreen}
                selectedContacts={selectedContacts}
                selectedCompanies={selectedCompanies}
                owners={owners}
                pipeline={pipeline}
                telegramCredits={telegramCredits}
                emailCredits={emailCredits}
                isCompany={isCompany}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
            {activeComponent === 'UpdateDeal' && (
              <HubspotUpdateDeal
                onCloseModal={onCloseModal}
                onBack={goBackToInitScreen}
                selectedContacts={selectedContacts}
                selectedCompanies={selectedCompanies}
                deals={deals}
                pipeline={pipeline}
                telegramCredits={telegramCredits}
                emailCredits={emailCredits}
                isCompany={isCompany}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
            {activeComponent === 'ExportContacts' && (
              <HubspotExportContacts
                onBack={goBackToInitScreen}
                onCloseModal={onCloseModal}
                selectedContacts={userIds}
                telegramCredits={telegramCredits}
                emailCredits={emailCredits}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
            {activeComponent === 'ExportCompanies' && (
              <HubspotExportCompanies
                onBack={goBackToInitScreen}
                companyIds={isCompany ? selected_companies : companyIds}
                onCloseModal={onCloseModal}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
          </>
        )}
      </Modal>
    </>
  )
}
export default HubspotModal
