import React, { useState } from 'react'
import { Modal, Typography, Switch, Button, message } from 'antd'

import styles from './ImportLeadsFromCsvModal.module.scss'
import import_and_mapping_active from 'common/assets/svg/import_and_mapping_active.svg'
import { TemplateGuildLine } from './TemplateGuildline'
import { ImportAndMapping } from './ImportAndMapping'
import { CsvPreview } from './CsvPreview'
import { useAppSelector } from 'state'
import { reduce } from 'lodash'

// import { useLazyGetLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
interface ImportLeadsFromCsvModalProps {
  open: boolean
  onClose: () => void
  onFinish: (campaign_id_tmp: any, campaign_name_tmp: any, csvData: any) => void
  selectedLists?: any
  isLoading?: boolean
  excludeLeadsInOtherCampaigns: boolean
  exclusePreviouslySent: boolean
  // Add the missing property types for setAllCntactsToggle and setFailedCntactsToggle
  setExcludeLeadsInOtherCampaigns: React.Dispatch<React.SetStateAction<boolean>>
  setExclusePreviouslySent: React.Dispatch<React.SetStateAction<boolean>>
  showButtonExcludeLeadsInOtherCampaigns?: boolean
  showButtonExclusePreviouslySent?: boolean
  fileName?: string
  fields?: string
}

const ImportLeadsFromCsvModal: React.FC<ImportLeadsFromCsvModalProps> = ({
  open,
  onClose,
  onFinish,
  selectedLists,
  isLoading,
  excludeLeadsInOtherCampaigns,
  exclusePreviouslySent,
  setExcludeLeadsInOtherCampaigns,
  setExclusePreviouslySent,
  showButtonExcludeLeadsInOtherCampaigns = true,
  showButtonExclusePreviouslySent = true,
  fileName = 'csv_template.csv',
  fields,
}) => {
  const initialStateFieldMapping = {
    handle: '',
    full_name: '',
    company: '',
    job_title: '',
    custom_1: '',
    custom_2: '',
  }
  const { campaign_id, campaign_name } = useAppSelector((state) => state.outreachCampaign)

  const [csvData, setCsvData] = useState<any>({})
  const [fieldMapping, setFieldMapping] = React.useState<any>(initialStateFieldMapping)
  const [disableButton, setDisableButton] = useState(false)

  const handleToggleSwitchAllCntactsToggle = () => {
    setExcludeLeadsInOtherCampaigns(!excludeLeadsInOtherCampaigns)
  }

  const handleToggleSwitchFailedCntactsToggle = () => {
    setExclusePreviouslySent(!exclusePreviouslySent)
  }
  // const [refetcGetLeads, { data: leadsData }] = useLazyGetLeadsQuery()
  // const [contactsListIds, setContactsListIds] = useState<any[]>([])
  // const [sources, setSources] = useState<any[]>([])
  // const selectedSources = selectedLists?.map((item: any) => item.source_name)
  // const [credits, setCredits] = useState(0)

  // useEffect(() => {
  //   const getLeads = async () => {
  //     await refetcGetLeads()
  //   }
  //   getLeads()
  // }, [])

  // useEffect(() => {
  //   if (selectedSources) {
  //     setSources(selectedSources)
  //   }
  // }, [selectedLists])

  // const handleCheckboxClick = (event: any, list: any) => {
  //   const { id, name, numberOfTelegramCredits } = list
  //   if (event.target.checked) {
  //     setContactsListIds((prevContactsListIds) => [...prevContactsListIds, id])
  //     setSources((prev) => [...prev, name])
  //     setCredits((prev) => prev + numberOfTelegramCredits)
  //   } else {
  //     setContactsListIds((prevContactsListIds) =>
  //       prevContactsListIds.filter((contactsListIds) => contactsListIds !== id),
  //     )
  //     setSources((prev) => prev.filter((listName) => listName !== name))
  //     setCredits((prev) => (prev - numberOfTelegramCredits < 0 ? 0 : prev - numberOfTelegramCredits))
  //   }
  // }
  const formatFieldKey = reduce(
    csvData.fieldArray,
    (result: any, value, index) => {
      result[value?.trim()] = index
      return result
    },
    {},
  )
  const formatedDataAfterMapping = () => {
    return csvData?.rowData?.map((row: any[]) => {
      return {
        handle: row[formatFieldKey[fieldMapping.handle]],
        full_name: row[formatFieldKey[fieldMapping.full_name]],
        company: row[formatFieldKey[fieldMapping.company]],
        job_title: row[formatFieldKey[fieldMapping.job_title]],
        custom_1: row[formatFieldKey[fieldMapping.custom_1]],
        custom_2: row[formatFieldKey[fieldMapping.custom_2]],
      }
    })
  }

  const handleOnClick = () => {
    const dataMapping = formatedDataAfterMapping() || []
    const data = dataMapping.filter((item: any) => item.handle)
    try {
      if (data.length > 0) {
        onFinish(campaign_id, campaign_name, data)
      } else {
        message.error('No data valid')
      }
    } catch (error) {
      console.error(error)
      message.error('Error when upload CSV')
    }
  }

  return (
    <>
      {open && (
        <Modal
          title={<Typography className={styles['import-page__header__left__pageName']}>Import Custom Leads</Typography>}
          centered
          open={open}
          onCancel={onClose}
          footer={
            <div className={styles.footer}>
              <Button loading={isLoading} onClick={handleOnClick} disabled={disableButton} type='primary'>
                Import
              </Button>
            </div>
          }
          width={'calc(100% - 150px)'}>
          <div>
            <div className={styles['import-page__body']}>
              <div className={styles['import-page__body__left']}>
                <div className={styles['import-page__header']}>
                  <div className={styles['import-page__header__left']}>
                    <img src={import_and_mapping_active} alt='' />
                    <span>Import And Mapping</span>
                  </div>
                </div>
                <ImportAndMapping
                  initialState={initialStateFieldMapping}
                  fieldMapping={fieldMapping}
                  setFieldMapping={setFieldMapping}
                  onFinish={onFinish}
                  csvData={csvData}
                  setCsvData={setCsvData}
                  isLoading={isLoading}
                  setDisableButton={setDisableButton}
                  // triggerImport={triggerImport}
                />
              </div>
              <div className={styles['import-page__body__right']}>
                {csvData?.file ? (
                  <CsvPreview fieldMapping={fieldMapping} csvData={csvData} />
                ) : (
                  <TemplateGuildLine fileName={fileName} />
                )}
                {/* )} */}
              </div>
            </div>
          </div>
          <div className={styles.importMapping__footer}>
            <div>
              {showButtonExcludeLeadsInOtherCampaigns && (
                <div className={styles.switchItem}>
                  <Switch
                    onChange={handleToggleSwitchAllCntactsToggle}
                    checked={excludeLeadsInOtherCampaigns}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>Exclude Leads Already Present in Other Campaigns</span>
                </div>
              )}
              {showButtonExclusePreviouslySent && (
                <div className={styles.switchItem}>
                  <Switch
                    onChange={handleToggleSwitchFailedCntactsToggle}
                    checked={exclusePreviouslySent}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>Exclude Leads Previously Sent in Other Campaigns</span>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ImportLeadsFromCsvModal
