import { createSlice } from '@reduxjs/toolkit'

interface ICampaignBuilderSlice {
  status: string
  builder_data: any
  builder_nodes: any
  builder_edges: any
  updated_builder_data: any
  updated_builder_nodes: any
  updated_builder_edges: any
  builder_setup_data: any
  builder_incomplete_nodes: any
  selected_builder_node: any
  use_custom_flow: boolean
  custom_flow_id: any
  open_delete_modal: boolean
}

const initialState = {
  status: '',
  builder_data: {},
  builder_nodes: [],
  builder_edges: [],
  updated_builder_data: {}, // Used for Saving
  updated_builder_nodes: [], // Used for Saving
  updated_builder_edges: [], // Used for Saving
  builder_setup_data: {},
  selected_builder_node: {},
  builder_incomplete_nodes: [],
  use_custom_flow: false,
  custom_flow_id: '',
  open_delete_modal: false,
} as ICampaignBuilderSlice

export const CampaignBuilderSlice = createSlice({
  name: 'outreachCampaign',
  initialState,
  reducers: {
    SET_BUILDER_DATA(state, action) {
      state.status = 'succeeded'
      state.builder_data = action.payload
      state.updated_builder_data = action.payload
    },
    SET_BUILDER_NODES(state, action) {
      state.status = 'succeeded'
      state.builder_nodes = action.payload
      state.updated_builder_nodes = action.payload
    },
    SET_BUILDER_EDGES(state, action) {
      state.status = 'succeeded'
      state.builder_edges = action.payload
      state.updated_builder_edges = action.payload
    },
    SET_UPDATED_BUILDER_DATA(state, action) {
      state.status = 'succeeded'
      state.updated_builder_data = action.payload
    },
    SET_UPDATED_BUILDER_NODES(state, action) {
      state.status = 'succeeded'
      state.updated_builder_nodes = action.payload
    },
    SET_UPDATED_BUILDER_EDGES(state, action) {
      state.status = 'succeeded'
      state.updated_builder_edges = action.payload
    },
    SET_BUILDER_SETUP_DATA(state, action) {
      const { id, data } = action.payload
      state.status = 'succeeded'
      if (!state.builder_setup_data) {
        state.builder_setup_data = {}
      }
      state.builder_setup_data[id] = data
    },
    RESET_BUILDER_SETUP_DATA(state) {
      state.status = 'succeeded'
      state.builder_setup_data = {}
    },
    REMOVE_BUILDER_SETUP_DATA_IDS(state, action) {
      const ids = action.payload
      if (Array.isArray(ids)) {
        ids.forEach((id) => {
          delete state.builder_setup_data[id]
        })
      }
      state.status = 'succeeded'
    },
    SET_SELECTED_BUILDER_NODE(state, action) {
      state.status = 'succeeded'
      state.selected_builder_node = action.payload
    },
    SET_USE_CUSTOM_FLOW(state, action) {
      state.status = 'succeeded'
      state.use_custom_flow = action.payload
    },
    SET_BUILDER_INCOMPLETE_NODES(state, action) {
      state.status = 'succeeded'
      state.builder_incomplete_nodes = action.payload
    },
    SET_CUSTOM_FLOW_ID(state, action) {
      state.status = 'succeeded'
      state.custom_flow_id = action.payload
    },
    SET_OPEN_DELETE_MODAL(state, action) {
      state.status = 'succeeded'
      state.open_delete_modal = action.payload
    },
    RESET_BUILDER(state) {
      return initialState
    },
  },
})

export const {
  SET_BUILDER_DATA,
  SET_BUILDER_NODES,
  SET_BUILDER_EDGES,
  SET_UPDATED_BUILDER_DATA,
  SET_UPDATED_BUILDER_NODES,
  SET_UPDATED_BUILDER_EDGES,
  SET_BUILDER_SETUP_DATA,
  RESET_BUILDER_SETUP_DATA,
  SET_SELECTED_BUILDER_NODE,
  SET_USE_CUSTOM_FLOW,
  SET_CUSTOM_FLOW_ID,
  SET_BUILDER_INCOMPLETE_NODES,
  SET_OPEN_DELETE_MODAL,
  REMOVE_BUILDER_SETUP_DATA_IDS,
  RESET_BUILDER,
} = CampaignBuilderSlice.actions
export const CampaignBuilderReducer = CampaignBuilderSlice.reducer
