import React, { useEffect } from 'react'
import black_tick from 'common/assets/svg/black_tick.svg'
import purple_lightning from 'common/assets/svg/purple_lightning.svg'
import styles from './NewCampaignIsReplied.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SEQUENCE_DATA } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

interface NewCampaignIsRepliedProps {
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const NewCampaignIsReplied: React.FC<NewCampaignIsRepliedProps> = ({ currentSideBar, nodesData, setNodesData }) => {
  const dispatch = useAppDispatch()
  const { message_replied } = useAppSelector((state) => state.outreachCampaign)
  const time = `${message_replied?.duration || message_replied?.duration === 0 ? message_replied.duration : 'X'} ${
    message_replied.duration === 1 ? message_replied.units.slice(0, -1) : message_replied.units
  }`
  const type = message_replied.units === 'hours' ? 0 : message_replied.units === 'days' ? 1 : 2

  useEffect(() => {
    const nodeId = currentSideBar.nodeId
    const updatedSteps = {
      ...nodesData.steps,
      [nodeId]: {
        type: 'is_message_replied',
        data: { duration: message_replied.duration, units: message_replied.units, type: type },
      },
    }

    setNodesData((prev: any) => {
      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
      return { ...prev, steps: updatedSteps }
    })
  }, [time])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={black_tick} alt='' />
        <span>Condition is Set</span>
      </div>
      <div className={styles.mainBody}>
        <img src={purple_lightning} alt='' />
        <p>If Message Repplied</p>
        <span>Within {time}, True or False paths will happen</span>
      </div>
    </div>
  )
}

export default NewCampaignIsReplied
