import {
  useLazyGetSlackConnectionQuery,
  useLazyDisconnectSlackQuery,
  useLazyDisconnectSalesforceQuery,
  useLazyGetSalesforceAuthorizationUrlQuery,
} from 'features/settings/state/api/SettingsApi'

const useIntegrations = () => {
  const [connectSlack] = useLazyGetSlackConnectionQuery()
  const [disconnectSlack] = useLazyDisconnectSlackQuery()
  const [getSalesforceAuthorizationUrl] = useLazyGetSalesforceAuthorizationUrlQuery()
  const [disconnectSalesforce] = useLazyDisconnectSalesforceQuery()

  const generateRandomString = (length: number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz'
    let randomString = ''
    const array = new Uint8Array(length)
    window.crypto.getRandomValues(array)
    for (let i = 0; i < length; i++) {
      randomString += chars.charAt(array[i] % chars.length)
    }
    return randomString
  }

  const base64URLEncode = (buffer: any) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }

  const generateCodeChallenge = async (codeVerifier: any) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(codeVerifier)
    const digest = await crypto.subtle.digest('SHA-256', data)
    return base64URLEncode(digest)
  }

  const handleConnect = async (platform: string) => {
    switch (platform) {
      case 'Slack':
        const { data: slackData } = await connectSlack()
        window.location.href = slackData?.url
        break
      case 'Salesforce':
        const codeVerifier = generateRandomString(64)
        const codeChallenge = await generateCodeChallenge(codeVerifier)
        localStorage.setItem('salesforce_code_verifier', codeVerifier)
        const { data } = await getSalesforceAuthorizationUrl({ codeVerifier, codeChallenge })
        window.location.href = data?.url
        break
      default:
        break
    }
  }

  const handleDisconnect = async (platform: string) => {
    switch (platform) {
      case 'Slack':
        const { data: slackDisconnectData } = await disconnectSlack()
        localStorage.removeItem('slack_access_token')
        return slackDisconnectData?.disconnected
      case 'Salesforce':
        const { data: salesforceDisconnectData } = await disconnectSalesforce()
        localStorage.removeItem('salesforce_code_verifier')
        localStorage.removeItem('salesforce_access_token')
        return salesforceDisconnectData?.disconnected
      default:
        return false
    }
  }

  return {
    handleConnect,
    handleDisconnect,
  }
}

export default useIntegrations
