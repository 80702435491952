import React from 'react'
import ReactFlow, { Background, ReactFlowProvider, useReactFlow } from 'reactflow'
import CustomNode from '../../Canvas/CustomNode'
import InitialNode from '../../Builder/UI/InitialNode'
import PlaceholderNode from '../../Builder/UI/PlaceholderNode'
import CustomCollapseNode from '../../Canvas/CustomCollapseNode'
import { filterPlaceholderNodesAndEdges, getLayoutedElements } from '../../Builder/Utils/BuilderUtils'
import { FalseEdge, TrueEdge } from '../../Canvas/CustomEdge'
interface FlowAsImageProps {
  template: any
}
const FlowAsImage = ({ template }: FlowAsImageProps) => {
  const { fitView } = useReactFlow()
  const edges = template?.template?.edges
  const nodes = template?.template?.nodes
  const nodeTypes: any = {
    customNode: CustomNode,
    initialNode: InitialNode,
    placeholderNode: () => <PlaceholderNode hideTitle={true} />,
    customCollapseNode: CustomCollapseNode,
  }
  const edgeTypes: any = { trueEdge: TrueEdge, falseEdge: FalseEdge }

  const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(nodes, edges)
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
  return (
    <ReactFlowProvider>
      <ReactFlow
        className={nodes[0]?.type === 'initialNode' ? 'flowBuilder' : ''}
        edges={layoutedEdges}
        nodes={layoutedNodes}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        elementsSelectable={false}
        nodesDraggable={false}
        panOnDrag={false}
        zoomOnScroll={false}
        snapToGrid={true}
        panOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        fitView
        onLoad={() => setTimeout(() => fitView(), 0)}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        defaultViewport={{
          x: window.innerWidth * 0.15,
          y: window.innerHeight * 0.15,
          zoom: 1,
        }}
        preventScrolling={false}
        proOptions={{ hideAttribution: true }}>
        <Background />
      </ReactFlow>
    </ReactFlowProvider>
  )
}
export default FlowAsImage
