import React, { useEffect, useState } from 'react'
import { Skeleton, Table, Tooltip } from 'antd'
import styles from './GroupsTable.module.scss'
// import { useAppDispatch, useAppSelector } from 'state'
// import { SET_SELECTED_LEADS } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import visible_eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import hidden_eye_icon from 'common/assets/svg/hidden_eye_icon.svg'
import { getGroupsColumns } from './GroupsColumns'
import { useAppSelector } from 'state'

interface GroupsTableProps {
  selectedGroup: string
  data: any
  isLoading: boolean
  selectedRowKeys: any
  setSelectedRowKeys: any
  onLeads: () => void
  loadingLeads: boolean
  showNewLeads: boolean
}

const GroupsTable: React.FC<GroupsTableProps> = ({
  selectedGroup,
  data,
  isLoading,
  selectedRowKeys,
  setSelectedRowKeys,
  onLeads,
  loadingLeads,
  showNewLeads,
}) => {
  const [tableSize, setTableSize] = useState('large')
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)

  const platform = data?.[0]?.platform || truePlatformsArray[0]

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL],
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1700) {
        setTableSize('small')
      } else {
        setTableSize('large')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  const parseBio = (bio: string) => {
    if (bio?.toLocaleLowerCase() === 'nan') {
      return ''
    } else {
      return bio
    }
  }

  const convertedData: any[] = data?.map((item: any, index: any) => ({
    key: item.username,
    full_name: item.full_name || item.username || '',
    username: item.username || '',
    biography: parseBio(item.biography),
    is_private: item.is_private,
    follower_count: item.follower_count,
    profile_pic_url: item.profile_pic_url,
    display: item.display,
  }))

  const handleHideLeads = async () => {
    onLeads()
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          Displaying <span style={{ fontWeight: 600 }}>all of {data?.length?.toLocaleString()} available leads</span>{' '}
          from {selectedGroup}
        </span>
        <Tooltip title={`${showNewLeads ? 'Show' : 'Hide'} leads from my other campaigns`}>
          <img
            className={styles.hiddenEye}
            onClick={handleHideLeads}
            src={showNewLeads ? hidden_eye_icon : visible_eye_icon}
            alt=''
          />
        </Tooltip>
      </div>
      <Table
        // scroll={{ y: '585px' }}
        // loading={isLoading}
        // locale={{
        //   emptyText: isLoading ? (
        //     <LoadingGroupsSkeleton
        //       listStyle={{ margin: '-20px -8px' }}
        //       listItemStyle={{ padding: '23px 0px 23px 0px' }}
        //       skeletonStyle={{
        //         paddingTop: '0px',
        //         paddingLeft: '5px',
        //         paddingRight: '5px',
        //         display: 'flex',
        //         alignItems: 'center',
        //         opacity: 0.5,
        //       }}
        //     />
        //   ) : (
        //     ''
        //   ),
        // }}
        // dataSource={data}
        // dataSource={convertedData}
        // columns={getGroupsColumns(platform)}
        dataSource={
          loadingLeads || isLoading
            ? [...Array(10)].map((_, index) => ({
                key: `key${index}`,
              }))
            : convertedData
        }
        columns={
          loadingLeads || isLoading
            ? getGroupsColumns(platform)?.map((column: any) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        // className='campaignsPageTable'
                        style={{
                          margin: '0px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingLeft: '5px',
                          height: '30px',
                          paddingRight: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          opacity: 0.5,
                        }}
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                        active
                      />
                    )
                  },
                }
              })
            : getGroupsColumns(platform)
        }
        rowSelection={rowSelection}
        pagination={{
          position: ['bottomRight'],
          total: data?.length,
          pageSize: tableSize === 'small' ? 6 : 8,
          showSizeChanger: false,
          //   onChange: handlePageChange,
        }}
        className='groupsTable'
        // scroll={tablePaywall ? {} : undefined}
        // scroll={tablePaywall ? undefined:'auto'}
      />
    </div>
  )
}

export default GroupsTable
