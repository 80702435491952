import { createSlice } from '@reduxjs/toolkit'

import { IContactsSlice } from 'features/contacts/state/interface/IContactsSlice'

const initialState = {
  status: '',
  contacts: [],
  selected_contacts: [],
  telegram_contacts: [],
  companyId: '',
  contact: null,
  hubspotDeals: null,
  last_action_page_contact: 1,
  last_action_page_outreach: 1,
  selected_contacts_in_outreach: [],
  filtered_contacts: [],
  contacts_selected_rows: [],
  delete_contacts_selected_rows: false,
  is_send_message: false,
} as IContactsSlice

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    SET_CONTACTS(state, action) {
      state.status = 'succeeded'
      state.contacts = action.payload.content
    },
    SET_SELECTED_CONTACTS(state, action) {
      state.status = 'succeeded'
      state.selected_contacts = action.payload
    },
    SET_CONTACT(state, action) {
      state.status = 'succeeded'
      state.contact = action.payload.content
    },
    SET_COMPANY_ID(state, action) {
      state.status = 'succeeded'
      state.companyId = action.payload
    },
    SET_HUBSPOT_DEALS(state, action) {
      state.status = 'succeeded'
      state.hubspotDeals = action.payload
    },
    SET_LAST_ACTION_PAGE_CONTACT(state, action) {
      state.status = 'succeeded'
      state.last_action_page_contact = action.payload
    },
    SET_LAST_ACTION_PAGE_OUTREAH(state, action) {
      state.status = 'succeeded'
      state.last_action_page_outreach = action.payload
    },
    SET_SELECTED_CONTACTS_IN_OUTREAH(state, action) {
      state.status = 'succeeded'
      state.selected_contacts_in_outreach = action.payload
    },
    SET_FILTERED_CONTACTS(state, action) {
      state.status = 'succeeded'
      state.filtered_contacts = action.payload
    },
    SET_CONTACTS_SELECTED_ROWS(state, action) {
      state.status = 'succeeded'
      state.contacts_selected_rows = action.payload
    },
    SET_DELETE_CONTACTS_SELECTED_ROWS(state, action) {
      state.status = 'succeeded'
      state.delete_contacts_selected_rows = action.payload
    },
    SET_IS_SEND_MESSAGE(state, action) {
      state.status = 'succeeded'
      state.is_send_message = action.payload
    },
  },
})

export const {
  SET_CONTACTS,
  SET_SELECTED_CONTACTS,
  SET_CONTACT,
  SET_COMPANY_ID,
  SET_HUBSPOT_DEALS,
  SET_LAST_ACTION_PAGE_CONTACT,
  SET_LAST_ACTION_PAGE_OUTREAH,
  SET_SELECTED_CONTACTS_IN_OUTREAH,
  SET_FILTERED_CONTACTS,
  SET_CONTACTS_SELECTED_ROWS,
  SET_DELETE_CONTACTS_SELECTED_ROWS,
  SET_IS_SEND_MESSAGE,
} = contactsSlice.actions
export const contactsReducer = contactsSlice.reducer
