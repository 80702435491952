import React, { useEffect, useState } from 'react'
import { Modal, Button, message, Checkbox } from 'antd'
import { ArrowRightOutlined, CreditCardOutlined } from '@ant-design/icons'
import styles from './UpgradePlanModal.module.scss'
import stripeLogo from 'common/assets/svg/stripe_logo.svg' // Adjust the path as necessary
import { useLazyChangeUserPlanQuery, useLazyGetUsersListQuery } from 'features/settings/state/api/SettingsApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import Success from 'common/components/SuccessAnimation/Success'
import { useAuth } from 'common/hooks/useAuth.hooks'

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  trial: 'Trial',
  trail: 'Trial',
  custom: 'Custom',
}

interface User {
  id: string
  name: string
  email: string
  plan_type: string
}

interface UpgradePlanModalProps {
  open: boolean
  onClose: () => void
  selectedPlan: 'go_to_market' | 'connect_plus' | 'market_dominance'
  billingCycle: 'monthly' | 'six_months' | 'yearly'
  defaultPaymentMethod?: {
    last4: string
    link?: string
  }
  usersToUpgrade?: User[]
  buttonText?: any
}

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  open,
  onClose,
  selectedPlan,
  defaultPaymentMethod,
  billingCycle,
  usersToUpgrade = [],
  buttonText,
}) => {
  const [refetchChangeUserPlan] = useLazyChangeUserPlanQuery()
  const [refetchUsersList] = useLazyGetUsersListQuery()
  // const [billingCycle, setBillingCycle] = useState<'monthly' | 'six_months' | 'yearly'>('monthly')
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const eligibleUsersToUpgrade = usersToUpgrade.filter((user) => user.plan_type !== selectedPlan)
  const [selectedUsers, setSelectedUsers] = useState<User[]>(buttonText === 'Downgrade' ? [] : eligibleUsersToUpgrade) // Initial state is all users
  const [totalPrice, setTotalPrice] = useState(0)
  const { updateUserDetails } = useAuth()

  const planDetails = {
    go_to_market: { name: 'Go To Market', prices: { monthly: 69, six_months: 354, yearly: 576 } },
    connect_plus: { name: 'Connect+', prices: { monthly: 239, six_months: 1218, yearly: 2004 } },
    market_dominance: { name: 'Market Dominance', prices: { monthly: 499, six_months: 2544, yearly: 4188 } },
  }

  const billingCycleMapping = {
    monthly: 'Every Month',
    six_months: 'Every Six Months',
    yearly: 'Every Year',
  }

  //   const handlePlanChange = (value: 'go_to_market' | 'connect_plus' | 'market_dominance') => {
  //     setSelectedPlan(value)
  //   }

  // const handleBillingCycleChange = (e: any) => {
  //   setBillingCycle(e.target.value)
  // }

  const handleUpgradePlan = async () => {
    try {
      setIsLoading(true)
      const { data } = await refetchChangeUserPlan({
        plan: selectedPlan,
        usersToUpgrade: selectedUsers,
        billing_cycle: billingCycle,
        // user_id: userToUpgrade?.id,
        // user_email: userToUpgrade?.email,
      })
      if (data) {
        setIsLoading(false)
        setIsCompleted(true)
        await refetchUsersList()
        updateUserDetails()
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } else {
        message.error('Your card was declined. Please change your payment method.')
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      message.error('Something went wrong. Please try again.')
      setIsLoading(false)
    }
  }

  const toggleUserSelection = (toggledUser: User) => {
    const isSelected = selectedUsers.some((user) => user.id === toggledUser.id)
    setSelectedUsers((prevSelectedUsers) =>
      isSelected ? prevSelectedUsers.filter((user) => user.id !== toggledUser.id) : [...prevSelectedUsers, toggledUser],
    )
  }

  // useEffect(() => {
  //   if (userToUpgrade?.plan_type === 'connect_plus') {
  //     setSelectedPlan('market_dominance')
  //   }
  // }, [userToUpgrade?.plan_type])

  // useEffect(() => {
  //   // Initialize all users as selected
  //   const initialSelectedUsers = usersToUpgrade.reduce((acc, user) => {
  //     acc[user.id] = true
  //     return acc
  //   }, {} as { [key: string]: boolean })
  //   setSelectedUsers(initialSelectedUsers)
  // }, [usersToUpgrade])

  useEffect(() => {
    const selectedCount = Object.values(selectedUsers).filter(Boolean).length
    const pricePerUser = planDetails[selectedPlan].prices[billingCycle]
    setTotalPrice(selectedCount * pricePerUser)
  }, [selectedUsers, selectedPlan, billingCycle])

  return (
    <Modal
      title={<div className={styles.modalTitle}>Upgrade Plan</div>}
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      width={580}
      className={styles.upgradePlanModal}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingBox />
        </div>
      ) : isCompleted ? (
        <div className={styles.completed}>
          <Success />
          <h2>Thank You For Your Payment!</h2>
        </div>
      ) : (
        <>
          {/* <div className={styles.planSelection}>
            <Select value={selectedPlan} style={{ width: '100%' }} onChange={setSelectedPlan} disabled={isLoading}>
              {userToUpgrade?.plan_type === 'go_to_market' && <Option value='connect_plus'>Connect Plus</Option>}
              <Option value='market_dominance'>Market Dominance</Option>
            </Select>
          </div> */}
          {/* <div className={styles.billingCycleSelection}>
            <Radio.Group defaultValue='monthly' onChange={handleBillingCycleChange}>
              <Radio.Button value='monthly'>Monthly</Radio.Button>
              <Radio.Button value='six_months'>Six Months</Radio.Button>
              <Radio.Button value='yearly'>Yearly</Radio.Button>
            </Radio.Group>
          </div> */}
          <div className={styles.allUsers}>
            {eligibleUsersToUpgrade.map((user) => (
              <div key={user.id} className={styles.userRow}>
                <Checkbox
                  checked={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                  onChange={() => toggleUserSelection(user)}>
                  {user.name} ({user.email})
                </Checkbox>
                <span>
                  {planMapping[user.plan_type]} <ArrowRightOutlined /> <strong>{planDetails[selectedPlan].name}</strong>
                </span>
              </div>
            ))}
          </div>
          <div className={styles.note}>
            <p>Upgrading plans will be calculated on a pro rata basis.</p>
          </div>
          <div className={styles.planDetails}>
            <p>
              Selected Plan: <strong style={{ color: '#7043ff' }}>{planDetails[selectedPlan].name}</strong>
            </p>
            <p>
              {/* Pricing: <strong>${planDetails[selectedPlan].prices[billingCycle]?.toLocaleString()}, </strong> */}
              Pricing: <strong style={{ color: '#7043ff' }}>${totalPrice.toLocaleString()}, </strong>
              <span style={{ fontWeight: 400, color: '#666' }}> {billingCycleMapping[billingCycle]}</span>
            </p>
          </div>
          <div className={styles.cardInfoSection}>
            {defaultPaymentMethod?.link ? (
              <img
                style={{ height: '20px', marginRight: '5px' }}
                src={defaultPaymentMethod.link}
                alt='Payment Method'
              />
            ) : (
              <CreditCardOutlined className={styles.cardIcon} />
            )}
            <span className={styles.cardText}>
              Paying with {`${defaultPaymentMethod?.link ? 'LINK' : ` •••• •••• •••• ${defaultPaymentMethod?.last4}`}`}
            </span>
          </div>
          <div className={styles.poweredByStripe}>
            <span className={styles.poweredBy}>Powered by</span>
            <img src={stripeLogo} alt='Stripe' className={styles.stripeLogo} />
          </div>
          <div className={styles.actionButtons}>
            <Button className={styles.cancelButton} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type='primary'
              className={styles.upgradeButton}
              onClick={handleUpgradePlan}
              disabled={selectedUsers?.length === 0}>
              {buttonText} Now
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default UpgradePlanModal
