import React, { useEffect, useState } from 'react'
import { Table, TableProps, Tooltip } from 'antd'
import lead_icon1 from 'common/assets/svg/facebook_icon.svg'
import lead_icon2 from 'common/assets/svg/lead_icon2.svg'
import lead_icon3 from 'common/assets/svg/telegram_logo.svg'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import lead_icon5 from 'common/assets/svg/discord_logo.svg'
import lead_icon6 from 'common/assets/svg/twitter_logo.svg'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import styles from './ViewLeadsList.module.scss'
// import CustomAccuracyTag from './UI/CustomAccuracyTag'
import { TagsList } from 'features/contacts/components/TagsList/TagsList'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import { useAppSelector } from 'state'
import CustomStatusTag from './UI/CustomStatusTag'
import { getCompaniesColumns } from './Leads/LeadsTableColumns'

interface DataType {
  key: React.Key
  name: string
  company: string
  location: string
  industry: string
  businessType: string[]
  source: string
  channels: string
  enrich: string
}

const ViewLeadsList: React.FC = () => {
  const { selected_leads, are_leads_from_groups, active_platforms, ai_leads_type, stored_temp_leads } = useAppSelector(
    (state) => state.outreachAICampaign,
  )

  const [activeLeads, setActiveLeads] = useState([])
  const companies_columns = getCompaniesColumns({})

  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const instagramActive = truePlatforms.includes('instagram')

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Contact Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: (text: string, data: any) => (
        <span className='flex_items'>
          <FancyImage url={data.lead_photo} className='smallRoundPicture' iscompany={false} name={text} size={24} />
          <div className={styles.contactName}>
            <Tooltip placement='topLeft' title={text}>
              <span style={{ fontWeight: 600 }}>{text.length > 16 ? `${text.substring(0, 16)}...` : text}</span>
            </Tooltip>
            <Tooltip placement='topLeft' title={data.jobTitle}>
              <span style={{ fontSize: '12px' }}>
                {data.jobTitle.length > 15 ? `${data.jobTitle.substring(0, 15)}...` : data.jobTitle}
              </span>
            </Tooltip>
          </div>
        </span>
      ),
    },
    ...(are_leads_from_groups
      ? [
          ...(instagramActive
            ? [
                {
                  title: '# of Followers',
                  dataIndex: 'follower_count',
                  key: 'follower_count',
                  width: 150,
                  render: (follower_count: any) => <span>{Number(follower_count).toLocaleString()}</span>,
                },
              ]
            : []),
          {
            title: 'Account Status',
            dataIndex: 'is_private',
            key: 'is_private',
            width: 150,
            render: (is_private: boolean) => {
              return (
                <div className={styles.accountStatus}>
                  <img src={is_private ? group_locked_icon : group_unlocked_icon} alt='' />
                </div>
              )
            },
          },
        ]
      : [
          {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            width: 210,
            render: (text: string, data: any) => (
              <span className='flex_items'>
                {data.company_photo !== '' && (
                  <FancyImage url={data.company_photo} className='smallRoundPicture' iscompany={false} />
                )}
                <Tooltip placement='topLeft' title={text}>
                  <span>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
                </Tooltip>
              </span>
            ),
          },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: 220,
            render: (text: string, data: any) => (
              <Tooltip placement='topLeft' title={text}>
                <span>{text.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
              </Tooltip>
            ),
          },
          {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
            width: 100,
          },
          {
            title: 'Business Type',
            dataIndex: 'businessType',
            key: 'businessType',
            width: 130,
            render: (businessTypes: string[]) => (
              <div className='tblFieldText'>
                {businessTypes.length > 0 ? <TagsList tags={businessTypes} sliceNum={1} /> : <span>{''}</span>}
              </div>
            ),
          },
          // {
          //   title: 'Accuracy',
          //   dataIndex: 'accuracy',
          //   key: 'accuracy',
          //   width: 50,
          //   render: (accuracy: any) => <CustomAccuracyTag number={accuracy} />,
          // },
        ]),
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 75,
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      key: 'channels',
      width: 150,
      render: (text: string, data: any) => {
        const style = 'grayscale(100%)'
        const socialMediaIcons = [
          { platform: 'facebook', url: data.facebook, icon: lead_icon1 },
          {
            platform: 'instagram',
            url: data.instagram !== null && data.instagram !== '' ? `https://instagram.com/${data.instagram}` : '',
            icon: lead_icon2,
          },
          {
            platform: 'telegram',
            url: data.telegram !== null && data.telegram !== '' ? data.telegram : '',
            icon: lead_icon3,
          },
          {
            platform: 'linkedin',
            url: data.linkedin !== null && data.linkedin !== '' ? data.linkedin : '',
            icon: lead_icon4,
          },
          {
            platform: 'discord',
            url: data.discord !== null && data.discord !== '' ? data.discord : '',
            icon: lead_icon5,
          },
          {
            platform: 'twitter',
            url: data.twitter !== null && data.twitter !== '' ? data.twitter : '',
            icon: lead_icon6,
          },
        ]

        return (
          <div className='img_group'>
            {socialMediaIcons.map(({ platform, url, icon }) => (
              <img
                key={platform}
                onClick={() => url && window.open(url, '_blank')}
                style={{
                  filter: !url ? style : '',
                  opacity: !url ? 0.25 : 1,
                  cursor: url ? 'pointer' : 'default',
                }}
                src={icon}
                alt={platform}
              />
            ))}
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'last_step',
      key: 'last_step',
      width: 150,
      render: (text: string, data: any) => {
        return <CustomStatusTag status={text || 'Pending'} />
      },
    },
  ]

  const step_type: any = {
    telegram_outreach: 'Outreach',
    telegram_followup: 'Followup',
    instagram_follow: 'IG follow',
    instagram_send_message: 'IG DM',
    instagram_send_comment: 'Comment',
  }

  const selectedLeadsData: DataType[] = activeLeads?.map((item: any, index: any) => ({
    key: item.key || '',
    name: item.name || '',
    lead_photo: item.lead_photo,
    company: item.company || '',
    company_photo: item.company_photo,
    jobTitle: item.jobTitle || '',
    accuracy: item.accuracy,
    location: item.location || '',
    industry: item.industry || '',
    businessType: item?.businessType && Object.entries(item?.businessType).length !== 0 ? [...item?.businessType] : [],
    source: item.source,
    channels: item.channels,
    enrich: item.enrich,
    facebook: item.facebook,
    linkedin: item.linkedin,
    instagram: item.instagram,
    twitter: item.twitter,
    discord: item.discord,
    telegram: item.telegram,
    follower_count: item.follower_count, // Added for Instagram
    is_private: item.is_private, // Added for Instagram
    last_step: step_type[item.step_type] || item.last_step,
    technologies: item.technologies,
    number_of_employees: item.number_of_employees || '',
    biography: item.biography || '',
    categories: item.categories || '',
  }))

  useEffect(() => {
    // Filter out leads that are present in `stored_temp_leads`
    const filteredLeads =
      selected_leads?.filter((lead: any) => {
        return !lead.isTemp
      }) || []

    setActiveLeads(filteredLeads)
  }, [selected_leads, stored_temp_leads]) // Add dependencies to re-run on changes

  return (
    <div className={styles.container}>
      <div className={styles.matchesLine}>
        <span>
          Your Campaign has <span style={{ fontWeight: 600 }}>{activeLeads?.length} leads</span>!
        </span>
      </div>
      <Table
        dataSource={selectedLeadsData}
        columns={ai_leads_type === 'leads' ? columns : companies_columns}
        pagination={{
          total: selectedLeadsData.length,
          pageSize: 15,
          showSizeChanger: false,
        }}
        className='Leads_Table'
      />
    </div>
  )
}

export default ViewLeadsList
