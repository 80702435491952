import React from 'react'
import { Handle, Position } from 'reactflow'
import styles from './InitialNode.module.scss'
import initial_builder_node from 'common/assets/svg/initial_builder_node.svg'

const InitialNode: React.FC = () => {
  return (
    <div className={`${styles.node} `}>
      <Handle type='target' position={Position.Top} />
      <div className={styles.leftSide}>
        <img src={initial_builder_node} alt='' />
        <p>Start</p>
      </div>
      <Handle type='source' position={Position.Bottom} id='a' />
    </div>
  )
}

export default InitialNode
