import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const ChatApi: any = createApi({
  reducerPath: 'ChatApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    ////////////////////////////
    ///////   TELEGRAM   ///////
    ////////////////////////////
    initUnify: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/initUnify`,
        method: 'GET',
      }),
    }),
    unify: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unify`,
        method: 'GET',
      }),
    }),
    unifySpecificAccounts: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unifySpecificAccounts`,
        method: 'POST',
        body: {
          accounts: params.accounts,
        },
      }),
    }),
    getChat: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getChat`,
        method: 'POST',
        body: params,
      }),
    }),
    getChatMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getChatMessages`,
        method: 'POST',
        body: params,
      }),
    }),
    getDialogs: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getDialogs`,
        method: 'GET',
      }),
    }),
    getMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getMessages?dialogId=${encodeURIComponent(
          params.dialogId,
        )}&senderData=${encodeURIComponent(params.senderData)}&platform=${encodeURIComponent(params.platform)}`,
        method: 'GET',
      }),
    }),
    sendChatMessage: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/sendMessage`,
        method: 'POST',
        body: {
          dialog_id: params.dialog_id,
          message: params.message,
          senderData: params.senderData,
          platform: params.platform,
          // area_code: params.area_code,
          // number: params.number,
        },
      }),
    }),
    markDialogRead: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/markDialogAsRead`,
        method: 'POST',
        body: {
          senderData: params.senderData,
          dialog_id: params.dialog_id,
          platform: params.platform,
        },
      }),
    }),
    changeMetadata: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/changeMetadata`,
        method: 'POST',
        body: {
          senderData: params.senderData,
          dialog_id: params.dialog_id,
          platform: params.platform,
          metadata: params.metadata,
        },
      }),
    }),
    getBookmark: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getBookmark`,
        method: 'GET',
      }),
    }),
    setBookmark: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/setBookmark`,
        method: 'POST',
        body: {
          message: params.message,
          platform: params.platform,
        },
      }),
    }),
    //////////////////////////////
    ///////   INSTAGRAM   ///////
    ////////////////////////////
    unifyInstagram: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/chat/unifyInstagram`,
        method: 'GET',
      }),
    }),
    getInstagramDialogs: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getInstagramDialogs`,
        method: 'GET',
      }),
    }),
    getInstagramMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/chat/getInstagramMessages?dialogId=${encodeURIComponent(
          params.dialogId,
        )}&senderData=${encodeURIComponent(params.senderData)}`,
        method: 'GET',
      }),
    }),
    //////////////////////////////
    /////////   TAGS   //////////
    ////////////////////////////
    getAllTagByUserId: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/tag`,
        method: 'GET',
      }),
    }),

    getAllTagByUserIdAndDialogId: builder.query({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/dialog/${body.dialogId}`,
        method: 'GET',
      }),
    }),

    attachTagToDialog: builder.query({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${body.tagId}/dialog/${body.dialogId}`,
        method: 'POST',
      }),
    }),

    removeTagFromDialog: builder.query({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${param.tagId}/dialog/${param.dialogId}`,
        method: 'DELETE',
      }),
    }),

    createTag: builder.query({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag`,
        method: 'POST',
        body: param,
      }),
    }),

    updateTag: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${params.id}`,
        method: 'PATCH',
        body: params.data,
      }),
    }),

    searchTag: builder.query({
      query: (searchQuery: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag?tagName=${searchQuery}`,
        method: 'GET',
      }),
    }),

    removeTag: builder.query({
      query: (tagId: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/${tagId}`,
        method: 'DELETE',
      }),
    }),

    getTagMultiDialog: builder.query({
      query: (arrID: any) => ({
        url: `${process.env.REACT_APP_API_URL}/tag/dialog`,
        method: 'POST',
        body: {
          dialogIds: arrID,
        },
      }),
    }),
  }),
})

export const {
  // TELEGRAM
  useLazyInitUnifyQuery,
  useLazyUnifyQuery,
  useLazyUnifySpecificAccountsQuery,
  useLazyGetChatQuery,
  useLazyGetChatMessagesQuery,
  useLazyGetDialogsQuery,
  useLazyGetMessagesQuery,
  useLazySendChatMessageQuery,
  useLazyMarkDialogReadQuery,
  useLazyChangeMetadataQuery,
  useLazyGetBookmarkQuery,
  useLazySetBookmarkQuery,

  // INSTAGRAM
  useLazyUnifyInstagramQuery,
  useLazyGetInstagramDialogsQuery,
  useLazyGetInstagramMessagesQuery,

  // TAGS
  useLazyGetAllTagByUserIdQuery,
  useLazyGetAllTagByUserIdAndDialogIdQuery,
  useLazyAttachTagToDialogQuery,
  useLazyRemoveTagFromDialogQuery,
  useLazyCreateTagQuery,
  useLazyUpdateTagQuery,
  useLazySearchTagQuery,
  useLazyRemoveTagQuery,
  useLazyGetTagMultiDialogQuery,
} = ChatApi
