import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import doxxed_checked from 'common/assets/svg/doxxed_checked.svg'
import doxxed_unchecked from 'common/assets/svg/doxxed_unchecked.svg'

import { TagsList } from '../TagsList/TagsList'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import { IContactsTable } from 'features/contacts/interfaces/contacts-table.interface'
import TelegramContactBadge from '../TelegramContactBadge/TelegramContactBadge'

export const ContactsTableColumns: ColumnsType<IContactsTable> = [
  {
    title: 'Contact name',
    dataIndex: 'full_name',
    key: 'full_name',
    width: 250,
    render: (text: string, item: any) => {
      return (
        <div className='tblLogoName'>
          <TelegramContactBadge isTelegramContact={item.telegramContact} connection_level={item.connection_level || 3}>
            <FancyImage className={'mainPicture'} url={item.photo_url} />
          </TelegramContactBadge>
          <Tooltip placement='topLeft' title={text}>
            <span className='textTooltip'>{text}</span>
          </Tooltip>
        </div>
      )
    },
  },
  {
    title: (
      <Tooltip placement='top' title={'People we managed to verify their identity.'}>
        {'Doxxed'}
      </Tooltip>
    ),
    width: 80,
    dataIndex: 'is_doxed',
    key: 'is_doxed',
    render: (text: boolean, item: any) => <img src={text ? doxxed_checked : doxxed_unchecked} alt='' />,
  },
  {
    title: 'Job title',
    dataIndex: 'job_title',
    key: 'job_title',
    width: 300,
    render: (text: string, item: any) => (
      <div className='tblFieldText'>
        <Tooltip placement='topLeft' title={text ? text : item.is_tg_admin && item.bio ? item.bio : 'N/A'}>
          <span className={'textTooltip'}>
            {text
              ? text.length > 30
                ? `${text.substring(0, 30)}...`
                : text
              : item.is_tg_admin && item.bio
              ? item.bio.length > 30
                ? `${item.bio.substring(0, 30)}...`
                : item.bio
              : 'N/A'}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 370,
    render: (text: string, item: any) => (
      <div className='tblFieldText'>
        <Tooltip placement='topLeft' title={text ? text : 'N/A'}>
          <span className={'textTooltip'}>
            {text ? (text.length > 45 ? `${text.substring(0, 45)}...` : text) : 'N/A'}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Tags',
    dataIndex: 'idx',
    key: 'idx',
    width: 175,
    render: (text: string, item: any) => {
      const tagsList = JSON.parse(item?.tags)
      const tags: string[] = []
      tagsList.forEach((element: any) => {
        element.value !== '' && element.type !== 'name' && tags.push(element.value)
      })

      return <div className='tblFieldText'>{tags.length > 0 ? <TagsList tags={tags} /> : <span>{'N/A'}</span>}</div>
    },
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
    key: 'company_name',
    width: 200,
    render: (text: string, item: any) => (
      <div className='tblFieldText company_field'>
        <img src={item.company_s3_logo} alt='' />
        <Tooltip placement='topLeft' title={text}>
          <span className='asLink'>{text}</span>
        </Tooltip>
      </div>
    ),
  },
]
