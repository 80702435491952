import React, { useEffect, useMemo, useState } from 'react'
import {
  Input,
  Select,
  Switch,
  Collapse,
  Card,
  Divider,
  Empty,
  message as antMessage,
  // Slider,
  // SliderSingleProps,
  InputNumber,
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import trash_icon_gray from 'common/assets/svg/trash_icon_gray.svg'
import styles from './AIResponders.module.scss'
import { capitalize } from 'lodash'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
// import { useLazyDeleteResponderQuery, useLazyGetRespondersQuery } from 'features/settings/state/api/SettingsApi'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_BUILDER_SETUP_DATA } from '../Slice/CampaignBuilderSlice'
import { useWindowSize } from 'usehooks-ts'

const { Panel } = Collapse
const { Option } = Select

interface Responder {
  id: string
  user_id: string
  categorizer_index: string
  categorizer_name: string
  responder_type: string
  responder_message: string
  isActive?: boolean
}

interface AIRespondersProps {
  responders: Responder[]
  onOpenDrawer: () => void
}

const AIResponders: React.FC<AIRespondersProps> = ({ responders, onOpenDrawer }) => {
  const dispatch = useAppDispatch()
  const { builder_setup_data, selected_builder_node, builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const { nodes_data, flow_data, selected_node } = useAppSelector((state) => state.outreachAICampaign)
  const isCustom = !(flow_data?.nodes?.length > 0)
  // const [getResponders] = useLazyGetRespondersQuery()
  // const [deleteResponder] = useLazyDeleteResponderQuery()
  const [activeResponders, setActiveResponders] = useState<Responder[]>([])
  const [inactiveResponders, setInactiveResponders] = useState<Responder[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedType, setSelectedType] = useState<string>('All Types')
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [responderToDelete, setResponderToDelete] = useState<string>('')
  const [inputNumber, setInputNumber] = useState<number>()
  const [error, setError] = useState(false)
  // const [sliderValue, setSliderValue] = useState<any>([0, 5])
  // const recommendedRange = [0, 5]
  const { height } = useWindowSize()

  const handleChaneInputNumber = (value: any) => {
    if (typeof value === 'number') {
      setError(false)
      const { id: nodeId } = selected_builder_node || {}

      setInputNumber(value)

      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: nodeId,
          data: {
            ...builder_setup_data[nodeId],

            configuration: { max_response_time: value },
          },
        }),
      )
    } else {
      setError(true)
    }
  }

  // const handleSliderChange = (newValues: any) => {
  //   let [leftValue, rightValue] = newValues

  //   // Clamp the left handle between 0 and 1
  //   leftValue = Math.max(0, Math.min(leftValue, 1))

  //   // Clamp the right handle between 5 and 180
  //   rightValue = Math.max(5, Math.min(rightValue, 180))

  //   // Update the slider values
  //   setSliderValue([leftValue, rightValue])
  // }

  const handleDeleteResponder = async () => {
    setLoading(true)

    // Retrieve current data for the selected builder node
    const currentData = builder_setup_data[selected_builder_node?.id] || {}
    const existingResponders = currentData.responders || []

    // Filter out the responder to be deleted
    const updatedResponders = existingResponders.filter((responder: any) => responder.id !== responderToDelete)

    // Update active and inactive responders state
    const deletedResponder = existingResponders.find((responder: any) => responder.id === responderToDelete)

    if (deletedResponder) {
      if (deletedResponder.isActive) {
        setActiveResponders(activeResponders.filter((responder) => responder.id !== responderToDelete))
      } else {
        setInactiveResponders(inactiveResponders.filter((responder) => responder.id !== responderToDelete))
      }
    }

    // Dispatch the updated responders list to the global state
    dispatch(
      SET_BUILDER_SETUP_DATA({
        id: selected_builder_node?.id,
        data: {
          ...currentData,
          responders: updatedResponders,
          configuration: { max_response_time: inputNumber },
        },
      }),
    )

    // Close modal and stop loading
    setOpenModal(false)
    setLoading(false)
  }

  const handleSwitchChange = (message: Responder, isActive: boolean) => {
    const { id: nodeId } = selected_builder_node || {}
    if (!nodeId) return

    // Get the current node's responders from the global state
    const currentResponders = builder_setup_data[nodeId]?.responders || []

    if (isActive) {
      // Find if there's another active responder with the same categorizer_index
      const existingActiveResponder = currentResponders.find(
        (responder: any) => responder.categorizer_index === message.categorizer_index && responder.isActive,
      )

      if (existingActiveResponder && existingActiveResponder.id !== message.id) {
        // Replace the existing active responder with the new one
        const updatedResponders = currentResponders.map(
          (responder: any) =>
            responder.id === existingActiveResponder.id
              ? { ...responder, isActive: false } // Deactivate the existing responder
              : responder.id === message.id
              ? { ...message, isActive: true } // Activate the new one
              : responder, // Keep other responders unchanged
        )

        antMessage.warning(`Switching categorizers. Another responder with the same category is now inactive.`)

        setActiveResponders(updatedResponders.filter((responder: any) => responder.isActive))
        setInactiveResponders(updatedResponders.filter((responder: any) => !responder.isActive))

        // Dispatch the updated responder list to the global state
        dispatch(
          SET_BUILDER_SETUP_DATA({
            id: nodeId,
            data: {
              ...builder_setup_data[nodeId],
              responders: updatedResponders,
              configuration: { max_response_time: inputNumber },
            },
          }),
        )
      } else {
        // No active responder with the same category, just activate the current one
        const updatedResponders = currentResponders.map((responder: any) =>
          responder.id === message.id ? { ...message, isActive: true } : responder,
        )

        setActiveResponders(updatedResponders.filter((responder: any) => responder.isActive))
        setInactiveResponders(updatedResponders.filter((responder: any) => !responder.isActive))

        // Dispatch the updated responder list to the global state
        dispatch(
          SET_BUILDER_SETUP_DATA({
            id: nodeId,
            data: {
              ...builder_setup_data[nodeId],
              responders: updatedResponders,
              configuration: { max_response_time: inputNumber },
            },
          }),
        )
      }
    } else {
      // Deactivating a responder
      // if (activeResponders.length === 1 && activeResponders[0].id === message.id) {
      //   // Ensure there's at least one active responder
      //   antMessage.warning('At least one responder must remain active.')
      //   return
      // }

      const updatedResponders = currentResponders.map((responder: any) =>
        responder.id === message.id ? { ...message, isActive: false } : responder,
      )

      setActiveResponders(updatedResponders.filter((responder: any) => responder.isActive))
      setInactiveResponders(updatedResponders.filter((responder: any) => !responder.isActive))

      // Dispatch the updated responder list to the global state
      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: nodeId,
          data: {
            ...builder_setup_data[nodeId],
            responders: updatedResponders,
            configuration: { max_response_time: inputNumber },
          },
        }),
      )
    }
  }

  const filteredMessages = (messages: Responder[]) => {
    return messages?.filter((msg) => {
      const matchesSearch = msg.responder_message.toLowerCase().includes(searchTerm.toLowerCase())
      const matchesType = selectedType === 'All Types' || msg.responder_type === selectedType
      return matchesSearch && matchesType
    })
  }

  // Filter active and inactive messages based on search and select type
  const filteredActiveMessages = useMemo(
    () => filteredMessages(activeResponders),
    [activeResponders, searchTerm, selectedType],
  )
  const filteredInactiveResponders = useMemo(
    () => filteredMessages(inactiveResponders),
    [inactiveResponders, searchTerm, selectedType],
  )

  useEffect(() => {
    // If there's a selected node, update the local state based on the global state
    if (selected_builder_node) {
      const defaultSelectedNodeData = isCustom
        ? builder_data?.nodes?.find((node: any) => node.id === selected_builder_node.id)?.data
        : nodes_data?.find((node: any) => node.step_id === selected_builder_node.id)?.data

      const nodeData = builder_setup_data[selected_builder_node?.id] || defaultSelectedNodeData

      if (nodeData) {
        const active = nodeData?.responders?.filter((responder: any) => responder.isActive)
        const inactive = nodeData?.responders?.filter((responder: any) => !responder.isActive)
        const inputNumber = nodeData?.configuration?.max_response_time || 60

        setActiveResponders(active)
        setInactiveResponders(inactive)
        setInputNumber(inputNumber)
      }
    }
  }, [builder_setup_data, selected_builder_node, selected_node])

  const getMaxHeight = () => {
    if (height < 600) {
      return '120px' // Mobile
    } else if (height < 900) {
      return '180px' // Tablet
    } else {
      return '250px' // Desktop
    }
  }

  const renderMessageCard = (message: Responder, isActive: boolean, key: any) => (
    <Card key={key} className={`CardNoPadding`}>
      <div className={styles.card}>
        <div className={styles.header}>
          <p className={styles.categorizer}>{message.categorizer_name}</p>
          <div className={styles.actions}>
            <span className={styles.messageType}>{capitalize(message.responder_type)}</span>
            <Divider type='vertical' />
            <Switch
              size='small'
              checked={isActive}
              onChange={() => handleSwitchChange(message, !isActive)}
              className={styles.switch}
            />
            <img
              src={trash_icon_gray}
              alt=''
              onClick={() => {
                if (activeResponders?.length < 2 && message.isActive) {
                  return antMessage.warning('At least one responder must remain active.')
                } else {
                  setOpenModal(true)
                  setResponderToDelete(message.id)
                }
              }}
            />
          </div>
        </div>
        <div className={styles.message}>
          <p>{message.responder_message}</p>
        </div>
      </div>
    </Card>
  )

  // const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value} mins`

  // const marks: SliderSingleProps['marks'] = {
  //   5: <span style={{ lineHeight: '35px' }}>Min</span>,
  //   180: <span style={{ lineHeight: '35px' }}>Max</span>,
  // }

  const defaultCard = (
    <Card className={`CardNoPadding`}>
      <div className={styles.card}>
        <div className={styles.header}>
          <p className={styles.categorizer}>Anything Else</p>
          <div className={styles.actions}>
            <span className={styles.messageType}>Skip</span>
            <Divider type='vertical' />
            <Switch size='small' checked={true} className={styles.switch} disabled />
          </div>
        </div>
      </div>
    </Card>
  )

  return (
    <div className={styles.container}>
      {/* Header Section */}
      <div className={styles.headerSection}>
        <p>AI Responders ({activeResponders?.length + inactiveResponders?.length + 1})</p>
        <span className={styles.newRespondBtn} onClick={onOpenDrawer}>
          <PlusCircleOutlined style={{ color: '#7043ff', marginRight: '5px', fontSize: '16px' }} />
          New Response
        </span>
      </div>

      {/* Search and Filter Section */}
      <div className={styles.searchFilterSection}>
        <Input
          placeholder='Search for messages'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
        <Select
          defaultValue='All Types'
          className={styles.filterSelect}
          style={{ width: '180px' }}
          onChange={(value) => setSelectedType(value)}>
          <Option value='All Types'>All Types</Option>
          {/* <Option value='ai'>AI Respond</Option> */}
          <Option value='text'>Text</Option>
          <Option value='skip'>Skip</Option>
        </Select>
      </div>

      {/* Active Section */}
      <Collapse ghost defaultActiveKey={['active', 'inactive']} className='collapseNoPadding'>
        <Panel
          header={
            <div className={styles.panelHeader}>
              <p>Active</p>
              <div className={styles.circle}>
                <span>{filteredActiveMessages.length + 1}</span>
              </div>
            </div>
          }
          key='active'
          className='collapseNoPadding'>
          <div className={styles.cards} style={{ maxHeight: getMaxHeight() }}>
            {filteredActiveMessages.map((message, index) => renderMessageCard(message, true, index))}
            {defaultCard}
          </div>
        </Panel>

        {/* Inactive Section */}
        <Panel
          header={
            <div className={styles.panelHeader}>
              <p>Inactive</p>
              <div className={styles.circle}>
                <span>{filteredInactiveResponders.length}</span>
              </div>
            </div>
          }
          key='inactive'
          className={styles.panel}>
          <div className={styles.cards} style={{ maxHeight: getMaxHeight() }}>
            {filteredInactiveResponders.length > 0 ? (
              filteredInactiveResponders.map((message, index) => renderMessageCard(message, false, index))
            ) : (
              <Empty description='No inactive responders' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Panel>
        <div className={styles.configuration}>
          <div className={styles.panelHeader}>
            <p style={{ padding: '12px 0px' }}>
              Configuration
              <br />
              <br />
              <span style={{ fontWeight: 500, fontSize: '14px' }}>Max Response Time (Min)</span>
            </p>
          </div>
          <div className={styles.inputNumber}>
            <InputNumber min={5} max={180} value={inputNumber} onChange={handleChaneInputNumber} />
            {error && (
              <span style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Can't go below 5 minutes</span>
            )}
            {/* <span className={styles.minutes}>Min</span> */}
          </div>
          {/* <div className={styles.slider}>
            <Slider
              className='customTooltipSlider'
              range
              value={sliderValue}
              // defaultValue={sliderValue}
              min={0}
              max={180}
              tooltip={{ open: false, formatter }}
              marks={marks}
              onChange={handleSliderChange}
            />
            <span
              className={styles.floating}
              style={{
                left: `${(sliderValue[0] / 180) * 100 - 1}%`,
                top: '-30px',
              }}>
              {sliderValue[0]} min
            </span>
            <span
              className={styles.floating}
              style={{
                left: `${(sliderValue[1] / 180) * 100 - 1}%`,
                top: '-30px',
              }}>
              {sliderValue[1]} min
            </span>
            {sliderValue[0] === recommendedRange[0] && sliderValue[1] === recommendedRange[1] && (
              <span
                className={styles.recommended}
                style={{
                  left: `${((recommendedRange[0] + recommendedRange[1] - 4) / 2 / 180) * 100}%`,
                  top: '-17px',
                }}>
                Recommended
              </span>
            )}
          </div> */}
        </div>
      </Collapse>
      {openModal && (
        <AreYouSureModal
          open={openModal}
          title='Are you sure you want to delete this responder?'
          message='This responder will be deleted immediately. You can select it again from your stored responders.'
          onClose={() => {
            setOpenModal(false)
            setResponderToDelete('')
          }}
          onConfirm={handleDeleteResponder}
          loading={loading}
        />
      )}
    </div>
  )
}

export default AIResponders
