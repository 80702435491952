import React, { useEffect, useState } from 'react'
import { Empty, Input, Layout, Spin, Tabs } from 'antd'
import styles from './OnboardingTrack.module.scss'
import refinement_icon from 'common/assets/svg/refinement_icon.svg'
import OnboardingTrackSidebar from './OnboardingTrackSider'
import { AutoSizer, Grid } from 'react-virtualized'
import GroupCard from 'common/components/OutreachAI/CreateCampaign/UI/Groups/GroupCard'
import { LoadingOutlined } from '@ant-design/icons'

const { Header, Content } = Layout
const { TabPane } = Tabs

interface OnboardingTrackProps {
  data: any
  setTrackData: any
  userSpecificData?: any
  userHistory?: any
  isFetching: boolean
}

const OnboardingTrack: React.FC<OnboardingTrackProps> = ({
  data = [],
  setTrackData,
  userSpecificData = [],
  // userSpecificData = { facebook: { data: [], history: {} }, instagram: { data: [] } },
  userHistory,
  isFetching,
}) => {
  const [selectedContent, setSelectedContent] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [filteredData, setFilteredData] = useState<any[]>([...data, ...userSpecificData])
  const [selectedDataType, setSelectedDataType] = useState<string>('all') // 'all', 'data', 'userSpecificData'
  const [selectedPlatform, setSelectedPlatform] = useState<string>('')

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  // const handleGroupSelect = (groupUsername: string) => {
  //   setSelectedContent((prevSelectedGroups) =>
  //     prevSelectedGroups.includes(groupUsername)
  //       ? prevSelectedGroups.filter((username) => username !== groupUsername)
  //       : [...prevSelectedGroups, groupUsername],
  //   )
  // }

  const handleGroupSelect = (group: any) => {
    setSelectedContent((prevSelectedGroups: any) => {
      const groupExists = prevSelectedGroups.some(
        (g: any) => g.username === group.username && g.platform === group.platform,
      )

      if (groupExists) {
        // Remove the group if it already exists
        return prevSelectedGroups.filter((g: any) => !(g.username === group.username && g.platform === group.platform))
      } else {
        // Add the new group if it doesn't exist
        return [...prevSelectedGroups, { username: group.username, platform: group.platform }]
      }
    })
  }

  const onMenuItemChange = (item: string) => {
    switch (item) {
      case 'Explore All':
        setFilteredData([...data, ...userSpecificData])
        setSelectedDataType('all')
        break
      case 'Suggested by Convrt':
        setFilteredData(data)
        setSelectedDataType('data')
        break
      case 'From your accounts':
        setFilteredData(userSpecificData)
        setSelectedDataType('userSpecificData')
        break
      default:
        setFilteredData([...data, ...userSpecificData])
        setSelectedDataType('all')
    }
  }

  const onChannelChange = (channel: string) => {
    setSelectedPlatform(channel)

    if (channel === '') {
      setFilteredData([...data, ...userSpecificData])
    } else {
      const filteredByPlatform = [...data, ...userSpecificData].filter(
        (item) => item.platform && item.platform.toLowerCase() === channel.toLowerCase(),
      )
      setFilteredData(filteredByPlatform)
    }
  }

  useEffect(() => {
    const allData = [...data, ...userSpecificData]
    let currentData = []

    // Filter data based on selected data type
    if (selectedDataType === 'all') {
      currentData = allData
    } else if (selectedDataType === 'data') {
      currentData = data
    } else if (selectedDataType === 'userSpecificData') {
      currentData = userSpecificData
    }

    // Filter based on platform if selected
    if (selectedPlatform) {
      currentData = currentData.filter(
        (item: any) => item.platform && item.platform.toLowerCase() === selectedPlatform.toLowerCase(),
      )
    }

    // Filter based on search value
    if (searchValue.trim() !== '') {
      const lowercasedSearch = searchValue.toLowerCase()
      currentData = currentData.filter(
        (item: any) =>
          item.name?.toLowerCase().includes(lowercasedSearch) ||
          item.username?.toLowerCase().includes(lowercasedSearch) ||
          item.tags?.some((tag: any) => tag.toLowerCase().includes(lowercasedSearch)) ||
          item.description?.toLowerCase().includes(lowercasedSearch),
      )
    }

    const sortedData = [...currentData].sort((a: any, b: any) => {
      const aSelected = selectedContent.some(
        (selectedGroup: any) => selectedGroup.username === a.username && selectedGroup.platform === a.platform,
      )
      const bSelected = selectedContent.some(
        (selectedGroup: any) => selectedGroup.username === b.username && selectedGroup.platform === b.platform,
      )

      // Selected items should come first
      if (aSelected && !bSelected) return -1
      if (!aSelected && bSelected) return 1
      return 0
    })

    setFilteredData(sortedData)
  }, [searchValue, selectedPlatform, selectedDataType, data, selectedContent, isFetching])

  useEffect(() => {
    setTrackData(selectedContent)
  }, [selectedContent])

  const contentStyle: React.CSSProperties = {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    padding: '20px 20px 0px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  }

  const layoutStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
  }

  const headerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderBottom: '1px solid #EBECF0',
    padding: '20px 30px',
    paddingInline: '30px',
    display: 'flex',
    height: 80,
    alignItems: 'center',
  }

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <Tabs defaultActiveKey='1' className='OnboardingCard'>
          <TabPane tab='Groups & Pages' key='1'></TabPane>
        </Tabs>
      </Header>
      <Layout>
        <OnboardingTrackSidebar onMenuItemChange={onMenuItemChange} onChannelChange={onChannelChange} />
        <Layout>
          <Content style={contentStyle}>
            <div className={styles.topSection}>
              <p>All Groups</p>
              <Input
                prefix={<img style={{ marginRight: '4px' }} src={refinement_icon} alt='' />}
                placeholder={`Search by Name, Username, Bio or Tags`}
                onChange={onSearchChange}
              />
            </div>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ width: '100%', height: '95%' }}>
                {filteredData?.length === 0 ? (
                  <div className={styles.noData}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        userHistory?.has_more ? (
                          <div className={styles.loading}>
                            <span>Loading Data...</span>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 14, color: '#00000040' }} spin />} />
                          </div>
                        ) : (
                          <span>No data</span>
                        )
                      }
                    />
                  </div>
                ) : (
                  <AutoSizer>
                    {({ width, height }) => (
                      <Grid
                        style={{ height: height }} // Replace with better css later
                        columnCount={3} // number of columns in the grid
                        columnWidth={width / 3} // width of each column
                        height={500} // height of the grid
                        rowCount={Math.ceil(filteredData.length / 3)} // number of rows in the grid
                        rowHeight={250} // height of each row
                        width={width + 10} // total width of the grid
                        cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                          const index = rowIndex * 3 + columnIndex
                          if (index >= filteredData.length) return null
                          const group = filteredData[index]
                          // const isSelected = selectedContent.includes(group?.username)
                          const isSelected = selectedContent.some(
                            (selectedGroup: any) =>
                              selectedGroup.username === group.username && selectedGroup.platform === group.platform,
                          )

                          const padding =
                            columnIndex === 0
                              ? '0px 5px 0px 0px'
                              : columnIndex === 1
                              ? '0px 2.5px 0px 2.5px'
                              : '0px 0px 0px 5px'

                          return (
                            <div key={key} style={{ ...style, padding }}>
                              <GroupCard
                                group={group}
                                onSelect={() => handleGroupSelect(group)}
                                setSelectedGroupName={() => {}}
                                myGroups={true}
                                refinement={'searchValue'}
                                isSelected={isSelected}
                                onboarding={true}
                              />
                            </div>
                          )
                        }}
                      />
                    )}
                  </AutoSizer>
                )}
                {filteredData?.length > 0 && selectedDataType !== 'data' && userHistory?.has_more && (
                  <div className={styles.loadingMore}>
                    <span>Loading More Data...</span>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 14, color: '#00000040' }} spin />} />
                  </div>
                )}
              </div>
              {/* <div className={styles.shadow} /> */}
            </div>
          </Content>
        </Layout>
      </Layout>
      <div className={styles.selectedContent}>Selected: {selectedContent?.length}</div>
    </Layout>
  )
}

export default OnboardingTrack
