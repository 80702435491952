import React, { useEffect, useState } from 'react'
import styles from './OutreachAndChannels.module.scss'
import SocialMediaCard from './SocialMediaCard'
import SocialMediaAccounts from './SocialMediaAccounts'
import { Select } from 'antd'
// import PercentageControl from './PercentageControl'
import CustomCollapseFooter from '../UI/CustomCollapseFooter'
import { useLocalStorage } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from 'state'
import {
  RESET_CAMPAIGN_AI,
  SET_ACTIVE_PLATFORMS,
  SET_SELECTED_PLATFORMS_ACCOUNTS,
  SET_SELECT_CUSTOM_TEMPLATE,
} from '../../state/outreachAICampaignSlice'
import { post } from 'common/api/axios'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { getFacebookAccounts, getInstagramAccounts, getLinkedinAccounts } from 'common/utils/userHelpers'
import { RESET_BUILDER } from '../../Builder/Slice/CampaignBuilderSlice'
import { useLazyDeleteCampaignDataQuery } from '../../state/api/OutreachAI-API'

interface OutreachAndChannelsProps {
  onBack?: () => void
  onNext: () => void
}
const { Option } = Select

const OutreachAndChannels: React.FC<OutreachAndChannelsProps> = ({ onBack, onNext }) => {
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const { active_platforms, generate_messages, selected_platforms_accounts, prompt, campaign_id } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const [deleteCampaignData] = useLazyDeleteCampaignDataQuery()
  const { use_custom_flow } = useAppSelector((state) => state.CampaignBuilder)
  const dispatch = useAppDispatch()
  const [connections, setConnections] = useState(active_platforms)
  const [user] = useLocalStorage<any>('user', null)
  const [instagramAccounts, setInstagramAccounts] = useState(getInstagramAccounts(user))
  const [linkedinAccounts, setLinkedinAccounts] = useState(getLinkedinAccounts(user))
  const [facebookAccounts, setFacebookAccounts] = useState(getFacebookAccounts(user))
  const [checkLocalStorage, setCheckLocalStorage] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [userMetadata] = useLocalStorage<any>('user_metadata', {})

  const toggleConnection = (platform: any) => {
    setSelectedPlatform(platform)

    const atLeastOneTrue = Object.values(active_platforms).some((value) => value === true) && prompt?.id //&& !generate_messages

    if (generate_messages && !use_custom_flow && !atLeastOneTrue) {
      post('/log', {
        action: `Percentage of Outreach Accuracy`,
        data: { platform },
      })
      setConnections((prev: any) => {
        if (user?.addons?.multi_channel_campaign === true) {
          dispatch(SET_ACTIVE_PLATFORMS({ ...prev, [platform]: !prev[platform] }))
          return { ...prev, [platform]: !prev[platform] }
        } else {
          const allFalsePlatforms = Object.fromEntries(Object.keys(prev).map((key) => [key, false]))
          dispatch(SET_ACTIVE_PLATFORMS({ ...allFalsePlatforms, [platform]: !prev[platform] }))
          return { ...allFalsePlatforms, [platform]: !prev[platform] }
        }
      })
    } else if (selected_platforms_accounts[platform]?.length === 0) {
      setOpenAreYouSureModal(true)
    }
  }

  const platformAccounts: any = {
    facebook: facebookAccounts.length,
    instagram: instagramAccounts.length,
    telegram: 0,
    linkedin: linkedinAccounts?.length,
    discord: 0,
    twitter: 0,
  }

  const generateSelectOptions: any = (platform: string) => {
    let options = []
    const platforms: any = {
      instagram: instagramAccounts,
      facebook: facebookAccounts.map((account: any) => {
        const inMetadata = userMetadata?.facebook?.find(
          (metadata: any) => metadata.username.toLowerCase() === account.toLowerCase(),
        )
        return inMetadata ? `${account} (${inMetadata.contact_name})` : account
      }),
      linkedin: linkedinAccounts,
      telegram: [],
    }
    for (let i = 0; i < platformAccounts[platform]; i++) {
      options.push(
        <Option key={platforms[platform][i]} value={platforms[platform][i]}>
          {platforms[platform][i]}
        </Option>,
      )
    }
    return options
  }

  const clearNonMatchingPlatforms = (data: { [key: string]: string[] }, platform: string) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = key === platform ? data[key] : []
      return acc
    }, {} as { [key: string]: string[] })
  }

  useEffect(() => {
    setConnections(active_platforms)
    if (selectedPlatform) {
      const updatedSelectedPlatformsAccounts = clearNonMatchingPlatforms(selected_platforms_accounts, selectedPlatform)
      dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(updatedSelectedPlatformsAccounts))
    }
  }, [active_platforms])

  useEffect(() => {
    setInstagramAccounts(getInstagramAccounts(user))
    setLinkedinAccounts(getLinkedinAccounts(user))
    setFacebookAccounts(getFacebookAccounts(user))
  }, [checkLocalStorage, user])

  const handleConfirmModal = async () => {
    dispatch(RESET_CAMPAIGN_AI())
    dispatch(RESET_BUILDER())
    dispatch(dispatch(SET_SELECT_CUSTOM_TEMPLATE(null)))
    setOpenAreYouSureModal(false)
    await deleteCampaignData({ campaign_id: campaign_id }) // to change ?

    post('/log', {
      action: `Percentage of Outreach Accuracy`,
      data: { selectedPlatform },
    })
    setConnections((prev: any) => {
      if (user?.addons?.multi_channel_campaign === true) {
        dispatch(SET_ACTIVE_PLATFORMS({ ...prev, [selectedPlatform]: !prev[selectedPlatform] }))
        return { ...prev, [selectedPlatform]: !prev[selectedPlatform] }
      } else {
        const allFalsePlatforms = Object.fromEntries(Object.keys(prev).map((key) => [key, false]))
        dispatch(SET_ACTIVE_PLATFORMS({ ...allFalsePlatforms, [selectedPlatform]: !prev[selectedPlatform] }))
        return { ...allFalsePlatforms, [selectedPlatform]: !prev[selectedPlatform] }
      }
    })
  }

  return (
    <div>
      <div className={styles.top}>
        <p style={{ fontSize: '18px', color: '#091E4299', margin: 0 }}>Pick a channel to build your sequence</p>

        {/* <PercentageControl /> */}
      </div>
      <div className={styles.socialMediaCards} style={{ marginTop: '20px' }}>
        {Object.entries(connections).map(([platform, isConnected]) => (
          <SocialMediaCard
            key={platform}
            platform={platform}
            count={platformAccounts[platform]}
            isConnected={true}
            socialId={platform}
            toggleConnection={() => toggleConnection(platform)}
            setCheckLocalStorage={setCheckLocalStorage}
          />
        ))}
      </div>
      <div>
        {Object.entries(connections).map(([platform, isConnected]) =>
          isConnected ? (
            <SocialMediaAccounts
              key={platform}
              platform={platform}
              selectId={platform}
              selectOptions={generateSelectOptions(platform)}
              setCheckLocalStorage={setCheckLocalStorage}
            />
          ) : null,
        )}
      </div>
      <CustomCollapseFooter id='1' onBack={onBack} onNext={onNext} />
      <AreYouSureModal
        open={openAreYouSureModal}
        onClose={() => {
          setOpenAreYouSureModal(false)
          // setCurrentKey('2')
        }}
        onConfirm={handleConfirmModal}
        title='Changing Channels will Change The Flow'
        message='This action will erase all of your campaign.'
        confirmText={'I understand'}
      />
    </div>
  )
}

export default OutreachAndChannels
