import eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import purple_eye_icon from 'common/assets/svg/purple_eye_icon.svg'
import styles from './CustomEyeButton.module.scss'
import { Button } from 'antd'
import Link from 'antd/es/typography/Link'
import { useState } from 'react'
interface CustomEyeButtonProps {
  eyeText: string
  buttonText: string
  onEyeClick: () => void
  onButtonClick: () => void
  isHovered?: boolean
}
const componentStyle = {
  transform: 'translateY(0)',
  opacity: 0,
  bottom: '-100%',
  transition: 'transform 0.3s ease-out, opacity 0.3s ease-out' /* Smooth transition */,
  overflow: 'hidden',
}
const componentVisibleStyle = {
  transform: 'translateY(-70%)',
  opacity: 1,
  overflow: 'hidden',
  transition: 'transform 0.3s ease-out, opacity 0.3s ease-out', // Ensuring transition applies
}
export const CustomEyeButton: React.FC<CustomEyeButtonProps> = ({
  eyeText,
  buttonText,
  onEyeClick,
  onButtonClick,
  isHovered,
}) => {
  const [isEyeHovered, setIsEyeHovered] = useState(false)
  return (
    <>
      <div style={isHovered ? componentVisibleStyle : componentStyle}>
        <div
          style={{ display: 'flex', gap: 15, alignItems: 'center', justifyContent: 'center' }}
          className={styles.eyeWrapper}>
          <Link
            onClick={onEyeClick}
            onMouseEnter={() => {
              setIsEyeHovered(true)
            }}
            onMouseLeave={() => {
              setIsEyeHovered(false)
            }}>
            <img src={isEyeHovered ? purple_eye_icon : eye_icon} alt='View' className={styles.buttonImage} />
            <span>{eyeText}</span>
          </Link>
          <div className={styles.collectButton}>
            <Button type='primary' onClick={onButtonClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
