import React from 'react'
import styles from './Canvas.module.scss'

import TemplateZero from './Templates/TemplateZero'
import TemplateOne from './Templates/TemplateOne'
import TemplateTwo from './Templates/TemplateTwo'

interface CanvasProps {
  template?: any
  setCurrentSideBar: any
  delay?: any
}

const Canvas: React.FC<CanvasProps> = ({ setCurrentSideBar, template, delay }) => {
  const renderTemplate =
    template === 0 ? (
      <TemplateZero setCurrentSideBar={setCurrentSideBar} delay={delay} />
    ) : template === 1 ? (
      <TemplateOne setCurrentSideBar={setCurrentSideBar} delay={delay} />
    ) : template === 2 ? (
      <TemplateTwo setCurrentSideBar={setCurrentSideBar} delay={delay} />
    ) : null
  return <div className={styles.container}>{renderTemplate}</div>
}

export default Canvas
