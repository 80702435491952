import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Table, Input, Tooltip } from 'antd'
import leads_plus_icon from 'common/assets/svg/leads_plus_icon.svg'
import styles from './LeadsTable.module.scss'
import ImportLeadsFromListsModal from './Modals/ImportLeadsFromListsModal'
import ImportTelegramContactsModal from './Modals/ImportTelegramContactsModal'
import type { MenuProps } from 'antd'
import {
  useLazySetCampaignRecipientsQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazySetRecipientsFromCsvQuery,
  useLazyUpdateRecipientDetailsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import CustomLeadsTag from './UI/CustomLeadsTag'
import ImportLeadsFromCsvModal from './Modals/ImportLeadsFromCsvModal'
import { useAuth } from 'common/hooks/useAuth.hooks'

// import CSVImporter from '../../../csv-importer/csv-importer'
import type { TableRowSelection, TableLocale } from 'antd/es/table/interface'
import { SET_SELECTED_LEADS } from '../../state/outreachCampaignSlice'
import { useAppDispatch } from 'state'
import OutOfPointsUseRemaining from 'common/components/OutOfPoints/OutOfPointsUseRemaining'
import { InfoCircleOutlined } from '@ant-design/icons'

interface LeadsTableProps {
  data: Array<{
    key: React.Key
    campaign_id?: any
    source_name?: any
    name?: any
    company?: string
    title?: string
    handle?: string
    outcome?: any
    source?: string
    status?: string
    source_type?: string
    convrt_index?: any
    vertical?: any
    job_title?: any
    full_name?: any
    user_id?: any
    sent?: any
    replied?: any
    blocked?: any
    campaign_name?: any
    sent_from?: any
    recipient?: any
    last_step?: any
    recipient_id?: any
    sent_followup?: any
    sent_second_followup?: any
    failed?: any
    sent_time?: any
  }>
  campaign_id: string
  campaign_name: string
}

interface DataType {
  key: React.Key
  name: string
  company: string
  title: string
  handle: string
  outcome: string
  source: string
  status: string
  replied?: any
  sent?: any
}

const LeadsTable: React.FC<LeadsTableProps> = ({ data, campaign_id, campaign_name }) => {
  const [refetchUpdateRecipientDetails] = useLazyUpdateRecipientDetailsQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [costPoints, setCostPoints] = useState(0)
  // const {  campaign_id, campaign_name } = useAppSelector((state) => state.outreachCampaign)

  const [importFromListsOpen, setImportFromListsOpen] = useState(false)
  const [importTelegramOpen, setImportTelegramOpen] = useState(false)
  // eslint-disable-next-line
  const [refetcSetCampaignRecipients, { data: campaignRecipientsData }] = useLazySetCampaignRecipientsQuery()
  // eslint-disable-next-line
  const [refetcSetRecipientsFromCsv, { data: campaignRecipientsCsvData }] = useLazySetRecipientsFromCsvQuery()

  const [refetcGetCampaignRecipients, { data: tableDataTest }] = useLazyGetCampaignsRecipientsByIdQuery()
  const [tableData, setTableData] = useState(data)
  const [yAxisTableSize, setYAxisTableSize] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfPoints, setOutOfPoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCSV, setIsLoadingCSV] = useState(false)

  const [importFromCsvOpen, setImportFromCsvOpen] = useState(false)
  const [isShowWarning, setIsShowWarning] = useState<any>()
  const [warningFields, setWarningFields] = useState<any>('')
  const [lastOperation, setLastOperation] = useState<any>({
    func: null,
    params: null,
    name: null,
  })
  const [excludeLeadsInOtherCampaigns, setExcludeLeadsInOtherCampaigns] = useState(true)
  const [exclusePreviouslySent, setExclusePreviouslySent] = useState(true)
  // const [leadsTableData, setLeadsTableData] = useState<any>()
  const { userDetails } = useAuth()
  const dispatch = useAppDispatch()

  const handleOnFinishImportFromLists = async (
    contactsListIds: any,
    credits: any,
    allCntactsToggle: any,
    failedCntactsToggle: any,
    useRemainingPointsFlag?: any,
  ) => {
    setIsLoading(true)
    setLastOperation({
      func: handleOnFinishImportFromLists,
      params: {
        contactsListIds,
        credits,
        allCntactsToggle,
        failedCntactsToggle,
        useRemainingPointsFlag,
      },
      name: 'handleOnFinishImportFromLists',
    })

    const response = await refetcSetCampaignRecipients({
      contactsListIds,
      campaign_id,
      campaign_name,
      credits,
      allCntactsToggle,
      failedCntactsToggle,
      useRemainingPoints: useRemainingPointsFlag || false,
    })
    // refetching the entire data of this campaign
    // (we can do better from backend instead of multiply the refetcGetCampaignRecipients):
    await refetcGetCampaignRecipients({ campaign_id: campaign_id })

    if (response?.error?.status === 470) {
      setCostPoints(response?.error?.data?.cost_point)
      setOutOfPoints(true)
    } else {
      setImportFromListsOpen(false)
    }
    setIsLoading(false)
  }
  const handleOnFinishImportTelegram = () => {
    setImportTelegramOpen(false)
  }

  const handleOnFinishImportFromCSV = async (campaign_id_tmp: any, campaign_name_tmp: any, csvData: any) => {
    setIsLoadingCSV(true)

    setLastOperation({
      func: handleOnFinishImportFromCSV,
      params: { campaign_id, campaign_name, csvData },
    })
    const response = await refetcSetRecipientsFromCsv({
      campaign_id,
      campaign_name,
      csvData,
      exclusePreviouslySent,
      excludeLeadsInOtherCampaigns,
    })
    // refetching the entire data of this campaign
    // (we can do better from backend instead of multiply the refetcGetCampaignRecipients):
    await refetcGetCampaignRecipients({ campaign_id: campaign_id })
    if (response?.error?.status === 470) {
      setOutOfCredits(true)
    }
    setImportFromCsvOpen(false)
    setIsLoadingCSV(false)
  }

  useEffect(() => {
    const getRecipients = async () => {
      try {
        setLoadingData(true)
        const respose = await refetcGetCampaignRecipients({ campaign_id: campaign_id })
        if (respose?.data?.response) {
          setTableData(respose?.data?.response)
          setLoadingData(false)
        }
      } catch (e) {
        console.error(e)
      }
    }
    getRecipients()
  }, [])

  useEffect(() => {
    // RESIZE TABLE
    const updateYAxisTableSize = () => {
      let newSize = window.innerHeight - 200 - 170
      if (newSize < 0.2 * window.innerHeight) {
        newSize = 0.2 * window.innerHeight
      }
      setYAxisTableSize(newSize)
    }
    updateYAxisTableSize()
    window.addEventListener('resize', updateYAxisTableSize)
    return () => {
      window.removeEventListener('resize', updateYAxisTableSize)
    }
  }, [window.innerHeight, window.innerWidth])

  // useEffect(() => {
  //   setLeadsTableData(campaignRecipientsCsvData)
  // }, [campaignRecipientsCsvData])

  // useEffect(() => {
  //   setLeadsTableData(campaignRecipientsData)
  // }, [campaignRecipientsData])

  useEffect(() => {
    const custom_1 = tableDataTest?.message?.some((obj: any) => obj.message.includes('@custom_1'))
    const num_custom_1 = custom_1
      ? tableDataTest?.response.filter((obj: any) => obj.custom_1 === '' || obj.custom_1 === null).length
      : 0

    const custom_2 = tableDataTest?.message?.some((obj: any) => obj.message.includes('@custom_2'))
    const num_custom_2 = custom_2
      ? tableDataTest?.response.filter((obj: any) => obj.custom_2 === '' || obj.custom_2 === null).length
      : 0

    const customFields = []
    if (num_custom_1 > 0) {
      customFields.push('@custom_1')
    }
    if (num_custom_2 > 0) {
      customFields.push('@custom_2')
    }
    const fields = customFields.join(', ')

    setWarningFields(fields)
    setIsShowWarning(num_custom_1 + num_custom_2)
  }, [tableDataTest])

  const items: MenuProps['items'] = [
    {
      label: <span>Import From Lists</span>,
      key: '0',
    },
    // {
    //   label: <span>Import From CSV</span>,
    //   key: '2',
    // },
    // {
    //   label: <span>Import From Telegram</span>,
    //   key: '1',
    // },
  ]
  const getRecipientsWhenEdit = async () => {
    try {
      const respose = await refetcGetCampaignRecipients({ campaign_id: campaign_id })
      if (respose?.data?.response) {
        setTableData(respose?.data?.response)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleUserEdit = async (key: React.Key, e: React.ChangeEvent<HTMLInputElement>, field: any) => {
    const newName = e.target.value
    const newData = tableData.map((item) => {
      if (item.key === key) {
        return { ...item, [field]: newName }
      }
      return item
    })
    setTableData(newData)
    await refetchUpdateRecipientDetails({
      field: field,
      userId: key,
      name: newName,
      campaignId: campaign_id,
    })
    getRecipientsWhenEdit()
  }

  userDetails.addons.campaign_import_csv &&
    items.push({
      label: <span>Import From CSV</span>,
      key: '2',
    })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      width: 150,
      key: 'name',
      render: (text: string, record: any) => {
        const isEditable = !record?.sent && !record?.replied && record?.source_name === 'CSV File'
        return isEditable ? (
          <Input
            defaultValue={text}
            onBlur={(e) => {
              e.target.style.backgroundColor = 'transparent'
              handleUserEdit(record.key, e, 'full_name')
            }}
            style={{
              border: 'none',
              boxShadow: 'none',
            }}
            onFocus={(e) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: 150,
      key: 'company',
      render: (text: string, record: any) => {
        // Check if the status allows editing
        const isEditable = !record?.sent && !record?.replied && record?.source_name === 'CSV File'
        return isEditable ? (
          <Input
            defaultValue={text}
            onBlur={(e) => {
              e.target.style.backgroundColor = 'transparent'
              handleUserEdit(record.key, e, 'company')
            }}
            style={{
              border: 'none',
              boxShadow: 'none',
            }}
            onFocus={(e) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      title: 'Title',
      dataIndex: 'job_title',
      width: 150,
      key: 'job_title',
      render: (text: string, record: any) => {
        // Check if the status allows editing
        const isEditable = !record?.sent && !record?.replied && record?.source_name === 'CSV File'
        return isEditable ? (
          <Input
            defaultValue={text}
            onBlur={(e) => {
              e.target.style.backgroundColor = 'transparent'
              handleUserEdit(record.key, e, 'job_title')
            }}
            style={{
              border: 'none',
              boxShadow: 'none',
            }}
            onFocus={(e) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      // title: 'Custom Variable 1',
      title: (
        <span>
          Custom Variable 1{' '}
          <Tooltip title='You can dynamically insert this variable in the sequence message by using @custom_1'>
            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'custom_1',
      width: 150,
      key: 'custom_1',
      render: (text: string, record: any) => {
        const isExistInMessage = tableDataTest?.message?.some((obj: any) => obj.message.includes('@custom_1'))

        // const isExistInMessage = tableDataTest?.message.some((obj: any) => Object.values(obj).includes('@custom_1'))

        // Check if the status allows editing
        const isEditable = !record?.sent && !record?.replied
        return isEditable ? (
          <Input
            defaultValue={text}
            onBlur={(e) => {
              e.target.style.backgroundColor = 'transparent'
              handleUserEdit(record.key, e, 'custom_1')
            }}
            style={{
              border: isExistInMessage && (text === '' || text === null || text === ' ') ? '1px solid #da1818' : 'none',
              boxShadow: 'none',
            }}
            onFocus={(e) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      title: (
        <span>
          Custom Variable 2{' '}
          <Tooltip title='You can dynamically insert this variable in the sequence message by using @custom_2'>
            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'custom_2',
      width: 150,
      key: 'custom_2',
      render: (text: string, record: any) => {
        const isExistInMessage = tableDataTest?.message?.some((obj: any) => obj.message.includes('@custom_2'))

        // const isExistInMessage = tableDataTest?.message.some((obj: any) => Object.values(obj).includes('@custom_2'))

        const isEditable = !record?.sent && !record?.replied
        return isEditable ? (
          <Input
            defaultValue={text}
            onBlur={(e) => {
              e.target.style.backgroundColor = 'transparent'
              handleUserEdit(record.key, e, 'custom_2')
            }}
            style={{
              border: isExistInMessage && (text === '' || text === null || text === ' ') ? '1px solid #da1818' : 'none',
              boxShadow: 'none',
            }}
            onFocus={(e) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        ) : (
          <span>{text}</span>
        )
      },
    },
    {
      title: 'Handle',
      dataIndex: 'handle',
      width: 150,
      key: 'handle',
    },
    // {
    //   title: 'Outcome',
    //   dataIndex: 'outcome',
    //   width: 150,
    //   key: 'outcome',
    // },

    {
      title: 'Source',
      dataIndex: 'source_name',
      width: 150,
      key: 'source_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      key: 'status',
      render: (_: any, data: any) => {
        return (
          <div>
            <CustomLeadsTag data={data} />
          </div>
        )
      },
    },
    {
      title: (
        <Dropdown
          menu={{
            items,
            onClick: (e) =>
              e.key === '0'
                ? setImportFromListsOpen(true)
                : e.key === '1'
                ? setImportTelegramOpen(true)
                : setImportFromCsvOpen(true),
          }}
          // placement='bottom'
          placement='bottomLeft'
          arrow={false}
          trigger={['click']}
          className={styles.dropDown}
          // dropdownRender={(menu) => <div>{React.cloneElement(menu as React.ReactElement, { style: menuStyle })}</div>}
        >
          <div className={styles.plusIcon}>
            <Button>
              <img src={leads_plus_icon} alt='' />
            </Button>
          </div>
        </Dropdown>
      ),
      dataIndex: 'plus',
      width: 50,
      key: 'plus',
    },
  ]

  // const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: DataType[]) => {
  //   setSelectedRowKeys(newSelectedRowKeys)
  //   dispatch(SET_SELECTED_LEADS(newSelectedRowKeys))
  // }

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    const filteredSelectedRowKeys = selectedRowKeys.filter((key) => {
      const record = tableDataTest?.response.find((data: any) => data.key === key)
      return !(record?.replied === true || record?.sent === true)
    })
    setSelectedRowKeys(filteredSelectedRowKeys)
    dispatch(SET_SELECTED_LEADS(filteredSelectedRowKeys))
  }

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    getCheckboxProps: (record) => {
      return {
        disabled: record.replied === true || record.sent === true,
      }
    },
  }

  const customLocale: TableLocale = {
    selectNone: 'Clear All Rows',
    selectionAll: 'Select All Rows',
  }

  const retryWithRemainingPoints = () => {
    if (lastOperation.name === 'handleOnFinishImportFromLists') {
      lastOperation.func(
        lastOperation.params.contactsListIds,
        lastOperation.params.credits,
        lastOperation.params.allCntactsToggle,
        lastOperation.params.failedCntactsToggle,
        true,
      )
    } else if (lastOperation.name === 'handleOnFinishImportFromCSV') {
      lastOperation.func(
        lastOperation.params.campaign_id,
        lastOperation.params.campaign_name,
        lastOperation.params.csvData,
      )
    }
    setOutOfPoints(false)
  }

  return (
    <div className={styles.table}>
      {/* <CSVImporter /> */}
      {isShowWarning > 0 && (
        <div
          style={{
            position: 'absolute',
            bottom: '8px',
            zIndex: '1000000',
            right: '220px',
            height: '100px',
            color: '#da1818',
          }}>{`We noticed you using ${warningFields} variable, which is not fully filled, we recommending filling it on all recipients or use a different variable`}</div>
      )}
      <Table
        scroll={{ y: yAxisTableSize }}
        tableLayout='fixed'
        loading={loadingData}
        // dataSource={data}
        locale={customLocale}
        dataSource={
          tableDataTest?.response
          // leadsTableData ? [...tableDataTest?.response, ...leadsTableData?.response] : tableDataTest?.response
        }
        columns={columns}
        className='mainCampaignTable'
        rowSelection={rowSelection}
        pagination={{ pageSize: 7, position: ['bottomCenter'] }}
        rowKey='key'
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
      />
      <ImportLeadsFromListsModal
        open={importFromListsOpen}
        onClose={() => setImportFromListsOpen(false)}
        onFinish={handleOnFinishImportFromLists}
        selectedLists={tableData}
        isLoading={isLoading}
      />
      <ImportTelegramContactsModal
        open={importTelegramOpen}
        onClose={() => setImportTelegramOpen(false)}
        onFinish={handleOnFinishImportTelegram}
      />
      <ImportLeadsFromCsvModal
        open={importFromCsvOpen}
        onClose={() => setImportFromCsvOpen(false)}
        onFinish={handleOnFinishImportFromCSV}
        isLoading={isLoadingCSV}
        setExcludeLeadsInOtherCampaigns={setExcludeLeadsInOtherCampaigns}
        setExclusePreviouslySent={setExclusePreviouslySent}
        excludeLeadsInOtherCampaigns={excludeLeadsInOtherCampaigns}
        exclusePreviouslySent={exclusePreviouslySent}
      />

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          type='limit'
          creditType={'Contact'}
          caption={'CSV import'}
          // location = {'campaign'}
        />
      )}
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
        />
      )}
    </div>
  )
}

export default LeadsTable
