export const filtersKeysMap = {
  intents: 'Intents',
  companies: 'Companies',
  companyHQ: `Company's HQ Location`,
  locations: 'Location',
  onChain: 'On-Chain',
  techStack: 'Tech Stack & Tools',
  web2metrics: 'Web2 Metrics',
  adServers: 'Ad Servers',
  adNetworks: 'Ad Networks',
  affiliateAdvertisingNetwork: 'Affiliate Advertising Network',
  analyticsAndTracking: 'Analytics and Tracking',
  appointmentScheduling: 'Appointment Scheduling',
  cms: 'CMS',
  cssAndJavascriptLibraries: 'CSS and Javascript Libraries',
  callTracking: 'Call Tracking',
  captcha: 'Captcha',
  chats: 'Chats',
  cloudServices: 'Cloud Services',
  comments: 'Comments',
  contentDeliveryNetworks: 'Content Delivery Networks',
  contentSyndicationNetworks: 'Content Syndication Networks',
  customerRelationshipManagement: 'Customer Relationship Management',
  customerReviews: 'Customer Reviews',
  dataManagementPlatform: 'Data Management Platform',
  demandSidePlatforms: 'Demand Side Platforms',
  domainNameServices: 'Domain Name Services',
  'e-CommercePlatforms': 'E-Commerce Platforms',
  'e-CommerceTools': 'E-Commerce Tools',
  educationManagementSoftware: 'Education Management Software',
  emailDelivery: 'Email Delivery',
  emailMarketing: 'Email Marketing',
  emailProviders: 'Email Providers',
  eventManagement: 'Event Management',
  fonts: 'Fonts',
  frameworksAndProgrammingLanguages: 'Frameworks and Programming Languages',
  hostedForms: 'Hosted Forms',
  hosting: 'Hosting',
  leadGenerationSoftware: 'Lead Generation Software',
  liveStreamingPlatforms: 'Live Streaming Platforms',
  loadBalancers: 'Load Balancers',
  loyaltyAndGamification: 'Loyalty and Gamification',
  marketingAutomation: 'Marketing Automation',
  onlineTestingPlatforms: 'Online Testing Platforms',
  onlineVideoPlatforms: 'Online Video Platforms',
  other: 'Other',
  payments: 'Payments',
  personalization: 'Personalization',
  publisherAdvertisingTools: 'Publisher Advertising Tools',
  recruitment: 'Recruitment',
  reservationSoftware: 'Reservation Software',
  retargeting: 'Retargeting',
  sslCertificates: 'SSL Certificates',
  searchAppliances: 'Search Appliances',
  searchMarketing: 'Search Marketing',
  security: 'Security',
  shipping: 'Shipping',
  siteAndCartAbandonment: 'Site and Cart Abandonment',
  socialLogin: 'Social Login',
  socialMediaMarketing: 'Social Media Marketing',
  socialSharing: 'Social Sharing',
  supplySidePlatforms: 'Supply Side Platforms',
  supportAndFeedback: 'Support and Feedback',
  tagManagement: 'Tag Management',
  videoAdvertisingNetworks: 'Video Advertising Networks',
  webAccelerators: 'Web Accelerators',
  webPerformanceMonitoring: 'Web Performance Monitoring',
  webPresentationTools: 'Web Presentation Tools',
  widgets: 'Widgets',
  ads: 'Ads',
  analytics: 'Analytics',
  blockchain_decentralized_storage: 'Blockchain Decentralized Storage',
  blockchain_development_and_testing_tools: 'Blockchain Development and Testing Tools',
  blockchain_infra_audits: 'Blockchain Infra Audits',
  blockchain_infra_dao: 'Blockchain Infra Dao',
  blockchain_infra_oracles: 'Blockchain Infra Oracles',
  blockchain_infra_programming_languages: 'Blockchain Infra Programming Languages',
  blockchain_infra_sdks: 'Blockchain Infra SDKs',
  blockchain_infra_security: 'Blockchain Infra Security',
  blockchain_infra_standards: 'Blockchain Infra Standards',
  blockchain_payment_providers: 'Blockchain Payment Providers',
  blockchain_rpc_providers: 'Blockchain Data Providers',
  cdn: 'CDN',
  framework: 'Framework',
  mapping: 'Mapping',
  media: 'Media',
  mobile: 'Mobile',
  mx: 'MX',
  ns: 'NS',
  payment: 'Payment',
  seo_headers: 'SEO Headers',
  server: 'Server',
  shop: 'Shop',
  ssl: 'SSL',
  web_server: 'Web Server',
  defi: 'Decentralized Finance (DeFi)',
  cryptoPaymentsAndGateways: 'Cryptocurrency Payments & Gateways',
  nftAndDigitalCollectables: 'NFT & Digital Collectibles',
  paymentsAndRemittance: 'Payments & Remittance',
  miningCompanies: 'Mining Companies',
  assetManagement: 'Asset Management',
  securityAndAudits: 'Security and Audits',
  outsourceAndAgencies: 'Outsource & Agencies',
  bankingAndFinancialInstitutions: 'Banking & Financial Institutions',
  privacy: 'Privacy',
  gaming: 'Gaming',
  web2Companies: 'Web2 Companies',
  ventureCapital: 'Venture Capital & VC',
  social: 'Social',
  infrastructure: 'Infrastructure',
  vertical: 'Vertical',
  companyList: 'List',
  contactsList: 'List',
  tags: 'Tags',
  headcount: 'Headcount',
  country: 'Country',
  chains: 'Chains',
  revenue: 'On-Chain Revenue',
  monthly: 'Monthly Active Wallets',
  volume: 'Volume',
  tvl: 'TVL',
  trafficByCountry: 'Traffic By Country',
  visitorsPerMonth: 'Visitors Per Month',
  twitter: 'Twitter',
  discord: 'Discord',
  telegram: 'Telegram',
  managementLevel: 'Management Level',
  contactsCountry: `Prospect's Location`,
  departments: 'Departments',
  jobTitle: 'Job Title',
  nameOfCompany: 'Search Value',
  nameOfContact: 'Search Value',
  events: 'Event',
  interests: 'Interests',
  milestones: 'Milestones',
  total_revenue: 'Total Revenue',
  onchain: 'On-Chain',
  offchain: 'Off-Chain',
  lastFundingAmount: 'Last Funding Amount',
  totalFundingAmount: 'Total Funding Amount',
  last_round_option: 'Last Round',
  any_round_option: 'Any Round',
  last_round_items: 'Last Round - Type',
  any_round_items: 'Any Round - Type',
  last_round: 'Last Round',
  any_round: 'Any Round',
  fundingDate: 'Funding Date',
  connectPlus: 'Connect+',
  funding: 'Funding',
  companiesName: 'Company',
  foundingYear: 'Founding Year',
}
