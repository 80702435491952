import React from 'react'
import styles from './CustomStatusTag.module.scss'

interface CustomStatusTagProps {
  status: string
}

const CustomStatusTag: React.FC<CustomStatusTagProps> = ({ status }) => {
  const text = status === 'condition_checking' ? 'In Process' : status?.[0]?.toLocaleUpperCase() + status.slice(1)
  const color =
    status === 'failed'
      ? 'red'
      : status === 'completed'
      ? '#65c6f2'
      : status === 'condition_checking'
      ? '#7043ff'
      : status === 'revoked'
      ? 'red'
      : '#FFC700'
  return (
    <div className={styles.customTagStatus}>
      <span className={styles.statusDot} style={{ backgroundColor: color }}></span>
      <span className={styles.statusText}>{text}</span>
    </div>
  )
}

export default CustomStatusTag
