import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const OutreachApi: any = createApi({
  reducerPath: 'OutreachApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    /* ---------- Exposed Contacts ------- */
    getSenderList: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/senderList`,
        method: 'GET',
      }),
    }),
    // downloadCSV: builder.query({
    //   query: (params: any) => ({
    //     url: `${process.env.REACT_APP_API_URL}/campaign/downloadCSV`,
    //     method: 'GET',
    //   }),
    // }),
    downloadCSV: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/downloadCSV`,
        method: 'POST',
        body: { fileName: params.fileName },
      }),
    }),
    getCheckReplies: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/checkReplies`,
        method: 'POST',
        body: {
          outreach: params.outreach,
          max_messages_to_check: params.max_messages_to_check,
          usernames: params.usernames,
        },
      }),
    }),
    getAutoCheckReplies: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/autoCheckReplies`,
        method: 'POST',
        body: {},
      }),
    }),
    /* ---------- Exposed Contacts ------- */

    getCampaigns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaigns`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),
    getAllCampaignNames: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getAllCampaignNames`,
        method: 'GET',
      }),
    }),
    getCampaignById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignById`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getMessageSuggestions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessageSuggestions`,
        method: 'GET',
      }),
    }),
    getSender: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSender`,
        method: 'GET',
      }),
    }),
    getCampaignHeader: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignHeader`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),
    getCampaignsRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getRecipients`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    getCampaignsRecipientsById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getRecipientsByCampaign`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getCampaignsMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessages`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    setCampaignsActive: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setActive`,
        method: 'POST',
        body: { campaign_ids: params.campaign_ids, is_active: params.is_active, status: params.status },
      }),
    }),
    createCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setCampaign`,
        method: 'POST',
        body: {
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
        },
      }),
    }),
    updateCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
        },
      }),
    }),
    editCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/editCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
        },
      }),
    }),
    getLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getContactLists`,
        method: 'GET',
      }),
    }),
    setCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setRecipients`,
        method: 'POST',
        body: {
          contacts_list_ids: params.contactsListIds,
          campaign_id: params.campaign_id,
          companyIndexes: params.companyIndexes,
          campaign_name: params.campaign_name,
          credits: params.credits,
          allCntacts: params.allCntactsToggle,
          failedCntacts: params.failedCntactsToggle,
          useRemainingPoints: params.useRemainingPoints || false,
        },
      }),
    }),
    deleteCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/deleteRecipients`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          recipient_ids: params.recipient_ids,
        },
      }),
    }),
    updateRecipientOutcome: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateRecipientOutcome`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          outcome: params.outcome,
          recipient_id: params.recipient_id,
        },
      }),
    }),
    setCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setSettings`,
        method: 'POST',
        body: {
          start_date: params.start_date,
          schedule_id: params.schedule_id,
          campaign_name: params.campaign_name,
          campaign_id: params.campaign_id,
          timezone: params.timezone,
          send_after_cooldown: params.cooldownSwitch,
        },
      }),
    }),
    getCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSettings`,
        method: 'GET',
        params: {
          campaign_id: params.campaign_id,
        },
      }),
    }),
    getCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSchedule`,
        method: 'GET',
      }),
    }),
    setCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          // schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    updateCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/updateSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    sendTemplateRequest: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/sendTemplateRequest`,
        method: 'POST',
        body: {
          request: params.request,
        },
      }),
    }),
    archiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/addToArchive`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    unarchiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/unarchiveCampaign`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    getCooldowns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCooldowns`,
        method: 'GET',
      }),
    }),
    getUserDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/user/userDetails`,
        method: 'GET',
      }),
    }),
    setRecipientsFromCsv: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setRecipientsFromCSV`,
        method: 'POST',
        body: {
          recipients: params.csvData,
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          excludeLeadsInOtherCampaigns: params.excludeLeadsInOtherCampaigns,
          exclusePreviouslySent: params.exclusePreviouslySent,
        },
      }),
    }),
    /* ---------- AI ------- */
    generateWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/generate`,
        method: 'POST',
        body: {
          audience: params.audience,
          pain_points: params.pain_points,
          solution: params.solution,
          hierarchy: params.hierarchy,
        },
      }),
    }),
    assistWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/assist`,
        method: 'POST',
        body: {
          message: params.message,
          task: params.task,
        },
      }),
    }),
    sentimentWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/sentiment`,
        method: 'POST',
        body: {
          text: params.text,
          initial_message: params.initial_message,
        },
      }),
    }),
    /* ---------- AI ------- */
    updateRecipientDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/putRecipientsByCampaign`,
        method: 'PUT',
        body: { userID: params.userId, name: params.name, campaign_id: params.campaignId, field: params.field },
      }),
    }),
    getAllLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getAllLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    getCompaniesLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCompaniesLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    getFilteredLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getFilteredLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    refineList: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/refineList`,
        method: 'POST',
        body: params,
      }),
    }),
    getMessageSuggestionsAI: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessageSuggestionsAI`,
        method: 'POST',
        body: params,
      }),
    }),
    setRecipientsFromCsvAi: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setRecipientsFromCsvAI`,
        method: 'POST',
        body: params,
      }),
    }),
    setRecipientsAi: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/setRecipientsAi`,
        method: 'POST',
        body: {
          contacts_list_ids: params.contactsListIds,
          companyIndexes: params.companyIndexes,
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          credits: params.credits,
          allCntacts: params.allCntactsToggle,
          failedCntacts: params.failedCntactsToggle,
          useRemainingPoints: params.useRemainingPoints || false,
          sent_from: params.sent_from,
          active_platforms: params.active_platforms,
        },
      }),
    }),
    getCampaignSummary: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getCampaignSummary`,
        method: 'POST',
        body: params,
      }),
    }),
    exposePlatformCost: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/exposePlatformCost`,
        method: 'POST',
        body: params,
      }),
    }),
    exposePlatform: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/exposePlatform`,
        method: 'POST',
        body: params,
      }),
    }),
    exposePlatformUseRemainingPoints: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/exposePlatformUseRemainingPoints`,
        method: 'POST',
        body: params,
      }),
    }),
    saveCampaignLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveCampaignLeads`,
        method: 'POST',
        body: {
          leads: params.leads,
          campaign_id: params.campaign_id,
          mode: params.mode,
        },
      }),
    }),
    getTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getTempLeads`,
        method: 'GET',
      }),
    }),
    exposeCompanyPlatform: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/exposeCompanyPlatform`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const {
  useLazyGetSenderListQuery,
  useLazyGetCheckRepliesQuery,
  useLazyGetAutoCheckRepliesQuery,
  useLazyGetCampaignsQuery,
  useLazyGetAllCampaignNamesQuery,
  useLazyGetCampaignByIdQuery,
  useLazyGetMessageSuggestionsQuery,
  useLazyGetSenderQuery,
  useLazyGetCampaignHeaderQuery,
  useLazyGetCampaignsRecipientsQuery,
  useLazyUpdateRecipientOutcomeQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazyGetCampaignsMessagesQuery,
  useLazySetCampaignsActiveQuery,
  useLazyCreateCampaignQuery,
  useLazyUpdateCampaignQuery,
  useLazyEditCampaignQuery,
  useLazyGetLeadsQuery,
  useLazySetCampaignRecipientsQuery,
  useLazyDeleteCampaignRecipientsQuery,
  useLazySetCampaignsSettingsQuery,
  useLazyGetCampaignsSettingsQuery,
  useLazyGetCampaignScheduleQuery,
  useLazySetCampaignScheduleQuery,
  useLazyUpdateCampaignScheduleQuery,
  useLazySendTemplateRequestQuery,
  useLazyArchiveCampaignQuery,
  useLazyUnarchiveCampaignQuery,
  useLazyGetCooldownsQuery,
  useLazyGetUserDetailsQuery,
  useLazySetRecipientsFromCsvQuery,
  useLazyGenerateWithAIQuery,
  useLazyAssistWithAIQuery,
  useLazySentimentWithAIQuery,
  useLazyUpdateRecipientDetailsQuery,
  useLazyDownloadCSVQuery,
  useLazyGetAllLeadsQuery,
  useLazyGetCompaniesLeadsQuery,
  useLazyGetFilteredLeadsQuery,
  useLazyRefineListQuery,
  useLazyGetMessageSuggestionsAIQuery,
  useLazySetRecipientsFromCsvAiQuery,
  useLazySetRecipientsAiQuery,
  useLazyGetCampaignSummaryQuery,
  useLazyExposePlatformQuery,
  useLazyExposePlatformCostQuery,
  useLazyExposePlatformUseRemainingPointsQuery,
  useLazySaveCampaignLeadsQuery,
  useLazyExposeCompanyPlatformQuery,
} = OutreachApi
