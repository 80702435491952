import React, { useEffect, useRef, useState } from 'react'
import styles from './MessageSetup.module.scss'
import plus_icon from 'common/assets/svg/add_node_icon.svg'
import stars from 'common/assets/svg/loading_stars.svg'
import instagram_logo from 'common/assets/svg/instagram_logo.svg'
import facebook_logo from 'common/assets/svg/facebook_logo.svg'
import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
import { Divider, Dropdown, Mentions, Menu, Select, Tag, Tooltip, message, Tabs } from 'antd'
import { useLazyAssistWithAIQuery } from '../../state/api/OutreachAI-API'
import type { SelectProps } from 'antd'
import MessagesTemplates from './MessagesTemplates'
import { RedoOutlined, UndoOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SELECTED_BUILDER_NODE, SET_BUILDER_SETUP_DATA } from '../Slice/CampaignBuilderSlice'
import { SET_NODES_DATA } from '../../state/outreachAICampaignSlice'
import { getSenderByPlatform } from 'common/utils/campaignHelpers'

const { TabPane } = Tabs

type TagRender = SelectProps['tagRender']

const tagsOptions: SelectProps['options'] = [
  {
    value: 'instagram',
    label: (
      <span className={styles.platformTag}>
        <img src={instagram_logo} alt='' />
        <span>Instagram</span>
      </span>
    ),
  },
  {
    value: 'facebook',
    label: (
      <span className={styles.platformTag}>
        <img src={facebook_logo} alt='' />
        <span>Facebook</span>
      </span>
    ),
  },
  {
    value: 'linkedin',
    label: (
      <span className={styles.platformTag}>
        <img src={linkedin_logo} alt='' />
        <span>LinkedIn</span>
      </span>
    ),
  },
  // {
  //   value: 'telegram',
  //   label: (
  //     <span className={styles.platformTag}>
  //       <img src={instagram_logo} alt='' />
  //       <span>Telegram</span>
  //     </span>
  //   ),
  // },
]

const optionLabels = ['first_name', 'full_name', 'job_title', 'company_name', 'vertical', 'custom_1', 'custom_2']

const options = optionLabels.map((value) => ({
  value,
  label: value,
}))

const successMessageMapping: any = {
  grammar: 'Grammar fixed by Convrt AI. Your message is now polished!',
  shorten: 'Message shortened by Convrt AI. Review your concise text!',
  casual: 'Message transformed to a casual tone by Convrt AI. Check out the friendly vibe!',
  formal: 'Message elevated to formal elegance by Convrt AI. Examine the professional tone!',
}

interface MessageSetupProps {
  messageSuggestions?: any
  isMandatory?: boolean
}

const MessageSetup: React.FC<MessageSetupProps> = ({ messageSuggestions, isMandatory = true }) => {
  const dispatch = useAppDispatch()
  const [refetchAssistWithAI] = useLazyAssistWithAIQuery()
  const [loading, setLoading] = useState(false)
  const [undoStack, setUndoStack] = useState<{ [key: string]: string[] }>({ A: [], B: [], C: [] })
  const [redoStack, setRedoStack] = useState<{ [key: string]: string[] }>({ A: [], B: [], C: [] })
  const mentionsRef = useRef<any>(null)
  const { active_platforms, selected_platforms_accounts, nodes_data, flow_data } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const { selected_builder_node, builder_data, updated_builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const allSenders = Object.values(selected_platforms_accounts).flat()
  const platforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const [activeTabKey, setActiveTabKey] = useState('A')

  const isCustom = !(flow_data?.nodes?.length > 0)

  const defaultSelectedNodeData = isCustom
    ? builder_data?.nodes?.filter((node: any) => node.id === selected_builder_node.id)
    : nodes_data?.filter((node: any) => node.step_id === selected_builder_node.id)
  const defaultMessages = defaultSelectedNodeData?.[0]?.data?.messages

  const [messages, setMessages] = useState<any>({
    A: defaultMessages?.[0] || '',
    B: defaultMessages?.[1] || '',
    C: defaultMessages?.[2] || '',
  })

  const saveToUndoStack = (tab: string, currentValue: string) => {
    setUndoStack((prevStack) => ({ ...prevStack, [tab]: [...prevStack[tab], currentValue] }))
    setRedoStack((prevStack) => ({ ...prevStack, [tab]: [] }))
  }

  const handleUndo = () => {
    if (undoStack[activeTabKey].length === 0) return
    const previousValue = undoStack[activeTabKey][undoStack[activeTabKey].length - 1]
    setUndoStack((prevStack) => ({
      ...prevStack,
      [activeTabKey]: prevStack[activeTabKey].slice(0, -1),
    }))
    setRedoStack((prevStack) => ({
      ...prevStack,
      [activeTabKey]: [...prevStack[activeTabKey], messages[activeTabKey]],
    }))
    setMessages((prevMessages: any) => ({
      ...prevMessages,
      [activeTabKey]: previousValue,
    }))
  }

  const handleRedo = () => {
    if (redoStack[activeTabKey].length === 0) return
    const nextValue = redoStack[activeTabKey][redoStack[activeTabKey].length - 1]
    setRedoStack((prevStack) => ({
      ...prevStack,
      [activeTabKey]: prevStack[activeTabKey].slice(0, -1),
    }))
    setUndoStack((prevStack) => ({
      ...prevStack,
      [activeTabKey]: [...prevStack[activeTabKey], messages[activeTabKey]],
    }))
    setMessages((prevMessages: any) => ({
      ...prevMessages,
      [activeTabKey]: nextValue,
    }))
  }

  const setTextMessageWithUndo = (tab: string, newTextMessage: string) => {
    saveToUndoStack(tab, messages[tab])
    setMessages((prevMessages: any) => ({
      ...prevMessages,
      [tab]: newTextMessage,
    }))
  }

  // const handleUndo = () => {
  //   if (undoStack.length === 0) return
  //   const previousValue = undoStack[undoStack.length - 1]
  //   setUndoStack((prevStack) => prevStack.slice(0, -1))
  //   setRedoStack((prevStack) => [...prevStack, textMessage])
  //   setTextMessage(previousValue)
  // }

  // const handleRedo = () => {
  //   if (redoStack.length === 0) return
  //   const nextValue = redoStack[redoStack.length - 1]
  //   setRedoStack((prevStack) => prevStack.slice(0, -1))
  //   setUndoStack((prevStack) => [...prevStack, textMessage])
  //   setTextMessage(nextValue)
  // }

  // const setTextMessageWithUndo = (newTextMessage: string) => {
  //   saveToUndoStack(textMessage)
  //   setTextMessage(newTextMessage)
  // }

  const handleAddToComposer = (message: string) => {
    if (mentionsRef.current) {
      const currentValue = mentionsRef.current.textarea.value
      const cursorPosition = mentionsRef.current.textarea.selectionStart
      const newValue = `${currentValue.slice(0, cursorPosition)}${message} ${currentValue.slice(cursorPosition)}`
      saveToUndoStack(activeTabKey, messages[activeTabKey])
      setMessages((prevMessages: any) => ({
        ...prevMessages,
        [activeTabKey]: newValue,
      }))
    }
  }

  const handleClickAskAIButton = async (key: string) => {
    if (!messages[activeTabKey]?.trim()) {
      message.warning('Please enter a message to proceed with Convrt AI enhancements.')
      return
    }
    setLoading(true)
    try {
      const { data } = await refetchAssistWithAI({
        message: messages[activeTabKey],
        task: key,
      })
      if (data?.result) {
        saveToUndoStack(activeTabKey, messages[activeTabKey])
        setMessages((prevMessages: any) => ({
          ...prevMessages,
          [activeTabKey]: data.result,
        }))
        message.success(successMessageMapping[key])
      } else {
        message.error('Something went wrong generating your message. Please try again.')
      }
    } catch (e) {
      console.error('An error has occurred: ', e)
      message.error('Something went wrong generating your message. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleAskAIAction = ({ key }: any) => {
    switch (key) {
      case 'fix_grammar':
        handleClickAskAIButton('grammar')
        break
      case 'make_shorter':
        handleClickAskAIButton('shorten')
        break
      case 'more_casual':
        handleClickAskAIButton('casual')
        break
      case 'more_formal':
        handleClickAskAIButton('formal')
        break
      default:
        return
    }
  }

  useEffect(() => {
    if (!isCustom) {
      // Update nodes_data
      const currentNode = nodes_data?.filter((node: any) => node.step_id === selected_builder_node.id)?.[0]

      // Get new messages that are not empty strings
      let newMessages = Object.values(messages)?.filter((m: any) => m?.trim() !== '')

      const allSenders = getSenderByPlatform(selected_platforms_accounts)

      // Ensure newMessages has at least two strings
      while (newMessages.length < 1) {
        newMessages.push('')
      }

      dispatch(
        SET_NODES_DATA({
          step_id: currentNode.step_id,
          data: { ...currentNode.data, messages: newMessages, sender: allSenders },
        }),
      )
    } else {
      const { selected, ...rest } = selected_builder_node
      const updatedNode = { ...rest, data: { ...rest.data, messages: Object.values(messages) } }
      dispatch(SET_SELECTED_BUILDER_NODE(updatedNode))
      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: selected_builder_node?.id,
          data: { messages: Object.values(messages), sender: allSenders },
        }),
      )
    }
  }, [messages])

  useEffect(() => {
    const defaultSelectedUpdatedNodeData = isCustom
      ? updated_builder_data?.nodes?.filter((node: any) => node.id === selected_builder_node.id)
      : nodes_data?.filter((node: any) => node.step_id === selected_builder_node.id)
    const defaultUpdatedMessages = defaultSelectedUpdatedNodeData?.[0]?.data?.messages

    setMessages({
      A: defaultUpdatedMessages?.[0] || '',
      B: defaultUpdatedMessages?.[1] || '',
      C: defaultUpdatedMessages?.[2] || '',
    })

    setUndoStack({ A: [], B: [], C: [] })
    setRedoStack({ A: [], B: [], C: [] })
    setActiveTabKey('A')
  }, [selected_builder_node?.id])

  // useEffect(() => {
  //   if (selected_builder_node.sender?.length === 0 || !selected_builder_node.sender) {
  //     const updatedNode = { ...selected_builder_node, sender: allSenders }
  //     dispatch(SET_SELECTED_BUILDER_NODE(updatedNode))
  //     dispatch(SET_BUILDER_SETUP_DATA({ id: selected_builder_node?.id, data: { messages: [textMessage] } }))
  //   }
  // }, [])

  const countFilledMessages = () => {
    return (
      Object.values(messages)?.filter((message: any) => message?.trim() !== '')?.length < 1 &&
      messages[activeTabKey]?.trim() === ''
    )
  }

  const tagRender: TagRender = (props) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        style={{ borderRadius: '8px' }}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        color='#EBECF0'>
        {label}
      </Tag>
    )
  }

  const PersonalizationMenu = (
    <Menu onClick={({ key }) => handleAddToComposer(key)}>
      {optionLabels.map((label) => (
        <Menu.Item key={`@${label}`}>
          <Tag color='#F2F0FF' style={{ margin: '0px', padding: '5px' }}>
            <span style={{ color: '#7043ff', fontSize: '13px', fontWeight: 600 }}>@{label}</span>
          </Tag>
        </Menu.Item>
      ))}
    </Menu>
  )

  const AskAIMenu = (
    <Menu onClick={handleAskAIAction}>
      <Menu.Item key='fix_grammar'>Fix Grammar</Menu.Item>
      <Menu.Item key='make_shorter'>Make Shorter</Menu.Item>
      <Menu.Item key='more_casual'>More Casual</Menu.Item>
      <Menu.Item key='more_formal'>More Formal</Menu.Item>
    </Menu>
  )

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <p>Sender</p>
        <Select
          disabled
          mode='multiple'
          value={allSenders}
          defaultValue={allSenders}
          className='platformsSelectSetup'
          size='large'></Select>
      </div>

      <div className={styles.select}>
        <p>Platforms</p>
        <Select
          disabled
          className='platformsSelectSetup'
          mode='multiple'
          size='large'
          tagRender={tagRender}
          maxTagCount={2}
          defaultValue={platforms}
          value={platforms}
          style={{ width: '100%', borderColor: '#97A0AF' }}
          options={tagsOptions}
        />
      </div>

      <div className={styles.messageContent}>
        <div className={styles.header}>
          <p>Message Content</p>
          <div className={styles.right}>
            <Tooltip title='Undo'>
              <UndoOutlined
                onClick={handleUndo}
                style={{
                  cursor: undoStack[activeTabKey]?.length > 0 ? 'pointer' : 'not-allowed',
                  color: undoStack[activeTabKey]?.length > 0 ? 'black' : 'lightgray',
                }}
              />
            </Tooltip>
            <Divider type='vertical' />
            <Tooltip title='Redo'>
              <RedoOutlined
                onClick={handleRedo}
                style={{
                  cursor: redoStack[activeTabKey]?.length > 0 ? 'pointer' : 'not-allowed',
                  color: redoStack[activeTabKey]?.length > 0 ? 'black' : 'lightgray',
                }}
              />
            </Tooltip>
            <Divider type='vertical' />
            <span style={{ cursor: 'not-allowed', color: 'lightgray' }}>Save Template</span>
          </div>
        </div>
        <div className={styles.mentions}>
          <Tabs
            className={countFilledMessages() && isMandatory ? 'mentionsTabs mentionsError' : 'mentionsTabs'}
            activeKey={activeTabKey}
            onChange={setActiveTabKey}>
            {Object.keys(messages).map((tabKey) => (
              <TabPane tab={tabKey} key={tabKey}>
                <Mentions
                  ref={mentionsRef}
                  disabled={loading}
                  value={messages[tabKey]}
                  onChange={(value) => setTextMessageWithUndo(tabKey, value)}
                  className='inputText'
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  maxLength={5000}
                  style={{ padding: '5px 0px 50px 5px', backgroundColor: 'white' }}
                  placeholder='Please input the text content you desire. You can ask AI and add personalization. You can use @ to reference user data.'
                  options={options}
                />
              </TabPane>
            ))}
          </Tabs>
          {/* <Mentions
            ref={mentionsRef}
            disabled={loading}
            value={textMessage}
            defaultValue={selected_builder_node?.messages?.[0]}
            onChange={(value) => {
              saveToUndoStack(textMessage)
              setTextMessage(value)
            }}
            className='inputText'
            autoSize={{ minRows: 6, maxRows: 6 }}
            maxLength={5000}
            style={{ padding: '5px 0px 50px 5px', backgroundColor: 'white' }}
            placeholder='Please input the text content you desire. You can ask AI and add personalization. You can use @ to reference user data.'
            options={options}
          /> */}
          <div className={styles.mentionsSettings}>
            <Dropdown overlay={PersonalizationMenu} trigger={['click']}>
              <div className={styles.left}>
                <img src={plus_icon} alt='' />
                <span>Personalization</span>
              </div>
            </Dropdown>
            <Dropdown overlay={AskAIMenu} trigger={['click']}>
              <div className={styles.right}>
                <img src={stars} alt='' />
                <span>Ask AI</span>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className={styles.templates}>
          <p>Templates</p>
          <MessagesTemplates
            messageSuggestions={messageSuggestions}
            setTextMessage={(val: any) => setTextMessageWithUndo(activeTabKey, val)}
          />
        </div>
      </div>
    </div>
  )
}

export default MessageSetup
