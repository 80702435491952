import React, { useEffect, useState } from 'react'
import { Modal, Layout, MenuProps, Menu, Row, Col, Button, Empty, Typography, Input } from 'antd'
import styles from './CustomCampaignModal.module.scss'
import Sider from 'antd/es/layout/Sider'
import { Content, Footer } from 'antd/es/layout/layout'
import CustomTemplateCard from './CustomTemplateCard'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_CURRENT_COLLAPSE_KEY, SET_SELECT_CUSTOM_TEMPLATE } from '../../state/outreachAICampaignSlice'
import { SET_BUILDER_DATA, SET_BUILDER_EDGES, SET_BUILDER_NODES } from '../../Builder/Slice/CampaignBuilderSlice'
import { replaceMessageIds } from 'common/utils/campaignHelpers'
import useCampaignNavigation from '../../Helpers/useCampaignNavigation'
import { addInitialAndPlaceholderNodes } from '../../Builder/Utils/BuilderUtils'

const headerStyle: React.CSSProperties = {
  color: 'black',
  fontWeight: 600,
  fontSize: '18px',
  height: 70,
  paddingInline: 24,
  lineHeight: '64px',
  backgroundColor: 'white',
  borderBottom: '1px solid #DFE1E6',
  alignContent: 'center',
}

const footerStyle: React.CSSProperties = {
  color: 'black',
  fontWeight: 600,
  fontSize: '14px',
  height: 60,
  paddingInline: 24,
  backgroundColor: 'white',
  borderTop: '1px solid #DFE1E6',
  zIndex: 1,
}

const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  height: 800,
}

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  paddingLeft: 10,
  background: 'white',
  paddingTop: 10,
}

const contentStyle: React.CSSProperties = {
  maxHeight: 550,
  overflowY: 'scroll',
  flexGrow: 1,
  color: '#fff',
  padding: '2px 10px 0px 20px',
  backgroundColor: 'white',
}

const { Header } = Layout

interface CustomCampaignModalProps {
  open: boolean
  onConfirm: (name: string) => void
  onCancel: () => void
  myTemplates?: any
}

const CustomCampaignModal: React.FC<CustomCampaignModalProps> = ({ open, onConfirm, onCancel, myTemplates }) => {
  const { navigateWithCampaignId } = useCampaignNavigation()
  const [selectedTemplate, setSelectedTemplate] = useState<any>()
  const [selectedKey, setSelectedKey] = useState<string>('My Templates')
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const [search, setSearch] = useState('')
  const [templateCount, setTemplateCount] = useState(0)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const dispatch = useAppDispatch()
  const [selectedData, setSelectedData] = useState<any>()

  const customTemplatesData: any = myTemplates?.filter((temp: any) => temp.type !== 'convrt' && temp.type !== 'default')
  const convrtTemplatesData: any = myTemplates?.filter(
    (temp: any) => temp?.type === 'convrt' && temp?.platforms?.some((e: any) => truePlatforms.includes(e)),
  )

  const items: MenuProps['items'] = ['All Templates'].map((text, index) => {
    const key = String(index + 1)
    if (text === 'All Templates') {
      const children = ['My Templates']
      if (convrtTemplatesData?.length > 0) {
        children.push('Convrt Templates')
      }
      return {
        key: `sub${key}`,
        label: <span style={{ fontWeight: 600 }}>{text}</span>,
        children: children.map((temp, j) => {
          return {
            key: temp,
            label: <span style={temp === 'View All' || temp === 'View Less' ? { color: '#7043ff' } : {}}>{temp}</span>,
          }
        }),
      }
    } else {
      return {
        key: `sub${key}`,
        label: <span style={{ fontWeight: 600 }}>{text === 'Twitter' ? '(X) Twitter' : text}</span>,
      }
    }
  })
  const onSelect = (e: any) => {
    setSelectedTemplate(e)
  }

  const handleNavigateBuilder = () => {
    navigateWithCampaignId('/outreach/create-new-campaign/campaign-builder')
    onCancel()
  }

  const onSelectTemplate = () => {
    const template = selectedTemplate?.template
    const updatedNodes = replaceMessageIds(template.nodes)
    const edges = template.edges
    const { nodes: newNodes, edges: newEdges } = addInitialAndPlaceholderNodes(updatedNodes, edges)
    dispatch(SET_BUILDER_DATA({ nodes: newNodes, edges: newEdges }))
    dispatch(SET_BUILDER_NODES(newNodes))
    dispatch(SET_BUILDER_EDGES(newEdges))
    const updatedTemplates = { ...selectedTemplate, template: { nodes: newNodes, edges: newEdges } }
    dispatch(SET_SELECT_CUSTOM_TEMPLATE(updatedTemplates))
    onConfirm(selectedTemplate?.name)

    dispatch(SET_CURRENT_COLLAPSE_KEY('3'))
  }

  function isDataValid(data: any) {
    return data?.platforms?.some((platform: any) => active_platforms[platform])
  }

  // Function to check if an array of data objects is "empty"
  function isArrayEmpty(dataArray: any) {
    return dataArray?.every((data: any) => !isDataValid(data))
  }

  function onSearchChange(e: any) {
    setSearch(e.target.value)
  }
  function handleSearch() {
    const selected = selectedKey === 'My Templates' ? customTemplatesData : convrtTemplatesData
    const filtered = selected.filter((data: any) => {
      return (
        data.tags.join(',').toLowerCase().includes(search.toLowerCase()) ||
        data.name.toLowerCase().includes(search.toLowerCase())
      )
    })
    setSelectedData(filtered)
  }

  const isEmpty = selectedKey === 'My Templates' ? isArrayEmpty(customTemplatesData) : isArrayEmpty(convrtTemplatesData)

  useEffect(() => {
    if (open) {
      const selectedData = selectedKey === 'My Templates' ? customTemplatesData : convrtTemplatesData
      setSelectedData(selectedData)
      setTemplateCount(
        selectedData?.filter((data: any, index: number) => {
          const isValid = data.platforms.every((platform: any) => active_platforms[platform])
          return isValid
        }).length,
      )
    }
  }, [selectedKey, open])
  useEffect(() => {}, [selectedData])
  useEffect(() => {
    if (search === '' && open) {
      setSelectedData(selectedKey === 'My Templates' ? customTemplatesData : convrtTemplatesData)
    } else {
      if (open) {
        handleSearch()
      }
    }
  }, [search])
  return (
    <Modal
      className={`GroupsModalCustom`}
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{
        padding: '0px',
      }}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <div className={styles.header}>
            <span style={{ fontSize: '20px' }}>Select Sequence Template</span>
            <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onCancel}>
              Cancel
            </span>
          </div>
        </Header>
        <Layout>
          <Sider style={siderStyle} width={220}>
            <Menu
              defaultOpenKeys={['sub1']}
              className='groupsSidebar'
              mode='inline'
              style={{
                height: '100%',
                marginTop: 8,
                borderRight: 0,
                maxHeight: '680px',
                overflowY: 'auto',
              }}
              items={items}
              color='black'
              onClick={(e) => setSelectedKey(e.key)}
              selectedKeys={[selectedKey]}
            />
          </Sider>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              padding: '24px 10px 0px 20px',
              backgroundColor: 'white',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                justifyContent: 'center',
              }}>
              <div className={styles.searchTop}>
                <div className={styles.input_box}>
                  <Input
                    size='large'
                    onChange={onSearchChange}
                    value={search}
                    onKeyDown={(e: any) => {
                      e.key === 'Enter' && handleSearch()
                    }}
                  />
                </div>
              </div>
              <div className={styles.search}>
                <span style={{ fontSize: '15px', fontWeight: 600 }}>
                  Convrt &nbsp;&nbsp;
                  <span style={{ fontSize: '13px', fontWeight: 500 }}>{templateCount} Templates</span>
                </span>
              </div>
            </div>
            <div style={{}}>
              <Content style={contentStyle}>
                <Row gutter={20}>
                  {isEmpty ? (
                    <div className={styles.emptyState}>
                      <Empty
                        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                        imageStyle={{ height: 60 }}
                        description={
                          <Typography.Text>
                            It looks like you don't have any templates for the selected platforms yet.
                            <br />
                            <strong>Get started by creating your first template now!</strong>
                          </Typography.Text>
                        }>
                        <Button type='primary' onClick={handleNavigateBuilder}>
                          Start from Scratch
                        </Button>
                      </Empty>
                    </div>
                  ) : (
                    <>
                      {selectedData?.map((data: any, index: number) => {
                        const isValid = data.platforms.every((platform: any) => active_platforms[platform])
                        if (!isValid) return <></>

                        return (
                          <React.Fragment key={index}>
                            <Col xs={8} sm={8} md={8} lg={8}>
                              <CustomTemplateCard
                                key={index}
                                template={data}
                                onSelect={onSelect}
                                isSelected={data.id === selectedTemplate?.id}
                                onSelectTemplate={onSelectTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                selectedTemplate={selectedTemplate}
                              />
                            </Col>
                          </React.Fragment>
                        )
                      })}
                    </>
                  )}
                </Row>
              </Content>
            </div>
          </div>
        </Layout>
        <Footer style={footerStyle}>
          <div className={styles.footer}>
            <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onCancel}>
              Exit
            </span>
          </div>
        </Footer>
      </Layout>
    </Modal>
  )
}

export default CustomCampaignModal
