import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, Carousel } from 'antd'
import refine_list_warning from 'common/assets/svg/refine_list_warning.svg'
import styles from './RefineListModal.module.scss'

interface RefineListModalProps {
  open: boolean
  onConfirm: () => Promise<void>
  onCancel: () => void
}

const RefineListModal: React.FC<RefineListModalProps> = ({ open, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [, setCarouselIndex] = useState(0)
  const carouselRef = useRef<any>(null)

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
      setIsLoading(false)
      onCancel()
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (isLoading) {
      interval = setInterval(() => {
        setCarouselIndex((prevIndex) => {
          const newIndex = prevIndex + 1
          if (newIndex < messages.length) {
            if (carouselRef.current) {
              carouselRef.current.goTo(newIndex)
            }
            return newIndex
          }
          return prevIndex
        })
      }, 5000)
    }
    return () => clearInterval(interval)
  }, [isLoading])

  const messages = [
    'Please wait while Convrt AI gathers the leads...',
    'Using AI to identify the leads...',
    'Getting the relevant leads...',
    'Preparing the leads...',
  ]

  return (
    <Modal
      width={600}
      title={null}
      open={open}
      footer={null}
      closable={false}
      centered
      className={`${styles.refineListModal} refineListModal`}>
      <div className={styles.content}>
        <div className={styles.warningIcon}>
          <img src={refine_list_warning} alt='' />
        </div>
        <h2 className={styles.title}>Refine Lead List</h2>
        {isLoading ? (
          <Carousel
            autoplay={false}
            dots={false}
            ref={carouselRef}
            effect='scrollx'
            style={{ height: 22, lineHeight: 'normal' }}>
            {messages.map((message, index) => (
              <p key={index} style={{ color: '#666' }}>
                {message}
              </p>
            ))}
          </Carousel>
        ) : (
          <p className={styles.description}>Selection made on lead list. Confirm refinement? Changes will be erased.</p>
        )}
        <div className={styles.actions}>
          <Button className={styles.cancelButton} onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleConfirm} loading={isLoading}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RefineListModal
