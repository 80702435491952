import React, { useEffect, useState } from 'react'
import styles from './BuilderSider.module.scss'
import { Tabs, TabsProps } from 'antd'
import ElementsTab from './Tabs/ElementsTab'
import SetupTab from './Tabs/SetupTab'
import { useAppSelector } from 'state'
import { useLazyGetMessageSuggestionsAIQuery } from '../state/api/OutreachAI-API'
import { useLazyGetCategorizerQuery, useLazyGetRespondersQuery } from 'features/settings/state/api/SettingsApi'

// interface BuilderProps {

// }

const BuilderSider: React.FC = () => {
  const [tab, setTab] = useState('1')
  const { selected_builder_node } = useAppSelector((state) => state.CampaignBuilder)
  const [getMessageSuggestions, { data: messageSuggestions }] = useLazyGetMessageSuggestionsAIQuery()
  const [getCategorizer, { data: categorizerData }] = useLazyGetCategorizerQuery()
  const [getResponders, { data: respondersData }] = useLazyGetRespondersQuery()

  const handleChangeTab = (key: string) => {
    setTab(key)
  }

  useEffect(() => {
    if (selected_builder_node?.id) {
      handleChangeTab('2')
    } else {
      handleChangeTab('1')
    }
  }, [selected_builder_node])

  useEffect(() => {
    const getSuggestions = async () => {
      await getMessageSuggestions()
    }

    const getCat = async () => {
      await getCategorizer()
    }

    const getRes = async () => {
      await getResponders()
    }

    getCat()
    getSuggestions()
    getRes()
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className={styles.tabHeader}>Elements</span>,
      children: <ElementsTab />,
    },
    {
      key: '2',
      label: <span className={styles.tabHeader}>Setup</span>,
      children: (
        <SetupTab
          messageSuggestions={messageSuggestions}
          categorizer={categorizerData?.data || []}
          responders={respondersData || []}
        />
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <Tabs
        className='builderSider'
        defaultActiveKey={tab}
        activeKey={tab}
        centered
        items={items}
        onChange={handleChangeTab}
      />
    </div>
  )
}

export default BuilderSider
