import React, { useState } from 'react'
import styles from './ElementsTab.module.scss'
import { Collapse, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import DraggableNode from './DraggableNode'

const { Panel } = Collapse

const ElementsTab: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const nodes = {
    conditions: [
      { type: 'is_message_replied', label: 'If Replied' },
      { type: 'lead_accepted', label: 'If Accepted' },
      // { type: 'lead_accepted_follow', label: 'If Accepted' },
      // { type: 'lead_accepted_connect', label: 'If Accepted' },
      // { type: 'if_open', label: 'If Open' },
      { type: 'is_wait', label: 'Wait' },
    ],
    actions: [
      { type: 'send_message', label: 'Send Message' },
      { type: 'ai_responder', label: 'AI Responder' },
      { type: 'like_post', label: 'Like a Post' },
      { type: 'comment_on_post', label: 'Comment on a Post' },
      { type: 'connect_lead', label: 'Connect' },
      { type: 'connect_lead_with_note', label: 'Connect' },
      { type: 'disconnect_lead', label: 'Connect' },
      { type: 'follow_lead', label: 'Follow' },
      { type: 'unfollow_lead', label: 'Unfollow' },
    ],
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase())
  }

  const filterNodes = (nodeList: { type: string; label: string }[]) => {
    return nodeList.filter((node) => node.label.toLowerCase().includes(searchQuery))
  }

  const conditionNodes = filterNodes(nodes.conditions)
  const actionNodes = filterNodes(nodes.actions)

  return (
    <div className={styles.container}>
      <Input
        className={styles.input}
        placeholder='Search for elements'
        prefix={<SearchOutlined style={{ color: 'lightgray' }} />}
        onChange={handleSearchChange}
      />
      <Collapse className='elementsTab' defaultActiveKey={['2']} ghost>
        <Panel key={1} header={<p style={{ margin: 0, fontWeight: 600 }}>Conditions</p>}>
          <aside style={{ position: 'relative' }}>
            <div className={styles.nodes}>
              {conditionNodes.map((node) => (
                <DraggableNode key={node.type} type={node.type} />
              ))}
            </div>
          </aside>
        </Panel>
        <Panel key={2} header={<p style={{ margin: 0, fontWeight: 600 }}>Actions</p>}>
          <div className={styles.nodes}>
            {actionNodes.map((node) => (
              <DraggableNode key={node.type} type={node.type} />
            ))}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default ElementsTab
