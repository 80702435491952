import React from 'react'
import { TableProps, Tooltip } from 'antd'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import styles from './GroupsColumns.module.scss'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'

export const getGroupsColumns = (platform: string): TableProps<any>['columns'] => {
  const commonColumns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'name',
      width: 220,
      render: (text: string, data: any) => {
        const name = data?.name || data?.full_name
        return (
          <div className={styles.full_name}>
            <FancyImage
              url={data.profile_pic_url}
              className='smallRoundPicture'
              iscompany={false}
              style={{ marginRight: '5px' }}
              size={28}
              name={name}
            />
            <div className={styles.contactName}>
              <Tooltip placement='topLeft' title={name}>
                <span style={{ fontWeight: 600, cursor: 'pointer' }}>
                  {name?.length > 15 ? `${name.substring(0, 15)}...` : name}
                </span>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Handle',
      dataIndex: 'username',
      key: 'username',
      width: 160,
      render: (text: string, data: any) => {
        if (data.display) {
          return <span style={{ fontWeight: 600 }}>{data.username}</span>
        } else if (data?.recipient_id) {
          return <span style={{ fontWeight: 600 }}>{data.recipient}</span>
        } else {
          return (
            <span className={styles.handle}>
              {text?.length > 22 ? 'blurhandleblurhandleblur...' : 'blurhandleblurhandleblur'.slice(0, text?.length)}
            </span>
          )
        }
      },
    },
    {
      title: 'Bio',
      dataIndex: 'biography',
      key: 'biography',
      width: 800,
      render: (text: string, data: any) => (
        <Tooltip placement='top' title={text}>
          <span style={{ fontWeight: 500, cursor: 'pointer' }}>
            {text?.length > 75 ? `${text.substring(0, 75)?.trim()}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Account Status',
      dataIndex: 'is_private',
      key: 'is_private',
      width: 150,
      render: (is_private: any) => (
        <div className={styles.accountStatus}>
          <img src={is_private ? group_locked_icon : group_unlocked_icon} alt='' />
        </div>
      ),
    },
  ]

  const platformSpecificColumns: any = {
    instagram: [
      {
        title: '# of Followers',
        dataIndex: 'follower_count',
        key: 'follower_count',
        width: 150,
        render: (follower_count: any, data: any) => {
          return <span>{Number(follower_count).toLocaleString()}</span>
        },
      },
    ],
    facebook: [
      // Add Facebook specific columns here
    ],
    // Add other platforms if needed
  }

  return [...commonColumns, ...(platformSpecificColumns[platform] || [])]
}
