import React, { memo } from 'react'
import { Handle, Position, NodeProps } from 'reactflow'
import styles from './CustomNode.module.scss'
import ai_responder_icon from 'common/assets/svg/ai_responder_icon.svg'
import step_like_post from 'common/assets/svg/step_like_post.svg'
import step_comment from 'common/assets/svg/step_comment.svg'
import step_view_story from 'common/assets/svg/step_view_story.svg'
import step_send_message from 'common/assets/svg/step_send_message.svg'
import condition_is_message_replied from 'common/assets/svg/condition_is_message_replied.svg'
import condition_wait from 'common/assets/svg/condition_wait.svg'
import condition_question_mark from 'common/assets/svg/condition_question_mark.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import telegram_icon from 'common/assets/svg/telegram_icon.svg'
import lead_respond from 'common/assets/svg/lead_respond.svg'
import lead_view_dm from 'common/assets/svg/lead_view_dm.svg'
import stop_response from 'common/assets/svg/stop_response.svg'
import trash_icon from 'common/assets/svg/trash_icon.svg'
import { useAppDispatch, useAppSelector } from 'state'
import CustomSelect from './UI/CustomSelect'
import { Switch } from 'antd'
import { SET_TRIGGER, TOGGLE_NODE_DISABLED } from '../state/outreachAICampaignSlice'
import { SET_OPEN_DELETE_MODAL, SET_SELECTED_BUILDER_NODE } from '../Builder/Slice/CampaignBuilderSlice'
import { getShouldContainMessages } from 'common/utils/campaignHelpers'

interface CustomNodeProps extends NodeProps {
  id: any
  data: any
  isConnectable: any
}

const CustomNode: React.FC<CustomNodeProps> = ({ id, data, isConnectable }) => {
  // const { deleteElements } = useReactFlow()
  const dispatch = useAppDispatch()
  const { active_platforms, nodes_data, stats_data, trigger, is_campaign_running_already, prompt } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const { selected_builder_node, builder_incomplete_nodes, updated_builder_data } = useAppSelector(
    (state) => state.CampaignBuilder,
  )
  // const disabledNodes = useAppSelector((state) => state.outreachAICampaign.disabled_nodes)
  // const isChecked = !disabledNodes.includes(id)
  const { title: promptTitle } = prompt
  const isCustom = promptTitle === 'Custom'
  const platforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const hasMessages = getShouldContainMessages(data.type, platforms)

  const currentNode = isCustom
    ? updated_builder_data?.nodes?.filter((node: any) => node?.id === id)
    : nodes_data.filter((node: any) => node?.step_id === id)

  const defaultNodeData = currentNode?.[0]?.data
  const isDisabled = defaultNodeData?.disabled || false

  const incomplete = isCustom
    ? builder_incomplete_nodes?.includes(id)
    : (hasMessages && !Array.isArray(defaultNodeData?.messages)) ||
      defaultNodeData?.messages?.filter((msg: string) => msg.trim() !== '').length < 1

  // const onClick = useCallback(() => {
  //   deleteElements({ nodes: [{ id }] })
  // }, [id, deleteElements])

  const defaultIsRepliedAmount = currentNode?.[0]?.step_id === '8_2' ? 10 : 2

  const getImageByType = (type: string) => {
    switch (type) {
      case 'send_message':
        return step_send_message
      case 'ai_responder':
        return ai_responder_icon
      case 'is_message_replied':
        return condition_is_message_replied
      case 'is_wait':
        return condition_wait
      case 'view_story':
        return step_view_story
      case 'like_post':
        return step_like_post
      case 'lead_profiles':
        return condition_question_mark
      case 'comment_on_photo':
        return step_comment
      case 'comment_on_post':
        return step_comment
      case 'comment_on_story':
        return step_comment
      case 'lead_respond':
        return lead_respond
      case 'stop_response':
        return stop_response
      case 'follow_lead':
        return stop_response
      case 'connect_lead':
        return stop_response
      case 'connect_lead_with_note':
        return stop_response
      case 'disconnect_lead':
        return stop_response
      case 'follow_x_friends':
        return stop_response
      case 'unfollow_lead':
        return stop_response
      case 'lead_view_dm':
        return lead_view_dm
      case 'is_public':
        return step_view_story
      case 'lead_accepted':
      case 'lead_accepted_follow':
      case 'lead_accepted_connect':
        return condition_question_mark
      default:
        return ''
    }
  }

  // const getLogoBySubType = (subType: string) => {
  //   switch (subType) {
  //     case 'instagram':
  //       return instagram_icon
  //     case 'facebook':
  //       return facebook_icon
  //     case 'linkedin':
  //       return linkedin_icon
  //     case 'twitter':
  //       return twitter_icon
  //     case 'discord':
  //       return discord_icon
  //     case 'telegram':
  //       return telegram_icon
  //     default:
  //       return ''
  //   }
  // }

  const getLogoBySubType = (subType: any) => {
    for (const platform in subType) {
      if (subType[platform]) {
        switch (platform) {
          case 'instagram':
            return instagram_icon
          case 'facebook':
            return facebook_icon
          case 'linkedin':
            return linkedin_icon
          case 'twitter':
            return twitter_icon
          case 'discord':
            return discord_icon
          case 'telegram':
            return telegram_icon
          default:
            return ''
        }
      }
    }
    return ''
  }

  const getStatusById = (stepId: any) => {
    if (stats_data[stepId]) {
      // eslint-disable-next-line
      const { pending, completed, failed } = stats_data[stepId]

      // add pending > 0 && ...
      return (
        <>
          <div className={styles.stats} style={{ bottom: '57px' }}>
            {pending > 0 && <span>Queue: {pending}</span>}
          </div>
          <div className={styles.stats}>
            {completed > 0 && (
              <span style={{ color: '#2AA58E', outlineColor: '#2AA58E', boxShadow: '0px 0px 5px #2AA58E' }}>
                Completed: {completed}
              </span>
            )}
            {/* {pending > 0 && (
              <span style={{ color: '#DF667C', outlineColor: '#DF667C', boxShadow: '0px 0px 5px #DF667C' }}>
                Failed: {pending}
              </span>
            )} */}
            {/* 
          <span>Completed: {completed}</span> */}
          </div>
          <div className={styles.stats} style={{ bottom: '20px', left: '220px', transform: 'rotate(270deg)' }}>
            {failed > 0 && (
              <span
                style={{
                  color: '#DF667C',
                  outlineColor: '#DF667C',
                  boxShadow: '0px 0px 5px #DF667C',
                  minWidth: '50px',
                  fontSize: '8px',
                  alignItems: 'center',
                  textAlign: 'center',
                  paddingTop: '3px',
                }}>
                Failed: {failed}
              </span>
            )}
            {/* 
          <span>Completed: {completed}</span> */}
          </div>
        </>
      )
    }
    return <></>
  }

  const getTextByType = (type: string, subType: any) => {
    const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
    const firstPlatform = truePlatforms?.[0]
    const platformLogo = getLogoBySubType({ [firstPlatform]: true })

    switch (type) {
      case 'send_message':
        return (
          <div className={styles.text}>
            <p>Send Message</p>
            <span>
              {firstPlatform?.length > 0 && (
                <div className={styles.subText}>
                  <span style={{ color: '#97A0AF' }}>To</span>
                  <span>{firstPlatform?.[0].toUpperCase() + firstPlatform.slice(1)}</span>
                  <img src={platformLogo} alt={firstPlatform} />
                  {truePlatforms.length > 1 && <span>+{truePlatforms.length - 1}</span>}
                </div>
              )}
            </span>
          </div>
        )
      case 'ai_responder':
        return (
          <div className={styles.text}>
            <p>AI Responder</p>
            <span>
              {firstPlatform?.length > 0 && (
                <div className={styles.subText}>
                  <span style={{ color: '#97A0AF' }}>To</span>
                  <span>{firstPlatform?.[0].toUpperCase() + firstPlatform.slice(1)}</span>
                  <img src={platformLogo} alt={firstPlatform} />
                  {truePlatforms.length > 1 && <span>+{truePlatforms.length - 1}</span>}
                </div>
              )}
            </span>
          </div>
        )
      case 'is_message_replied':
        return (
          <div className={styles.text}>
            <p>Message Replied</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Within</span>{' '}
              <CustomSelect
                id={id}
                type='time'
                defaultValue={{
                  amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : defaultIsRepliedAmount,
                  unit: defaultNodeData?.unit || 'day',
                }}
              />
            </span>
          </div>
        )
      case 'is_wait':
        return (
          <div className={styles.text}>
            <p>Wait</p>
            <span>
              For
              <CustomSelect
                id={id}
                type='time'
                defaultValue={{
                  amount:
                    currentNode?.[0]?.extraData?.defaultAmount >= 0
                      ? currentNode?.[0]?.extraData?.defaultAmount
                      : defaultNodeData?.amount !== undefined
                      ? defaultNodeData?.amount
                      : 1,
                  unit: defaultNodeData?.unit || 'day',
                }}
              />
            </span>
          </div>
        )
      case 'view_story':
        return (
          <div className={styles.text}>
            <p>View Story</p>
          </div>
        )
      case 'like_post':
        return (
          <div className={styles.text}>
            <p>Like</p>
            <span>
              on
              <CustomSelect
                id={id}
                type='content'
                defaultValue={{
                  amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : 1,
                  unit: defaultNodeData?.unit || 'post',
                }}
              />
            </span>
          </div>
        )

      case 'lead_profiles':
        return (
          <div className={styles.text}>
            <p>Lead Profiles</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Is</span> Public and Relevant
            </span>
          </div>
        )
      case 'comment_on_photo':
        return (
          <div className={styles.text}>
            <p>Comment on a Photo</p>
            {firstPlatform?.length > 0 && (
              <div className={styles.subText}>
                <span style={{ color: '#97A0AF' }}>With</span>
                <span>{firstPlatform[0].toUpperCase() + firstPlatform.slice(1)}</span>
                <img src={platformLogo} alt={firstPlatform} />
                {truePlatforms.length > 1 && <span>+{truePlatforms.length - 1}</span>}
              </div>
            )}
          </div>
        )
      case 'comment_on_post':
        return (
          <div className={styles.text}>
            <p>Comment on a Post</p>
            <span>
              {firstPlatform?.length > 0 && (
                <div className={styles.subText}>
                  <span style={{ color: '#97A0AF' }}>On</span>
                  <span>{firstPlatform[0].toUpperCase() + firstPlatform.slice(1)}</span>
                  <img src={platformLogo} alt={firstPlatform} />
                  {truePlatforms.length > 1 && <span>+{truePlatforms.length - 1}</span>}
                </div>
              )}
            </span>
          </div>
        )
      case 'comment_on_story':
        return (
          <div className={styles.text}>
            <p>Comment on a Story</p>
            <span>
              {firstPlatform?.length > 0 && (
                <div className={styles.subText}>
                  <span style={{ color: '#97A0AF' }}>With</span>
                  <span>{firstPlatform[0].toUpperCase() + firstPlatform.slice(1)}</span>
                  <img src={platformLogo} alt={firstPlatform} />
                  {truePlatforms.length > 1 && <span>+{truePlatforms.length - 1}</span>}
                </div>
              )}
            </span>
          </div>
        )
      case 'lead_view_dm':
        return (
          <div className={styles.text}>
            <p>Lead Viewed the DM</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Within</span>{' '}
              <CustomSelect
                id={id}
                type='content'
                defaultValue={{
                  amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : 2,
                  unit: defaultNodeData?.unit || 'post',
                }}
              />
            </span>
          </div>
        )
      case 'lead_respond':
        return (
          <div className={styles.text}>
            <p>Lead Respond</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Within</span>{' '}
              <CustomSelect
                id={id}
                type='content'
                defaultValue={{
                  amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : 2,
                  unit: defaultNodeData?.unit || 'post',
                }}
              />
            </span>
          </div>
        )
      case 'stop_response':
        return (
          <div className={styles.text}>
            <p>Stop Response</p>
          </div>
        )
      case 'is_public':
        return (
          <div className={styles.text}>
            <p>Lead is Public</p>
          </div>
        )
      case 'follow_lead':
        return (
          <div className={styles.text}>
            <p>Follow Lead's Account</p>
          </div>
        )
      case 'connect_lead':
        return (
          <div className={styles.text}>
            <p>Connect with Lead's Account</p>
          </div>
        )
      case 'connect_lead_with_note':
        return (
          <div className={styles.text}>
            <p>Connect with a Message</p>
          </div>
        )
      case 'disconnect_lead':
        return (
          <div className={styles.text}>
            <p>Disconnect with the Lead</p>
          </div>
        )
      case 'follow_x_friends':
        return (
          <div className={styles.text}>
            <p>Follow Lead's Friends</p>
            <span>
              <span style={{ color: '#97A0AF' }}>Count</span>{' '}
              <CustomSelect
                id={id}
                type='count'
                defaultValue={{ amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : 2 }}
              />
            </span>
          </div>
        )
      case 'unfollow_lead':
        return (
          <div className={styles.text}>
            <p>Unfollow Lead's Account</p>
          </div>
        )
      case 'lead_accepted':
      case 'lead_accepted_connect':
      case 'lead_accepted_follow':
        return (
          <div className={styles.text}>
            <p>Lead Accepted</p>
            <span>
              Within
              <CustomSelect
                id={id}
                type='time'
                defaultValue={{
                  amount: defaultNodeData?.amount !== undefined ? defaultNodeData?.amount : 2,
                  unit: defaultNodeData?.unit || 'day',
                }}
              />
            </span>
          </div>
        )
      default:
        return ''
    }
  }

  const image = getImageByType(data.type)
  const text = getTextByType(data.type, data.subType)
  const deletableNodes = [
    'like_post',
    'comment_on_post',
    'send_message',
    'ai_responder',
    'connect_lead',
    'connect_lead_with_note',
  ]
  // const deletableNodes = ['']

  const toggleDisabledNode = () => {
    // dispatch(SET_DISABLED_NODES(id))
    const parts = id.split('_')
    parts[0] = (parseInt(parts[0], 10) + 1).toString()
    const incrementedStepId = parts.join('_')
    // dispatch(SET_DISABLED_NODES(incrementedStepId))
    dispatch(TOGGLE_NODE_DISABLED(id))
    dispatch(TOGGLE_NODE_DISABLED(incrementedStepId))
    dispatch(SET_TRIGGER(trigger + 1))
  }

  const handleOpenModal = () => {
    dispatch(SET_OPEN_DELETE_MODAL(true))
    const selectedNode = updated_builder_data?.nodes?.filter((node: any) => node.id === id)?.[0]
    dispatch(SET_SELECTED_BUILDER_NODE(selectedNode))
  }

  const conditions = ['is_message_replied', 'lead_accepted', 'is_wait']
  const path = window.location.pathname
  const disableDelete = path === '/outreach/create-new-campaign/campaign-builder'

  return (
    <div
      className={`${styles.node} ${isDisabled ? styles.disabled : ''} ${
        selected_builder_node?.id === id ? styles.outline : ''
      } ${incomplete ? styles.incomplete : ''} ${
        data.type === 'ai_responder' && selected_builder_node?.id !== id ? styles.aiResponder : ''
      }`}>
      {stats_data && path.includes('/summary') && Object.keys(stats_data).length > 0 && getStatusById(id)}
      <Handle type='target' position={Position.Top} />
      <div className={styles.leftSide}>
        <img src={image} alt='' />
        {text}
      </div>
      {!conditions.includes(data.type) && disableDelete && !is_campaign_running_already ? (
        <div className={`nopan nodrag ${styles.rightSide}`}>
          <img src={trash_icon} alt='' onClick={handleOpenModal} />
        </div>
      ) : (
        deletableNodes.includes(data?.type) &&
        !data.builder && (
          <div className={`nopan nodrag ${styles.rightSide}`}>
            {/* <img onClick={onClick} src={trash_icon} alt='' /> */}
            <Switch size='small' checked={!isDisabled} onChange={toggleDisabledNode} />
          </div>
        )
      )}
      <Handle type='source' position={Position.Bottom} id='a' />
    </div>
  )
}

export default memo(CustomNode)
