import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Typography, Dropdown, MenuProps, message, Divider } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import styles from './AdminSettings.module.scss'
import {
  useLazyArchiveUserQuery,
  useLazyDisableUserQuery,
  useLazyGetAssignedPointsQuery,
  useLazyGetAssignedSeatsQuery,
  useLazyGetUnassignedPointsQuery,
  useLazyGetUnassignedSeatsQuery,
  useLazyGetUsersListQuery,
  useLazyMakeCompanyAdminQuery,
  useLazyResetUserPasswordQuery,
} from '../state/api/SettingsApi'
import ResetPasswordModal from './Components/ResetPasswordModal'
import AssignSeatsModal from 'common/components/BuyConvrt/AssignSeatsModal'
import AddMoreSeatsModal from './Components/AddMoreSeatsModal'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useNavigate } from 'react-router-dom'
import BuyCreditsModal from 'common/components/BuyConvrt/BuyCreditsModal'
import AreYouSureModal from 'common/components/General/AreYouSureModal'

const { Title, Text } = Typography

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  custom: 'Custom',
  trial: 'Trial',
}

interface AdminSettingsProps {
  data: any
  shouldHideCheckout: any
  paymentData: any
}

const AdminSettings: React.FC<AdminSettingsProps> = ({ data, shouldHideCheckout, paymentData }) => {
  const [assignSeatsModal, setOpenAssignSeatsModal] = useState(false)
  const [archiveUserData, setArchiveUserData] = useState<any>(null)
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const [refetchDisableUser] = useLazyDisableUserQuery()
  const [refetchArchiveUser] = useLazyArchiveUserQuery() // What should this do?
  // eslint-disable-next-line
  const [refetchResetUserPassword] = useLazyResetUserPasswordQuery() // To Do
  const [refetchMakeCompanyAdmin] = useLazyMakeCompanyAdminQuery() // To Do
  const [refetchUsersList] = useLazyGetUsersListQuery()
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>()
  // const [seatsToAssign, setSeatsToAssign] = useState<any>()
  const [yAxisTableSize, setYAxisTableSize] = useState<number>(window.innerHeight - 320)
  const [refetchGetUnassignedSeats, { data: seatsToAssign }] = useLazyGetUnassignedSeatsQuery()
  const [refetchGetAssignedSeats, { data: assignedSeats }] = useLazyGetAssignedSeatsQuery()
  const [refetchGetUnassignedPoints, { data: pointsToAssign }] = useLazyGetUnassignedPointsQuery()
  const [refetchGetAssignedPoints, { data: assignedPoints }] = useLazyGetAssignedPointsQuery()
  // eslint-disable-next-line
  const [userToUpgrade, setUserToUpgare] = useState<any>()
  const [openAddSeatsModal, setOpenAddSeatsModal] = useState(false)
  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)
  const { userDetails } = useAuth()
  const navigate = useNavigate()

  const items: MenuProps['items'] = [
    {
      label: <span>Disable/Enable User</span>,
      key: '0',
    },
    {
      label: <span>Archive User</span>,
      key: '1',
    },
    // {
    //   label: <span>Reset Password</span>,
    //   key: '2',
    // },
    // {
    //   label: <span>Make Company Admin</span>,
    //   key: '3',
    // },
  ]

  const handleDropdownClick = async (e: any, data: any) => {
    // const userId = data.id
    switch (e.key) {
      case '0': // Disable User
        const resultDelete = await refetchDisableUser({ userData: data })
        if (resultDelete?.status !== 'rejected') {
          await refetchUsersList()
          message.success(`${data?.disabled ? 'Enabled' : 'Disabled'} Successfully`)
        } else {
          message.error('Something went wrong. Please try again.')
        }
        return
      case '1': // Archive User
        if (data.company_admin) {
          return message.error("You can't archive an admin!")
        }
        setArchiveUserData(data)
        setOpenAreYouSureModal(true)
        return
      case '2': // Reset Password
        // const resetPasswordResult = await refetchResetUserPassword({ userData: data })
        setOpenResetPasswordModal(true)
        setSelectedUser(data)

        return
      case '3': // Make Company Admin
        await refetchMakeCompanyAdmin({ userData: data })

        return
      default:
        return null
    }
  }

  const handleArchiveUser = async () => {
    const resultArchive = await refetchArchiveUser({ userData: archiveUserData })
    if (resultArchive?.status !== 'rejected') {
      await refetchUsersList()
      setOpenAreYouSureModal(false)
      await refetchGetUnassignedSeats()
      await refetchGetAssignedSeats()
      await refetchGetUnassignedPoints()
      await refetchGetAssignedPoints()
      message.success(`${archiveUserData.name} was Archived Successfully`)
    } else {
      message.error('Something went wrong. Please try again.')
    }
  }

  const columns = [
    {
      title: <div className={styles.column}>{'Seat #'}</div>,
      dataIndex: 'premium_seat_id',
      key: 'premium_seat_id',
      render: (seatNumber: number) => (
        <div className={styles.column}>
          <span>{seatNumber}</span>
        </div>
      ),
    },
    {
      title: <div className={styles.column}>{'Email'}</div>,
      dataIndex: 'email',
      key: 'email',
      render: (email: number) => (
        <div className={styles.column}>
          <span>{email}</span>
        </div>
      ),
    },
    {
      title: <div className={styles.column}>{'Points'}</div>,
      dataIndex: 'initial_convrt_points',
      key: 'initial_convrt_points',
      render: (text: number) => (
        <div className={styles.column}>
          <span>{text}</span>
          {/* <Text
            style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }}
            onClick={() => {
              setOpenBuyCreditsModal(true)
            }}>
            Add Points
          </Text> */}
        </div>
      ),
    },
    {
      title: <div className={styles.column}>{'Points Used'}</div>,
      dataIndex: 'convrt_points',
      key: 'convrt_points',
      render: (convrt_points: number, data: any) => (
        <div className={styles.column}>{data?.initial_convrt_points - convrt_points}</div>
      ),
    },
    // {
    //   title: <div className={styles.column}>{'Contacts Outreached'}</div>,
    //   dataIndex: 'contactsOutreached',
    //   key: 'contactsOutreached',
    //   render: (contactsOutreached: number) => <div className={styles.column}>{contactsOutreached}</div>,
    // },
    {
      title: <div className={styles.column}>{'Plan'}</div>,
      dataIndex: 'plan_type',
      key: 'plan_type',
      render: (plan_type: string, data: any) => (
        <div className={styles.column}>
          <span>{planMapping[plan_type]}</span>
          {!shouldHideCheckout && data.plan_type !== 'market_dominance' && data.plan_type !== 'custom' && (
            <Text
              style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }}
              onClick={() => {
                // setOpenAssignSeatsModal(true)
                navigate('/checkout')
                // setOpenUpgradePlanModal(true)
                // setUserToUpgare(data)
              }}>
              Upgrade
            </Text>
          )}
        </div>
      ),
    },
    {
      title: <div className={styles.column}>{'User Type'}</div>,
      dataIndex: 'company_admin',
      key: 'company_admin',
      render: (userType: number, data: any) => (
        <div className={styles.column}>{userType ? 'Admin' : `User ${data.disabled ? '(Disabled)' : ''}`}</div>
      ),
    },
    {
      title: <div className={styles.column}>{'Settings'}</div>,
      dataIndex: 'settings',
      key: 'settings',
      render: (_: any, data: any) => (
        <div className={styles.column}>
          <Dropdown
            menu={{
              items,
              onClick: (e) => handleDropdownClick(e, data),
              // onClick: (e) =>
              // e.key === '0' ? handleEditCampaign(item) : e.key === '1' ? handleArchiveCampaignModal(item) : null,
            }}
            placement='bottom'
            arrow={true}
            trigger={['click']}
            className={styles.dropDown}>
            <SettingOutlined style={{ color: '#7043ff' }} className={styles.settingsIcon} />
          </Dropdown>
        </div>
      ),
    },
  ]

  useEffect(() => {
    // RESIZE TABLE
    const updateYAxisTableSize = () => {
      const settingsCardElement =
        document.querySelector('[class^="AdminSettings_adminSettingsCard"]')?.clientHeight || 0
      let newSize = window.innerHeight - settingsCardElement - 320
      if (newSize < 0.2 * window.innerHeight) {
        newSize = 0.2 * window.innerHeight
      }
      setYAxisTableSize(newSize)
    }
    updateYAxisTableSize()
    window.addEventListener('resize', updateYAxisTableSize)
    return () => {
      window.removeEventListener('resize', updateYAxisTableSize)
    }
  }, [window.innerHeight, window.innerWidth])

  useEffect(() => {
    const getSeats = async () => {
      await refetchGetUnassignedSeats()
      await refetchGetAssignedSeats()
      await refetchGetUnassignedPoints()
      await refetchGetAssignedPoints()
    }
    getSeats()
  }, [])

  // const handleNavigation = () => {
  //   if (shouldHideCheckout) {
  //     return
  //   } else {
  //     navigate('/checkout')
  //   }
  // }

  const handlAddSeats = () => {
    if (shouldHideCheckout) {
      return
    } else {
      setOpenAddSeatsModal(true)
    }
  }

  const totalPointsToAssign =
    pointsToAssign?.data?.reduce((acc: any, item: any) => {
      if (item.product_type === 'points') {
        return acc + parseInt(item.product, 10)
      }
      return acc
    }, 0) || 0

  const totalAssignedPoints =
    assignedPoints?.data?.reduce((acc: any, item: any) => {
      if (item.product_type === 'points') {
        return acc + parseInt(item.product, 10)
      }
      return acc
    }, 0) || 0

  return (
    <Card className={styles.adminSettingsCard}>
      <div className={styles.header}>
        <Title level={3}>{userDetails?.company}'s Cockpit Settings</Title>
        <div className={styles.rightHeader}>
          {totalPointsToAssign !== 0 && (
            <>
              <span style={{ fontWeight: 600 }}>
                {totalAssignedPoints} / {totalAssignedPoints + totalPointsToAssign} Assigned Points
              </span>
              <Divider type='vertical' />
            </>
          )}
          {seatsToAssign?.length !== 0 && seatsToAssign !== undefined && (
            <span style={{ fontWeight: 600, marginRight: '10px' }}>
              {assignedSeats?.length || 0} / {assignedSeats?.length + seatsToAssign?.length || 0} Assigned Seats
            </span>
          )}
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              setOpenAssignSeatsModal(true)
            }}>
            Assign Seats & Points
          </Button>
          {!shouldHideCheckout && <Button onClick={handlAddSeats}>Add Seats</Button>}
        </div>
      </div>
      <Table dataSource={data} columns={columns} pagination={false} scroll={{ y: yAxisTableSize }} />
      {/* <div className={styles.column}>
        <Button className={styles.getMoreSeatsButton} onClick={() => navigate('/checkout')} type='primary' size='large'>
          Get More Seats
        </Button>
      </div> */}
      {openResetPasswordModal && (
        <ResetPasswordModal
          open={openResetPasswordModal}
          onCancel={() => setOpenResetPasswordModal(false)}
          data={selectedUser}
        />
      )}
      {assignSeatsModal && (
        <AssignSeatsModal
          open={assignSeatsModal}
          onClose={() => {
            setOpenAssignSeatsModal(false)
          }}
          seatsToAssign={seatsToAssign}
          assignedSeats={assignedSeats}
          pointsToAssign={pointsToAssign?.data}
          assignedPoints={assignedPoints}
          allEmails={pointsToAssign?.allEmails}
        />
      )}
      {openAddSeatsModal && (
        <AddMoreSeatsModal
          open={openAddSeatsModal}
          onClose={() => setOpenAddSeatsModal(false)}
          defaultPaymentMethod={paymentData}
        />
      )}
      {/* {openUpgradePlanModal && (
        <UpgradePlanModal
          open={openUpgradePlanModal}
          onClose={() => {
            setOpenUpgradePlanModal(false)
          }}
          defaultPaymentMethod={paymentData}
          usersToUpgrade={[]}
          // userToUpgrade={userToUpgrade}
        />
      )} */}
      {openBuyCreditsModal && (
        <BuyCreditsModal
          open={openBuyCreditsModal}
          onClose={() => {
            setOpenBuyCreditsModal(false)
          }}
        />
      )}
      {openAreYouSureModal && (
        <AreYouSureModal
          open={openAreYouSureModal}
          onClose={() => setOpenAreYouSureModal(false)}
          onConfirm={handleArchiveUser}
          title={`Are you sure you want to archive ${archiveUserData?.name || 'this user'}?`}
          message={`After archiving, ${
            archiveUserData?.name || 'the user'
          }'s seat & points will be released, so you can re-assign them later.`}
          widthStyle={600}
        />
      )}
    </Card>
  )
}

export default AdminSettings
