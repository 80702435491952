import React, { useEffect, useState } from 'react'
// eslint-disable-next-line
import { Menu, MenuProps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import vectorBlack from 'common/assets/svg/vector_black.svg'
import vectorWhite from 'common/assets/svg/vector_white.svg'
// import lightBulbWhite from 'common/assets/svg/Lightbulb.svg'
// import lightBulbBlack from 'common/assets/svg/LightbulbBlack.svg'
// import contactsWhite from 'common/assets/svg/contactsWhite.svg'
// import contactsBlack from 'common/assets/svg/contactsBlack.svg'
import chatsBlack from 'common/assets/svg/chatsBlack.svg'
import chatsWhite from 'common/assets/svg/chatsWhite.svg'
import tasksBlack from 'common/assets/svg/tasks_tab_icon_black.svg'
import tasksWhite from 'common/assets/svg/tasks_tab_icon_white.svg'
import homeBlack from 'common/assets/svg/home_tab_icon_black.svg'
import homeWhite from 'common/assets/svg/home_tab_icon_white.svg'
// import eventsWhite from 'common/assets/svg/meetingsWhite.svg'
// import eventsBlack from 'common/assets/svg/meetingsBlack.svg'
import { MenuServices } from 'common/services/menu-services'
import { useAppSelector } from 'state'

import styles from './top-menu.module.scss'
import ExitModal from '../OutreachCampaign/NewCampaign/UI/ExitModal'
import { useLocalStorage } from 'usehooks-ts'

interface ITopMenu {
  collapsed: boolean
  selectedKey: string
  callbackOnChange?: (value?: string) => void
}

const TopMenu: React.FC<ITopMenu> = ({ collapsed, callbackOnChange, selectedKey }) => {
  const [openExitModal, setOpenExitModal] = useState(false)
  const [localUser] = useLocalStorage<any>('user', null)
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)
  const [blockNavigation, setBlockNavigation] = useState(false)

  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const [navigateTo, setNavigateTo] = useState('')

  type MenuItem = Required<MenuProps>['items'][number]

  const location = useLocation()

  // const lightBulb =
  //   location.pathname.includes('/companies') || location.pathname.includes('/contacts')
  //     ? lightBulbBlack
  //     : lightBulbWhite
  // const contacts = location.pathname.includes('/contacts') ? contactsBlack : contactsWhite
  // const events = location.pathname.includes('/events') ? eventsBlack : eventsWhite
  // const exposedContacts = location.pathname.includes('/exposedContacts') ? contactsBlack : contactsWhite
  const unifiedInbox = location.pathname.includes('/unifiedInbox') ? chatsBlack : chatsWhite
  const outreach = location.pathname.includes('/outreach') ? vectorBlack : vectorWhite
  const tasks = location.pathname.includes('/tasks') ? tasksBlack : tasksWhite
  const home = location.pathname.includes('/home') ? homeBlack : homeWhite

  const items: MenuItem[] = [
    // MenuServices.getItem(
    //   !collapsed && 'Research',
    //   'companies' || 'contacts',
    //   <img src={lightBulb} alt={''} />,
    //   blockNavigation,
    // ),
    // MenuServices.getItem(
    //   !collapsed && 'My Contacts',
    //   'exposedContacts',
    //   <img src={exposedContacts} alt={''} />,
    //   blockNavigation,
    // ),
    MenuServices.getItem(!collapsed && 'Engage+', 'outreach', <img src={outreach} alt={''} />, blockNavigation),
    MenuServices.getItem(!collapsed && 'Home', 'home', <img src={home} alt={''} />, blockNavigation),
    MenuServices.getItem(!collapsed && 'Tasks', 'tasks', <img src={tasks} alt={''} />, blockNavigation),
    MenuServices.getItem(
      !collapsed && 'Unified Inbox',
      'unifiedInbox',
      <img src={unifiedInbox} alt={''} />,
      blockNavigation,
    ),
  ]

  const handleNavigation = (value: any) => {
    const routeToNavigate = `/${value.key}`
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo(routeToNavigate)
    } else {
      callbackOnChange && callbackOnChange(value.key)
      navigate(routeToNavigate)
    }

    const refreshAfterNavigation = ['/checkout/success']
    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate(routeToNavigate)
    }
  }

  useEffect(() => {
    if (localUser) {
      const { requires_onboarding, onboarding_status } = localUser?.addons
      if ((requires_onboarding && !onboarding_status) || isSubscriptionDaysPassed) {
        setBlockNavigation(true)
      } else {
        setBlockNavigation(false)
      }
    }
  }, [localUser])

  //In Outreach tab you can see all the contacts you exposed and easly reach out to them in bulk and see your stats.
  return (
    <>
      <Menu
        theme='dark'
        defaultSelectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('tasks') >= 0
            ? 'tasks'
            : window.location.pathname.indexOf('home') >= 0
            ? 'home'
            : window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        selectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('tasks') >= 0
            ? 'tasks'
            : window.location.pathname.indexOf('home') >= 0
            ? 'home'
            : window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        mode='inline'
        items={items}
        className={styles.topMenu}
        onSelect={handleNavigation}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </>
  )
}

export default TopMenu
