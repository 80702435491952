import React, { useEffect, useState } from 'react'
import { Button, Drawer, Input, message, Spin, Typography, Select, Divider, Tag } from 'antd'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './AddCategorizer.module.scss'
import { injectionRegex } from 'common/static-data/userData'
import { useUpdateCategoryMutation } from '../../../state/api/SettingsApi'

const { Option } = Select

const getColorByIndex = (index: number) => {
  const tagColors = [
    'blue', // Blue
    'purple', // Purple
    'orange', // Orange
    'red', // Red
    'green', // Green
    'yellow', // Yellow
    'pink', // Pink
    'cyan', // Cyan
    'magenta', // Magenta
  ]
  // Use modulo to cycle through colors if the index is larger than the array length
  return tagColors[index % tagColors.length]
}

export const EditCategorizerSider = ({ open, onClose, data = {}, listPersonas, onChange }: any) => {
  const { category_name, index, category_description, personas } = data
  const [cateName, setCateName] = useState(category_name)
  const [promptData, setPromptData] = useState(category_description)
  const [selectedPersonas, setSelectedPersonas] = React.useState<string[]>([])

  const [updateCategory, { isLoading }] = useUpdateCategoryMutation()

  const handleEdit = async () => {
    try {
      if (injectionRegex.test(cateName)) {
        message.error('Invalid Category Name')
        return
      }

      await updateCategory({
        index,
        category_name: cateName,
        category_description: promptData,
        personas: selectedPersonas.join(','),
      }).unwrap()

      // Update was successful
      message.success(`${cateName} Edit Successful`)
      onChange({
        index,
        category_name: cateName,
        category_description: promptData,
      })
      onClose()
    } catch (error) {
      console.error(error)
      message.error('Update failed, please try again.')
    }
  }

  const handleClose = () => {
    onClose()
  }

  const renderTitle = () => {
    return (
      <div className={styles.sider__header}>
        <div className={styles.sider__header__left}>
          <Typography>Edit Categorizer</Typography>
          {isLoading && (
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          )}
        </div>
        <Button type='primary' style={{ borderRadius: '24px' }} disabled={!cateName || isLoading} onClick={handleEdit}>
          Save
        </Button>
      </div>
    )
  }

  const handlePersonaSelect = (value: string) => {
    if (!selectedPersonas.some((p: any) => p.id === value)) {
      setSelectedPersonas([...selectedPersonas, value])
    }
  }

  const handlePersonaDeselect = (value: string) => {
    setSelectedPersonas(selectedPersonas.filter((item: any) => item !== value))
  }

  useEffect(() => {
    setCateName(category_name)
    setPromptData(category_description)
    if (personas) {
      setSelectedPersonas(personas.map((item: any) => item.id))
    }
  }, [data])

  return (
    <Drawer
      title={renderTitle()}
      open={open}
      closable
      onClose={handleClose}
      closeIcon={<CloseOutlined />}
      className={'add-list-sider'}>
      <div className={styles.sider__body}>
        <div>
          <Typography>Categorizer Name</Typography>
          <Input
            value={cateName}
            onChange={(e) => setCateName(e.target.value)}
            style={{ padding: '6px 16px' }}
            placeholder='Enter Properties category_name'
          />
        </div>
        <div>
          <Typography>Categorizer Prompt</Typography>
          <Input
            value={promptData}
            onChange={(e) => setPromptData(e.target.value)}
            style={{ padding: '6px 16px' }}
            placeholder='Enter prompt'
          />
          <span className={styles.sider__note_text}>View description when hovering over fields in task or views</span>
        </div>
        <div>
          <Typography>Attached to persona</Typography>
          <Select
            mode='multiple'
            value={selectedPersonas}
            placeholder='Type a new option or select from the list'
            onSelect={handlePersonaSelect}
            onDeselect={handlePersonaDeselect}
            tagRender={() => <></>}
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: '100%' }}>
            {listPersonas?.length > 0 &&
              listPersonas.map((persona: any) => (
                <Option key={persona.id} value={persona.id}>
                  {persona.name}
                </Option>
              ))}
          </Select>
          <span className={styles.sider__note_text}>Press Enter or comma to confirm your selection</span>
          <Divider style={{ margin: '10px 0' }} />
          <div className={styles.tagContainer}>
            {selectedPersonas.map((value, idx) => {
              const persona = listPersonas?.find((p: any) => p.id === value)
              return (
                <Tag
                  key={value}
                  color={getColorByIndex(idx)}
                  closable
                  onClose={() => handlePersonaDeselect(value)}
                  style={{ margin: '6px 6px 6px 0px', padding: '0 16px', borderRadius: 10 }}>
                  {persona?.name}
                </Tag>
              )
            })}
          </div>
        </div>
      </div>
    </Drawer>
  )
}
