import { useNavigate, useLocation } from 'react-router-dom'

const useCampaignNavigation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const getCurrentCampaignId = () => {
    const params = new URLSearchParams(location.search)
    return params.get('campaign_id')
  }

  const navigateWithCampaignId = (
    path: string,
    options: { replace?: boolean; state?: any; useCampaignId?: boolean } = {},
  ) => {
    const { replace = false, state = {}, useCampaignId = true } = options
    const campaignId = getCurrentCampaignId()

    // If `useCampaignId` is true and a campaign ID exists, append it to the URL
    const newPath = useCampaignId && campaignId ? `${path}?campaign_id=${campaignId}` : path

    navigate(newPath, { replace, state })
  }

  return { navigateWithCampaignId }
}

export default useCampaignNavigation
