import React from 'react'
import { Modal, Button } from 'antd'
import campaign_created_icon from 'common/assets/svg/campaign_created_icon.svg'
import styles from './CampaignSuccessModal.module.scss' // Import your custom CSS if needed
import { useNavigate } from 'react-router-dom'

interface CampaignSuccessModalProps {
  open: boolean
  onClose: () => void
  onClick?: () => void
}

const CampaignSuccessModal: React.FC<CampaignSuccessModalProps> = ({ open, onClose, onClick }) => {
  const navigate = useNavigate()
  const handleNav = () => {
    navigate('/outreach')
  }
  return (
    <Modal
      style={{ borderRadius: '16px' }}
      title={null}
      open={open}
      footer={null}
      closable={false}
      centered
      onOk={onClose}
      onCancel={onClose}>
      <div className={styles.campaignSuccessContent}>
        <img src={campaign_created_icon} alt='' />
        <h1>Your campaign was Created successfully!</h1>
        <p style={{ color: '#5E6C84', fontSize: '18px' }}>
          Now that your campaign was generated it will be sent to your leads.
        </p>
        <div className={styles.campaignSuccessButtons}>
          <Button
            block
            onClick={() => {
              navigate('/outreach/create-new-campaign/summary')
            }}
            style={{ marginRight: '15px', marginBottom: '30px' }}>
            View Campaign
          </Button>
          <Button type='primary' block onClick={handleNav}>
            Run Campaign
          </Button>
        </div>
        <Button
          type='link'
          block
          onClick={handleNav}
          style={{ position: 'absolute', bottom: '25px', left: 0, right: 0, color: '#7043ff', fontWeight: 600 }}>
          Go to My Campaign
        </Button>
      </div>
    </Modal>
  )
}

export default CampaignSuccessModal
