import Icon from '@ant-design/icons'

const UpSVG = () => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683416 6.09763 0.292892 5.70711C-0.0976315 5.31658 -0.0976315 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.097631 5.31658 -0.097631 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711Z'
      fill='#5E6C84'
    />
  </svg>
)
const DownSVG = () => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292894C8.68342 -0.0976312 9.31658 -0.0976312 9.70711 0.292894C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
      fill='#5E6C84'
    />
  </svg>
)

const UpOutlined = (props: Partial<any>) => <Icon component={UpSVG} {...props} />
const DownOutlined = (props: Partial<any>) => <Icon component={DownSVG} {...props} />

export { UpOutlined, DownOutlined }
