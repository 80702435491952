import React, { useState, useEffect } from 'react'
import { Tag, Select, Button, Input, Layout, Spin, Avatar, message, Tooltip, Divider } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import styles from './MessageComponent.module.scss'
import bookmark from 'common/assets/svg/bookmark.svg'
import bookmark_black from 'common/assets/svg/bookmark_black.svg'
import bookmark_chat from 'common/assets/svg/bookmark_chat.svg'
import bookmark_chat_after from 'common/assets/svg/bookmark_chat_after.svg'
import double_tick from 'common/assets/svg/double_tick.svg'
import bookmark_blue from 'common/assets/svg/bookmark_blue.svg'
import empty_state_messages from 'common/assets/svg/empty_state_messages.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
import instagram from 'common/assets/svg/instagram_icon.svg'
import pin_icon from 'common/assets/svg/pin_chat.svg'
import pinned_chat from 'common/assets/svg/pinned_chat.svg'
import Linkify from 'linkify-react'
import {
  SendOutlined,
  CloseOutlined,
  SearchOutlined,
  UserAddOutlined,
  ClockCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  useLazyChangeMetadataQuery,
  useLazyGetBookmarkQuery,
  useLazyGetDialogsQuery,
  useLazyGetMessagesQuery,
  useLazySendChatMessageQuery,
  useLazySetBookmarkQuery,
} from './state/api/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_CLOSE_LEFT_SIDEBAR,
  SET_PENDING_MESSAGES,
  SET_REUNIFY,
  SET_SELECTED_CHAT_RECIPIENT,
} from './state/chatSlice'
import ProfileSider from './ProfileSider'
import { post } from 'common/api/axios'
import CustomMeetingBook from './UI/CustomMeetingBook'
import CustomAvatar from './UI/CustomAvatar'
import TagItem from './Tag/TagItem'
import TagDialog from './Tag'
import useClickOutside from './Tag/Hook/useClickOutside'

const isWithinOneMinute = (date1: string | null, date2: string | null) => {
  const isValidDate = (date: string | null) => {
    if (date === null) return false
    const parsedDate = new Date(date)
    return !isNaN(parsedDate.getTime())
  }

  if (!isValidDate(date1) || !isValidDate(date2)) {
    return false
  }

  const date1Time = new Date(date1!).getTime()
  const date2Time = new Date(date2!).getTime()
  return Math.abs(date1Time - date2Time) <= 60000 // 1 minute
}

interface Props {
  channelId: string | null // Adjusted type definition to accept null
  channelMessages: {
    id: string
    text: string
    sender: string
    receiver: string
    timestamp: string
  }[] // Define the type for messages
  // onSendMessage: (newMessage: { text: string sender: string receiver: string }) => void // Callback function to send a new message
}

const MessageComponent: React.FC<Props> = ({ channelId, channelMessages }) => {
  // API Calls
  const [getDialogs] = useLazyGetDialogsQuery()
  const [sendChatMessage] = useLazySendChatMessageQuery()
  const [getMessages, { data: messagesData }] = useLazyGetMessagesQuery()
  const [changeMetadata] = useLazyChangeMetadataQuery()
  const [getBookmarks, { data: bookmarksData }] = useLazyGetBookmarkQuery()
  const [setBookmark] = useLazySetBookmarkQuery()

  // useApp
  const { selected_chat_recipient, is_unify_finished, close_left_sidebar, selected_chat_platform, pendingMessages } =
    useAppSelector((state) => state.chat)
  const additionalTags = JSON.parse(selected_chat_recipient?.tags || '[]')
  const dispatch = useAppDispatch()
  const { current_tags_dialog } = useAppSelector((state) => state.chat)
  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === selected_chat_recipient.id)
  // eslint-disable-next-line
  const tagsOfDialog = filteredTagsDialog?.data

  const mergeMessages = (fetchedMessages: any[]) => {
    if (!fetchedMessages) {
      return []
    }

    const latestMessage = fetchedMessages[fetchedMessages.length - 1]

    const baseData = {
      thread_id: latestMessage?.thread_id,
      sender_username: latestMessage?.sender_username,
      recipient_username: latestMessage?.recipient_username,
    }

    // Filter out pending messages that should be included based on the criteria
    const relevantPendingMessages = pendingMessages.filter(
      (pmsg: any) =>
        pmsg.thread_id === baseData.thread_id &&
        pmsg.sender_username === baseData.sender_username &&
        pmsg.recipient_username === baseData.recipient_username,
    )

    const isMessageInFetched = (message: any) => {
      return fetchedMessages.some(
        (msg) =>
          msg.message === message.message &&
          msg.thread_id === message.thread_id &&
          msg.outgoing === message.outgoing &&
          msg.sender_username === message.sender_username &&
          msg.recipient_username === message.recipient_username &&
          isWithinOneMinute(msg.sent_at, message.sent_at),
      )
    }

    // Filter out pending messages that are already in fetchedMessages
    const uniquePendingMessages = relevantPendingMessages?.filter((pmsg: any) => !isMessageInFetched(pmsg))
    // Combine unique fetched messages with new pending messages
    return [...fetchedMessages, ...uniquePendingMessages]
  }

  // useStates
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(mergeMessages(messagesData))
  const [openProfileSider, setOpenProfileSider] = useState(false)
  const [openAllTagDetail, setOpenAllTagDetail] = useState(false)
  // eslint-disable-next-line
  const [chatImg, setChatImg] = useState(bookmark_chat)
  const [nameImg, setNameImg] = useState(bookmark)
  const [pin, setPin] = useState(pin_icon)
  const [defaultPriority, setDefaultPriority] = useState(selected_chat_recipient?.priority)
  const [defaultMeetingBook, setDefaultMeetingBook] = useState(selected_chat_recipient?.meeting_book)
  const [bookmarkCollapsed, setBookmarkCollapsed] = useState(true)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [filteredMessages, setFilteredMessages] = useState<string[]>([])
  // eslint-disable-next-line
  const [chatImgStates, setChatImgStates] = useState<{ [key: string]: string }>({})
  const [loading, setLoading] = useState(false)

  // useRefs
  const messageEndRef = React.useRef<any>(null)
  const tagDetailRef = React.useRef<any>(null)

  // Functions & Methods
  const handleInputChange = (event: any) => {
    setSelectedMessage(event.target.value)
  }

  const handleChangePriority = async (priority: string) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      metadata: { priority: priority },
    })
    setDefaultPriority(priority)
    dispatch(SET_SELECTED_CHAT_RECIPIENT({ ...selected_chat_recipient, priority: priority }))
    await getDialogs()
    post('/log', {
      action: `Unified Inbox: Priority Changed to: ${priority}`,
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }

  const handleChangeMeetingBook = async (meeting_book: string) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      // convrt_index: selected_chat_recipient.convrt_index,
      metadata: { meeting_book: meeting_book },
    })
    setDefaultMeetingBook(meeting_book)
    await getDialogs()
    post('/log', {
      action: `Unified Inbox: Meeting Book Changed to: ${meeting_book}`,
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }

  const handlePinChatClick = async (check: boolean) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      metadata: { pin: check },
    })
    dispatch(SET_SELECTED_CHAT_RECIPIENT({ ...selected_chat_recipient, pin: check }))
    await getDialogs()
    if (check) {
      post('/log', {
        action: `Unified Inbox: Chat ${check ? 'Pinned' : 'Unpinned'}`,
        data: {
          id: selected_chat_recipient.id,
          platform: selected_chat_recipient.platform,
          senderData: selected_chat_recipient.senderData,
        },
      })
    }
  }

  const sendMessage = async () => {
    if (selectedMessage.trim() !== '' && !loading) {
      try {
        const lastMessage = updateMessage[updateMessage.length - 1]
        const lastMessageId = parseInt(updateMessage[updateMessage.length - 1].id)
        const sent_time = new Date().toISOString()
        // Generate the new ID for the next message
        const newMessageId = (lastMessageId + 1).toString()
        const newMessage = {
          id: newMessageId,
          dialog_id: lastMessage.dialog_id ? lastMessage.dialog_id : lastMessage?.thread_id,
          message: selectedMessage,
          sent_at: sent_time,
          receiver_number: lastMessage.receiver_number,
          outgoing: true,
          status: 'pending',
        }
        // const updatedMessages = [...updateMessage, newMessage]

        const lastFetchedMessage = messagesData[messagesData?.length - 1]

        const newPendingMessage = {
          message: selectedMessage,
          thread_id: lastFetchedMessage.dialog_id ? lastFetchedMessage.dialog_id : lastFetchedMessage?.thread_id,
          outgoing: true,
          sent_at: sent_time,
          updated_at: sent_time,
          sequence: lastMessage.sequence ? lastMessage.sequence + 1 : null,
          sender_username: lastFetchedMessage.sender_username ? lastFetchedMessage.sender_username : '',
          recipient_username: lastFetchedMessage.recipient_username ? lastFetchedMessage.recipient_username : '',
          status: 'pending',
        }
        dispatch(SET_PENDING_MESSAGES([...pendingMessages, newPendingMessage]))

        const sendChatMessageData = {
          dialog_id: lastMessage.dialog_id ? lastMessage.dialog_id : lastMessage?.thread_id,
          message: selectedMessage,
          senderData: selected_chat_recipient.senderData,
          platform: selected_chat_recipient.platform,
        }
        setUpdateMessage([...updateMessage, newPendingMessage])
        setSelectedMessage('')

        const data = await sendChatMessage(sendChatMessageData)

        if (data?.error?.status) {
          message.error('An error occured. Please try again later.')
          setLoading(false)
          return
        }

        post('/log', {
          action: `Unified Inbox: User Sent a Message'}`,
          data: newMessage,
        })

        await getDialogs()
        setLoading(false)
      } catch (err) {
        message.error('An error occured. Please try again later.')
        console.error('Error sending a message. ', err)
      }
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  const changeBookmark = () => {
    let value = nameImg

    if (value === bookmark) {
      setNameImg(bookmark_blue)
    } else {
      setNameImg(bookmark)
    }
    setBookmarkCollapsed(!bookmarkCollapsed)
  }

  const suffix = <img src={nameImg} style={{ cursor: 'pointer' }} onClick={changeBookmark} alt='' />

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = filteredMessages.filter((msg) => msg.toLowerCase().includes(e.target.value.toLowerCase()))
    setFilteredMessages(filtered.map((msg) => msg))
  }

  const closeBookmark = () => {
    setBookmarkCollapsed(true)
    setNameImg(bookmark)
  }

  const changeChatBookmark = async (messageText: string) => {
    // Check if the message text is already in the filteredMessages
    if (!filteredMessages.includes(messageText)) {
      // If not present, add it to the filteredMessages array
      const updatedFilteredMessages = [...filteredMessages, messageText]
      // Update the state with the new array
      setFilteredMessages(updatedFilteredMessages)
    }

    // Update the state for the clicked message text
    setChatImgStates((prevState) => {
      if (!bookmarksData.includes(messageText)) {
        post('/log', {
          action: `Unified Inbox: User ${bookmarksData.includes(messageText) ? 'Deleted' : 'Added'} a Bookmark`,
          data: { bookmark: messageText },
        })
      }
      return {
        ...prevState,
        [messageText]: prevState[messageText] === bookmark_chat ? bookmark_chat_after : bookmark_chat,
      }
    })

    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()
  }

  const handleDeleteBookmark = async (messageText: string) => {
    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()
    post('/log', {
      action: `Unified Inbox: User Deleted a Bookmark`,
      data: { bookmark: messageText },
    })
  }

  const changePin = () => {
    if (!selected_chat_recipient.pin) {
      setPin(pinned_chat)
      handlePinChatClick(true)
    } else {
      setPin(pin_icon)
      handlePinChatClick(false)
    }
  }

  const valuepass = (value: any) => {
    setSelectedMessage(value)
    setBookmarkCollapsed(true)
    setNameImg(bookmark)

    post('/log', {
      action: `Unified Inbox: User Used a Bookmark`,
      data: { bookmark: value },
    })
  }

  const getColorByUsername = (username: string) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#f44336', '#009688']
    const cleanedName = username.replace(/\s+/g, '').toUpperCase()
    let hash = 0
    for (let i = 0; i < cleanedName.length; i++) {
      hash = cleanedName.charCodeAt(i) + ((hash << 5) - hash)
    }
    const index = Math.abs(hash) % colors.length
    return colors[index]
  }

  const userImage = (size: number, stringSize?: 'small' | 'medium') => {
    return selected_chat_recipient?.photo || selected_chat_recipient?.title ? (
      <CustomAvatar
        name={selected_chat_recipient?.title}
        src={selected_chat_recipient?.photo}
        size={stringSize || 'medium'}
        triggerRerender={selected_chat_recipient?.title}
        style={{ marginRight: '15px' }}
      />
    ) : (
      <Avatar size={size} style={{ minWidth: `${size}px` }} icon={<UserAddOutlined />} />
    )
  }

  const formatDate = (inputDate: string) => {
    const now = new Date()
    const sentDate = new Date(inputDate)
    const dateDiff = now.getTime() - sentDate.getTime()
    const seconds = Math.round(dateDiff / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const formatTime = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    if (seconds < 60) {
      return 'A few seconds ago'
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else if (hours < 48) {
      // Yesterday
      return `Yesterday at ${formatTime(sentDate)}`
    } else if (days < 3) {
      // 2 days ago
      return `${days} days ago at ${formatTime(sentDate)}`
    } else {
      // Formatting date in MM/DD/YYYY, HH:MM format
      return sentDate.toLocaleDateString() + ' at ' + formatTime(sentDate)
    }
  }

  const handleOpenSideBar = () => {
    setOpenProfileSider(!openProfileSider)
    dispatch(SET_CLOSE_LEFT_SIDEBAR(!openProfileSider))
  }

  useClickOutside(tagDetailRef, () => setOpenAllTagDetail(false))

  const handleOpenSocialPage = () => {
    switch (selected_chat_platform) {
      case 'telegram':
        break
      case 'instagram':
        window.open(`https://www.instagram.com/${selected_chat_recipient?.recipient_username}`, '_blank')
        break
      case 'facebook':
        window.open(selected_chat_recipient?.recipient_username)
        break
    }
  }

  const renderMsgIconByStatus = (status: any) => {
    switch (status) {
      case 'pending':
        return (
          <Tooltip title={'On quque'}>
            <ClockCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        )
      case 'failed':
        return (
          <Tooltip title={'Failed Sending'}>
            <WarningOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        )
      case null:
      case undefined:
        return <img src={double_tick} alt='' />
      default:
        return <img src={double_tick} alt='' />
    }
  }

  // useEffects
  const getChatdata = async () => {
    const { error } = await getDialogs()
    if (error?.data?.status === 'empty_cache') {
      dispatch(SET_REUNIFY(true))
    }
  }

  useEffect(() => {
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  useEffect(() => {
    const getMessagesData = async () => {
      setLoadingMessages(true)
      const { data } = await getMessages({
        dialogId: selected_chat_recipient?.id,
        senderData: selected_chat_recipient.senderData,
        platform: selected_chat_recipient.platform,
      })

      if (data) {
        setUpdateMessage(mergeMessages(data))
      }
      setLoadingMessages(false)
    }
    if (is_unify_finished && selected_chat_recipient?.id && selected_chat_recipient.senderData) {
      getMessagesData()
    }
  }, [selected_chat_recipient, is_unify_finished])

  useEffect(() => {
    let intervalId: any

    const fetchMessages = async () => {
      if (!is_unify_finished) {
        return
      }

      try {
        getChatdata()

        if (selected_chat_recipient?.id) {
          const { data } = await getMessages({
            dialogId: selected_chat_recipient.id,
            senderData: selected_chat_recipient.senderData,
            platform: selected_chat_recipient.platform,
          })

          if (data) {
            setUpdateMessage(mergeMessages(data))
          }
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error)
      }
    }

    if (is_unify_finished) {
      intervalId = setInterval(fetchMessages, 5000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [selected_chat_recipient, is_unify_finished, pendingMessages])

  useEffect(() => {
    setPin(selected_chat_recipient.pin ? pinned_chat : pin_icon)
    setDefaultPriority(selected_chat_recipient.priority)
    setDefaultMeetingBook(selected_chat_recipient.meeting_book)
    setOpenAllTagDetail(false)
    setSelectedMessage('')
  }, [selected_chat_recipient])

  useEffect(() => {
    const getAllBookmarks = async () => {
      await getBookmarks()
    }
    if (is_unify_finished) {
      getAllBookmarks()
    }
  }, [is_unify_finished])

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      for (const message of bookmarksData) {
        setChatImgStates((prevState) => ({
          ...prevState,
          [message]: bookmark_chat_after,
        }))
      }
    }
  }, [bookmarksData])

  useEffect(() => {
    if (messagesData) {
      // Filter out the messages that are now confirmed and remove them from pendingMessages
      const confirmedMessages = pendingMessages.filter(
        (pmsg: any) =>
          !messagesData.some(
            (msg: any) =>
              msg.message === pmsg.message &&
              msg.thread_id === pmsg.thread_id &&
              msg.outgoing === pmsg.outgoing &&
              msg.sender_username === pmsg.sender_username &&
              msg.recipient_username === pmsg.recipient_username &&
              isWithinOneMinute(msg.sent_at, pmsg.sent_at),
          ),
      )

      // Update the pendingMessages state with the remaining unconfirmed messages
      dispatch(SET_PENDING_MESSAGES(confirmedMessages))

      // Merge fetched messages with the pending messages
      const updatedMessages = mergeMessages(messagesData)

      // Update the `updateMessage` state with the merged messages
      setUpdateMessage(updatedMessages)
    }
  }, [messagesData])

  if (!selected_chat_recipient.senderData) {
    if (!is_unify_finished) return <></>
    return (
      <div className={styles.emptyState}>
        <img src={empty_state_messages} alt='empty' />
        <p>No Chats to View</p>
        <span>Please choose one of the Chats to view</span>
      </div>
    )
  }

  return (
    <Layout>
      <Content>
        <div
          className={`${styles['MessageComponent']} flex flex-column relative max-h-full`}
          style={{ userSelect: 'text' }}>
          <div className={styles.MessageComponent__TopSection}>
            <div className={styles.MessageComponent__TopSection__left}>
              <div onClick={handleOpenSideBar} className={styles.userImage}>
                {userImage(48)}
              </div>
              <div onClick={handleOpenSideBar}></div>
              <div className={`${styles['left_recipient']} left_recipient`}>
                <div className={styles.flexDiv}>
                  <Tooltip title={selected_chat_recipient?.title}>
                    <h2 onClick={handleOpenSideBar} style={{ cursor: 'pointer' }}>
                      {selected_chat_recipient?.title?.length > 40
                        ? `${selected_chat_recipient?.title.slice(0, 40)?.trim()}...`
                        : selected_chat_recipient?.title}
                    </h2>
                  </Tooltip>

                  <img
                    onClick={handleOpenSocialPage}
                    src={selected_chat_recipient.platform === 'instagram' ? instagram : facebook}
                    alt=''
                    style={{ margin: '0px 5px', height: '20px', cursor: 'pointer' }}
                  />

                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                    onClick={() => setOpenAllTagDetail((pre) => !pre)}>
                    <div>
                      {tagsOfDialog?.slice(0, 1).map((tag: any) => (
                        <TagItem key={tag.id} title={tag.tag_name}></TagItem>
                      ))}
                    </div>
                    {tagsOfDialog?.length > 1 && (
                      <Tooltip title={'View All tag'}>
                        <span className={styles['chat_receipt_box__bottomSection__tag__more']}>
                          +{tagsOfDialog?.length - 1}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  {openAllTagDetail && tagsOfDialog?.length > 0 && (
                    <div ref={tagDetailRef} className={styles['chat_receipt_box__bottomSection__tag__detail']}>
                      <TagDialog showDescription={false} />
                    </div>
                  )}
                </div>
                <div className={styles.MessageComponent__TopSection__left__tags}>
                  <span className={styles.tags}>
                    {additionalTags.slice(0, 2).map((tag: any) => (
                      <Tag key={tag}>{tag}</Tag>
                    ))}
                    {additionalTags.length > 2 && (
                      <Tooltip
                        title={additionalTags.slice(2).map((tag: any, index: number, array: any) => (
                          <Tag
                            style={{
                              backgroundColor: '#f2efff',
                              color: '#7043ff',
                              borderColor: '#f2f0ff',
                              marginRight: index === array.length - 1 ? '0px' : undefined,
                            }}
                            key={tag}>
                            {tag}
                          </Tag>
                        ))}>
                        <span className='chat_receipt_box__bottomSection__more'>+{additionalTags.length - 2}</span>
                      </Tooltip>
                    )}
                  </span>
                  {/* <span className={styles.socials}>
                    <img src={facebook} alt='' />
                    <img src={instagram} alt='' />
                    <img src={telegram} alt='' />
                    <img src={linkedin} alt='' />
                    <img src={discord} alt='' />
                    <img src={twitter} alt='' />
                  </span> */}
                </div>
              </div>
            </div>
            <div className={styles.MessageComponent__TopSection__right}>
              <CustomMeetingBook
                defaultValue={defaultMeetingBook}
                value={defaultMeetingBook}
                onChange={handleChangeMeetingBook}
              />
              <Divider type='vertical' />
              <Select
                className='selectPriority'
                size='small'
                defaultValue={defaultPriority}
                value={defaultPriority}
                style={{ width: 99 }}
                placeholder='Priority'
                onChange={handleChangePriority}>
                <Select.Option value='high'>
                  <div className={styles.priority}>
                    <span className='select_dots'></span>
                    <span>High</span>
                  </div>
                </Select.Option>
                <Select.Option value='medium'>
                  <div className={styles.priority}>
                    <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
                    <span>Medium</span>
                  </div>
                </Select.Option>
                <Select.Option value='low'>
                  <div className={styles.priority}>
                    <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
                    <span>Low</span>
                  </div>
                </Select.Option>
              </Select>
              <div>
                <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={pin} alt='pin' onClick={changePin} />
              </div>
              {/* </span> */}
              {/* <span className={styles.enrich}>Enrich</span> */}
            </div>
            {/* <div className={styles.sentFrom}>
              <span>Sender: {selected_chat_recipient.sender_username}</span>
            </div> */}
          </div>
          {selected_chat_recipient.can_be_used === false && (
            <div className={styles.outOfSyncBanner}>
              <span>This account is out of sync. Sent messages will be sent when the account is back online.</span>
            </div>
          )}
          {loadingMessages ? (
            <div className={styles.MessageComponent__middleSection}>
              <Spin tip='Loading' size='large'>
                <div className='content' />
              </Spin>
            </div>
          ) : (
            <div className={styles.MessageComponent__middleSection}>
              {[...updateMessage]?.reverse()?.map((msg: any, i: any) => {
                return (
                  <div key={i}>
                    {!msg.outgoing ? (
                      <div className={styles.left_chat}>
                        {userImage(24, 'small')}
                        <div className={styles.left_chat_text}>
                          <Linkify tagName='p' options={{ target: '_blank', rel: 'noopener noreferrer' }}>
                            <div className={styles.groupUsername}>
                              {selected_chat_recipient?.dialog_type === 'chat' && (
                                <>
                                  <span
                                    style={{ fontWeight: 600, color: getColorByUsername(msg.sender_username || 'a') }}>
                                    {msg.sender_username}
                                  </span>
                                  <br />
                                </>
                              )}
                              <span>{msg.message}</span>
                            </div>
                          </Linkify>
                          <span className={styles.left_chat_time}>
                            {/* {msg.receiver} <span className={styles.left_chat_text__dots}></span>Few seconds ago */}
                            <span className={styles.left_chat_text__dots}></span>
                            {formatDate(msg.sent_at)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.right_chat}>
                        <div className={styles.right_chat_text}>
                          <div className={styles.messageContent}>
                            <Linkify tagName='p' options={{ target: '_blank' }}>
                              {msg.message}
                            </Linkify>
                            {/* <img src={double_tick} alt='' /> */}
                            {renderMsgIconByStatus(msg.status)}
                          </div>
                          <span className={styles.left_chat_time}>
                            <span>
                              <span className={styles.left_chat_text__dots}></span>
                              {formatDate(msg.sent_at)}
                            </span>
                            <div className={styles.rightBottomMessage}>
                              {msg.from_ai && msg.status !== 'failed' && <span>Sent by Convrt AI</span>}
                              {msg.status === 'failed' && <span style={{ color: '#DF667C' }}>Failed</span>}
                              <img
                                src={chatImgStates[msg.message] || chatImg}
                                style={{ height: '20px', width: '16px', marginLeft: '5px' }}
                                onClick={() => changeChatBookmark(msg.message)}
                                alt=''
                              />
                            </div>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
              <div ref={messageEndRef} />
            </div>
          )}
          <div className={styles.MessageComponent__bottomSection}>
            <span className='MessageComponent__bottomSection__middle'>
              <div className={styles.bookmarkAbsolute}>{suffix}</div>
              <Input.TextArea
                className='messageComponentInput'
                placeholder={`Message from ${selected_chat_recipient.sender_username}`}
                size='large'
                autoSize={{ minRows: 1, maxRows: 6 }}
                value={selectedMessage}
                onChange={handleInputChange}
                onPressEnter={handleKeyPress}
                disabled={loading}
              />
              {loading && (
                <div className={styles.loadingSendMessage}>
                  <Spin style={{ marginRight: '5px' }} />
                </div>
              )}
            </span>
            <span className='MessageComponent__bottomSection__right'>
              <Button type='primary' icon={<SendOutlined />} onClick={sendMessage}></Button>
            </span>
          </div>
        </div>
      </Content>
      <Sider
        width='340px'
        className={`${styles['MessageComponent_sider']} MessageComponent_sider`}
        collapsedWidth='0'
        collapsed={bookmarkCollapsed}>
        <div className={styles.MessageComponent_sider__topSection}>
          <h1>
            <img src={bookmark_black} alt='' /> Chat Bookmark
          </h1>
          <CloseOutlined onClick={closeBookmark} />
        </div>
        <Input size='large' placeholder='Search for messages' prefix={<SearchOutlined />} onChange={handleSearch} />
        {bookmarksData?.map((message: string, index: number) => (
          <div className={styles.sider_bookmark} key={index} onClick={() => valuepass(message)}>
            <p>{message}</p>
            <CloseOutlined
              className={styles.close_icon}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteBookmark(message)
              }}
            />
          </div>
        ))}
      </Sider>
      <ProfileSider
        isVisible={openProfileSider}
        onOpen={handleOpenSideBar}
        onClose={() => {
          setOpenProfileSider(false)
          dispatch(SET_CLOSE_LEFT_SIDEBAR(!close_left_sidebar))
        }}
        userImage={
          <CustomAvatar
            name={selected_chat_recipient?.title}
            src={selected_chat_recipient?.photo}
            size='xl'
            triggerRerender={selected_chat_recipient?.title}
          />
        }
      />
    </Layout>
  )
}

export default MessageComponent

// const filteredMessages = messages.filter((message) => message.toLowerCase().includes(searchQuery.toLowerCase()))
// const filteredMessages = [...updateMessage, ...channelMessages].map((msg) => {
//   return msg.text
// })
// .filter((text) => text.toLowerCase().includes(searchQuery.toLowerCase()))

// const changeChatBookmark = () => {
//   const newMessage = 'Lorem ipsum dolor sit amet consectetur. Viverra fames tincidunt duis fringilla ac.'
//   const updatedMessages = messages.filter((message) => message !== newMessage)
//   updatedMessages.unshift(newMessage)
//   setMessages(updatedMessages)

//   let value = chatImg
//   if (value === bookmark_chat) {
//     setChatImg(bookmark_chat_after)
//   } else {
//     setChatImg(bookmark_chat)
//   }
// }
