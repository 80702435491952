import React from 'react'
import styles from './MessagesTemplates.module.scss'
import { Tabs, TabsProps, Tag } from 'antd'
import increase_icon from 'common/assets/svg/increase_icon.svg'
import right_purple_arrow from 'common/assets/svg/right_purple_arrow.svg'

interface TemplateProps {
  message: string
  messageType: string
  messageName: string
  responseRate: string
  updatedAt: string
  onClick: any
}

// Utility function to convert timestamp to "X Y ago"
const timeAgo = (date: string) => {
  const now = new Date()
  const updatedAt = new Date(date)
  const diffInSeconds = Math.floor((now.getTime() - updatedAt.getTime()) / 1000)

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ]

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds)
    if (count >= 1) {
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
    }
  }

  return 'Just Now'
}

const Template: React.FC<TemplateProps> = ({ message, messageType, messageName, responseRate, updatedAt, onClick }) => {
  return (
    <div className={styles.template} onClick={onClick}>
      <div className={styles.header}>
        <p>{messageName}</p>
        <div className={styles.tags}>
          <Tag style={{ borderRadius: '8px', color: '#7043ff' }} color='#F2F0FF'>
            {'Convrt'}
          </Tag>
          <Tag style={{ borderRadius: '8px', color: '#02C3AB' }} color='#02C3AB1A'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={increase_icon} alt='' />
              <span>{responseRate}%</span>
            </div>
          </Tag>
        </div>
      </div>
      <div className={styles.body}>{message}</div>
      <div className={styles.footer}>
        <span>Last Update: {timeAgo(updatedAt)}</span>
        <img src={right_purple_arrow} alt='' />
      </div>
    </div>
  )
}

interface MessagesTemplatesProps {
  setTextMessage: any
  messageSuggestions: any
}
const MessagesTemplates: React.FC<MessagesTemplatesProps> = ({ setTextMessage, messageSuggestions }) => {
  const suggestions = messageSuggestions?.response
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className={styles.tabHeader}>Suggestions</span>,
      children: (
        <div className={styles.templateContainer}>
          {suggestions?.map((suggestion: any) => (
            <Template
              key={suggestion.message_id}
              message={suggestion.message}
              messageType={suggestion.message_type}
              messageName={suggestion.message_name}
              updatedAt={suggestion.updated_at}
              responseRate={suggestion.response_rate}
              onClick={() => setTextMessage(suggestion.message)}
            />
          ))}
        </div>
      ),
    },
    {
      key: '2',
      label: <span className={styles.tabHeader}>Convrt</span>,
      children: '',
      disabled: true,
    },
    {
      key: '3',
      label: <span className={styles.tabHeader}>Custom</span>,
      children: '',
      disabled: true,
    },
  ]

  const handleChangeTab = () => {}
  return (
    <div className={styles.container}>
      <Tabs className='builderSider' defaultActiveKey='1' centered items={items} onChange={handleChangeTab} />
    </div>
  )
}

export default MessagesTemplates
