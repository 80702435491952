import React, { useState } from 'react'
import { Popover, Tooltip } from 'antd'
import styles from './CompanyInfo.module.scss'
import risk_low from 'common/assets/svg/risk_low.svg'
import { InfoCircleOutlined } from '@ant-design/icons'
// import { ReactComponent as LockComponent } from 'common/assets/svg/lock.svg'
import { ReactComponent as LockComponent } from 'common/assets/svg/crown.svg'
import { capitalizeText } from 'common/utils/capitalizeText'

// const dummyData = {
//   name: 'GOOGLE LIMITED',
//   address: '37 Nelmes Way, Hornchurch, Essex, RM11 2QY',
//   description:
//     'Lorem ipsum dolor sit amet consectetur. Quam dui mi dui aenean sed accumsan consequat scelerisque urna. Felis egestas commodo tempus non ut. Lacinia posuere ut felis duis pulvinar quis.',
//   tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
//   riskScore: 'low',
//   riskDescription:
//     'Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Nisl viverra ut suspendisse. Cursus vitae venenatis eu.',
//   details: {
//     fundedYear: 2008,
//     category: 'Technology',
//     subCategories: 'e-commerce, consumer electronics, and artificial intelligence (AI)',
//     phoneNumber: '050-5445-433',
//     physicalLocation: '24/03/2023',
//     fundingSources: 'General Fund',
//     estimatedRevenue: '136.6B',
//     employees: 500000,
//     website: 'www.google.com',
//     distributionChannels: 'Online portals, websites, platforms, and apps',
//     estimatedActivity: 'online sales, international activity',
//     products:
//       'Search engine technology, cloud computing, computer software, quantum computing, e-commerce, consumer electronics, and artificial intelligence (AI)',
//     growthSignal: 'Growing, Static, Reduction',
//   },
// }

interface CompanyInfoProps {
  data: any
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ data }) => {
  const [expanded, setExpanded] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const riskScoreImg = risk_low

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleReadMoreClick = () => {
    setReadMore(!readMore)
  }

  const handleOpenWebsite = () => {
    if (website) {
      window.open(website, '__blank')
    }
  }

  const fallbackJSON = (tags: any) => {
    try {
      const res = JSON.parse(tags || '[]')
      return res
    } catch {
      return []
    }
  }

  const company_name = data.company
  const location = data.location
  const description = data.description || 'No available description.'
  const tags = fallbackJSON(data.tags)
    ?.slice(0, 4)
    ?.map((tag: any, index: any) => {
      if (tag?.length > 12) {
        return (
          <Tooltip title={capitalizeText(tag)}>
            <span key={index} className={styles.tag}>
              {capitalizeText(tag?.slice(0, 12))?.trim() + '...'}
            </span>
          </Tooltip>
        )
      }
      return (
        <span key={index} className={styles.tag}>
          {capitalizeText(tag?.slice(0, 10))}
        </span>
      )
    })
  const riskDescription =
    'Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Nisl viverra ut suspendisse. Cursus vitae venenatis eu.'
  const fundedYear = Math.trunc(Number(data.founded_year))
  const categories = data.categories
  const sub_categories = data.sub_categories
  const phone_number = data.phone
  const physical_location = data.physical_location
  const fundingSources = data.fundingSources
  const estimatedRevenue = data.estimatedRevenue
  const number_of_employees = data.number_of_employees
  const website = data.company_website
  const distributionChannels = data.distributionChannels
  const estimatedActivity = data.estimatedActivity
  const products = data.products
  const growthSignal = data.growthSignal

  const content = (
    <div className={styles.details}>
      <h2>{company_name}</h2>
      <span>{location}</span>
      <p>
        {description.length > 200 && !readMore ? `${description?.slice(0, 200)}...` : description}
        {description.length > 200 && (
          <span className={styles.readMore} onClick={handleReadMoreClick}>
            {readMore ? ' read less' : ' read more'}
          </span>
        )}
      </p>
      {tags.length > 0 && <div className={styles.tags}>{tags}</div>}
      {expanded && (
        <div className={styles.expanded}>
          <div className={styles.riskScore}>
            <p>
              Risk Score
              <Tooltip title='Not available in your plan'>
                <LockComponent style={{ marginLeft: '2px', height: '18px' }} />
              </Tooltip>
            </p>
            <div className={styles.level}>
              <img src={riskScoreImg} alt='' />
              <span className={styles.premium}>{riskDescription}</span>
            </div>
          </div>
          <div className={styles.companyDetails}>
            <p>Company Details</p>
            <ul>
              {fundedYear && (
                <li>
                  <span>Funded year:</span>
                  {fundedYear}
                </li>
              )}
              {categories && (
                <li>
                  <span>Category:</span>
                  {categories}
                </li>
              )}
              {sub_categories && (
                <li>
                  <span>Sub Categories:</span>
                  {sub_categories}
                </li>
              )}
              {phone_number && (
                <li>
                  <span>Phone Number:</span>
                  {phone_number}
                </li>
              )}
              {physical_location && (
                <li>
                  <span>Physical Location:</span>
                  {physical_location}
                </li>
              )}
              {fundingSources && (
                <li>
                  <span>Funding Sources:</span>
                  {fundingSources}
                </li>
              )}
              {estimatedRevenue && (
                <li>
                  <span>Estimated Revenue:</span>
                  {estimatedRevenue}
                </li>
              )}
              {number_of_employees && (
                <li>
                  <span>Employees #:</span>
                  {number_of_employees}
                </li>
              )}
              {website && (
                <li>
                  <span>Website:</span>
                  <div style={{ cursor: 'pointer' }} onClick={handleOpenWebsite}>
                    {website}
                  </div>
                </li>
              )}
              {distributionChannels && (
                <li>
                  <span>Distribution Channels:</span>
                  {distributionChannels}
                </li>
              )}
              {estimatedActivity && (
                <li>
                  <span>Estimated Activity:</span>
                  {estimatedActivity}
                </li>
              )}
              {products && (
                <li>
                  <span>Products:</span>
                  {products}
                </li>
              )}
              {growthSignal && (
                <li>
                  <span>Growth Signal:</span>
                  {growthSignal}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      <span className={styles.seeMoreLess} onClick={handleExpandClick}>
        {expanded ? 'See less' : 'See more'}
      </span>
    </div>
  )

  return (
    <Popover content={content} trigger='click' placement='right'>
      <InfoCircleOutlined style={{ cursor: 'pointer', color: 'lightgray', margin: 0 }} />
    </Popover>
  )
}

export default CompanyInfo
