import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import styles from './CustomCollapseFooter.module.scss'
import { useAppSelector, useAppDispatch } from 'state'
import CampaignSuccessModal from './CampaignSuccessModal'
import {
  useLazyAddCampaignAILeadsQuery,
  useLazyAddLeadsFromGroupsQuery,
  useLazyCreateCampaignActionsQuery,
  useLazyCreateCampaignQuery,
  // useLazyCreateCampaignRecipientsQuery,
  // useLazyCreateCampaignRecipientsUseRemainingPointsQuery,
} from '../../state/api/OutreachAI-API'
import { useNavigate } from 'react-router-dom'
import { transformToNewStructure } from '../transformStepsUtil'
import {
  SET_ARE_MESSAGES_MISSING,
  SET_IS_REVIEW_MODE,
  SET_LOADING_GENERATE_CAMPAIGN,
} from '../../state/outreachAICampaignSlice'
import OutOfPointsUseRemaining from 'common/components/OutOfPoints/OutOfPointsUseRemaining'
import { getSenderByPlatform } from 'common/utils/userHelpers'

interface CustomCollapseFooterProps {
  id?: any
  onBack?: () => void
  onNext?: () => void
  onSkip?: () => void
  onReset?: () => void
  onGenerate?: boolean
  leftButton?: any
}

const CustomCollapseFooter: React.FC<CustomCollapseFooterProps> = ({
  id,
  onBack,
  onNext,
  onGenerate,
  onSkip,
  onReset,
  leftButton,
}) => {
  const [createCampaignActions] = useLazyCreateCampaignActionsQuery()
  const [createCampaign] = useLazyCreateCampaignQuery()
  const [addLeadsFromGroups] = useLazyAddLeadsFromGroupsQuery()
  // const [createCampaignRecipients] = useLazyCreateCampaignRecipientsQuery()
  // const [createCampaignRecipientsUseRemainingPoints] = useLazyCreateCampaignRecipientsUseRemainingPointsQuery()
  const [addCampaignAILeadsQuery] = useLazyAddCampaignAILeadsQuery() // Only For Companies AI for now.

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { custom_flow_id } = useAppSelector((state) => state.CampaignBuilder)
  const {
    prompt,
    saved_prompt_params,
    selected_leads,
    selected_platforms_accounts,
    campaign_id,
    campaign_settings_ai,
    nodes_data,
    messages_types,
    active_platforms,
    ai_campaign_name,
    are_messages_missing,
    skip_prompt,
    select_custom_template,
    are_leads_from_groups,
    stored_leads_from_db,
    ai_leads_type,
    setup_method,
  } = useAppSelector((state) => state.outreachAICampaign)
  const [openModal, setOpenModal] = useState(false)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  const validator = () => {
    switch (id) {
      case '1':
        const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
          (value) => Array.isArray(value) && value.length > 0,
        )
        return atLeastOneNonEmpty
      case '2':
        if (prompt.id) {
          return true
        }

        return false
      case '3':
        if (setup_method === 'manually') {
          return true
        }

        if (prompt.id) {
          const allNonEmpty = Object.entries(saved_prompt_params).every(([key, value]) => {
            // if (key === 'goal' || key === 'expertise') return true
            return typeof value === 'string' && value.trim() !== ''
          })

          return allNonEmpty
        }

        return false

      case '4':
        return selected_leads?.length > 0
      case '5':
        return true
      default:
        return false
    }
  }

  // const validatorSkipButton = () => {
  //   switch (id) {
  //     case '1':
  //       return prompt.id ? true : false
  //     default:
  //       return false
  //   }
  // }

  const generateCampaignValidator = () => {
    const allNonEmpty = Object.entries(saved_prompt_params).every(([key, value]) => {
      // if (key === 'goal') return true
      return typeof value === 'string' && value.trim() !== ''
    })
    const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
      (value) => Array.isArray(value) && value.length > 0,
    )

    const allMessageIdsHaveData = nodes_data.every((node: any) => {
      if (messages_types.includes(node.type) && node?.data?.messages) {
        for (const message of node?.data?.messages) {
          if (message?.trim() === '') {
            return false
          }
        }
        if (node?.data?.messages?.length < 1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    })

    if (selected_leads?.length > 0 && (allNonEmpty || skip_prompt) && atLeastOneNonEmpty && !allMessageIdsHaveData) {
      dispatch(SET_ARE_MESSAGES_MISSING(true))
    } else {
      dispatch(SET_ARE_MESSAGES_MISSING(false))
    }

    return (
      selected_leads?.length > 0 &&
      nodes_data?.length > 0 &&
      (allNonEmpty || skip_prompt) &&
      atLeastOneNonEmpty &&
      allMessageIdsHaveData
    )
  }

  const [outOfPoints, setOutOfPoints] = useState(false)
  const [costPoints, setCostPoints] = useState(0)

  const handleGenerateCampaign = async (isRetryWithRemainingPoints: any) => {
    try {
      dispatch(SET_IS_REVIEW_MODE(true))
      dispatch(SET_LOADING_GENERATE_CAMPAIGN(true))
      // Update latest changes in campaigns table
      const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
      const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
        if (value.length > 0) {
          acc[key] = value
        }
        return acc
      }, {})

      // Filter out leads that are already stored in the database
      const filteredLeads = selected_leads.filter((lead: any) => {
        if (!lead.source || lead.mode !== 'groups') {
          return !stored_leads_from_db.includes(lead.index)
        }
        const combinedIndex = `${lead.source}_${lead.index}`
        return !stored_leads_from_db.includes(lead.index) && !stored_leads_from_db.includes(combinedIndex)
      })

      const indexValues = filteredLeads
        .filter((item: any) => item.type !== 'company' && item?.source !== 'CSV')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null && val?.trim() !== '')

      const companyIndexes = filteredLeads
        .filter((item: any) => item.type === 'company')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null)
      const recipientsData = filteredLeads.filter((item: any) => item.source === 'CSV')
      const filtered_active_platforms = Object.entries(active_platforms)
        .filter(([platform, isActive]) => isActive === true)
        .map(([platform]) => platform)
      // Create campign recipient

      const allSenders = getSenderByPlatform(selected_platforms_accounts)

      let campaignRecipientsData
      if (are_leads_from_groups) {
        campaignRecipientsData = await addLeadsFromGroups({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          leads: filteredLeads.map((item: any) => ({
            username: item.username,
            platform: item.platform,
            source: item?.followed_username || item?.source || item.group_name,
          })), // Each item contains platform, in case we need in the future
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
        // return dispatch(SET_LOADING_GENERATE_CAMPAIGN(false)) // delete later
      } else {
        campaignRecipientsData = await addCampaignAILeadsQuery({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          companyLeads: companyIndexes,
          AILeads: indexValues,
          CSVLeads: recipientsData,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
      }

      if (campaignRecipientsData?.error?.status === 470) {
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))

        setCostPoints(campaignRecipientsData?.error?.data?.cost_point)
        setOutOfPoints(true)
      } else {
        await createCampaign({
          campaign_id: campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          prompt_params: JSON.stringify(saved_prompt_params),
          platforms: platforms,
          ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
          steps: JSON.stringify(nodes_data),
          summarytSettings: {
            selected_platforms_accounts,
            prompt,
            saved_prompt_params,
            active_platforms,
            campaign_settings_ai,
            skip_prompt,
            are_leads_from_groups,
            select_custom_template,
            ai_leads_type,
            setup_method,
            allSenders,
          },
          custom_flow_id: custom_flow_id,
        })
        // Create Campaign Actions
        await createCampaignActions({
          campaign_id: campaign_id,
          campaign_settings: campaign_settings_ai,
          platforms: filtered_active_platforms,
        })
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
        // navigate(`/outreach/create-new-campaign/summary/${campaign_id}`)

        navigate(`/outreach/create-new-campaign/summary?campaign_id=${campaign_id}`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const retryWithRemainingPoints = () => {
    handleGenerateCampaign(true)
    setOutOfPoints(false)
  }

  return (
    <div className={styles.footer}>
      <div className={styles.leftSide}>
        {leftButton ||
          (id === '3' && (
            <span
              className={styles.reset}
              onClick={onReset}
              style={{ color: '#7043ff', fontWeight: 600, cursor: 'pointer' }}>
              Reset
            </span>
          ))}
      </div>
      <div className={styles.rightSide}>
        {/* {onSkip && (
          <Button style={{ marginRight: '10px' }} onClick={onSkip} disabled={!validatorSkipButton()}>
            Skip
          </Button>
        )} */}
        {onBack && (
          <Button onClick={onBack} className={styles.white}>
            Back
          </Button>
        )}
        {onNext && (
          <Button onClick={onNext} disabled={!validator()}>
            Next
          </Button>
        )}
        {onGenerate && (
          <Tooltip title={are_messages_missing ? 'Please fill all messages' : null}>
            <Button
              disabled={!generateCampaignValidator()}
              onClick={() => {
                handleGenerateCampaign(false)
              }}>
              Review & Launch
            </Button>
          </Tooltip>
        )}
        {openModal && (
          <CampaignSuccessModal
            open={openModal}
            onClose={() => {
              setOpenModal(false)
            }}
          />
        )}
      </div>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
        />
      )}
    </div>
  )
}

export default CustomCollapseFooter
