import React from 'react'
import { Button, Layout, Modal, Space, message } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import add_to_list from '../../../common/assets/svg/add_to_list.svg'
import styles from './RemoveFromListModal.module.scss'
import { useAppSelector } from 'state'
import { useLazyUpdateListQuery } from 'features/intent-signals/state/api/CompaniesApi'
import { useActiveFilters } from 'common/hooks/useFilters'
import { useLocation } from 'react-router-dom'
import { get, post } from 'common/api/axios'

interface RemoveFromListModalProps {
  open: boolean
  onClose: () => void
  isCompany?: boolean
}

const RemoveFromListModal: React.FC<RemoveFromListModalProps> = ({ open, onClose, isCompany = false }) => {
  const locationPathName = useLocation().pathname.split('/')[1]
  const { selected_contacts } = useAppSelector((state) => state.contacts)
  const { selected_companies } = useAppSelector((state) => state.companies)
  const { company_lists } = useAppSelector((state) => state.companies)
  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [activeFilters] = useActiveFilters()
  const list_id =
    locationPathName === 'contacts' ? activeFilters.contactsList[0].list_id : activeFilters.companyList[0].list_id
  const activeList = company_lists.filter((list: any) => list_id === list.id)[0]
  const disable =
    (activeList.type === '1' && locationPathName === 'companies') ||
    (activeList.type === '2' && locationPathName === 'contacts')

  const numberToRemove =
    locationPathName === 'companies'
      ? `${selected_companies.length} ${selected_companies.length === 1 ? 'company' : 'companies'}`
      : `${selected_contacts.length} ${selected_contacts.length === 1 ? 'contact' : 'contacts'}`

  const arraysEqual = (a: any, b: any) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  const handleRemoveItemFromListCompany = async () => {
    const companiesItems = activeList.company_ids
    const companiesArray = companiesItems.split(',')
    const filteredCompaniesArray = companiesArray.filter((company: any) => !selected_companies.includes(company))
    const updatedCompaniesItems = filteredCompaniesArray.join(',')
    post('/log', {
      action: 'Remove from list (Companies)',
    })
    try {
      await refetchUpdateList({
        id: list_id,
        items_list: updatedCompaniesItems,
        // field_name: 'company_ids',
        field_name: locationPathName === 'companies' ? 'company_ids' : 'contact_ids',
        // page_type: 'company',
        page_type: locationPathName === 'companies' ? 'company' : 'contact',
      })

      message.success(`Items removed from the list successfully!`)
      onClose()
      window.location.reload() // Switch with refetching data... ?
    } catch (e) {
      console.error(e)
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleRemoveItemFromListContact = async () => {
    const contactsItems = activeList.contact_ids
    const companiesItems = activeList.company_ids
    const companiesArray = companiesItems?.split(',')?.filter((item: any) => item !== '')

    const contactsArray = contactsItems.split(',')
    const filteredContactsArray = contactsArray.filter((contact: any) => !selected_contacts.includes(contact))
    const updatedContactsItems = filteredContactsArray.join(',')

    post('/log', {
      action: 'Remove from list (Contacts)',
    })
    try {
      await refetchUpdateList({
        id: list_id,
        items_list: updatedContactsItems,
        // field_name: 'contact_ids',
        field_name: locationPathName === 'companies' ? 'company_ids' : 'contact_ids',
        // page_type: locationPathName === 'companies' ? 'company' : 'contact',
      })

      const newData = await get('/contacts/getCompanyIdByContactId', { params: { contact_id: filteredContactsArray } })
      const newCompanyIds = newData.data.company_ids

      if (!arraysEqual(newCompanyIds, companiesArray)) {
        await refetchUpdateList({
          id: list_id,
          items_list: newCompanyIds,
          field_name: 'company_ids',
          page_type: 'company',
        })
      }
      message.success(`Items removed from the list successfully!`)
      onClose()
      window.location.reload() // Switch with refetching data... ?
    } catch (e) {
      console.error(e)
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleRemoveItemFromList = isCompany ? handleRemoveItemFromListCompany : handleRemoveItemFromListContact

  return (
    <Modal
      className='modalStyleNewDeal'
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={() => {
        onClose()
      }}
      width={'fit-content'}
      style={{ minWidth: '400px', maxWidth: '400px' }}>
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className={styles.headerStyle}>
            <img src={add_to_list} alt='' />
            <h1>Remove Selected From List</h1>
          </Header>
          <Content className={styles.contentStyle} style={{ borderBottom: '1px solid #ebecf0' }}>
            <div className={styles.createNew}>
              {!disable ? (
                <p>You can only remove contacts from a contacts list, and companies from a companies list.</p>
              ) : (
                <p>
                  Are you sure you want <br />
                  to remove {numberToRemove} from your list?
                </p>
              )}
            </div>
          </Content>
          <Footer className={styles.footerStyle}>
            <div className={styles.section}>
              <div className={styles.back} onClick={onClose}>
                <span>Cancel</span>
              </div>
              <Button className={styles.button} onClick={!disable ? onClose : handleRemoveItemFromList}>
                {disable ? 'Confirm' : 'Ok'}
              </Button>
            </div>
          </Footer>
        </Layout>
      </Space>
    </Modal>
  )
}
export default RemoveFromListModal
