import React from 'react'
import premium_home_screen from 'common/assets/svg/premium_home_screen.svg'
import styles from './Home.module.scss'

const Home: React.FC = () => {
  return (
    <div className='blur-overlay'>
      <div className={styles.container}>
        <img src={premium_home_screen} alt='' className={styles.fullscreenImage} />
      </div>
    </div>
  )
}

export default Home
