import React, { useCallback } from 'react'
import styles from './CustomCollapseNode.module.scss'
import { Handle, Position } from 'reactflow'
import collapse_node_icon from 'common/assets/svg/collapse_node_icon.svg'
import { useUpdateNodeInternals } from 'reactflow'

interface CustomCollapseNodeProps {
  id: any
  data: any
}

const CustomCollapseNode: React.FC<CustomCollapseNodeProps> = ({ id, data }) => {
  //   const [isExpanded, setIsExpanded] = useState(false)

  const updateNodeInternals = useUpdateNodeInternals()

  const toggleExpansion = useCallback(() => {
    // setIsExpanded(!isExpanded)
    updateNodeInternals(id)
  }, [id, updateNodeInternals])

  return (
    <div className={`${styles.node} ${data.isExpand ? styles.expanded : ''}`} onClick={toggleExpansion}>
      <Handle type='target' position={Position.Top} />
      <div className={styles.leftSide}>
        <img src={collapse_node_icon} alt='' />
        <div className={styles.text}>
          <p>{data.headerText}</p>
          <span>
            <span style={{ color: '#97A0AF' }}>Step's Amount</span> {data.stepsAmount}
          </span>
        </div>
      </div>
      <div className={styles.rightSide}>
        {data.isExpand && (
          <div className={styles.additionalContent}>
            <p>Additional content goes here...</p>
            <p>Additional content goes here...</p>
            <p>Additional content goes here...</p>
          </div>
        )}
      </div>
      <Handle type='source' position={Position.Bottom} id='a' />
    </div>
  )
}

export default CustomCollapseNode
