import { useEffect, useState } from 'react'
import styles from './ConnectPlusFilter.module.scss'
import { Switch } from 'antd'

import LoadingBox from '../LoadingBox/LoadingBox'
import AnalyzingData from './AnalyzingData'
import { telegramGet } from 'common/api/axios'
import { useConnectPlus } from 'common/providers/ConnectPlusProvider'
import { SET_FILTER, SET_LAST_ACTION_PAGE } from 'features/intent-signals/state/slice/companiesSlice'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import MoreOptions from 'features/intent-signals/components/MoreOptions/MoreOptions'
import { useActiveFilters } from 'common/hooks/useFilters'
import ConnectPlusModal from '../ConnectPlus/ConnectPlusModal'
import { useAuth } from 'common/hooks/useAuth.hooks'
import CircleSwitch from './CircleSwitch'
import { SET_LAST_ACTION_PAGE_CONTACT } from 'features/contacts/state/slice/contactsSlice'

const videoSrc = require('../../assets/mp4/connect_plus.mp4') as any

const routeMap = {
  companies: {
    amount: 'companies consist of your Telegram contacts',
    amountSingle: 'company consists of your Telegram contacts',
    description: 'Show only companies that have your Telegram contacts',
  },
  contacts: {
    amount: 'contacts are on your Telegram contacts',
    amountSingle: 'contact is on your Telegram contacts',
    description: 'Show only contacts that appear on your Telegram contacts',
  },
}

const DISPLAYED = 3

const ConnectPlusFilter = ({ route }: { route: 'companies' | 'contacts' }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [fetchingStatus, setFetchingStatus] = useState<any>()
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)
  const { userDetails } = useAuth()
  const [, setActiveFilters] = useActiveFilters()
  const { connectPlusSum, connectPlusPictures } = useConnectPlus()
  const [selectedCircles, setSelectedCircles] = useState<number[]>([])
  const userData = localStorage.getItem('user') || '{}'
  const userTelegram = JSON.parse(userData)?.telegram
  const hasTelegram = !!Object.keys(userDetails?.telegram || {})?.length || !!Object.keys(userTelegram || {})?.length

  // const hasTelegram = !!Object.keys(userDetails?.telegram || {})?.length
  const disableCircleSwitch = !filter?.connectPlus

  useEffect(() => {
    setChecked(!!filter?.connectPlus)
  }, [filter])

  useEffect(() => {
    if (checked === false) {
      setSelectedCircles([])
    }
  }, [checked])

  useEffect(() => {
    if (!hasTelegram) return

    const getFetchStatus = async () => {
      try {
        setLoading(true)
        const response: any = await telegramGet(`/getContributorLogs`, {
          params: {
            ...userDetails?.telegram,
          },
        })

        setFetchingStatus(response?.data[0]?.completion)
      } catch (e) {
        console.error(`Error getting fetch status: ${e}`)
      } finally {
        setLoading(false)
      }
    }

    getFetchStatus()
    //v ADD HERE
  }, [hasTelegram])

  const toggleConnectPlus = (switchCheck: boolean) => {
    setChecked(switchCheck)
    const filterCopy = JSON.parse(JSON.stringify(filter))

    if (!filterCopy?.connectPlus) filterCopy.connectPlus = true
    else delete filterCopy.connectPlus

    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

    dispatch(SET_FILTER(filterCopy))
    setActiveFilters(filterCopy)
  }

  const handleChange = (switchCheck: boolean) => (hasTelegram ? toggleConnectPlus(switchCheck) : setOpen(true))

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeCircle = (circleValue: any) => {
    const index = selectedCircles.indexOf(circleValue)
    const newSelectedCircles = [...selectedCircles]

    if (index === -1) {
      newSelectedCircles.push(circleValue)
    } else {
      newSelectedCircles.splice(index, 1)
    }

    setSelectedCircles(newSelectedCircles)
  }

  useEffect(() => {
    const filterCopy = JSON.parse(JSON.stringify(filter))

    if (selectedCircles.length === 0 || !checked) {
      delete filterCopy.circle
    } else {
      filterCopy.circle = selectedCircles
    }

    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

    dispatch(SET_FILTER(filterCopy))
    setActiveFilters(filterCopy)
  }, [selectedCircles, checked])

  return (
    <div className={styles.column}>
      {loading ? (
        <LoadingBox />
      ) : hasTelegram && fetchingStatus !== 100 ? (
        <AnalyzingData completion={fetchingStatus ? (fetchingStatus < 17 ? 17 : fetchingStatus) : 17} />
      ) : (
        <>
          <Switch checked={checked || !!filter?.connectPlus} onClick={handleChange} />
          <h1>{'Turn on Connect+ Filter'}</h1>
          <p>{routeMap[route]?.description}</p>
          {route === 'contacts' && (
            <div className={styles.container}>
              <CircleSwitch
                text='1st'
                tooltipText='These are people in your contact list.'
                color='green'
                checked={selectedCircles.includes(1)}
                onChange={() => {
                  handleChangeCircle(1)
                }}
                disabled={disableCircleSwitch}
                addons={user?.addons?.connectplus_1st}
              />
              <CircleSwitch
                text='2nd'
                tooltipText='These connections share small and private groups with you.'
                color='purple'
                checked={selectedCircles.includes(2)}
                onChange={() => {
                  handleChangeCircle(2)
                }}
                disabled={disableCircleSwitch}
                addons={user?.addons?.connectplus_2nd}
              />
              <CircleSwitch
                text='3rd'
                tooltipText='These connections share large groups, communities, and channels with you.'
                color='pink'
                checked={selectedCircles.includes(3)}
                onChange={() => {
                  handleChangeCircle(3)
                }}
                disabled={disableCircleSwitch}
                addons={user?.addons?.connectplus_3rd}
              />
            </div>
          )}
          {hasTelegram ? (
            !!connectPlusSum && (
              <div className={styles.teaser}>
                <div className={styles.picsRow}>
                  {connectPlusPictures?.slice(0, 3)?.map((url: string) => (
                    <FancyImage url={url} className='smallRoundPicture' iscompany={route === 'companies'} />
                  ))}
                  {connectPlusSum > DISPLAYED && (
                    <MoreOptions className='more' sum={connectPlusSum} displayed={DISPLAYED} />
                  )}
                </div>
                <h5>{`${connectPlusSum} ${routeMap[route][connectPlusSum === 1 ? 'amountSingle' : 'amount']}`}</h5>
              </div>
            )
          ) : (
            <div className={styles.videoContainer}>
              <video src={videoSrc} autoPlay loop muted />
            </div>
          )}
          {!hasTelegram && <ConnectPlusModal open={open} onClose={handleClose} />}
        </>
      )}
    </div>
  )
}

export default ConnectPlusFilter
