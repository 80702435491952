/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'common/hooks/useAuth.hooks'

import { Col, Divider, Input, Modal, Popconfirm, Popover, Row, Tooltip, message } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { post } from 'common/api/axios'

import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import large_lists from 'common/assets/svg/large_lists.svg'
import arrow_down from 'common/assets/svg/arrow_down.svg'
import company_list from 'common/assets/svg/company_list.svg'
import contact_list from 'common/assets/svg/contact_list.svg'
import arrow_up from 'common/assets/svg/arrow_up.svg'
import stars from 'common/assets/svg/stars.svg'

import FancyFilterExpandItemBody from 'common/components/fancy-filter-expand-item-body/fancy-filter-expand-item-body'
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { IExpandGroupeChildren, IExpandListChildren, IFilter } from 'common/interfaces/IFilters'

import { useAppDispatch, useAppSelector } from 'state'
import { setReset, setTechSidebarOpen, setTechSidebarType } from 'state/filtersSlice/filtersSlice'

import FancyFilterExpandGroupe from '../fancy-filter-expand-groupe/fancy-filter-expand-grupe'
import styles from './fancy-filter-expand-item.module.scss'

import { ReactComponent as LockComponent } from 'common/assets/svg/lock.svg'
import {
  useLazyAddNewListQuery,
  useLazyDeleteListQuery,
  useLazyGetCompaniesByIdsQuery,
  useLazyGetListsQuery,
  useLazyRenameListQuery,
} from '../../../features/intent-signals/state/api/CompaniesApi'
import {
  SET_FILTER,
  SET_LISTS,
  SET_SELECTED_COMPANIES_DETAILS,
} from '../../../features/intent-signals/state/slice/companiesSlice'
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CustomTag } from '../CustomTag/CustomTag'
import { removeEmpty } from '../../utils/objectToValues'
import { useCookies } from 'react-cookie'
import { ConfirmBox } from '../../constants/modal.constants'
import { injectionRegex } from '../../static-data/userData'
import { MESSAGE_ERROR } from '../../constants/messages.constants'
import NewTag from '../NewTag/NewTag'
// import BlurPaywall from '../../../features/intent-signals/components/paywalls/BlurPaywall'
import { outreach } from 'common/constants/outreach.constants'
import BlurPaywallFilters from 'features/intent-signals/components/paywalls/BlurPaywallFilters'

interface IFancyFilterExpandItem {
  item: IFilter
}

const FancyFilterExpandItem: React.FC<IFancyFilterExpandItem> = ({ item }) => {
  const { userDetails } = useAuth()
  const [outOfCredits, setOutOfCredits] = useState(false)

  const location = useLocation()
  const [openContactSales, setOpenContactSales] = useState(false)
  const [expand, setExpand] = useState(!!item?.open)
  const [renameList, setRenameList] = useState('')
  const [addToListInput, setAddToListInput] = useState(false)
  const [selectedList, setSelectedList] = useState<any>(null)
  const [activeCompanies, setActiveCompanies] = useState(0)
  const dispatch = useAppDispatch()
  const { isTechSidebarOpen, resetFilter } = useAppSelector((state) => state.filters)
  const { companyId } = useAppSelector((state) => state.contacts)
  const { selected_companies_details, company_lists, global_company_selected } = useAppSelector(
    (state) => state.companies,
  )
  const [refetchGetCompaniesByIds] = useLazyGetCompaniesByIdsQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchDeleteList] = useLazyDeleteListQuery()
  const [refetchRenameList] = useLazyRenameListQuery()
  const { filter } = useAppSelector((state) => state.companies)
  const [listNameCookies, setListNameCookies] = useCookies(['list_name'])
  const [refetchAddList] = useLazyAddNewListQuery()
  // eslint-disable-next-line
  const [selectedCompaniesCookies, setSelectedCompaniesCookies] = useCookies(['selected_companies'])

  useEffect(() => {
    if (resetFilter) {
      setSelectedList(null)
      dispatch(setReset(false))
      setExpand(false)
      setAddToListInput(false)
    }
  }, [resetFilter])

  useEffect(() => {
    if (selectedCompaniesCookies['selected_companies']?.length) {
      if (selected_companies_details.length === 0 && location.pathname === '/contacts' && !selectedList) {
        const fetchData = async () => {
          const response = await refetchGetCompaniesByIds(global_company_selected.join(','))
          if (!response.isError) {
            dispatch(SET_SELECTED_COMPANIES_DETAILS(response?.data?.map((obj: any) => ({ ...obj, selected: true }))))
          }
        }

        if (selected_companies_details.length === 0 && global_company_selected.length !== 0) fetchData()
      } else {
        const fetchDataCompanyLists = async () => {
          let result = await refetchLists()
          if (result) dispatch(SET_LISTS(result.data))
        }

        fetchDataCompanyLists()
      }
      setActiveCompanies(selected_companies_details.filter((item: any) => item.selected === true).length)
    }
  }, [companyId, selected_companies_details])

  useEffect(() => {
    if ((filter.contactsList || filter.companyList) && selected_companies_details.length > 0) {
      setActiveCompanies(0)
    } else {
      setActiveCompanies(selected_companies_details.filter((item: any) => item.selected === true).length)
    }
  }, [activeCompanies, company_lists, filter, listNameCookies])

  useEffect(() => {
    setSelectedList(null)
  }, [location.pathname])

  useEffect(() => {
    if (selectedList) {
      const fetchData = async () => {
        const filterCopy = JSON.parse(JSON.stringify(filter))
        if (location.pathname === '/contacts') {
          delete filterCopy.contactsList
          _.set(filterCopy, 'contactsList', [
            {
              list_id: selectedList.id,
            },
          ])
        } else if (location.pathname === '/companies') {
          delete filterCopy.contactsList
          _.set(filterCopy, 'companyList', [
            {
              list_id: selectedList.id,
            },
          ])
        }
        dispatch(SET_FILTER(removeEmpty(filterCopy)))
      }

      fetchData()
    }
  }, [selectedList])

  const toggleExpand = (key: string) => {
    if (!item?.comingSoon) {
      if (item.type === 'sidelist') {
        dispatch(setTechSidebarOpen(!isTechSidebarOpen))
        dispatch(setTechSidebarType(key))
      }

      setExpand(!expand)
    }
  }

  const handleDeleteList = async (listId: string) => {
    const result = await refetchDeleteList({ id: listId })

    if (result) {
      let getLists = await refetchLists()

      if (getLists) dispatch(SET_LISTS(getLists.data))
      message.success(`List deleted successfully`)
    }
  }

  const handleRenameList = async (event: any) => {
    if (13 === event.keyCode) {
      let getList = company_lists.filter((element: any) => element.name === event.target.value)

      if (getList.length === 0) {
        const result = await refetchRenameList({ id: renameList, name: event.target.value })

        if (result) {
          let getLists = await refetchLists()

          if (getLists) dispatch(SET_LISTS(getLists.data))
          setRenameList('')
          message.success(`List renamed successfully`)
        }
      } else {
        setRenameList('')
        message.success(`We already have a list with the same name!`)
      }
    }
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => item.name === event.target.value)

        if (!checkItem) {
          let result = await refetchAddList({
            name: event.target.value,
            type: location.pathname === '/contacts' ? 2 : 1,
          })

          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()

              if (getLists) dispatch(SET_LISTS(getLists.data))
              setAddToListInput(!addToListInput)
              message.success(`The list, '${event.target.value}' was created successfully.`)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  useEffect(() => {
    if (company_lists?.length === 0) {
      const fetchDataCompanyLists = async () => {
        const result = await refetchLists()

        if (result) dispatch(SET_LISTS(result.data))
      }

      fetchDataCompanyLists()
    }
  }, [])

  const boxShadow =
    location?.pathname === '/companies'
      ? item?.key === 'intents'
        ? '0px 4px 4px rgba(50, 50, 50, 0.2) inset'
        : item?.key === 'funding'
        ? '0px -4px 4px rgba(50, 50, 50, 0.2) inset'
        : ''
      : location?.pathname === '/contacts'
      ? item?.key === 'intents'
        ? '0px 4px 4px rgba(50, 50, 50, 0.2) inset'
        : item?.key === 'funding'
        ? '0px -4px 4px rgba(50, 50, 50, 0.2) inset'
        : ''
      : ''
  return (
    <>
      <Row
        className={classNames(styles.item, expand && styles.expanded)}
        key={item.key}
        style={
          item.comingSoon
            ? {
                backgroundColor: '#f3f4f6',
                boxShadow: boxShadow,
                borderBottom: '1px solid #dfe1e6',
              }
            : {}
        }>
        <Tooltip
          placement='right'
          title={
            userDetails?.subscription_type === 'BETA' &&
            item?.tooltip && (
              <div style={{ margin: '10p' }}>
                <div>
                  {item?.tooltip}
                  <span style={{ textDecoration: 'underline' }}>{item?.tooltipUnderline}</span>
                </div>
              </div>
            )
          }>
          <div
            className='flex cursor-pointer w-full'
            onClick={() => {
              toggleExpand(item.key)

              if (item.comingSoon) {
                post('/log', {
                  action: `${item.label}`,
                })
                item.comingSoon && setOpenContactSales(true)
              }
            }}>
            {item.image &&
              (typeof item.image === 'string' ? (
                <Col span={4}>
                  <img src={item.image} alt='' />
                </Col>
              ) : (
                <Col className={styles.pulse}>
                  <item.image />
                </Col>
              ))}
            {item.comingSoon && <LockComponent className={styles.soonTag} />}
            <Col span={item.image ? 16 : 20}>
              {item.beta === true ? (
                <span className={classNames(styles.itemLabel, item.className ? styles[item.className] : '')}>
                  {item.label}
                  <span
                    style={{
                      marginLeft: '5px',
                      backgroundColor: '#7043ff',
                      color: 'white',
                      borderRadius: '6px',
                      padding: '4px 6px',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}>
                    BETA
                  </span>
                </span>
              ) : (
                <span className={classNames(styles.itemLabel, item.className ? styles[item.className] : '')}>
                  {item.label}
                </span>
              )}
              {item?.new && <NewTag />}
            </Col>
            <Col span={4}>
              <img
                className='pl-2 cursor-pointer'
                onClick={() => toggleExpand(item.key)}
                src={!expand ? arrow_down : arrow_up}
                alt={''}
              />
            </Col>
          </div>
        </Tooltip>

        {item.children && expand && item.type === 'expandList' && item.children.length > 0 && (
          <FancyFilterExpandItemBody data={item.children as IExpandListChildren[]} />
        )}
        {expand && item.type === 'expandGroupe' && (
          <div className='py-3 w-full flex flex-column gap-3'>
            {item?.children &&
              item?.children?.map((child: IExpandGroupeChildren | IExpandListChildren, index: number) => (
                <>
                  <FancyFilterExpandGroupe key={index} data={child as IExpandGroupeChildren} />
                </>
              ))}
          </div>
        )}
        {/* {expand && item.type === 'companiesList' && (
          <>
            <div className={styles.selectedCompaniesExpanded}>
              <Swiper
                className='mx-0'
                modules={[]}
                navigation={{ nextEl: '.nextEl', prevEl: '.prevEl' }}
                slidesPerView={'auto'}>
                <SwiperSlide className='w-auto'>
                  <CustomTag
                    className='h-2rem w-min'
                    closable
                    onClose={() => {
                      dispatch(
                        SET_SELECTED_COMPANIES_DETAILS(
                          selected_companies_details.map((obj: any) => {
                            return { ...obj, selected: false }
                          }),
                        ),
                      )
                    }}>
                    {' '}
                    {`(${activeCompanies})`}
                  </CustomTag>
                </SwiperSlide>
              </Swiper>
            </div>
            { {selected_companies_details?.map((company: any) => {
              return (
                <div
                  className={classNames(styles.companyItem, !company.selected ? styles.unselectedCompany : '')}
                  key={company.id}>
                  <span className={styles.label}>{company.name}</span>
                  <img
                    className={classNames(styles.close, !company.selected ? styles.unselected : '')}
                    src={close_company}
                    alt=''
                    onClick={() => {
                      handleActiveCompany(company.id)
                    }}
                  />
                </div>
              )
            })} }
          </>
        )} */}
        {!expand && item.type === 'companiesList' && (
          <div className={styles.selectedCompanies}>
            <Swiper
              className='mx-0'
              modules={[]}
              navigation={{ nextEl: '.nextEl', prevEl: '.prevEl' }}
              slidesPerView={'auto'}>
              <SwiperSlide className='w-auto'>
                <CustomTag
                  className='h-2rem w-min'
                  closable
                  onClose={() => {
                    dispatch(
                      SET_SELECTED_COMPANIES_DETAILS(
                        selected_companies_details.map((obj: any) => {
                          return { ...obj, selected: false }
                        }),
                      ),
                    )
                  }}>
                  {' '}
                  {`(${activeCompanies})`}
                </CustomTag>
              </SwiperSlide>
            </Swiper>
          </div>
        )}
        {expand && item.type === 'listsFilter' && (
          <div className='py-3 w-full flex flex-column gap-3'>
            {company_lists.length === 0 && (
              <div className={styles.emptyLists}>
                <img src={large_lists} alt='' />
                <p>Create Lists</p>
                <span>
                  To create a list select your companies or contacts of interest and at the bottom of the screen click{' '}
                  <span style={{ fontWeight: 600 }}>{'Actions -> Add to list'}</span>
                </span>
              </div>
            )}
            {company_lists.map((item: any) => {
              return renameList === item.id ? (
                <Input
                  placeholder=''
                  className='renameListInput'
                  onKeyDown={handleRenameList}
                  defaultValue={item.name}
                />
              ) : (
                <>
                  <div
                    className={classNames('filterListItem', selectedList?.id === item.id ? 'selectedListItem' : '')}
                    onClick={() => {
                      let expires = new Date()
                      expires.setTime(expires.getTime() * 1000)
                      setSelectedList(item)
                      if (renameList !== '') setRenameList('')
                      setListNameCookies('list_name', item.name, { path: '/', expires })
                    }}>
                    {item.name.includes('twitter_mentions_list') ? (
                      <img src={stars} alt={''} style={{ width: '20px', height: '20px' }} />
                    ) : (
                      <img src={Number(item?.type) === 1 ? company_list : contact_list} alt={''} />
                    )}
                    <Tooltip placement='topLeft' title={item.name}>
                      <span className={classNames('textTooltipLists')}>{item.name}</span>
                    </Tooltip>
                    <Popover
                      placement='right'
                      title={''}
                      content={
                        <div className='listOptions'>
                          <span
                            className='removePadding'
                            onClick={() => {
                              setRenameList(item.id)
                            }}>
                            Rename List
                          </span>
                          <Divider />
                          <span className='removePadding'>
                            <Popconfirm
                              title={ConfirmBox.DELETE_LIST}
                              description={
                                <Col>
                                  <div>{`${ConfirmBox.DELETE_LIST_ARE_YOU_SURE}`}</div>
                                </Col>
                              }
                              onConfirm={() => {
                                handleDeleteList(item.id)
                              }}
                              onCancel={() => {
                                message.error('You have left this action!')
                              }}
                              okText='Yes'
                              cancelText='No'>
                              Delete List
                            </Popconfirm>
                          </span>
                        </div>
                      }
                      trigger='click'>
                      <span className='moreOptions'>...</span>
                    </Popover>
                  </div>
                </>
              )
            })}
            {addToListInput && (
              <div className={classNames('addNewContainer', 'addNewContainerNoMargin')}>
                <Input placeholder='' className={classNames('addNewInput')} onKeyDown={handleAddNewItem} />
              </div>
            )}
            {/* <span
              className={classNames('createANewItem', 'createNewItemNoMargin')}
              onClick={() => {
                setAddToListInput(!addToListInput)
              }}>
              + Create New
            </span> */}
          </div>
        )}
        {expand && item.component && item.component}
      </Row>
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={item.key}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            component={[]}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
        />
      )}
    </>
  )
}

export default FancyFilterExpandItem
