import React, { useEffect, useState } from 'react'
import { Table, Card, Layout, Button, Tooltip, Modal, message, Empty, Popover } from 'antd'
import welcome_to_convrt from 'common/assets/svg/welcome_to_convrt.svg'
import styles from './ICPSettings.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { PlusCircleOutlined } from '@ant-design/icons'
import OnboardingICP from './Onboarding/OnboardingICP'
import {
  useLazyGetUserSettingsQuery,
  useLazySubmitOnboardingStepQuery,
  useLazyUpdateICPQuery,
} from '../state/api/SettingsApi'
import { DownOutlined, UpOutlined } from 'styles/Icons/Icons'
import { areAllKeysFilled, getColumns, renderExpandableEditField } from './SettingsUtils'

interface DataType {
  key: string
  name: string
  job_title: string[]
  locations: string[]
  industry: string[]
  number_of_employees: string[]
  competitive_advantage: string[]
  problem: string
  solution: string
  results: string
}

interface ICPSettingsProps {
  data: DataType[]
}
const ICPSettings: React.FC<ICPSettingsProps> = ({ data }) => {
  const [tableData, setTableData] = useState(data)
  const [editingKey, setEditingKey] = useState<string | null>(null)
  const [editData, setEditData] = useState<DataType | any>(null)
  const [highlightMissingValues, setHighlightMissingValues] = useState(false)
  const [disableAddPersona, setDisableAddPersona] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [ICPData, setICPData] = useState({})
  const [loading, setLoading] = useState(false)
  const [emptyState, setEmptyState] = useState(false)
  const [submitOnboardingStep] = useLazySubmitOnboardingStepQuery()
  const [updateICP] = useLazyUpdateICPQuery()
  const [getUserSettings] = useLazyGetUserSettingsQuery()

  const startEdit = (record: DataType) => {
    setEditingKey(record.key)
    setEditData({ ...record })
  }

  const replaceObjectByKey = (data: any, newObject: any) => {
    return data.map((item: any) => (item.key === newObject.key ? newObject : item))
  }

  const validate = (data: any) => {
    if (!data.name) {
      return 'Persona Name'
    }
    if (!data.job_title || data.job_title?.length === 0) {
      return 'Job Title'
    }
    if (!data.industry || data.industry?.length === 0) {
      return 'Industry'
    }
    if (!data.locations || data.locations?.length === 0) {
      return 'Location'
    }
    if (!data.number_of_employees || data.number_of_employees?.length === 0) {
      return 'Number of Employees'
    }
    return 'None'
  }
  const saveEdit = async () => {
    const valid = validate(editData)
    if (valid !== 'None') {
      message.error(`${valid} is missing.`)
      return
    }
    const updatedData = replaceObjectByKey(data, editData)
    const { data: icpData } = await updateICP({ data: editData })
    if (icpData) {
      setTableData(updatedData)
      setEditingKey(null)
      setEditData(null)
      await getUserSettings()
      message.success('Persona updated successfully')
    } else {
      message.error('Something went wrong. Please try again.')
    }
  }

  const cancelEdit = () => {
    setEditingKey(null)
    setEditData(null)
  }

  const columns = getColumns(editingKey, editData, startEdit, saveEdit, cancelEdit, setEditData)

  const expandable = {
    expandedRowRender: (record: DataType) => {
      const isEditing = record.key === editingKey
      return (
        <div className={styles.expandedContent}>
          <Card className={styles.section}>
            <strong>Problem</strong>
            {isEditing ? (
              renderExpandableEditField('problem', editData, setEditData)
            ) : record?.problem ? (
              <p>{record.problem}</p>
            ) : (
              <Empty description='No Problem Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Solutions</strong>
            {isEditing ? (
              renderExpandableEditField('solution', editData, setEditData)
            ) : record?.solution ? (
              <p>{record.solution}</p>
            ) : (
              <Empty description='No Solutions Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Results</strong>
            {isEditing ? (
              renderExpandableEditField('results', editData, setEditData)
            ) : record?.results ? (
              <p>{record.results}</p>
            ) : (
              <Empty description='No Results Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
        </div>
      )
    },
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }: any) =>
      expanded ? (
        <UpOutlined onClick={(e: any) => onExpand(record, e)} />
      ) : (
        <DownOutlined onClick={(e: any) => onExpand(record, e)} />
      ),
    rowExpandable: (record: DataType) => record.name !== '',
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  const handleCreatePersona = async () => {
    if (!areAllKeysFilled(ICPData, true)) {
      setOpenPopover(true)
      setHighlightMissingValues(true)
    } else {
      proceedWithCreatePersona()
    }
  }

  const proceedWithCreatePersona = async () => {
    setLoading(true)
    const { data } = await submitOnboardingStep({ step: 'ICP', data: ICPData })
    if (data?.success) {
      message.success('Persona Created Successfully!')
      await getUserSettings()
      setOpenModal(false)
    } else {
      message.error(`Can't update user's ICP. Please try again or contact support.`)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data)
      setEmptyState(false)
      setDisableAddPersona(true)
    } else {
      setEmptyState(true)
      setDisableAddPersona(false)
    }
  }, [data])

  const footerStyle: React.CSSProperties = {
    textAlign: 'end',
    color: '#fff',
    backgroundColor: '#F9FAFB',
    padding: 0,
  }
  const contentStyle: React.CSSProperties = {
    backgroundColor: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: 0,
  }
  const headerStyle: React.CSSProperties = {
    backgroundColor: '#F9FAFB',
    padding: 0,
    height: 'fit-content',
  }
  const layoutStyle: React.CSSProperties = {
    display: 'flex',
    padding: '32px 64px',
    flexDirection: 'column',
    background: '#F9FAFB',
    height: '100%',
    gap: '32px',
  }

  const popoverContent = (
    <div className={styles.popoverContainer}>
      <p>Some values are missing. Would you like to fill them in?</p>
      <span
        onClick={() => {
          setOpenPopover(false)
          setHighlightMissingValues(false)
          proceedWithCreatePersona()
        }}>
        Continue Anyway
      </span>
    </div>
  )

  const empty = (
    <div className={styles.emptyState}>
      <img src={welcome_to_convrt} alt='' />
      <h1>Add your first Persona</h1>
      <p>Create a Persona and help our AI model identify, match and optimize your leads and campaigns</p>
      <Button disabled={disableAddPersona} type='primary' onClick={showModal}>
        <div className={styles.buttonDiv}>
          <PlusCircleOutlined />
          <span>Create a Persona</span>
        </div>
      </Button>
    </div>
  )

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <h2 className={styles.title}>Personas</h2>
      </Header>
      <Content style={contentStyle}>
        <Card className={`${styles.container} ${styles.table} ${emptyState ? 'cardEmpty' : ''}`}>
          {emptyState ? (
            empty
          ) : (
            <Table
              className='ICPTable hoverableScrollbarTable'
              tableLayout='fixed'
              columns={columns}
              scroll={{ y: 460, x: '100%' }}
              expandable={expandable}
              dataSource={tableData}
              pagination={false}
            />
          )}
        </Card>
      </Content>
      <Footer style={footerStyle}>
        {!emptyState && (
          <Tooltip title={disableAddPersona ? 'You can have only a single persona' : ''}>
            <Button disabled={disableAddPersona} type='primary' onClick={showModal}>
              <div className={styles.buttonDiv}>
                <PlusCircleOutlined />
                <span>Add New Persona</span>
              </div>
            </Button>
          </Tooltip>
        )}
      </Footer>
      <Modal
        width={'75%'}
        open={openModal}
        onCancel={handleCancel}
        title={
          <div className={styles.modalHeader}>
            <h2>Let's Create a Persona</h2>
          </div>
        }
        footer={
          <div className={styles.modalFooter}>
            <Popover
              content={popoverContent}
              open={openPopover}
              onOpenChange={() => {
                setOpenPopover(false)
                setHighlightMissingValues(false)
              }}>
              <Button
                loading={loading}
                disabled={!areAllKeysFilled(ICPData, false)}
                type='primary'
                onClick={handleCreatePersona}>
                Create Persona
              </Button>
            </Popover>
          </div>
        }>
        <OnboardingICP setICPData={setICPData} highlightMissingValues={highlightMissingValues} />
      </Modal>
    </Layout>
  )
}

export default ICPSettings
