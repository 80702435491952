import React, { useEffect, useState } from 'react'
import { Select, Input, Row, Col, Typography } from 'antd'
import { CountryRegionData } from 'react-country-region-selector'
import styles from './OnboardingICP.module.scss'
import { industries, personas } from 'common/components/OutreachAI/Helpers/CampaignConstants'

const { TextArea } = Input
const { Option } = Select
const { Text } = Typography

const industryValues = industries.map((industry) => ({ value: industry }))
const personasValues = personas.map((persona) => ({ value: persona }))

const formatOptions = () => {
  return CountryRegionData.flatMap(([countryName, , regionsString]) => {
    const regions = regionsString ? regionsString.split('|') : []
    const countryOption = {
      value: countryName,
      label: (
        <div>
          <label style={{ fontWeight: 600, cursor: 'pointer' }}>
            {countryName} <span style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>country</span>
          </label>
        </div>
      ),
    }
    const regionOptions = regions.map((region) => {
      const [regionName, regionCode] = region.split('~')
      return {
        value: `${regionName}, ${regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''}${countryName}`,
        label: (
          <div style={{ cursor: 'pointer' }}>
            <label style={{ fontSize: '12px', cursor: 'pointer' }}>{`${regionName}, ${
              regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''
            }${countryName} `}</label>
            <label style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>region</label>
          </div>
        ),
      }
    })

    return [countryOption, ...regionOptions]
  })
}

// const parseLocation = (location: string) => {
//   if (!location) return { state: '', region: '', country: '' }

//   const parts = location.split(',').map((part) => part.trim())

//   if (parts.length === 3) {
//     // Format: Region, State, Country
//     return { region: parts[0], state: parts[1], country: parts[2] }
//   } else if (parts.length === 2) {
//     // Format: State, Country or Region, Country
//     const [first, second] = parts
//     // Assume the last part is always the country
//     const isPossibleRegion = CountryRegionData.some(
//       ([country, , regions]) => regions && regions.split('|').some((region) => region.includes(first)),
//     )
//     if (isPossibleRegion) {
//       return { region: first, state: '', country: second }
//     }
//     return { state: first, country: second }
//   } else if (parts.length === 1) {
//     // Format: Country
//     return { state: '', region: '', country: parts[0] }
//   }

//   return { state: '', region: '', country: '' }
// }

const parseLocation = (locations: string[]) => {
  return locations.map((location) => {
    const parts = location.split(',').map((part) => part.trim())

    if (parts.length === 3) {
      // Format: Region, State, Country
      return { region: parts[0], state: parts[1], country: parts[2] }
    } else if (parts.length === 2) {
      // Format: State, Country or Region, Country
      const [first, second] = parts
      // Assume the last part is always the country
      const isPossibleRegion = CountryRegionData.some(
        ([country, , regions]) => regions && regions.split('|').some((region) => region.includes(first)),
      )
      if (isPossibleRegion) {
        return { region: first, state: '', country: second }
      }
      return { state: first, country: second }
    } else if (parts.length === 1) {
      // Format: Country
      return { state: '', region: '', country: parts[0] }
    }

    return { state: '', region: '', country: '' }
  })
}

interface OnboardingICPProps {
  setICPData: (values: any) => void
  highlightMissingValues?: boolean
}

const OnboardingICP: React.FC<OnboardingICPProps> = ({ setICPData, highlightMissingValues }) => {
  const [rowsNumber, setRowsNumber] = useState(5)
  const [industry, setIndustry] = useState<string[] | undefined>(undefined)
  const [numberOfEmployees, setNumberOfEmployees] = useState<string[] | undefined>(undefined)
  const [location, setLocation] = useState<string[]>([])
  const [jobTitle, setJobTitle] = useState<string | undefined>(undefined)
  const [problem, setProblem] = useState<string>('')
  const [solution, setSolution] = useState<string>('')
  const [results, setResults] = useState<string>('')
  const [competitiveAdvantage, setCompetitiveAdvantage] = useState<string>('')
  const [name, setName] = useState<string>('')
  const options = formatOptions()

  const sortSelectedOptions = (options: any, selectedValues: any) => {
    const selectedOptions = options.filter((option: any) => selectedValues.includes(option.value))
    const unselectedOptions = options.filter((option: any) => !selectedValues.includes(option.value))
    return [...selectedOptions, ...unselectedOptions]
  }

  const sortedLocationOptions = sortSelectedOptions(options, location)

  const selectStyle: any = {
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.001em',
  }

  useEffect(() => {
    // RESIZE
    const updateSize = () => {
      if (window.innerHeight < 800) {
        setRowsNumber(5)
      } else {
        setRowsNumber(10)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  useEffect(() => {
    // const { state: parsedState, region: parsedRegion, country: parsedCountry } = parseLocation(location)
    const parsedLocations = parseLocation(location)

    const data = {
      name,
      job_title: jobTitle,
      industry,
      number_of_employees: numberOfEmployees,
      locations: location,
      parsed_locations: parsedLocations,
      // state: parsedState,
      // region: parsedRegion,
      // country: parsedCountry,
      problem,
      solution,
      results,
      competitive_advantage: competitiveAdvantage,
    }

    setICPData(data)
  }, [name, industry, numberOfEmployees, location, jobTitle, problem, solution, results, competitiveAdvantage])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text className={styles.textAreaLabel}>Persona Name</Text>
        <Input
          placeholder={`Please type your Persona Name`}
          className={styles.input}
          style={{ maxWidth: '250px' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className={styles.outerBorder}>
        <div className={styles.icpContainer}>
          <div className={styles.textSelect}>
            <Text className={styles.label}>Our ideal persona is</Text>
            <Select
              mode='multiple'
              maxTagCount={1}
              placeholder='Select job titles'
              className={`onboardingICP`}
              style={{ ...selectStyle, minWidth: '160px' }}
              value={jobTitle}
              onChange={setJobTitle}
              options={personasValues}
            />
          </div>
          <div className={styles.textSelect}>
            <Text className={styles.label}>working at</Text>
            <Select
              mode='multiple' // limit to 3
              maxTagCount={1}
              placeholder='Select up to 3 industries'
              className={`onboardingICP`}
              style={{ ...selectStyle, minWidth: '200px' }}
              value={industry}
              onChange={(value: string[]) => {
                if (value.length > 3) {
                  const latest = value?.pop()
                  value?.pop()
                  const newVal: any = [...value, latest]
                  setIndustry(newVal)
                } else {
                  setIndustry(value)
                }
              }}
              options={industryValues}
            />
          </div>
          <div className={styles.textSelect}>
            <Text className={styles.label}>industry with</Text>
            <Select
              mode='multiple'
              maxTagCount={1}
              placeholder='Select number of employee’s'
              className={`onboardingICP`}
              style={{ ...selectStyle, minWidth: '230px' }}
              value={numberOfEmployees}
              onChange={setNumberOfEmployees}>
              <Option value='1-10'>1-10</Option>
              <Option value='11-50'>11-50</Option>
              <Option value='51-200'>51-200</Option>
              <Option value='201-500'>201-500</Option>
              <Option value='501-2000'>501-2000</Option>
              <Option value='2000+'>2000+</Option>
            </Select>
          </div>
          <div className={styles.textSelect}>
            <Text className={styles.label}>employees located in</Text>
            <Select
              mode='multiple'
              maxTagCount={1}
              placeholder='Select Location'
              className={`onboardingICP`}
              style={{ ...selectStyle, minWidth: '350px' }}
              dropdownStyle={{ minWidth: '350px' }}
              value={location}
              onChange={setLocation}
              options={sortedLocationOptions}
              filterOption
            />
          </div>
        </div>
      </div>

      <Row className={styles.formRow} gutter={[16, 16]}>
        <Col span={8} className={styles.textAreaStyle}>
          <Text className={styles.textAreaLabel}>Problem</Text>
          <TextArea
            placeholder='What is your personas problem?'
            maxLength={500}
            rows={rowsNumber}
            className={'onboardingICP'}
            showCount
            style={{ resize: 'none', borderColor: highlightMissingValues && !problem ? 'red' : '' }}
            value={problem}
            onChange={(e) => setProblem(e.target.value)}
          />
        </Col>
        <Col span={8} className={styles.textAreaStyle}>
          <Text className={styles.textAreaLabel}>Solutions</Text>
          <TextArea
            placeholder='What is your personas solution?'
            maxLength={500}
            rows={rowsNumber}
            className={'onboardingICP'}
            showCount
            style={{ resize: 'none', borderColor: highlightMissingValues && !solution ? 'red' : '' }}
            value={solution}
            onChange={(e) => setSolution(e.target.value)}
          />
        </Col>
        <Col span={8} className={styles.textAreaStyle}>
          <Text className={styles.textAreaLabel}>Results</Text>
          <TextArea
            placeholder='Write a bio about yourself'
            maxLength={500}
            rows={rowsNumber}
            className={'onboardingICP'}
            showCount
            style={{ resize: 'none', borderColor: highlightMissingValues && !results ? 'red' : '' }}
            value={results}
            onChange={(e) => setResults(e.target.value)}
          />
        </Col>
      </Row>

      <Row className={styles.formRow} gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Text className={styles.textAreaLabel}>Competitive Advantage</Text>
          <Input
            placeholder='Please type your Competitive advantage'
            className={styles.input}
            value={competitiveAdvantage}
            onChange={(e) => setCompetitiveAdvantage(e.target.value)}
            style={{ borderColor: highlightMissingValues && !competitiveAdvantage ? 'red' : '' }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingICP
