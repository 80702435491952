import _ from 'lodash'
import { shortenNumber } from './inputUtils'

export const objectToValues = (obj: any): any => {
  const lastObjects: any[] = []
  const excludedObjects: any[] = []
  const includedObjects: any[] = []

  const addDollaSign = (key: string) => ['revenue', 'volume', 'tvl'].includes(key)

  const generateRange = (key: string, start?: number, end?: number) => {
    if (key === 'foundingYear') {
      if (start !== undefined && end !== undefined) {
        return `${addDollaSign(key) ? '$' : ''}${start} to ${addDollaSign(key) ? '$' : ''}${end}`
      }
      if (start !== undefined) {
        return `More than ${addDollaSign(key) ? '$' : ''}${start}`
      }
      if (end !== undefined) {
        return `Up to ${addDollaSign(key) ? '$' : ''}${end}`
      }
    }

    if (key === 'headcount') {
      if (end === 1000000) {
        return `More than ${addDollaSign(key) ? '$' : ''} 5k`
      }
    }

    if (start !== undefined && end !== undefined) {
      return `${addDollaSign(key) ? '$' : ''}${shortenNumber(start)} to ${addDollaSign(key) ? '$' : ''}${shortenNumber(
        end,
      )}`
    }
    if (start !== undefined) {
      return `More than ${addDollaSign(key) ? '$' : ''}${shortenNumber(start)}`
    }
    if (end !== undefined) {
      return `Up to ${addDollaSign(key) ? '$' : ''}${shortenNumber(end)}`
    }
  }

  const innerKeys: string[] = []

  function traverseObject(node: any) {
    for (const key in node) {
      if (key === 'companyName') {
        lastObjects.push({
          key: key,
          value: node[key],
          categories: Object.values(node[key]),
        })
      }
      if (key.indexOf('nameOfCompany') >= 0) {
        lastObjects.push({
          key: key,
          value: node[key],
          categories: Object.values(node[key]),
        })
      }
      if (key.indexOf('events') >= 0) {
        lastObjects.push({
          key: key,
          value: node[key][0].event_company ? node[key][0].event_company : node[key][0].event_contact,
          categories: Object.values(node[key]),
        })
      }
      if (key.indexOf('nameOfContact') >= 0) {
        lastObjects.push({
          key: key,
          value: node[key],
          categories: Object.values(node[key]),
        })
      }
      if (key.indexOf('companyList') >= 0) {
        if (document.cookie?.split('list_name=')[1]) {
          lastObjects.push({
            key: key,
            value: decodeURI(document.cookie?.split('list_name=')[1]?.split(';')[0]),
            categories: [decodeURI(document.cookie?.split('list_name=')[1]?.split(';')[0])],
          })
        }
      }
      if (key.indexOf('contactsList') >= 0) {
        if (document.cookie?.split('list_name=')[1])
          lastObjects.push({
            key: key,
            value: decodeURI(document.cookie.split('list_name=')[1].split(';')[0]),
            categories: Object.values(node[key]),
          })
      }
      if (key.indexOf('fundingDate') >= 0) {
        lastObjects.push({
          key: key,
          value: node[key],
        })
      }

      if (
        typeof node[key] === 'object' &&
        node[key] !== null &&
        !(Array.isArray(node[key]) && node[key].some((value: any) => [1, 2, 3].includes(value)))
      ) {
        if (Object.values(node[key]).some((value: any) => value?.excluded !== undefined)) {
          innerKeys.push(...Object.keys(node[key]))
          const excludedCount = Object.values(node[key]).filter((value: any) => value?.excluded).length
          const includedCount = Object.values(node[key]).filter((value: any) => value?.excluded === false).length
          const naturalCount = Object.values(node[key]).length - excludedCount - includedCount

          if (!!excludedCount) {
            excludedObjects.push({
              key: key,
              number: excludedCount,
              categories: Object.values(node[key]).filter((value: any) => value?.excluded),
            })
          }

          if (!!includedCount)
            includedObjects.push({
              key: key,
              number: includedCount,
              categories: Object.values(node[key]).filter((value: any) => value?.excluded === false),
            })

          if (!!naturalCount)
            lastObjects.push({
              key: key,
              number: naturalCount,
              categories: Object.values(node[key]),
            })
        }

        if (
          Object.values(node[key]).every((value) => typeof value !== 'object') &&
          Object.values(node[key]).length > 0
        ) {
          if (Object.values(node[key]).every((value) => typeof value === 'number')) {
            lastObjects.push({
              key: key,
              range: generateRange(
                key,
                node[key]?.[`${key}Min`] as number | undefined,
                node[key]?.[`${key}Max`] as number | undefined,
              ),
              categories: [
                generateRange(
                  key,
                  node[key]?.[`${key}Min`] as number | undefined,
                  node[key]?.[`${key}Max`] as number | undefined,
                ),
              ],
            })
          } else if (typeof node[key] === 'string') {
            lastObjects.push({
              key: key,
              value: node[key],
              categories: Object.values(node[key]),
            })
          } else if (
            key !== 'companies' &&
            key !== 'searchBarCompany' &&
            key !== 'funding' &&
            isNaN(Number(key)) &&
            key !== 'searchBarContact' &&
            !innerKeys.includes(key)
          ) {
            lastObjects.push({ key: key, number: Object.keys(node[key]).length, categories: Object.values(node[key]) })
          }
        }

        traverseObject(node[key])
      }
    }
  }
  traverseObject(obj)

  return { excluded: excludedObjects, included: includedObjects, natural: lastObjects }
}

export const removePropertyByLeaf = (obj: any, keyToRemove: string) => {
  function traverseObject(node: any) {
    for (const key in node) {
      if (node[key] && typeof node[key] === 'object') {
        Object.keys(node[key]).forEach((innerKey) => {
          if (innerKey === keyToRemove) {
            delete node?.[key]?.[innerKey]
            return true
          }
        })
        if (key === keyToRemove) {
          delete node[key]
          return true
        }
        if (traverseObject(node[key])) {
          return true
        }
      } else if ((key === 'companyList' || key === 'contactsList') && key === keyToRemove) {
        delete node[key]
        return true
      }
    }
    return false
  }

  const clearEmpties = (obj: any) => {
    for (var key in obj) {
      if (!obj[key] || typeof obj[key] !== 'object') {
        continue
      }

      clearEmpties(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
    return obj
  }

  const objCpy = JSON.parse(JSON.stringify(obj))
  traverseObject(objCpy)
  clearEmpties(objCpy)

  return objCpy
}

export const removeEmpty = (obj: any): any => {
  const filtered: any = _.pickBy(obj, (value, key) => {
    if ((_.isObject(value) && !_.isEmpty(value)) || key === 'connectPlus') {
      return removeEmpty(value)
    }
    return !_.isEmpty(value)
  })

  return _.isEmpty(filtered) ? {} : filtered
}

export const filterObjectToString = (filter: any) =>
  JSON.stringify(
    (Object.values(objectToValues(filter)).reduce((sum: any, current: any) => [...sum, ...current], []) as any[])
      ?.map((value: any) => value?.value || value?.categories)
      ?.reduce((sum: any, current: any) => [...sum, ...(Array?.isArray(current) ? current : [current])], [])
      ?.map((value: any) => value?.title || value),
  )
    ?.replace(/["[\]]/g, ' ')
    .trim()
