import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
// import styles from './OnboardingTrackSidebar.module.scss'
import type { MenuProps } from 'antd'
import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_icon.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'

const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform.toLocaleLowerCase()
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

const { Sider } = Layout

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  padding: '10px',
  background: 'white',
}

interface OnboardingTrackSidebarProps {
  onMenuItemChange: (item: string) => void
  onChannelChange: (channel: string) => void
}

const OnboardingTrackSidebar: React.FC<OnboardingTrackSidebarProps> = ({ onMenuItemChange, onChannelChange }) => {
  const [selectedKey, setSelectedKey] = useState<string | null>('Explore All')

  const displayedCategories = ['Explore All', 'Suggested by Convrt', 'From your accounts']
  const platforms = ['Facebook', 'Instagram', 'LinkedIn', 'Telegram', 'Discord', 'Twitter']

  const handleExploreAll = () => {
    setSelectedKey('Explore All')
    onChannelChange('')
    onMenuItemChange('')
  }

  const handlePlatformSelection = (key: string) => {
    if (key === selectedKey) {
      setSelectedKey(null)
      onChannelChange('')
    } else {
      setSelectedKey(key)
      onChannelChange(key)
    }
  }

  const handleCategorySelection = (key: string) => {
    if (key === selectedKey) {
      setSelectedKey(null)
      onMenuItemChange('')
    } else {
      setSelectedKey(key)
      onMenuItemChange(key)
    }
  }

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'Explore All':
      case 'All Groups':
        handleExploreAll()
        break
      case 'Suggested by Convrt':
      case 'From your accounts':
        handleCategorySelection(e.key)
        break
      default:
        platforms.includes(e.key) ? handlePlatformSelection(e.key) : handleCategorySelection(e.key)
        break
    }
  }

  const items: MenuProps['items'] = [
    ...displayedCategories.map((cat) => ({
      key: cat,
      label: <span style={cat === 'View All' || cat === 'View Less' ? { color: '#7043ff' } : {}}>{cat}</span>,
    })),
    {
      key: 'div',
      type: 'divider',
    },
    {
      key: 'channels',
      label: <span style={{ fontWeight: 600 }}>Per Channels</span>,
      children: platforms.map((channel) => ({
        key: channel,
        label: channel === 'Twitter' ? 'X (Twitter)' : channel,
        icon: (
          <img
            style={{
              height: '25px',
              width: '25px',
              filter: channel !== 'Facebook' && channel !== 'Instagram' ? 'grayscale(1)' : 'none',
              opacity: channel !== 'Facebook' && channel !== 'Instagram' ? 0.5 : 1, // Optional: reduces opacity for disabled effect
            }}
            src={getLogoByPlatform(channel)}
            alt=''
          />
        ),
        disabled: channel !== 'Facebook' && channel !== 'Instagram', // Disable if not Instagram or Facebook
      })),
    },
  ]

  return (
    <Sider style={siderStyle} width={'18%'}>
      <Menu
        defaultOpenKeys={['sub1', 'channels']}
        className='onboardingTrack'
        mode='inline'
        style={{ height: '100%', borderRight: 0, maxHeight: '680px', overflowY: 'auto' }}
        items={items}
        onClick={onClick}
        selectedKeys={selectedKey ? [selectedKey] : []}
      />
    </Sider>
  )
}

export default OnboardingTrackSidebar
