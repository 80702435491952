import React, { useEffect, useState } from 'react'
import { Card, Radio, message } from 'antd'
import styles from './AIGerneratePrompt.module.scss'
import stopWatch from 'common/assets/png/stop-watch.png'
import { useAppDispatch, useAppSelector } from 'state'
import {
  RESET_SELECTED_LEADS,
  SET_ARE_LEADS_FROM_GROUPS,
  SET_SETUP_METHOD,
  SET_GENERATE_MESSAGES,
  SET_INITIAL_AI_DATA,
  SET_INITIAL_AI_COMPANIES_DATA,
  SET_PROMPT,
  SET_RESET_CAMPAIGN_DATA,
  SET_SAVED_PROMPT_PARAMS,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_SELECTED_NODE,
  SET_NODES_DATA,
  SET_STEPS,
} from '../state/outreachAICampaignSlice'
import premium_badge from 'common/assets/svg/premium_badge.svg'
import { post } from 'common/api/axios'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyDeleteCampaignDataQuery, useLazyGetMyTemplatesQuery } from '../state/api/OutreachAI-API'
import CustomCampaignModal from './CustomCampaign/CustomCampaignModal'
import { RESET_BUILDER, RESET_BUILDER_SETUP_DATA } from '../Builder/Slice/CampaignBuilderSlice'
import { promptTitle } from '../Helpers/CampaignConstants'
import CustomCollapseFooter from '../CreateCampaign/UI/CustomCollapseFooter'
import AI_Prompt_search_icon from 'common/assets/png/AI_Prompt_search_icon.png'
import AI_Prompt_Icon_Smart_engagement from 'common/assets/png/AI_Prompt_Icon_Smart_engagement.png'
import AI_Prompt_Icon_Outreach from 'common/assets/png/AI_Prompt_Icon_Outreach.png'
import AI_Prompt_Icon_Custom from 'common/assets/png/AI_Prompt_Icon_Custom.png'

const RadioGroup = Radio.Group

const promptImageMap: any = {
  1: AI_Prompt_search_icon,
  2: AI_Prompt_Icon_Smart_engagement,
  3: AI_Prompt_Icon_Outreach,
  4: AI_Prompt_Icon_Custom,
}

interface CampaignGenerateReportProps {
  currentKey: any
  setCurrentKey: any
  onBack?: () => void
  onNext?: () => void
  onSkip?: () => void
}
const CampaignGenerateReport: React.FC<CampaignGenerateReportProps> = ({
  currentKey,
  setCurrentKey,
  onBack,
  onNext,
  onSkip,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [openCustomCampaignModal, setOpenCustomCampaignModal] = useState(false)
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const [deleteCampaignData] = useLazyDeleteCampaignDataQuery()
  const [getMyTemplates, { data: templates }] = useLazyGetMyTemplatesQuery()
  const [confirmedReset, setConfirmedReset] = useState(false)
  const { prompt, nodes_data, saved_prompt_params, campaign_id } = useAppSelector((state) => state.outreachAICampaign)
  const { builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const dispatch = useAppDispatch()

  const [selectedCard, setSelectedCard] = useState<number | null>(null)

  const handleSelectCard = (item: any) => {
    if (user?.addons[item.addon_name]) {
      setSelectedCard(item.id)
      dispatch(SET_PROMPT(item))
      dispatch(SET_SAVED_PROMPT_PARAMS({ campaign_type: item.title }))
      dispatch(SET_SELECT_CUSTOM_TEMPLATE(null))
    }
  }

  const handleResetCampaign = async () => {
    setSelectedCard(null)
    dispatch(SET_PROMPT({}))
    dispatch(RESET_SELECTED_LEADS([]))
    dispatch(SET_INITIAL_AI_DATA([]))
    dispatch(SET_INITIAL_AI_COMPANIES_DATA([]))
    dispatch(SET_RESET_CAMPAIGN_DATA(true))
    dispatch(SET_ARE_LEADS_FROM_GROUPS(false))
    dispatch(SET_GENERATE_MESSAGES(true))
    dispatch(SET_SETUP_METHOD(''))
    dispatch(SET_SELECTED_NODE(''))
    dispatch(SET_NODES_DATA([]))
    dispatch(SET_STEPS([]))
    dispatch(RESET_BUILDER_SETUP_DATA())
    await deleteCampaignData({ campaign_id: campaign_id }) // to change ?
  }

  useEffect(() => {
    if (selectedCard !== null) {
      const data = promptTitle.find((item: any) => item.id === selectedCard)
      if (data) {
        post('/log', {
          action: `Outreach Generation Prompt`,
          data,
        })
        handleSelectCard(data)
      }
    }
  }, [selectedCard])

  useEffect(() => {
    if ((builder_data.nodes || nodes_data?.length > 0) && currentKey === '2') {
      setOpenAreYouSureModal(true)
    }
  }, [currentKey])

  useEffect(() => {
    if (confirmedReset) {
      handleResetCampaign()
      dispatch(RESET_BUILDER())
      setConfirmedReset(false)
    }
  }, [saved_prompt_params, confirmedReset])

  useEffect(() => {
    const getTemplates = async () => {
      await getMyTemplates()
    }
    getTemplates()
  }, [])

  useEffect(() => {
    if (prompt === 0) {
      setSelectedCard(null)
    }
  }, [prompt])

  const modalContent = {
    title: 'Editing the Prompt will Reset your Campaign',
    message: 'This action will erase all selected leads and messages.',
    onConfirm: () => {
      setOpenAreYouSureModal(false)
      setConfirmedReset(true)
    },
    onClose: () => {
      setOpenAreYouSureModal(false)
      setCurrentKey('3')
    },
  }

  return (
    <div className={styles.CampaignGenerateReport}>
      <div className={styles.top}>
        <p style={{ fontSize: '18px', color: '#091E4299', margin: 0 }}>
          Select a template or choose 'Custom' to build your sequence manually
        </p>
      </div>
      <div className={styles.AIGeneratePrompt}>
        <RadioGroup
          style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}
          value={prompt ? prompt.id : selectedCard}
          defaultValue={prompt ? prompt.id : selectedCard}>
          {promptTitle.map((items, index) => (
            <>
              {/*<img src={items} alt='Small Image' style={{ marginBottom: 10, width: 35 }} />*/}
              <div
                key={index}
                className={styles.cardBody}
                style={{
                  background:
                    selectedCard === items.id || prompt?.id === items.id
                      ? 'linear-gradient(#7144FF, #B649F3)'
                      : '#ffffff',
                  border:
                    selectedCard === items.id || prompt?.id === items.id
                      ? '2px solid transparent'
                      : '2px solid #DFE1E6',
                }}>
                <Card
                  key={index}
                  className={styles.cardStyling}
                  bodyStyle={{
                    borderRadius: '8px',
                    backgroundColor: selectedCard === items.id || prompt?.id === items.id ? '#f6f3fb' : '#ffffff',
                  }}
                  onClick={() => handleSelectCard(items)}>
                  {!user?.addons[items.addon_name] && (
                    <img src={premium_badge} alt='Small' className={styles.premium_badge} />
                  )}
                  <div className={styles.cardHeader}>
                    <img src={promptImageMap[items.id]} alt='Small' />
                    <Radio
                      key={index}
                      value={items.id}
                      className='Tiles_radio'
                      disabled={!user?.addons[items.addon_name]}
                    />
                  </div>
                  <h1 className={styles.warmUp}>{items.title}</h1>
                  <p className={styles.warmUpLorem}>{items.description}</p>
                  <p className={styles.warmUpEstimated}>
                    <img src={stopWatch} alt='' />{' '}
                    <span>
                      Estimated time{' '}
                      {items.id === 1
                        ? 'of 3-12 days'
                        : items.id === 2
                        ? 'of 7-20 days'
                        : items.id === 3
                        ? 'of 2-8 days'
                        : 'depends on your selection'}
                    </span>
                  </p>
                </Card>
              </div>
            </>
          ))}
        </RadioGroup>

        <CustomCollapseFooter id='2' onBack={onBack} onNext={onNext} onSkip={onSkip} />
      </div>
      <AreYouSureModal
        open={openAreYouSureModal}
        onClose={modalContent.onClose}
        onConfirm={modalContent.onConfirm}
        title={modalContent.title}
        message={modalContent.message}
        confirmText={'I understand'}
      />
      <CustomCampaignModal
        myTemplates={templates}
        open={openCustomCampaignModal}
        onConfirm={(name) => {
          setOpenCustomCampaignModal(false)
          message.success(`${name} Selected`)
        }}
        onCancel={() => {
          setOpenCustomCampaignModal(false)
          dispatch(SET_PROMPT(0))
          setSelectedCard(null)
        }}
      />
    </div>
  )
}

export default CampaignGenerateReport
