import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Divider } from 'antd'
import { Content } from 'antd/es/layout/layout'
import styles from './GroupsContent.module.scss'
import refinement_icon from 'common/assets/svg/refinement_icon.svg'
import GroupCard from './GroupCard'
import PlatformTag from './PlatformTag'
import { Grid, AutoSizer } from 'react-virtualized'
import { useAppSelector } from 'state'
import { MyGroupImport } from './MyGroups'
import { useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'
// import { useLazyGetGroupsQuery } from 'common/components/OutreachAI/state/api/OutreachAI-API'
// import { SET_INITIAL_GROUP_DATA } from 'common/components/OutreachAI/state/outreachAICampaignSlice'

interface Group {
  name: string
  username: string
  leads: number
  platform: string
  locked: boolean
  description: string
  img_url: string
  tags: string[]
  progress?: any
}

// interface GroupData {
//   category: string
//   data: Group[]
//   myGroups: any
//   requestedGroups: Group[]
// }

interface GroupsContentProps {
  groupsData: Group[]
  selectedCategory: any
  setSelectedGroup: any
  setSelectedGroupName: any
  myGroupsTab: boolean
  myGroupsData: any
  requestedGroups?: any
}

const GroupsContent: React.FC<GroupsContentProps> = ({
  groupsData = [],
  selectedCategory,
  setSelectedGroup,
  setSelectedGroupName,
  myGroupsTab,
  myGroupsData,
  requestedGroups,
}) => {
  // const dispatch = useAppDispatch()
  // const [getRefinedGroups] = useLazyGetGroupsQuery()
  const [getPointsStore] = useLazyGetPointsStoreQuery()
  const { active_platforms, my_groups_filters } = useAppSelector((state) => state.outreachAICampaign)
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({})
  const [selectedPlatforms, setSelectedPlatforms] = useState<Record<string, string>>({})
  const [searchValue, setSearchValue] = useState('')
  const [pointsStore, setPointsStore] = useState()
  // const [loading, setLoading] = useState(false)

  const truePlatformsArray = Object.entries(active_platforms)
    ?.filter(([key, value]) => value)
    ?.map(([key, value]) => key)

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const matchesSearch = (obj: any, searchValue: string) => {
    const lowerCaseSearchValue = searchValue.toLowerCase()

    return (
      obj?.name?.toLowerCase()?.includes(lowerCaseSearchValue) ||
      obj?.username?.toLowerCase()?.includes(lowerCaseSearchValue) ||
      obj?.tags?.some((tag: any) => tag?.toLowerCase().includes(lowerCaseSearchValue)) ||
      obj?.description?.toLowerCase()?.includes(lowerCaseSearchValue)
    )
  }

  // const handleRefineList = async () => {
  //   try {
  //     setLoading(true)
  //     const { data } = await getRefinedGroups({ refinement: searchValue })
  //     if (data) {
  //       dispatch(SET_INITIAL_GROUP_DATA(data))
  //       message.success('Group data refined successfully.')
  //     } else {
  //       message.error('Refinement failed. Please try again.')
  //     }
  //     setLoading(false)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // eslint-disable-next-line
  const toggleCategory = (category: string) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [category]: !prevExpanded[category],
    }))
  }

  const handlePlatformClick = (category: string, platform: string, uniquePlatforms: any) => {
    if (uniquePlatforms?.length > 1) {
      setSelectedPlatforms((prevPlatforms) => ({
        ...prevPlatforms,
        [category]: prevPlatforms[category] === platform ? '' : platform,
      }))
    }
  }

  const getUniquePlatforms = (groups: Group[]) => {
    const platformsSet = new Set(groups?.map((group) => group.platform.toLowerCase()))
    return Array.from(platformsSet)
  }

  // groupsData?.map((groupData, index) => { // data, category, myGroups, requestedGroups
  const myGroupsMap = new Map(myGroupsData?.map((val: any) => [val?.username, val?.trackMode]))

  // Filtering data based on truePlatformsArray
  let filteredData = groupsData
    ?.filter((obj) => truePlatformsArray.includes(obj.platform))
    .map((obj) => ({ ...obj, trackMode: myGroupsMap.get(obj.username) }))
    ?.filter((obj) => matchesSearch(obj, searchValue))

  if (myGroupsTab) {
    switch (my_groups_filters) {
      case 'trackMode':
        filteredData = filteredData
          ?.filter((obj) => myGroupsMap.has(obj.username) && myGroupsMap.get(obj.username))
          .map((obj) => ({ ...obj, trackMode: true }))
        break
      case 'in_progress':
        filteredData = requestedGroups
        break
      default:
        // Filtering and mapping data to include trackMode
        filteredData = filteredData?.filter((obj) => myGroupsMap.has(obj.username))
        //.map((obj) => ({ ...obj, trackMode: myGroupsMap.get(obj.username) }))
        // Pushing requested groups to the filtered data
        if (requestedGroups) {
          filteredData.push(
            ...requestedGroups
              ?.filter((obj: any) => truePlatformsArray.includes(obj.platform))
              .map((obj: any) => ({ ...obj, trackMode: myGroupsMap.get(obj.username) }))
              ?.filter((obj: any) => matchesSearch(obj, searchValue)),
          )
        }
        break
    }
  }

  // const category = groupData.category
  const category = selectedCategory
  const uniquePlatforms = getUniquePlatforms(filteredData)
  // eslint-disable-next-line
  const isExpanded = expandedCategories[category] || groupsData?.length === 1
  const selectedPlatform = selectedPlatforms[category]
  // Use when adding View All / View Less back
  // const visibleData =
  //   isExpanded || !selectedCategory
  //     ? filteredData.filter((group) => !selectedPlatform || group.platform.toLowerCase() === selectedPlatform)
  //     : filteredData.slice(0, 3)
  const visibleData = filteredData?.filter(
    (group) => !selectedPlatform || group.platform.toLowerCase() === selectedPlatform,
  )

  useEffect(() => {
    setExpandedCategories({})
  }, [groupsData])

  useEffect(() => {
    const getPoints = async () => {
      const { data } = await getPointsStore()
      setPointsStore(data?.response)
    }

    getPoints()
  }, [])

  const contentStyle: React.CSSProperties = {
    minHeight: 669,
    color: '#fff',
    padding: '24px 10px 0px 20px',
    backgroundColor: 'white',
  }
  return (
    <Content style={contentStyle}>
      {myGroupsTab && <MyGroupImport refinement={searchValue} pointsStore={pointsStore} />}
      <div className={styles.input}>
        <Input
          prefix={<img style={{ marginRight: '4px' }} src={refinement_icon} alt='' />}
          placeholder={`Search by Name, Username, Bio or Tags`}
          // placeholder={`Please write your refinement, for example: "I want groups with more than 1000 followers that are not private."`}
          onChange={onSearchChange}
          // onKeyDown={(e: any) => {
          //   e.key === 'Enter' && handleRefineList()
          // }}
        />
        {/* <Button loading={loading} onClick={handleRefineList}>
          Refine List
        </Button> */}
      </div>
      <Row gutter={10} className={styles.rowStyle}>
        <React.Fragment>
          <Col span={24} style={{ maxHeight: 87 }} className={styles.colStyle}>
            <div className={styles.categoryHeader}>
              <div className={styles.leftSide}>
                <span style={{ fontWeight: 600 }}>{category || 'Explore All'}</span>
                <Divider type='vertical' />
                <span>
                  {filteredData.length} Group{visibleData.length === 1 ? '' : 's'}
                </span>
              </div>
              {/* {groupsData?.length > 1 && (
                <div className={styles.rightSide}>
                  <span onClick={() => toggleCategory(category)}>{isExpanded ? 'View Less' : 'View All'}</span>
                </div>
              )} */}
            </div>
            {/* {isExpanded && ( */}
            <div className={styles.platforms}>
              {uniquePlatforms?.map((platform) => (
                <div onClick={() => handlePlatformClick(category, platform, uniquePlatforms)} key={platform}>
                  <PlatformTag platform={platform} selectedPlatform={selectedPlatform} />
                </div>
              ))}
            </div>
            {/* )} */}
          </Col>
          <div style={{ width: '100%', height: '70%' }}>
            <AutoSizer>
              {({ width }) => (
                <Grid
                  style={{ paddingBottom: '20px' }} // Replace with better css later
                  columnCount={3} // number of columns in the grid
                  columnWidth={width / 3} // width of each column
                  height={500} // height of the grid
                  rowCount={Math.ceil(visibleData.length / 3)} // number of rows in the grid
                  rowHeight={270} // height of each row
                  width={width + 10} // total width of the grid
                  cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                    const index = rowIndex * 3 + columnIndex
                    if (index >= visibleData.length) return null
                    const group = visibleData[index]
                    return (
                      <div key={key} style={style}>
                        <div style={{ padding: '2px 10px 0px 10px' }}>
                          <GroupCard
                            group={group}
                            onSelect={setSelectedGroup}
                            setSelectedGroupName={setSelectedGroupName}
                            myGroups={myGroupsData}
                            refinement={searchValue}
                            pointsStore={pointsStore}
                          />
                        </div>
                      </div>
                    )
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </React.Fragment>
      </Row>
    </Content>
  )
}

export default GroupsContent
