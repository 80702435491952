import { useEffect, useRef, useState } from 'react'

import no_logo_contact from 'common/assets/svg/no_logo_contact.svg'
import no_logo_company from 'common/assets/svg/no_logo_company.svg'

export const FancyImage = ({
  url,
  iscompany,
  className,
  name = '',
  size = 48,
  marginRight = 0,
  fontSize,
  style,
  color = false,
  fallbackImage,
}: {
  url: string
  iscompany?: boolean
  className?: string
  name?: string
  size?: number
  marginRight?: number
  fontSize?: number
  style?: any
  color?: boolean
  fallbackImage?: any
}) => {
  const ref = useRef<HTMLImageElement | null>(null)
  const [displayInitials, setDisplayInitials] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    ref.current.src = url
    //@ts-ignore "style is readonly"
    if (!iscompany && !color) ref.current.style = 'filter:grayScale(100%)'
  }, [url])

  const handleImgError = () => {
    if (!ref.current) return
    ref.current.src = fallbackImage ? fallbackImage : iscompany ? no_logo_company : no_logo_contact
    setDisplayInitials(true)
  }

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .reduce((acc, part) => acc + part.charAt(0), '')
      .toUpperCase()
      ?.slice(0, 2)
  }

  if (displayInitials && name) {
    return (
      <div
        className={`${className} initials-fallback`}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          backgroundColor: '#7043ff',
          color: 'white',
          display: 'flex',
          float: 'left',
          marginRight: `${marginRight}px`,
          marginTop: `${marginRight ? 3 : 0}px`,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: fontSize || `${Math.min(16, (size / 48) * 16 * 1.3)}px`,
          fontWeight: 'bold',
          lineHeight: 'normal',
          ...style,
        }}>
        {getInitials(name)}
      </div>
    )
  }

  if (fallbackImage) {
    return <img alt={''} ref={ref} src={url || fallbackImage} onError={handleImgError} className={className} />
  }

  return (
    <img
      alt={''}
      ref={ref}
      src={url || iscompany ? no_logo_company : no_logo_contact}
      onError={handleImgError}
      className={className}
    />
  )
}

// import { useEffect, useRef } from 'react'

// import no_logo_contact from 'common/assets/svg/no_logo_contact.svg'
// import no_logo_company from 'common/assets/svg/no_logo_company.svg'

// export const FancyImage = ({ url, iscompany, className }: { url: string; iscompany?: boolean; className?: string }) => {
//   const ref = useRef<HTMLImageElement | null>(null)
//   useEffect(() => {
//     if (!ref.current) return
//     ref.current.src = url
//     //@ts-ignore "style is readonly"
//     if (!iscompany) ref.current.style = 'filter:grayScale(100%)'
//   }, [url])

//   const handleImgError = () => {
//     if (!ref.current) return
//     ref.current.src = iscompany ? no_logo_company : no_logo_contact
//   }

//   return (
//     <img
//       alt={''}
//       ref={ref}
//       src={url || iscompany ? no_logo_company : no_logo_contact}
//       onError={handleImgError}
//       className={className}
//     />
//   )
// }
