import { useEffect, useState } from 'react'
import cx from 'classnames'
import { ConfigProvider, Layout } from 'antd'
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom'

import { SideFilter } from 'common/components/SideFilter/SideFilter'
import { TopBar } from 'common/components/TopBar/TopBar'
import { useAuth } from 'common/hooks/useAuth.hooks'
// eslint-disable-next-line import-helpers/order-imports
import Sidebar from './common/components/sidebar/sidebar'

import Login from 'features/login/login'
import SignUpPage from 'features/sign-up/sign-up'
import ResetPasswordPage from 'features/sign-up/reset-password'
import ContactsPage from 'features/contacts/contacts'
import IntentSignalsPage from 'features/intent-signals/intent-signals'

import MobileLayout from 'common/layouts/MobileLayout'
import { useAppSelector } from 'state'
import './App.css'
import './antd-overwrites.scss'
import '/node_modules/primeflex/primeflex.css'

import 'swiper/css'
import 'swiper/css/navigation'
// import EventsPage from './features/events/events'
import { ErrorBoundary } from 'react-error-boundary'
import CompanyAdminPanel from './features/company-admin-pannel/company-admin-pannel'
// import TrialFunnel from 'common/components/TrialFunnel/TrialFunnel'
import { Userpilot } from 'userpilot'
import ConvrtTabs from 'common/components/TopBar/ConvrtTabs'
// import BuyConvrtModal from 'common/components/BuyConvrt/BuyConvrtModal'
import BuyConvrt from 'common/components/BuyConvrt/BuyConvrt'
// import ThankYouPage from 'common/components/BuyConvrt/ThankYouPage'
import SignupRegistrationCode from 'features/sign-up/signupRegistrationCode'
import EditCampaign from 'common/components/OutreachCampaign/EditCampaign/EditCampaign'
// import Exposed from 'features/Outreach/Exposed'
import ExposedContacts from 'features/Outreach/ExposedContacts'
import Settings from 'features/settings/SettingsPage'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import CreateNewCampaign from 'common/components/OutreachAI/CreateCampaign/CreateNewCampaign'
// import CampaignSummary from 'common/components/OutreachAI/CreateCampaign/CampaignSummary'
// import EditNewCampaignManually from 'common/components/OutreachAI/CreateCampaign/EditNewCampaignManually'
import { setIsSubscriptionDaysPassed } from 'state/filtersSlice/filtersSlice'
import { useAppDispatch } from 'state'
import Chat from 'common/components/Chat/Chat'

import { useIntercom } from 'react-use-intercom'
import CampaignSummaryView from 'common/components/OutreachAI/CreateCampaign/CampaignSummaryView'
import Campaigns from 'common/components/OutreachAI/HomePage/Campaigns'
import Builder from 'common/components/OutreachAI/Builder/Builder'
import Tasks from 'common/components/Tasks/Tasks'
import Home from 'common/components/Home/Home'
// import LimitSettings from 'features/settings/Pages/LimitSettings'
import { useLocalStorage } from 'usehooks-ts'
import Onboarding from 'features/settings/Pages/Onboarding/Onboarding'
import {
  useLazyGetExistingSocialMetadataQuery,
  useLazyGetSocialMetadataQuery,
} from 'features/settings/state/api/SettingsApi'

// import CheckoutPage from 'common/components/BuyConvrt/CheckoutPage'
// import { FilterBar } from 'common/components/FilterBar/FilterBar'
// import OutreachBanner from 'common/components/TrialBanner/OutreachBanner'
// import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'
// import { useActiveFilters } from 'common/hooks/useFilters'

const { Content } = Layout

const App = () => {
  const { boot, shutdown } = useIntercom()
  const { isSidebarOpen } = useAppSelector((state) => state.filters)
  const locationPathName = useLocation().pathname.split('/')[1]
  const [localUser] = useLocalStorage<any>('user', null)
  const [, setLocalUserMetadata] = useLocalStorage<any>('user_metadata', null)
  const [userAddons, setUserAddons] = useState(localUser?.addons || {})
  const { requires_onboarding, onboarding_status } = userAddons
  const { user, logout, userDetails } = useAuth()
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [userData, setUserData] = useState(userDetails)
  const [populateSocialMetadata] = useLazyGetSocialMetadataQuery()
  const [getExistingSocialMetadata] = useLazyGetExistingSocialMetadataQuery()
  const dispatch = useAppDispatch()

  const bootIntercom = () =>
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })

  // const [activeFilters, setActiveFilters] = useActiveFilters()
  // const [openBanner, setOpenBanner] = useState(true)

  const navigate = useNavigate()

  const currentDate = new Date()
  const createdSubscriptionDate = new Date(userDetails?.subscription_created_at)
  const subscriptionDays = userDetails?.subscription_days
  const timeDifference = currentDate?.getTime() - createdSubscriptionDate?.getTime()
  const daysPassed = timeDifference / (1000 * 60 * 60 * 24)
  const navToCheckout =
    !userDetails?.flag && userDetails?.subscription_type === 'BETA' && Math.trunc(subscriptionDays - daysPassed) <= 0

  const showSidebar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/')

  const showTopBar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/tasks' &&
    window.location.pathname !== '/home' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/') &&
    !window.location.pathname.startsWith('/settings') &&
    !window.location.pathname.startsWith('/unifiedInbox') &&
    window.location.pathname !== '/checkout' &&
    window.location.pathname !== '/checkout/success' &&
    !window.location.pathname.startsWith('/outreach') &&
    !window.location.pathname.startsWith('/exposedContacts') &&
    window.location.pathname !== '/onboarding'

  dispatch(setIsSubscriptionDaysPassed(navToCheckout))

  useEffect(() => {
    if (!user) {
      const path = window.location.pathname.split('/')[1]
      switch (path) {
        case 'signup':
          navigate('/signup')
          return
        case 'premium-signup':
          return
        case 'reset-password':
          return
        case 'login':
          navigate('/login')
          return
        default:
          navigate('/login')
          return
      }
      // navigate(window.location.pathname === '/signup' ? `/signup` : `/login`)
    } else if (requires_onboarding && !onboarding_status) {
      navigate('/onboarding')
    } else if (!requires_onboarding || onboarding_status) {
      // If the user has completed onboarding, prevent access to the onboarding route
      if (window.location.pathname === '/onboarding') {
        navigate('/outreach')
      }
    } else if (navToCheckout) {
      if (window.location.pathname !== '/checkout') {
        navigate('/checkout')
      }
    } else if (user) {
      Userpilot.identify(userDetails.id, {
        name: userDetails.name,
        email: userDetails.email,
        created_at: userDetails.subscription_created_at,
      })
      bootIntercom()
    } else if (user && window.location.pathname === '/') {
      navigate(`/outreach`)
    }
    const getMetadata = async () => {
      try {
        populateSocialMetadata({ platform: 'all' })

        const { data: metadata } = await getExistingSocialMetadata()
        setLocalUserMetadata(metadata)
      } catch (error) {
        console.log(error)
      }
    }
    getMetadata()
  }, [user])

  const handleError = () => {
    if (process.env.NODE_ENV === 'development') return
    shutdown()
    logout()
    window.location.reload()
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
      setUserData(data)
    }
    if (user) {
      getUserDetails()
    }
  }, [])

  useEffect(() => {
    if (user && user.addons) {
      setUserAddons(user.addons)
    }
  }, [user])

  const PayWall = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        fontSize: '20px',
        zIndex: 13,
      }}>
      <p style={{ paddingLeft: '80px' }}>This new feature is only for premium users.</p>
    </div>
  )

  return (
    <ErrorBoundary fallback={<>{'Something went wrong'}</>} onError={handleError}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7043FF',
          },
        }}>
        <Layout className='hidden sm:flex min-h-screen layout'>
          {showSidebar && <Sidebar />}

          <Layout className='h-screen' style={{ marginLeft: !showSidebar ? 0 : '64px' }}>
            {showTopBar && <TopBar />}

            <Content className='overflow-auto flex max-h-full'>
              {isSidebarOpen && <SideFilter />}

              {((!userData?.addons?.unified_inbox && window.location.pathname.includes('unifiedInbox')) ||
                (!userData?.addons?.research &&
                  (window.location.pathname.includes('companies') || window.location.pathname.includes('contacts'))) ||
                (!userData?.addons?.my_contacts && window.location.pathname.includes('exposedContacts'))) &&
                PayWall}
              {userData?.subscription_type === 'BETA' &&
                window.location.pathname !== '/login' &&
                window.location.pathname !== '/signup' &&
                !window.location.pathname.startsWith('/reset-password') &&
                !window.location.pathname.startsWith('/premium-signup/') &&
                !window.location.pathname.includes('checkout') &&
                !window.location.pathname.includes('settings') &&
                !window.location.pathname.includes('outreach') &&
                !window.location.pathname.includes('unifiedInbox') &&
                !window.location.pathname.includes('onboarding') &&
                PayWall}
              {window.location.pathname.includes('tasks') && PayWall}
              {window.location.pathname.includes('home') && PayWall}
              <Layout
                style={{
                  backgroundColor: '#F9FAFB',
                }}
                className={cx('flex flex-column relative overflow-auto max-h-full')}>
                {/* {window.location.pathname !== '/login' &&
                  window.location.pathname !== '/signup' &&
                  Object.keys(activeFilters).length !== 0 && (
                    <FilterBar
                      emptyMessage={
                        window.location.pathname === '/contacts'
                          ? 'Select contacts to export'
                          : 'Select companies to export contact or data'
                      }
                    />
                  )} */}
                {/* {window.location.pathname.indexOf('outreach') === -1 &&
                  localStorage.getItem('showBanner') === 'true' &&
                  openBanner && (
                    <OutreachBanner
                      handleCloseBanner={() => {
                        setOpenBanner(false)
                        localStorage.setItem('showBanner', 'false')
                      }}
                    />
                  )} */}
                {/* {window.location.pathname !== '/login' &&
                  window.location.pathname !== '/signup' &&
                  window.location.pathname !== '/events' &&
                  window.location.pathname !== '/outreach' &&
                  locationPathName !== 'outreach' &&
                  locationPathName !== 'events' && <ConvrtTabs activeTab={locationPathName} />} */}

                {(locationPathName === 'contacts' || locationPathName === 'companies') && (
                  <ConvrtTabs activeTab={locationPathName} />
                )}
                <div className='flex flex-column h-full'>
                  <Routes>
                    {!shouldHideCheckout && (
                      <>
                        <Route path='/checkout' element={<BuyConvrt />} />
                        <Route path='/checkout/success' element={<BuyConvrt />} />
                      </>
                    )}
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<SignUpPage />} />
                    <Route path='/reset-password' element={<ResetPasswordPage />} />
                    <Route path='/reset-password/:userId' element={<ResetPasswordPage />} />
                    <Route path='/premium-signup/:registrationCode' element={<SignupRegistrationCode />} />

                    {!navToCheckout ? (
                      <>
                        <Route path='/companies' element={<IntentSignalsPage />} />
                        <Route path='/companies/:selectedId' element={<IntentSignalsPage />} />
                        <Route path='/contacts' element={<ContactsPage />} />
                        <Route path='/contacts/:selectedId' element={<ContactsPage />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/settings/success' element={<Settings />} />
                        <Route path='/settings/slack/success' element={<Settings />} />
                        <Route path='/settings/salesforce/success' element={<Settings />} />
                        {/* <Route path='/settings' element={<SettingsPage />} />
                    <Route path='/settings/success' element={<SettingsPage />} /> */}
                        {/* <Route path='/events' element={<EventsPage />} />
                    <Route path='/events/:selectedId' element={<EventsPage />} /> */}

                        <Route path='/home' element={<Home />} />
                        <Route path='/tasks' element={<Tasks />} />
                        <Route path='/exposedContacts' element={<ExposedContacts />} />
                        <Route path='/exposedContacts/:selectedId' element={<ExposedContacts />} />
                        <Route path='/outreach' element={<Campaigns />} />
                        <Route path='/outreach/create-new-campaign/campaign-builder' element={<Builder />} />
                        <Route path='/outreach/create-new-campaign' element={<CreateNewCampaign />} />
                        {/* <Route path='/outreach/edit-campaign' element={<CreateNewCampaign />} /> */}
                        {/* <Route path='/outreach/create-new-campaign/summary' element={<CampaignSummary />} /> */}
                        <Route path='/outreach/create-new-campaign/summary' element={<CampaignSummaryView />} />
                        {/* <Route
                          path='/outreach/create-new-campaign/edit-manually'
                          element={<EditNewCampaignManually />}
                        />
                        <Route
                          path='/outreach/create-new-campaign/edit-manually/view/:id'
                          element={<EditNewCampaignManually />}
                        /> */}
                        <Route path='/outreach/create-new-campaign/edit-manually' element={<Builder />} />
                        <Route path='/outreach/create-new-campaign/edit-manually/view' element={<Builder />} />
                        <Route path='/outreach/edit-campaign' element={<EditCampaign />} />
                        <Route path='/outreach/edit-campaign/:id' element={<EditCampaign />} />
                        <Route path='/company_admin' element={<CompanyAdminPanel />} />
                        {/* <Route path='/checkout/success' element={<ThankYouPage />} /> */}
                        <Route path='*' element={<Navigate to='/outreach' />} />
                        <Route path='/unifiedInbox' element={<Chat />} />
                        <Route path='/onboarding' element={<Onboarding />} />
                      </>
                    ) : (
                      <Route path='*' element={<Navigate to='/checkout' />} />
                    )}
                  </Routes>
                  {/* {user && userDetails?.did_funnel === false && <TrialFunnel open={true} />} */}
                </div>
              </Layout>
            </Content>
          </Layout>
        </Layout>
        <MobileLayout className='flex sm:hidden' />
      </ConfigProvider>
    </ErrorBoundary>
  )
}

export default App
