import React, { useEffect, useState } from 'react'
import { Table, TablePaginationConfig } from 'antd'

import { useAuth } from 'common/hooks/useAuth.hooks'
import { IContactsTable } from 'features/contacts/interfaces/contacts-table.interface'
import IntentSignalsPaywall from 'features/intent-signals/components/paywalls/IntentSignalsPaywall'
// import LoadingBoxWithText from '../LoadingBox/LoadingBoxWithText'
import LoadingSkeleton from '../LoadingBox/LoadingSkeleton'

interface IFancyTable {
  data: any
  columns: any
  page_type: string
  className?: string
  displayAll: boolean
  totalItems?: number
  tableParams?: any
  setTableParams?: any
  selected_items?: string[]
  totalDataNumber?: number
  hasBanner?: boolean
  loading?: boolean
  isFromOutreach?: boolean
  removeSelectRowOption?: boolean
  handleRemoveRow?: (key: string) => void
  handleShowDetails?: (id: string) => void
  handleUnSelectRows?: (rows: any) => void
  handleSelectedRows?: (rows: any, keys: any) => void
  handleSelectFifty?: (rows: any) => void
}

const FancyTable: React.FC<IFancyTable> = ({
  data,
  columns,
  page_type,
  displayAll,
  totalItems,
  tableParams,
  setTableParams,
  selected_items,
  totalDataNumber,
  hasBanner,
  loading,
  isFromOutreach,
  removeSelectRowOption,
  handleRemoveRow,
  handleShowDetails,
  handleUnSelectRows,
  handleSelectedRows,
  handleSelectFifty,
}) => {
  const { userDetails } = useAuth()
  const [yAxisTableSize, setYAxisTableSize] = useState(window.innerHeight)
  const tableHeaderElement = document.querySelector('[class^="ant-table-header"]')?.clientWidth || 0
  // const [prevData, setPrevData] = useState([])

  const getSelectFiftyText = (value: any) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const selection_limit = user.addons.selection_limit === undefined ? 50 : user.addons.selection_limit

    const currentValue = +value.current
    const rangeStart = Math.floor((currentValue - 1) / 5) * selection_limit + 1
    const rangeEnd = Math.min(
      rangeStart + selection_limit - 1,
      Math.floor((currentValue - 1) / 5 + 1) * selection_limit,
    )

    return `${rangeStart}-${+value.total < rangeEnd ? +value.total : rangeEnd}`
  }

  const SelectFiftyText = getSelectFiftyText(tableParams)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IContactsTable[]) => {
      handleSelectedRows && handleSelectedRows(selectedRows, selectedRowKeys)
    },
    getCheckboxProps: (record: IContactsTable) => ({}),
    selectedRowKeys: selected_items,
    onSelect: (selected: any, selectedRows: boolean) => {
      if (!selectedRows) handleRemoveRow && handleRemoveRow(selected?.key)
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (!selected) handleUnSelectRows && handleUnSelectRows(changeRows)
    },
    selections: userDetails.subscription_type === 'PREMIUM' &&
      !isFromOutreach && [
        {
          key: 'selectAll',
          text: `Select This Page`,
          onSelect: (selected: any, selectedRows: boolean) => {
            handleSelectedRows && handleSelectedRows(selected, selected)
          },
        },
        {
          key: 'fifty',
          text: `Select Rows ${SelectFiftyText}`,
          onSelect: handleSelectFifty,
        },
      ],
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (!setTableParams) return

    //@ts-ignore
    const modifiedTotal = Math.min(pagination?.total, pagination?.pageSize * 10)

    let params = { ...pagination, pageSize: Number(pagination.pageSize), total: modifiedTotal }

    if ((params?.current || 1) > tableParams.current && !displayAll) return

    setTableParams(params)
  }

  useEffect(() => {
    const updateYAxisTableSize = () => {
      const outreachBannerElement = document.querySelector('[class^="OutreachBanner"]')?.clientHeight || 0
      const trialBannerElement = document.querySelector('[class^="TrialBanner"]')?.clientHeight || 0
      const messageStatusElement = document.querySelector('[class^="MessagesStatus_title"]')?.clientHeight || 0
      const headerElement = document.querySelector('[class^="ant-layout-header"]')?.clientHeight || 0
      const filtersElement = document.querySelector('[class^="c-filters"]')?.clientHeight || 0
      const footerElement =
        document.querySelector('[class^="contacts_companiesFooter"]')?.clientHeight ||
        document.querySelector('[class^="intent-signals_companiesFooter"]')?.clientHeight ||
        0
      const paywallElement = document.querySelector('[class^="intent-signals-paywall"]')?.clientHeight || 0
      const outreachTabsElement = document.querySelector('[class^="ant-tabs-nav"]')?.clientHeight || 0
      const outreachStatusElement = document.querySelector('[class^="MessagesStatus_container"]')?.clientHeight || 0
      const company_admin = window.location.pathname === '/company_admin' ? 50 : 0
      const pageElement = 200
      // const pageElement = page_type === 'contacts' ? 200 : 280

      let newSize =
        window.innerHeight -
        pageElement -
        outreachBannerElement -
        trialBannerElement -
        messageStatusElement -
        headerElement -
        filtersElement -
        footerElement -
        outreachTabsElement -
        paywallElement -
        outreachStatusElement -
        company_admin

      newSize += outreachBannerElement === 0 ? 20 : 0

      if (newSize < 0.2 * window.innerHeight) {
        newSize = 0.2 * window.innerHeight
      }
      setYAxisTableSize(newSize)
      document.documentElement.style.setProperty('--min-fancy-table-height', `${newSize}px`)
    }

    updateYAxisTableSize()

    window.addEventListener('resize', updateYAxisTableSize)

    return () => {
      window.removeEventListener('resize', updateYAxisTableSize)
    }
  }, [hasBanner, window.innerHeight, window.innerWidth, loading])

  // useEffect(() => {
  //   if (!loading && data) {
  //     setPrevData(data)
  //   }
  //   // NEED TO CLEAR THE prevData STATE IF REMOVING ALL FILTERS!!!
  // }, [data])

  return (
    <div className='marginTable' style={{ overflowX: 'auto' }}>
      {/* {data?.length > 0 ? ( */}
      <Table
        // locale={{ emptyText: ' ' }}
        // loading={{ spinning: loading, indicator: <LoadingSkeleton /> }}
        locale={{
          emptyText: loading ? (
            // <Skeleton active={true} paragraph={{ rows: 10 }} style={{ opacity: 0.5 }} />
            <LoadingSkeleton
              listStyle={{ margin: '-8px' }}
              listItemStyle={{ padding: isFromOutreach ? '13px 0px 13px 0px' : '12px 0px 12px 0px' }}
              skeletonStyle={{
                paddingTop: '0px',
                paddingLeft: isFromOutreach ? '40px' : '60px',
                paddingRight: isFromOutreach ? '40px' : '60px',
                display: 'flex',
                alignItems: 'center',
                opacity: 0.5,
              }}
            />
          ) : (
            {}
          ),
        }}
        style={{ minWidth: 'fit-content' }}
        size='small'
        className={'fancyTable'}
        scroll={{ y: yAxisTableSize }}
        tableLayout='fixed'
        rowClassName='fancyTable-row'
        rowSelection={
          removeSelectRowOption
            ? null
            : {
                type: 'checkbox',
                ...rowSelection,
              }
        }
        columns={columns}
        dataSource={data}
        onRow={(record) => ({
          onClick: (event: any) => {
            if (
              (event?.target?.children[0]?.classList[0] === undefined ||
                event?.target?.children[0]?.classList[0]?.indexOf('ant-checkbox') === -1) &&
              handleShowDetails
            )
              handleShowDetails(record.id || record.index)
          },
        })}
        pagination={
          tableParams
            ? {
                ...(tableParams
                  ? {
                      ...tableParams,
                      pageSize: +tableParams?.pageSize,
                      total:
                        userDetails?.subscription_type === 'BETA'
                          ? tableParams?.total < 41
                            ? tableParams?.total
                            : 41
                          : tableParams?.total,
                    }
                  : {}),
                itemRender(page, type, element: any) {
                  if (
                    page > 3 &&
                    userDetails?.subscription_type === 'BETA' &&
                    window.location.pathname.indexOf('exposedContacts') === -1
                  )
                    return (
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                        }}>
                        {element?.type === 'button' ? (
                          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                          <a style={{ paddingLeft: '10px' }} rel='nofollow'>
                            Next
                          </a>
                        ) : (
                          element
                        )}
                      </span>
                    )
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  if (type === 'prev')
                    return (
                      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                      <a style={{ paddingRight: '10px' }} rel='nofollow'>
                        Prev
                      </a>
                    )
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  if (type === 'next')
                    return (
                      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                      <a style={{ paddingLeft: '10px' }} rel='nofollow'>
                        Next
                      </a>
                    )

                  return element
                },
              }
            : false
        }
        {...(tableParams && { onChange: handleTableChange })}
      />
      {/* ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <h1>{'Your search did not turn up any results, sorry'}</h1>
        </div>
      )} */}
      {!displayAll && !loading && data?.length > 0 && (
        <div style={{ minWidth: 'fit-content' }}>
          <IntentSignalsPaywall
            totalCompanies={totalItems || 0}
            totalDataNumber={totalDataNumber || 0}
            subscriptionType={userDetails?.subscription_type}
            page_type={page_type}
            style={
              data?.length > 0
                ? {
                    // paddingBottom: (yAxisTableSize / 2) * 0.8,
                    // paddingTop: (yAxisTableSize / 2) * 0.8,
                    width: tableHeaderElement,
                  }
                : { paddingBottom: '25px', paddingTop: '10px' }
            }
          />
        </div>
      )}
    </div>
  )
}

export default FancyTable
