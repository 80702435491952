import React, { useEffect, useState } from 'react'
import {
  Button,
  Popover,
  Select,
  Input,
  message,
  Switch,
  Slider,
  Tooltip,
  Modal,
  Layout,
  SliderSingleProps,
} from 'antd'
import crown from 'common/assets/svg/crown_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
// import linkedin from 'common/assets/svg/linked_icon.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
// import telegram from 'common/assets/svg/tele_icon.svg'
// import discord from 'common/assets/svg/disc_icon.svg'
// import twitter from 'common/assets/svg/xtwit_icon.svg'
// import three_dots_myGroups from 'common/assets/svg/three_dots_myGroups.svg'
// import trash_icon_groups from 'common/assets/svg/trash_icon_groups.svg'
// import refetch_group from 'common/assets/svg/refetch_group.svg'

import eye_icon from 'common/assets/svg/eye_icon.svg'
import eye_icon_purple from 'common/assets/svg/eye_icon_purple.svg'
import points_icon from 'common/assets/svg/points_icon.svg'

import styles from './MyGroups.module.scss'
import {
  useLazyGetGroupsQuery,
  useLazyImportGroupQuery,
  // useLazyRefetchGroupQuery,
  // useLazyRemoveGroupQuery,
  useLazySetGroupTrackModeQuery,
  useLazyCollectLeadsQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import validator from 'validator'
import { SET_INITIAL_GROUP_DATA } from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { Content, Header } from 'antd/es/layout/layout'
import ProgressBar from 'common/components/OutreachAI/HomePage/CampaignsTab/ProgressBar'

const { Option } = Select

interface MyGroupImportProps {
  refinement: any
  pointsStore: any
}

export const MyGroupImport: React.FC<MyGroupImportProps> = ({ refinement, pointsStore }) => {
  const dispatch = useAppDispatch()
  const [user] = useLocalStorage('user', null)
  const { addons } = user
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const [importGroup] = useLazyImportGroupQuery()
  const [getGroups] = useLazyGetGroupsQuery()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sourceType, setSourceType] = useState<any>(null)
  const [url, setUrl] = useState('')
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  const truePlatformsArray = Object.entries(active_platforms)
    ?.filter(([key, value]) => value)
    ?.map(([key, value]) => key)
  const [platform, setPlatform] = useState<any>(truePlatformsArray?.[0])
  const max_import_group_leads =
    platform === 'instagram'
      ? addons?.max_import_group_instagram_leads || 1000
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : addons?.max_import_group_leads || 1000

  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  const handleOpenChange = (open: boolean) => {
    setOpen(open)
  }

  const handleSelectChange = (value: any) => {
    setPlatform(value)
  }

  const handleSelectSourceTypeChange = (value: any) => {
    setSourceType(value.key)
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setUrl(value)
  }

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const validateUrl = (value: string) => {
    if (validator.isURL(value) && value?.includes(platform)) {
      return true
    } else {
      return false
    }
  }

  const getGroupNameFromUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url)
      const pathname = parsedUrl.pathname
      const segments = pathname.split('/').filter(Boolean)
      return segments[segments.length - 1]
    } catch (error) {
      console.error('Invalid URL:', error)
      return null
    }
  }

  const handleImportGroup = async () => {
    try {
      setLoading(true)

      if (!sourceType) {
        message.error('Please select a source type to proceed.')
        return setLoading(false)
      }

      const isValidUrl = validateUrl(url)
      if (!isValidUrl && platform !== 'instagram') {
        message.error('Please insert a valid url that matches the platform you selected.')
        return setLoading(false)
      }
      const parsedUrl = platform === 'instagram' ? url : getGroupNameFromUrl(url)

      const { data, error } = await importGroup({
        platform: platform,
        sourceType: sourceType,
        url: parsedUrl,
        numberOfLeads: numberOfLeads,
      })

      if (data?.status) {
        message.success('Group imported successfully!')
        // setPlatform(null)
        setUrl('')
        setOpen(false)
        // getGroups
        const { data } = await getGroups({})
        dispatch(SET_INITIAL_GROUP_DATA(data))
      } else if (error?.data?.status === false) {
        message.error(error?.data?.message)
      } else {
        message.error('Something went wrong. Please try again.')
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const sourceTypeOption = [
    { key: 'followers', value: 'Page Followers', premium: platform === 'facebook' ? true : false },
    { key: 'members', value: 'Group Members', premium: platform === 'instagram' ? true : false },
    { key: 'hashtag', value: 'Hashtag', premium: true },
    { key: 'post_interactions', value: 'Post Likes & Comments', premium: true },
    { key: 'search_results', value: 'Search', premium: true },
  ]

  const content = (
    <div className={styles.popoverContent}>
      <div className={styles.container}>
        <label style={{ marginBottom: '10px' }}>Channel</label>
        <Select
          onChange={handleSelectChange}
          value={platform}
          defaultValue={platform}
          placeholder='Select Channel'
          disabled
          style={{ width: '100%', marginTop: '5px' }}>
          <Option value='instagram'>
            <div className={styles.option}>
              <img src={instagram} alt='Instagram' className={styles.icon} />
              <span>Instagram</span>
            </div>
          </Option>
          <Option value='facebook'>
            <div className={styles.option}>
              <img src={facebook} alt='Facebook' className={styles.icon} />
              <span>Facebook</span>
            </div>
          </Option>
          {/* <Option value='linkedin'>
            <div className={styles.option}>
              <img src={linkedin} alt='LinkedIn' className={styles.icon} />
              <span>LinkedIn</span>
            </div>
          </Option> */}
          {/* <Option value='telegram'>
            <div className={styles.option}>
              <img src={telegram} alt='Telegram' className={styles.icon} />
              <span>Telegram</span>
            </div>
          </Option>
          <Option value='discord'>
            <div className={styles.option}>
              <img src={discord} alt='Discord' className={styles.icon} />
              <span>Discord</span>
            </div>
          </Option>
          <Option value='twitter'>
            <div className={styles.option}>
              <img src={twitter} alt='Twitter' className={styles.icon} />
              <span>Twitter</span>
            </div>
          </Option> */}
        </Select>
      </div>
      <div className={styles.container}>
        <label>Source Type</label>
        {/* <Select
          onChange={handleSelectSourceTypeChange}
          value={sourceType}
          defaultValue={sourceType}
          placeholder='Select where to gather leads from'
          style={{ width: '100%', marginTop: '5px' }}>
          {sourceTypeOption.map((type: any) => (
            <Option key={type.key} value={type.value}>
              <div className={styles.option}>
                <span>{type.value}</span>
              </div>
            </Option>
          ))}
        </Select> */}
        <Select
          onChange={handleSelectSourceTypeChange}
          value={sourceType}
          labelInValue
          placeholder='Select where to gather leads from'
          style={{ width: '100%', marginTop: '5px' }}>
          {sourceTypeOption.map((type: any) => (
            <Option key={type.key} value={type.key} disabled={type.premium}>
              <div className={styles.option}>
                <span style={type.premium ? { color: 'gray' } : {}}>{type.value}</span>
                {type.premium && (
                  <img style={{ marginLeft: '5px', width: '15px', height: '15px' }} src={crown} alt='' />
                )}
              </div>
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.container}>
        <label>Insert URL</label>
        <Input
          value={url}
          defaultValue={url}
          onChange={handleInputChange}
          style={{ marginTop: '5px' }}
          placeholder='Please Insert the URL you want to add'
        />
      </div>
      <div className={styles.container}>
        <div className={styles.flexDiv}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            Number of Leads
            <Tooltip
              title={`Choose how many leads to import from the selected group, up to ${max_import_group_leads} or the total number of leads in the group, whichever is lower.`}>
              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
            </Tooltip>
          </label>
          <Tooltip
            style={{ width: '600px' }}
            overlayInnerStyle={{ background: 'white', color: 'black' }}
            title={
              <div className={styles.tooltipPoints}>
                <InfoCircleOutlined />{' '}
                <span>
                  Importing {numberOfLeads} costs {totalCost} Convrt Points
                </span>
              </div>
            }>
            <div className={styles.points}>
              <span>{totalCost}</span>
              <img src={points_icon} alt='' />
            </div>
          </Tooltip>
        </div>
        <Slider
          min={0}
          max={max_import_group_leads}
          defaultValue={numberOfLeads}
          step={100}
          onChange={handleSliderChange}
          tooltip={{ formatter }}
        />
      </div>
      <div className={styles.footer}>
        <Button loading={loading} type='primary' onClick={handleImportGroup}>
          Import
        </Button>
      </div>
    </div>
  )

  return (
    <div className={styles.import}>
      <span style={{ color: 'black', fontWeight: 600 }}>All Channels</span>
      <Popover
        placement='bottomRight'
        content={content}
        title=''
        trigger='click'
        open={open}
        onOpenChange={handleOpenChange}>
        <Button type='primary'>Custom Import</Button>
      </Popover>
    </div>
  )
}

interface MyGroupsSwitchOptionsProps {
  group: any
  refinement: any
  myGroups?: boolean
}

export const MyGroupsSwitchOptions: React.FC<MyGroupsSwitchOptionsProps> = ({ group, refinement, myGroups }) => {
  // const [removeGroup] = useLazyRemoveGroupQuery()
  // const [refetchGroup] = useLazyRefetchGroupQuery()
  const [setGroupTrackMode] = useLazySetGroupTrackModeQuery()
  const [getGroups] = useLazyGetGroupsQuery()
  const [trackMode, setTrackMode] = useState(myGroups || group?.trackMode)
  const [loadingTrackMode, setLoadingTrackMode] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  // const handleMenuItemClick = async (e: any) => {
  //   const key = e.key
  //   switch (key) {
  //     case '1':
  //       // Request a Refetch
  //       try {
  //         const { error } = await refetchGroup({ platform: group.platform, group_id: group.username })
  //         if (error?.data?.status === false) {
  //           return message.error(error.data.message)
  //         }
  //         message.success('Request Sent Successfully!')
  //       } catch (e) {
  //         message.error('Something went wrong. Please try again.')
  //       }
  //       break
  //     case '2':
  //       // Remove
  //       try {
  //         await removeGroup({ platform: group.platform, group_id: group.username })
  //         message.success('Hub Removed Successfully')
  //         // Get Groups
  //         const { data } = await getGroups({})
  //         dispatch(SET_INITIAL_GROUP_DATA(data))
  //       } catch (e) {
  //         message.error('Something went wrong. Please try again.')
  //       }
  //       break
  //   }
  // }

  const handleSwitchChange = async (checked: boolean) => {
    setLoadingTrackMode(true)
    setTrackMode(checked)
    await setGroupTrackMode({ platform: group.platform, group_id: group.username, trackMode: checked })
    // Get Groups
    const { data } = await getGroups({})
    dispatch(SET_INITIAL_GROUP_DATA(data))
    setLoadingTrackMode(false)
  }

  useEffect(() => {
    setTrackMode(myGroups)
  }, [myGroups])

  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: (
  //       <div className={styles.dropdown}>
  //         <img src={refetch_group} alt='' />
  //         <span>Request to Refetch</span>
  //       </div>
  //     ),
  //     onClick: (e: any) => handleMenuItemClick(e),
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <div className={styles.dropdown}>
  //         <img src={trash_icon_groups} alt='' />
  //         <span>Remove</span>
  //       </div>
  //     ),
  //     onClick: (e: any) => handleMenuItemClick(e),
  //   },
  // ]

  // const contentStyle: React.CSSProperties = {
  //   backgroundColor: 'white',
  //   borderRadius: '12px',
  //   boxShadow:
  //     '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  // }

  // const menuStyle: React.CSSProperties = {
  //   boxShadow: 'none',
  //   padding: 0,
  // }

  return (
    <div
      className={styles.myGroups}
      onClick={(e: any) => {
        e.stopPropagation()
      }}>
      <Switch
        defaultChecked={trackMode}
        checked={trackMode}
        className='custom-switch'
        onChange={handleSwitchChange}
        loading={loadingTrackMode}
      />
      {/* <Dropdown
        placement='bottomRight'
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{ padding: '8px 5px' }}
        dropdownRender={(menu) => (
          <div
            style={contentStyle}
            onClick={(e: any) => {
              e.stopPropagation()
            }}>
            {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
            <Space style={{ padding: '5px 12px' }}>
              <span>Tracking Mode On</span>
              <Switch
                defaultChecked={trackMode}
                checked={trackMode}
                className='custom-switch'
                onChange={handleSwitchChange}
                loading={loadingTrackMode}
              />
            </Space>
          </div>
        )}>
        <div className={styles.moreOptions}>
          <img src={three_dots_myGroups} alt='' />
        </div>
      </Dropdown> */}
    </div>
  )
}

interface CustomEyeButtonProps {
  eyeText: string
  buttonText: string
  onViewLeads: () => void
  onCollect: () => void
  myGroups: boolean
  collectionProgress: any
  isHovered: boolean
  collectedLeads: any
  totalRequested: any
}

export const CustomEyeButton: React.FC<CustomEyeButtonProps> = ({
  eyeText,
  buttonText,
  onViewLeads,
  onCollect,
  myGroups,
  collectionProgress,
  isHovered,
  collectedLeads,
  totalRequested,
}) => {
  const [isEyeHovered, setIsEyeHovered] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const animationClass = isHovered ? styles.fadeInUp : styles.fadeOutDown

  useEffect(() => {
    if (isHovered) {
      // Make visible immediately on hover
      setIsVisible(true)
    } else {
      // Wait for fade-out animation duration (500ms) before hiding
      const timer = setTimeout(() => setIsVisible(false), 500) // Match the duration of fade-out animation

      // Clear timeout when component unmounts or when 'isHovered' changes
      return () => clearTimeout(timer)
    }
  }, [isHovered])

  return (
    <>
      {collectionProgress !== false && collectionProgress < 100 && (
        <Tooltip title={<span>Importing leads; it will take a couple of minutes.</span>}>
          <div
            className={`${styles.progress}`}
            style={{
              width: '100%',
              // width: isHovered ? '35%' : '100%', // Adjust width based on visibility
              transition: 'width 0.75s ease', // Add transition for smooth animation
            }}>
            <p>
              Collecting {Number(collectedLeads) ? Number(collectedLeads).toLocaleString() : '0'} /{' '}
              {Number(totalRequested) ? Number(totalRequested).toLocaleString() : '0'} leads
            </p>
            <ProgressBar percentage={collectionProgress} size='xl' />
          </div>
        </Tooltip>
      )}
      {myGroups && isVisible && collectedLeads > 0 && (
        <div className={`${styles.eye} ${animationClass}`}>
          {/* eslint-disable-next-line */}
          <a
            onMouseEnter={() => setIsEyeHovered(true)}
            onMouseLeave={() => setIsEyeHovered(false)}
            onClick={onViewLeads}>
            <img src={isEyeHovered ? eye_icon_purple : eye_icon} alt='View' className={styles.buttonImage} />
            <span>{eyeText}</span>
          </a>
        </div>
      )}
      {isVisible && (
        <div className={`${styles.collectButton} ${animationClass}`}>
          <Button type='primary' onClick={onCollect}>
            {buttonText}
          </Button>
        </div>
      )}
    </>
  )
}

interface CollectLeadsModalProps {
  open: boolean
  onClose: () => void
  group: any
  pointsStore: any
}

const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value?.toLocaleString()}`

export const CollectLeadsModal: React.FC<CollectLeadsModalProps> = ({ open, onClose, group, pointsStore }) => {
  const dispatch = useAppDispatch()
  const [getGroups] = useLazyGetGroupsQuery()
  const [collectLeads] = useLazyCollectLeadsQuery()
  const [loading, setLoading] = useState(false)
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  // const [disclaimerText, setDisclaimerText] = useState('Disclaimer')
  const [user] = useLocalStorage('user', null)
  const { addons } = user
  const platform = group?.platform
  const max_import_group_leads =
    platform === 'instagram'
      ? addons?.max_import_group_instagram_leads || 1000
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : addons?.max_import_group_leads || 1000

  const max_leads = Math.min(
    +group?.leads - +group.number_of_requested_leads,
    max_import_group_leads - +group.number_of_requested_leads,
  )
  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const handleCollect = async () => {
    setLoading(true)
    const { data: collectionData, error } = await collectLeads({
      platform: group.platform,
      username: group.username,
      amount: numberOfLeads,
    })

    if (collectionData?.message) {
      const { data } = await getGroups({})
      dispatch(SET_INITIAL_GROUP_DATA(data))
      message.success(collectionData?.message)
      onClose()
    } else if (error?.data?.status === 'points') {
      const convrt_points = error?.data?.convrt_points
      message.error(`You have ${convrt_points} points left. This action costs ${totalCost} points.`)
    } else {
      message.error('Something went wrong collecting leads. Please try again or contact support.')
    }
    setLoading(false)
  }

  // const handleDisclaimer = () => {
  //   setDisclaimerText('x')
  // }

  const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: 'black',
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #EBECF0',
  }

  const contentStyle: React.CSSProperties = {
    minHeight: 320,
    height: '100%',
    color: 'black',
    backgroundColor: 'white',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  return (
    <Modal
      className='CollectLeadsModal'
      open={open}
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
      maskClosable={false}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <div className={styles.modalHeader}>
            <p>Select How Many Leads to Collect</p>
            <span onClick={onClose}>Cancel</span>
          </div>
        </Header>
        <Content style={contentStyle}>
          <div className={styles.modalContent}>
            <div className={styles.progressContainer}>
              <img src={points_icon} alt='' />
              <p>{numberOfLeads?.toLocaleString()}</p>
              <Slider
                min={0}
                max={max_leads}
                style={{ width: 300 }}
                defaultValue={numberOfLeads}
                step={100}
                onChange={handleSliderChange}
                tooltip={{ formatter }}
              />
            </div>
            <span style={{ color: '#5E6C84' }}>
              Collecting {numberOfLeads?.toLocaleString()} leads from group will cost you {totalCost} points
            </span>
            <div className={styles.footer}>
              <Button type='primary' loading={loading} disabled={!numberOfLeads} onClick={handleCollect}>
                Start Collecting
              </Button>
              {/* <span className={styles.disclaimer} onClick={handleDisclaimer}>
                {disclaimerText}
              </span> */}
            </div>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
}
interface OnboardingOptionsProps {
  group: any
  onSelect: any
  isSelected: any
}

export const OnboardingsSwitchOptions: React.FC<OnboardingOptionsProps> = ({ group, onSelect, isSelected }) => {
  const handleSwitchChange = async (checked: boolean) => {
    onSelect()
  }

  return (
    <div
      className={styles.myGroups}
      onClick={(e: any) => {
        e.stopPropagation()
      }}>
      <Switch
        defaultChecked={isSelected}
        checked={isSelected}
        className='custom-switch'
        onChange={handleSwitchChange}
      />
    </div>
  )
}
