import React from 'react'
import { Handle, Position } from 'reactflow'
import styles from './PlaceholderNode.module.scss'
import add_node_icon from 'common/assets/svg/add_node_icon.svg'

interface PlaceholderNodeProps {
  style?: React.CSSProperties
  hideTitle?: boolean
}
const PlaceholderNode: React.FC<PlaceholderNodeProps> = ({ style, hideTitle }) => {
  return (
    <div className={styles.node} style={style}>
      <Handle type='target' position={Position.Top} />
      <div>
        {!hideTitle && <p>Drag Action/Condition to here</p>}
        <div className={styles.addNode}>
          <img src={add_node_icon} alt='' />
          <span>Add</span>
        </div>
      </div>
      <Handle type='source' position={Position.Bottom} id='a' />
    </div>
  )
}

export default PlaceholderNode
