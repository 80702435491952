import React, { useEffect, useState } from 'react'
import { Button, Modal, Input, Form, Space, Popover, Tooltip, Select, message, AutoComplete } from 'antd'
import styles from './AssignSeatsModal.module.scss'
import {
  useLazyGetAssignedSeatsQuery,
  useLazyGetUnassignedSeatsQuery,
  useLazyAssignSeatsQuery,
  useLazyGetUnassignedPointsQuery,
  useLazyAssignPointsQuery,
  useLazyGetAssignedPointsQuery,
  useLazyGetUsersListQuery,
} from 'features/settings/state/api/SettingsApi'
import LoadingBox from '../LoadingBox/LoadingBox'
import { CopyOutlined } from '@ant-design/icons'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAuth } from 'common/hooks/useAuth.hooks'

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  custom: 'Custom',
  trial: 'Trial',
}

interface AssignSeatsModalProps {
  open: boolean
  onClose: () => void
  seatsToAssign?: any
  assignedSeats?: any
  pointsToAssign?: any
  assignedPoints?: any
  allEmails?: any
}

const AssignSeatsModal: React.FC<AssignSeatsModalProps> = ({
  open,
  onClose,
  seatsToAssign,
  assignedSeats,
  pointsToAssign,
  assignedPoints,
  allEmails,
}) => {
  const [domain, setDomain] = useState('')
  const [form] = Form.useForm()
  // const [emails, setEmails] = useState<string[]>([])
  const [openPopover, setOpenPopover] = useState(false)
  const [refetchAssignSeats] = useLazyAssignSeatsQuery()
  const [isLoading, setIsLoading] = useState(false)
  const [refetchGetUnassignedSeats] = useLazyGetUnassignedSeatsQuery()
  const [refetchGetAssignedSeats] = useLazyGetAssignedSeatsQuery()
  const [refetchGetUnassignedPoints] = useLazyGetUnassignedPointsQuery()
  const [refetchGetAssignedPoints] = useLazyGetAssignedPointsQuery()
  const [refetchUsersList] = useLazyGetUsersListQuery()
  const [refetchAssignPoints] = useLazyAssignPointsQuery()
  const [pointsValues, setPointsValues] = useState<any>([])
  const [refetchGetUserDetails, { data: fetchedUserDetails }] = useLazyGetUserDetailsQuery()
  const autoCompleteOptions = allEmails?.map((email: any) => ({ value: email?.split('@')[0] }))
  const { updateUserDetails } = useAuth()

  // const handleAddEmail = async () => {
  //   try {
  //     const values = await form.validateFields(['email'])
  //     if (values.email.includes(' ')) {
  //       return message.warning('Invalid email.')
  //     }
  //     const email = `${values.email}@${domain}`
  //     if (email === userDetails.email) {
  //       return message.warning("You can't assign yourself.")
  //     }
  //     await get('/user/getExistingInvite', { params: { email } })
  //     if (email && !emails.includes(email) && emails.length < maximumSeats) {
  //       setEmails([...emails, email])
  //       form.resetFields(['email'])
  //     } else {
  //       if (emails.length >= maximumSeats) {
  //         message.warning(`You can't add more than ${maximumSeats} emails.`)
  //       } else {
  //         message.warning('Please enter a valid email or the email is already added.')
  //       }
  //     }
  //   } catch (e: any) {
  //     console.error(e)
  //     const existsUser = e?.response?.data?.existsUser
  //     existsUser && message.error(`${existsUser} was already invited by you!`)
  //     const hasPlan = e?.response?.data?.hasPlan
  //     hasPlan && message.error(`${hasPlan} already has a plan.`)
  //   }
  // }

  // const handleRemoveEmail = (emailToRemove: string) => {
  //   setEmails(emails.filter((email) => email !== emailToRemove))
  // }

  // const handleConfirm = async () => {
  //   try {
  //     const values = await form.validateFields(['emails'])
  //     const { data } = await post('/user/upgradeExtraSeats', { emails })
  //     setRegistrationCodes(data.invites)
  //     setSuccessfullyAssigned(data.emails)
  //     setDisableAddEmail(true)
  //     message.success('Premium membership assigned successfully!')
  //   } catch (e: any) {
  //     message.error(e?.response?.data?.message)
  //     const existingUsers = e?.response?.data?.existsUsers
  //     existingUsers?.forEach((user: any) => {
  //       message.error(`${user} already has a seat!`)
  //     })
  //     console.error(e)
  //   }
  // }

  const handleSelectPointsChange = (value: any, index: number) => {
    const obj = { ...pointsToAssign[index], email: value }
    setPointsValues((prev: any) => [...prev, obj])
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputValue = e.target.value
    form.setFieldsValue({ [`a_${index}`]: inputValue })
  }
  const handleConfirm = async () => {
    try {
      const seatsValues: any = []
      for (let index = 0; index < seatsToAssign?.length; index++) {
        const value = await form.validateFields([index])
        if (value[index]) {
          const email = `${value[index]}@${domain}`
          const selectedPlan = seatsToAssign?.[index]
          const assignedSeatsEmails = assignedSeats?.map((item: any) => item.user_email)
          if (email !== fetchedUserDetails.email && !assignedSeatsEmails.includes(email)) {
            seatsValues.push({ email: email, ...selectedPlan })
          }
        }
      }
      for (let index = 0; index < assignedSeats.length; index++) {
        const value = await form.validateFields([`a_${index}`])
        if (value[`a_${index}`]) {
          const email = `${value[`a_${index}`]}@${domain}`
          const selectedPlan = assignedSeats[index]
          seatsValues.push({ email: email, ...selectedPlan })
        }
      }

      // if (seatsValues?.length === 0 && pointsValues?.length === 0) {
      //   onClose()
      // }

      setIsLoading(true)
      if (seatsValues?.length > 0) {
        const d = await refetchAssignSeats(seatsValues)
        await refetchGetUnassignedSeats()
        await refetchGetAssignedSeats()
        if (d.status === 'fulfilled') {
          message.success('Seats Assigned Successfully')
        }
      }
      if (pointsValues?.length > 0) {
        const d = await refetchAssignPoints(pointsValues)
        await refetchGetUnassignedPoints()
        await refetchGetAssignedPoints()
        updateUserDetails()
        setPointsValues([])
        if (d.status === 'fulfilled') {
          message.success('Convrt Points Assigned Successfully')
        }
      }
      await refetchUsersList()
      form.resetFields()
      setIsLoading(false)
    } catch (e: any) {
      console.error(e)
    }
  }

  const handleCopyLink = (url: string) => {
    try {
      const link = `https://app.convrt.io/premium-signup/${url}`
      const tempInput = document.createElement('input')
      tempInput.value = link
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      setOpenPopover(true)
      setTimeout(() => {
        setOpenPopover(false)
      }, 850)
    } catch (error) {
      console.error('Unable to copy link to clipboard', error)
    }
  }

  useEffect(() => {
    if (seatsToAssign?.[0]?.owner_email) {
      setDomain(seatsToAssign[0]?.owner_email?.split('@')[1])
    } else if (assignedSeats?.[0]?.owner_email) {
      setDomain(assignedSeats[0]?.owner_email?.split('@')[1])
    }
  }, [seatsToAssign])

  useEffect(() => {
    const getUserDetails = async () => {
      await refetchGetUserDetails()
    }
    getUserDetails()
  }, [])

  // const invitedEmailsLinks = (
  //   <div className={styles.emailsAssigned}>
  //     {invites.length > 0 && !invites.every((invite: any) => invite.expired) && (
  //       <span style={{ fontWeight: 600 }}>Assigned Seats:</span>
  //     )}
  //     {invites
  //       .filter((invite: any) => !invite.expired)
  //       .map((invite: any) => (
  //         <div key={invite.email} className={styles.emailItem}>
  //           <span
  //             className={styles.copyLink}
  //             onClick={() => {
  //               handleCopyLink(invite.registration_url)
  //             }}>
  //             Copy Invite Link
  //           </span>
  //           <span>{invite.email}</span>
  //         </div>
  //       ))}
  //   </div>
  // )

  const convertBillingCycleText = (text: string) => {
    switch (text) {
      case 'monthly':
        return 'Monthly'
      case 'six_months':
        return 'Six Months'
      case 'yearly':
        return 'Year'
      default:
        return 'Monthly'
    }
  }

  return (
    <div className={styles.modal}>
      <Modal
        centered
        open={open}
        onCancel={onClose}
        width={600}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
        }}
        footer={false}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            {/* {!isLoading && maximumSeats !== 0 && <p>Assign Seats</p>} */}
            {isLoading ? (
              <div className={styles.loading}>
                <LoadingBox />
              </div>
            ) : (
              <>
                {(seatsToAssign?.length > 0 || assignedSeats?.length > 0) && <p>Assign Seats</p>}
                <Form
                  name='emails'
                  form={form}
                  layout='vertical'
                  // style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}
                >
                  {seatsToAssign?.map((seat: any, index: number) => (
                    <div key={seat.item_id} className={styles.seatContainer}>
                      <p className={styles.seatType}>
                        {planMapping[seat.product]} Plan
                        <span>{` /${convertBillingCycleText(seat?.billing_cycle)}`}</span>
                      </p>
                      <Form.Item
                        name={index}
                        rules={[
                          {
                            validator: (_, value) =>
                              value === fetchedUserDetails.email.split('@')[0]
                                ? Promise.reject(new Error(`You can't assign yourself.`))
                                : Promise.resolve(),
                          },
                        ]}>
                        <Space direction='horizontal'>
                          <AutoComplete
                            options={autoCompleteOptions}
                            onSelect={(value) => form.setFieldsValue({ [index]: value })}
                            filterOption={(inputValue, option: any) =>
                              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }>
                            <Input style={{ width: '250px' }} placeholder='Username' suffix={`@${domain}`} />
                          </AutoComplete>
                          {/* <AutoComplete style={{ width: '250px' }} placeholder='Username' suffix={`@${domain}`} /> */}
                        </Space>
                      </Form.Item>
                    </div>
                  ))}
                  {assignedSeats?.map((seat: any, index: number) => (
                    <div key={seat.item_id} className={styles.seatContainer} style={{ color: '#7043ff' }}>
                      <p className={styles.seatType}>
                        {planMapping[seat.product]} Plan
                        <span>{` /${convertBillingCycleText(seat?.billing_cycle)}`}</span>
                      </p>
                      <Form.Item name={`a_${index}`}>
                        <Space direction='horizontal'>
                          <Input
                            disabled={!seat?.invited}
                            style={{ width: '250px' }}
                            defaultValue={seat?.user_email?.split('@')[0]}
                            placeholder={seat?.user_email?.split('@')[0]}
                            suffix={`@${domain}`}
                            onChange={(value: any) => {
                              handleChangeInput(value, index)
                            }}
                          />
                        </Space>
                        {seat?.invited && (
                          <Tooltip title='Copy Registration Link'>
                            <div className={styles.copyRegistrationLink}>
                              <CopyOutlined
                                onClick={() => {
                                  handleCopyLink(seat?.registration_code)
                                }}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </Form.Item>
                    </div>
                  ))}
                  {(pointsToAssign?.length > 0 || assignedPoints?.length > 0) && <p>Assign Points</p>}
                  {pointsToAssign?.map((points: any, index: number) => (
                    <div key={points.item_id} className={styles.seatContainer}>
                      <p className={styles.seatType}>
                        {points.product} Convrt Points
                        <span>{` /${convertBillingCycleText(points?.billing_cycle)}`}</span>
                      </p>
                      <Form.Item name={`p_${index}`}>
                        <Space direction='horizontal'>
                          <Select
                            showSearch
                            disabled={points?.assigned}
                            style={{ width: '250px' }}
                            // defaultValue={points?.user_email?.split('@')[0]}
                            // placeholder={points?.user_email?.split('@')[0]}
                            // suffix={`@${domain}`}
                            onChange={(value: any) => {
                              handleSelectPointsChange(value, index)
                            }}>
                            {allEmails?.map((user: any, index: number) => (
                              <Select.Option key={index} value={user}>
                                {user}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      </Form.Item>
                    </div>
                  ))}
                  {assignedPoints?.map((points: any, index: number) => {
                    return (
                      <div key={points.item_id} className={styles.seatContainer} style={{ color: '#7043ff' }}>
                        <p className={styles.seatType}>
                          {points.product} Convrt Points
                          <span>{` /${convertBillingCycleText(points?.billing_cycle)}`}</span>
                        </p>
                        <Form.Item name={`a_p_${index}`}>
                          <Space direction='horizontal'>
                            <Select disabled style={{ width: '250px' }} value={points.user_email} />
                          </Space>
                        </Form.Item>
                      </div>
                    )
                  })}
                </Form>

                {/* {(maximumSeats > 0 || pointsToAssign?.length > 0) && ( */}
                <div style={{ marginBottom: '15px' }}>
                  <Button key='back' onClick={onClose}>
                    Cancel
                  </Button>
                  <Button key='confirm' type='primary' onClick={handleConfirm}>
                    Confirm
                  </Button>
                </div>
                {/* )} */}
              </>
            )}
          </div>
        </div>
        <Popover
          trigger={['click']}
          open={openPopover}
          content={<span>Registration Link copied to clipboard!</span>}
          placement='bottom'
          arrow={false}
        />
      </Modal>
    </div>
  )
}

export default AssignSeatsModal
