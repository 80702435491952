export const industries = [
  'Construction',
  'Restaurants',
  'Healthcare',
  'Real Estate',
  'E-commerce',
  'Building Materials',
  'Education',
  'Legal Services',
  'Property Management',
  'Automotive',
  'Technology',
  'Financial Services',
  'Beauty and Wellness',
  'Travel and Hospitality',
  'Retail',
  'Entertainment and Media',
  'Fitness and Sports',
  'Non-profit and Charity',
  'Environmental and Green Energy',
  'Food and Beverage',
  'Fashion and Apparel',
  'Manufacturing and Industrial',
  'Agriculture and Farming',
  'Pet Services',
  'Arts and Crafts',
  'Home and Garden',
  'Event Planning and Services',
  'Security and Investigation',
  'Transportation and Logistics',
  'Insurance',
  'Pharmaceutical and Biotech',
  'Telecommunications',
  'Publishing and Printing',
  'Energy and Utilities',
  'Mining and Metals',
  'Aerospace and Defense',
  'Maritime and Shipping',
  'Childcare and Education',
  'Gaming and eSports',
  'Virtual Reality and Augmented Reality',
  'Robotics and Automation',
  'Personal Finance and Wealth Management',
  'Freelancing and Gig Economy',
  'Human Resources and Recruitment',
  'Cleaning and Maintenance',
  'Plumbing and HVAC',
  'Architecture and Design',
  'Photography and Videography',
  'Sound and Music Production',
  'Veterinary and Animal Care',
  'Local Government and Civic Organizations',
]

export const promptTitle = [
  {
    id: 1,
    title: 'Warm Up Prospects',
    // image: AI_Prompt_search_icon,
    description: 'Engage your audience with likes and follows to subtly warm up leads without overwhelming them.',
    addon_name: 'prompt_warm_up_prospects',
  },
  {
    id: 2,
    title: 'Smart Engagement',
    // image: AI_Prompt_Icon_Smart_engagement,
    description:
      'Leverage sophisticated engagement strategies for maximum interaction and an unparalleled response rate.',
    addon_name: 'prompt_smart_engagement',
  },
  {
    id: 3,
    title: 'Cold Outreach',
    // image: AI_Prompt_Icon_Outreach,
    description:
      'Directly reach out to potential leads with targeted messages designed for immediate impact and quick responses.',
    addon_name: 'prompt_cold_outreach',
  },
  {
    id: 4,
    title: 'Custom',
    // image: AI_Prompt_Icon_Custom,
    description: 'Tailor your outreach with custom campaigns designed specifically for your unique goals and audience.',
    addon_name: 'prompt_custom',
  },
]

export const personas = [
  'Owner',
  'Founder',
  'CEO',
  'Sales',
  'Operations',
  'Marketing',
  'President',
  'COO',
  'Partner',
  'Director',
  'Engineering',
  'Finance',
]
