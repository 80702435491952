import React, { useEffect, useState } from 'react'
import { Card, Divider, Tooltip } from 'antd'
import styles from './GroupCard.module.scss'

import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/xtwit_icon.svg'

import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import number_of_leads_icon from 'common/assets/svg/number_of_leads_icon.svg'

import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import { capitalize } from 'lodash'
import groupFallback from 'common/assets/svg/add_leads_group.svg'
import { MyGroupsSwitchOptions, OnboardingsSwitchOptions, CustomEyeButton, CollectLeadsModal } from './MyGroups'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
// import ProgressBar from 'common/components/OutreachAI/HomePage/CampaignsTab/ProgressBar'

interface GroupData {
  name: string
  username: string
  leads: number
  platform: string
  locked: boolean
  description: string
  tags: string[]
  img_url: string
  progress?: any
  number_leads_request?: any
  trackMode?: any
  collected_leads?: any
  number_of_requested_leads?: any
}

interface GroupCardProps {
  group: GroupData
  onSelect: any
  setSelectedGroupName: any
  myGroups: any
  refinement: any
  isSelected?: boolean
  onboarding?: boolean
  pointsStore?: any
}

const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform.toLocaleLowerCase()
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

function formatNumber(num: number) {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000)?.toFixed(1)?.replace(/\.0$/, '') + 'B'
  }
  if (num >= 1_000_000) {
    return (num / 1_000_000)?.toFixed(1)?.replace(/\.0$/, '') + 'M'
  }
  if (num >= 1_000) {
    return (num / 1_000)?.toFixed(1)?.replace(/\.0$/, '') + 'k'
  }
  return num?.toString()
}

const GroupCard: React.FC<GroupCardProps> = ({
  group,
  onSelect,
  setSelectedGroupName,
  myGroups,
  refinement,
  isSelected,
  onboarding = false,
  pointsStore,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [openCollectModal, setOpenCollectModal] = useState(false)
  const [openNoMoreLeadsModal, setOpenNoMoreLeadsModal] = useState(false)
  // eslint-disable-next-line
  const [isVisible, setIsVisible] = useState(false)

  const isMyGroup = onboarding ? true : myGroups?.some((myGroup: any) => myGroup.username === group.username)

  const handleSelectGroup = () => {
    if (!openCollectModal) {
      onSelect(group)
      setSelectedGroupName(group.name)
    }
  }

  const handleCollect = () => {
    if (+group?.number_of_requested_leads >= +group?.leads) {
      setOpenNoMoreLeadsModal(true)
    } else {
      setOpenCollectModal(true)
    }
  }

  const elipsisDescription =
    group.description?.length > 120 ? (
      <Tooltip title={group.description}>{group.description.slice(0, 120)}...</Tooltip>
    ) : (
      <span>{group.description}</span>
    )
  const description = group.description?.length > 0 ? elipsisDescription : 'No available description.'

  const elipsisName =
    group.name?.length > 40 ? (
      <Tooltip title={group.name}>{group.name.slice(0, 40)?.trim()}...</Tooltip>
    ) : (
      <span>{group.name}</span>
    )

  // const numFetchedLeads =
  //   group?.progress && group?.number_leads_request ? (+group?.progress / 100) * +group?.number_leads_request : null

  const numCollectedLeads = +group?.collected_leads || 0
  const numRequestedLeads = +group?.number_of_requested_leads || 0
  const collectionProgress: any =
    numRequestedLeads > 0 ? Math.ceil((numCollectedLeads / numRequestedLeads) * 100) : false

  useEffect(() => {
    if (isHovered) {
      // If hovered, make visible immediately
      setIsVisible(true)
    } else {
      // If not hovered, wait for the fade-out animation duration (e.g., 500ms) before hiding
      const timer = setTimeout(() => setIsVisible(false), 500) // Match the duration of fade-out animation

      return () => clearTimeout(timer)
    }
  }, [isHovered])

  return (
    <Card
      // className={`addLeadsModal ${styles.groupCard} ${group?.progress < 1 ? styles.disabled : ''} ${
      className={`addLeadsModal ${styles.groupCard} ${isSelected ? 'addLeadsModalSelected' : ''}`}
      hoverable
      style={{ cursor: 'default' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // onClick={handleSelectGroup}
    >
      {!group?.progress && !onboarding && (
        <MyGroupsSwitchOptions group={group} refinement={refinement} myGroups={isMyGroup} />
      )}
      {onboarding && <OnboardingsSwitchOptions group={group} onSelect={onSelect} isSelected={isSelected} />}
      <div className={styles.cardImage}>
        <div style={{ minHeight: '46px' }}>
          <FancyImage url={group.img_url} color={true} fallbackImage={groupFallback} />
        </div>
      </div>
      <div className={styles.cardHeader}>
        <div className={styles.groupName}>{elipsisName}</div>
      </div>
      <div className={styles.leads}>
        {group.leads && (
          <>
            <div className={styles.numberOfLeads}>
              <img src={number_of_leads_icon} alt='' />

              <span>
                {numCollectedLeads >= 0 && typeof numCollectedLeads === 'number' && group.leads
                  ? `${formatNumber(numCollectedLeads)}/${formatNumber(group?.leads)}`
                  : `${group.leads?.toLocaleString()}`}
              </span>
            </div>
            <Divider type='vertical' />
          </>
        )}
        <div className={styles.platform}>
          <img src={getLogoByPlatform(group.platform)} alt='' />
          <span>{group.platform === 'Twitter' ? 'X (Twitter)' : capitalize(group.platform)}</span>
        </div>
        <Divider type='vertical' />
        <div className={styles.groupStatus}>
          <img src={group.locked ? group_locked_icon : group_unlocked_icon} alt='' />
          <span>{group.locked ? 'Locked' : 'Unlocked'}</span>
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.groupDescription}>{description}</div>
        <div className={styles.groupTags}>
          <div
            className={`${styles.hoverableContainer} ${
              collectionProgress === false || collectionProgress === 100
                ? isHovered
                  ? styles.fadeInUp
                  : styles.fadeOutDown
                : ''
            }`}
            style={
              collectionProgress >= 0 && collectionProgress < 100
                ? {}
                : {
                    position: 'absolute',
                    bottom: '26px',
                    right: '20px',
                    width: 'fit-content',
                  }
            }
            onClick={(e: any) => {
              e.stopPropagation()
            }}>
            {!onboarding && (
              <CustomEyeButton
                eyeText='View Leads'
                buttonText='Collect'
                onCollect={handleCollect}
                onViewLeads={handleSelectGroup}
                myGroups={isMyGroup}
                collectionProgress={collectionProgress}
                isHovered={isHovered}
                collectedLeads={group?.collected_leads}
                totalRequested={group?.number_of_requested_leads}
              />
            )}
          </div>
          {(collectionProgress === false || collectionProgress === 100) && (
            <div
              style={
                collectionProgress >= 0 && collectionProgress < 100
                  ? { position: 'absolute', bottom: '24px', left: '24px', width: 'fit-content' }
                  : {}
              }>
              {group?.tags?.length > 0 ? (
                group?.tags?.map((tag, tagIndex) => (
                  <span key={tagIndex} className={styles.tag}>
                    {tag}
                  </span>
                ))
              ) : (
                <span className={styles.noTags}>No tags yet.</span>
              )}
            </div>
          )}
          {/* {group?.progress >= 0 ? (
              <Tooltip title={<span>Importing leads; it will take a couple of minutes.</span>}>
                <div
                  className={styles.progress}
                  style={{
                    width: isHovered ? '35%' : '100%',
                    transition: 'width 0.75s ease',
                  }}>
                  <ProgressBar percentage={group.progress} size='xl' />
                </div>
              </Tooltip>
            ) : (
              <></>
            )} */}
        </div>
      </div>
      {/* {false && (
        <div
          className={`${styles.hoverableContainer} ${isHovered ? styles.fadeInUp : styles.fadeOutDown}`}
          style={
            collectionProgress >= 0
              ? {}
              : {
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px',
                  width: 'fit-content',
                }
          }
          onClick={(e: any) => {
            e.stopPropagation()
          }}>
          <CustomEyeButton
            eyeText='View Leads'
            buttonText='Collect'
            onCollect={handleCollect}
            onViewLeads={handleSelectGroup}
            myGroups={isMyGroup}
            collectionProgress={collectionProgress}
            isHovered={isHovered}
            collectedLeads={group?.collected_leads}
            totalRequested={group?.number_of_requested_leads}
          />
        </div>
      )} */}
      {openCollectModal && (
        <CollectLeadsModal
          open={openCollectModal}
          onClose={() => {
            setOpenCollectModal(false)
          }}
          group={group}
          pointsStore={pointsStore}
        />
      )}
      {openNoMoreLeadsModal && (
        <AreYouSureModal
          open={openNoMoreLeadsModal}
          onClose={() => {
            setOpenNoMoreLeadsModal(false)
          }}
          title='No more leads to fetch from this group!'
          message='Please try another one.'
          onConfirm={() => {
            setOpenNoMoreLeadsModal(false)
          }}
          confirmText={'Got it!'}
        />
      )}
    </Card>
  )
}

export default GroupCard
