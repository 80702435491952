import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

import animationData from '../../../common/assets/lottie-animations/Failure.json'

const FailureAnimation = () => {
  const lottieContainerRef = useRef(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        loop: false,
        renderer: 'svg',
      })
    }
  }, [])

  return <div ref={lottieContainerRef} />
}

export default FailureAnimation

// import { useEffect, useRef } from 'react'
// import lottie from 'lottie-web'

// import failureAnimation from '../../../common/assets/lottie-animations/Failure.json'
// import preAnimation from '../../../common/assets/lottie-animations/Endless.json'

// const FailureAnimation = ({ loopCount = 0 }) => {
//   const lottieContainerRef = useRef<HTMLDivElement>(null)

//   useEffect(() => {
//     if (lottieContainerRef.current) {
//       const preAnim = lottie.loadAnimation({
//         container: lottieContainerRef.current!,
//         animationData: preAnimation,
//         loop: loopCount,
//         renderer: 'svg',
//       })
//       preAnim.setSpeed(2)
//       preAnim.addEventListener('complete', () => {
//         lottieContainerRef.current!.innerHTML = ''

//         const successAnim = lottie.loadAnimation({
//           container: lottieContainerRef.current!,
//           animationData: failureAnimation,
//           loop: false,
//           renderer: 'svg',
//         })
//         successAnim.play()
//       })
//     }
//   }, [])

//   return <div ref={lottieContainerRef} />
// }

// export default FailureAnimation
