import styles from './OnBoardingTelegramAccountSetupPage.module.scss'
// import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'

import { useState, useEffect } from 'react'
import { Input, Form, Spin } from 'antd'

import axios from 'axios'

import { LoadingOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { post } from 'common/api/axios'

import profilePictureDefault from '../../../assets/svg/profilePictureDefault.svg'
import profilePictureDefaultOver from '../../../assets/svg/profilePictureDefaultOver.svg'

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

interface Props {
  handleFormTelegramProfileDataChange: (
    data: any,
    anyimageUrlVal: any,
    userNameVal: any,
    telegramVal: any,
    bioVal: any,
    imageUrlS3Val: any,
    senderNameVal: any,
    senderCompanyVal: any,
    usernameAvailability: any,
  ) => void
  anyimageUrl: any
  userName: any
  senderName: any
  senderCompany: any
  telegram: any
  bio: any
  imageUrlS3?: any
  usernameAvailability: any
}

const OnBoardingTelegramAccountSetupPage = ({
  handleFormTelegramProfileDataChange,
  anyimageUrl,
  userName,
  senderName,
  senderCompany,
  telegram,
  bio,
  imageUrlS3,
  usernameAvailability,
}: Props) => {
  const [userNameVal, setUserName] = useState(userName)
  const [telegramVal, setTelegram] = useState(telegram)
  const [senderNameVal, setSenderName] = useState(senderName)
  const [senderCompanyVal, setSenderCompany] = useState(senderCompany)

  const [bioVal, setBio] = useState(bio)
  const [isHovered, setIsHovered] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingUserName, setLoadingUserName] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>(anyimageUrl)
  const [imageUrlS3Val, setImageUrlS3] = useState<any>(imageUrlS3)
  const [usernameAvailabilityVal, setUsernameAvailability] = useState(usernameAvailability)

  //checkUsernameAvailability

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
    if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }

  const image = isHovered ? profilePictureDefaultOver : profilePictureDefault

  const uploadButton = (
    <>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img src={image} alt='' className={styles.img} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
      )}
    </>
  )
  const [form] = Form.useForm()

  const handleValuesChange = (changedValues: any, allValues: any) => {
    setUserName(allValues.name !== undefined ? allValues.name : userNameVal)
    setTelegram(allValues.username !== undefined ? allValues.username : telegramVal)
    setSenderName(allValues.senderName !== undefined ? allValues.senderName : senderNameVal)

    setSenderCompany(allValues.senderCompany !== undefined ? allValues.senderCompany : senderCompanyVal)
    setBio(allValues.bio !== undefined ? allValues.bio : bioVal)
  }

  const checkUsernameAvailability = async (_: any, value: any) => {
    setUsernameAvailability(false)
    setLoadingUserName(true)
    const response = await post('/user/checkUsernameAvailability', { telegramVal: value })

    setLoadingUserName(false)

    if (response.status === 200 && response?.data?.data?.available === true) {
      return { error: null, vailability: true }
    } else if (response.status === 200 && response?.data?.data?.available === false) {
      return { error: null, vailability: false }
    } else {
      return { error: response.data?.error, vailability: false }
    }
  }

  useEffect(() => {
    const validationStatus =
      userNameVal !== '' &&
      senderNameVal !== '' &&
      senderCompanyVal !== '' &&
      telegramVal !== '' &&
      bioVal !== '' &&
      userNameVal !== undefined &&
      senderNameVal !== undefined &&
      senderCompanyVal !== undefined &&
      telegramVal !== undefined &&
      bioVal !== undefined &&
      /^[^`']+$/g.test(bioVal) === true &&
      imageUrl !== undefined &&
      imageUrl !== '' &&
      imageUrlS3Val !== '' &&
      imageUrlS3Val !== undefined &&
      usernameAvailabilityVal === true &&
      bioVal.length <= 70

    handleFormTelegramProfileDataChange(
      validationStatus,
      imageUrl,
      userNameVal,
      telegramVal,
      bioVal,
      imageUrlS3Val,
      senderNameVal,
      senderCompanyVal,
      usernameAvailabilityVal,
    )
  }, [imageUrl, userNameVal, telegramVal, bioVal, usernameAvailabilityVal, senderNameVal, senderCompanyVal])

  const customRequest = async ({ file, onSuccess }: any) => {
    const formData = new FormData()
    formData.append('image', file)
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/upload/uploadImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    setImageUrlS3(data.s3_url)
    onSuccess('ok')
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Account Setup'}</p>
        <section>
          Fill the information for your account. Keep it clean and reliable
          <br />
        </section>
      </div>
      <div className={styles.parent}>
        <div className={styles.details}>
          <Upload
            customRequest={customRequest}
            onChange={handleChange}
            showUploadList={false}
            beforeUpload={beforeUpload}>
            {imageUrl ? (
              <img src={imageUrl} alt='avatar' style={{ width: '100px', borderRadius: '100px', height: ' 100px' }} />
            ) : (
              uploadButton
            )}
          </Upload>

          <div className={styles.userName}>{'Profile Picture'}</div>
          <div className={styles.telegram}>{'A real picture of you will increase your response rate'}</div>
          <div className={styles.bio}>{'(+7% Response Rate)'}</div>
        </div>
        <Form
          form={form}
          name='validateOnly'
          layout='vertical'
          onValuesChange={handleValuesChange}
          className={styles.form}>
          <div style={{ overflow: 'auto', maxHeight: '350px', padding: '0px 10px' }}>
            <div className={styles.margin_user_name}>{'Name'}</div>
            <Form.Item
              name='name'
              rules={[
                { required: true, message: 'Name is required' },
                {
                  message: 'Invalid Name',
                  pattern: new RegExp(/^[^`']+$/g),
                },
              ]}>
              <Input defaultValue={userNameVal} />
            </Form.Item>
            <span className={styles.recommended}>{'Recommended: First Name / Your Company '}</span>
            <span className={styles.color_2AA58E}>{'(+17% Response Rate)'}</span>

            <div className={styles.input_caption}>{'Username'}</div>
            <Form.Item
              name='username'
              rules={[
                { required: true, message: 'UserName is required' },
                {
                  validator: async (_, value: any) => {
                    if (value && (!/^[a-zA-Z0-9_]+$/.test(value) || value.length <= 5 || value.length >= 32)) {
                      setUsernameAvailability(false)
                      return Promise.reject(
                        'Username must be between 5 and 32 characters long and can only contain alphanumeric characters and underscores',
                      )
                    }
                    if ((value && value.length > 5) || value.length < 32) {
                      const res = await checkUsernameAvailability(_, value)

                      if (res.error === null && res.vailability) {
                        setUsernameAvailability(true)
                        return Promise.resolve()
                      } else {
                        setUsernameAvailability(false)
                        return Promise.reject(res.error || 'this username is already occupied.')
                      }
                    }
                  },
                },
              ]}>
              <Input
                value={telegramVal}
                defaultValue={telegramVal}
                // addonAfter={
                //   loadingUserName && (
                //     <Spin
                //       style={{ background: '#ffffff' }}
                //       indicator={<LoadingOutlined style={{ fontSize: 15, background: '#ffffff' }} spin />}
                //     />
                //   )
                // }q
              />
            </Form.Item>
            {loadingUserName && (
              <Spin
                style={{ background: '#ffffff', position: 'absolute', top: '288px', left: '590px' }}
                indicator={<LoadingOutlined style={{ fontSize: 15, background: '#ffffff' }} spin />}
              />
            )}
            <span className={styles.recommended}>{'Recommended:@namecompany '}</span>
            <span className={styles.color_2AA58E}>{'(+7% Response Rate)'}</span>

            <div className={styles.input_caption}>{'Bio'}</div>
            <Form.Item
              name='bio'
              rules={[
                { required: true, message: 'Bio is required' },
                { message: 'Invalid Bio', pattern: new RegExp(/^[^`']+$/g) },
                // { max: 70, message: 'Bio should not exceed 70 characters' },
              ]}>
              <Input value={bioVal} defaultValue={bioVal} showCount maxLength={70} />
            </Form.Item>
            <span className={styles.recommended}>{'Recommended: Position @ Company / LinkedIn '}</span>
            <span className={styles.color_2AA58E}>{'(+32% Response Rate)'}</span>
            <div className={styles.margin_user_name}>{'Sender Name'}</div>
            <Form.Item
              name='senderName'
              rules={[
                { required: true, message: 'sender name is required' },
                {
                  message: 'Invalid Sender Name',
                },
              ]}>
              <Input defaultValue={senderNameVal} />
            </Form.Item>
            <div className={styles.margin_user_name}>{'Sender Company'}</div>
            <Form.Item
              name='senderCompany'
              rules={[
                { required: true, message: 'sender company is required' },
                {
                  message: 'Invalid Sender Company',
                },
              ]}>
              <Input defaultValue={senderNameVal} />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default OnBoardingTelegramAccountSetupPage
