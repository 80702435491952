import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

import animationData from '../../../common/assets/lottie-animations/Success.json'

const SuccessAnimation = () => {
  const lottieContainerRef = useRef(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        loop: false,
        renderer: 'svg',
      })
    }
  }, [])

  return <div ref={lottieContainerRef} />
}

export default SuccessAnimation

// import React, { useEffect, useRef } from 'react'
// import lottie from 'lottie-web'

// import animationData0 from '../../../common/assets/lottie-animations/Success.json'
// import animationData1 from '../../../common/assets/lottie-animations/Failure.json'

// const SuccessAnimation = ({ anim = 0 }) => {
//   const lottieContainerRef = useRef(null)

//   useEffect(() => {
//     let animationData

//     switch (anim) {
//       case 0:
//         animationData = animationData0
//         break
//       case 1:
//         animationData = animationData1
//         break
//       default:
//         animationData = animationData0
//     }

//     if (lottieContainerRef.current && animationData) {
//       lottie.loadAnimation({
//         container: lottieContainerRef.current,
//         animationData: animationData,
//         loop: false,
//         renderer: 'svg',
//       })
//     }
//   }, [anim]) // Re-run the effect when the anim prop changes

//   return <div ref={lottieContainerRef} />
// }

// export default SuccessAnimation

// import { useEffect, useRef, useState } from 'react'
// import lottie from 'lottie-web'

// import successAnimation from '../../../common/assets/lottie-animations/Success.json'
// import preAnimation from '../../../common/assets/lottie-animations/Endless.json'

// const SuccessAnimation = ({ loopsCount = 0 }) => {
//   const lottieContainerRef = useRef<HTMLDivElement>(null)

//   useEffect(() => {
//     if (lottieContainerRef.current) {
//       const preAnim = lottie.loadAnimation({
//         container: lottieContainerRef.current!,
//         animationData: preAnimation,
//         loop: loopsCount,
//         renderer: 'svg',
//       })

//       preAnim.setSpeed(2)
//       preAnim.addEventListener('complete', () => {
//         lottieContainerRef.current!.innerHTML = ''

//         const successAnim = lottie.loadAnimation({
//           container: lottieContainerRef.current!,
//           animationData: successAnimation,
//           loop: false,
//           renderer: 'svg',
//         })
//         successAnim.play()
//       })
//     }
//   }, [])

//   return <div ref={lottieContainerRef} />
// }

// export default SuccessAnimation
