import React, { useEffect, useState } from 'react'
import { Button, Space, Collapse, Checkbox, Select, TreeSelect, Divider } from 'antd'
// import type { CheckboxProps } from 'antd'
import styles from './LeadsFilter.module.scss'
// import { countriesWithPhonePrefix } from 'common/static-data/countires'
// import AccuracyData from './AccuracyData'
// import SourceData from './SourceData'
// import ChannelData from './ChannelData'
import { TreeNode } from 'antd/es/tree-select'
import { post } from 'common/api/axios'
import { useAppSelector } from 'state'

const { Panel } = Collapse

interface Lead {
  key: string
  name: string
  lead_photo: string
  company: string
  company_photo: string
  jobTitle: string
  accuracy: number
  location: string
  industry: string
  businessType: string[]
  source: string
  channels: string
  enrich: string
  facebook?: string
  linkedin?: string
  instagram?: string
  twitter?: string
  discord?: string
}

interface LeasFilterProps {
  onFilter: (filters: any) => void
  data: Lead[]
}

// const accuracyRanges = [
//   { label: '0-25%', value: '0-25' },
//   { label: '26-50%', value: '26-50' },
//   { label: '51-75%', value: '51-75' },
//   { label: '76-100%', value: '76-100' },
// ]

const LeadsFilter: React.FC<LeasFilterProps> = ({ onFilter, data }) => {
  const { ai_leads_type } = useAppSelector((state) => state.outreachAICampaign)
  const showFilter = ai_leads_type !== 'companies_leads'
  const [selectedFilters, setSelectedFilters] = useState<any>({
    company: [],
    location: [],
    industry: [],
    businessType: [],
    jobTitle: [],
    accuracy: [],
    source: [],
    channels: [],
  })
  const [companyOptions, setCompanyOptions] = useState<any[]>([])
  const [industryOptions, setIndustryOptions] = useState<any[]>([])
  const [locationOptions, setLocationOptions] = useState<any[]>([])
  const [businessTypeOptions, setBusinessTypeOptions] = useState<any[]>([])
  const [jobTitleOptions, setJobTitleOptions] = useState<any[]>([])
  const [sourceOptions, setSourceOptions] = useState<any[]>([])
  const [channelsOptions, setChannelsOptions] = useState<any[]>([])

  const handleFilterChange = (filterType: string, checked: boolean, value: any) => {
    setSelectedFilters((prevFilters: any) => {
      const currentFilterValues = prevFilters[filterType]
      let updatedFilterValues
      if (checked) {
        updatedFilterValues = currentFilterValues.includes(value)
          ? currentFilterValues
          : [...currentFilterValues, value]
      } else {
        updatedFilterValues = currentFilterValues.filter((item: any) => item !== value)
      }
      return {
        ...prevFilters,
        [filterType]: updatedFilterValues,
      }
    })
  }

  const handleFilterSelectChange = (filterType: string, selectedValues: any[]) => {
    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [filterType]: selectedValues,
    }))
  }

  const handleTreeSelectChange = (filterType: string, value: any) => {
    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [filterType]: value,
    }))
  }

  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
  }

  const handleSaveFilters = () => {
    post('/log', {
      action: `Add Leads to Campaign AI filters`,
      data: selectedFilters,
    })
    onFilter(selectedFilters)
  }

  const handleClearFilters = () => {
    const resetFilters = {
      company: [],
      location: [],
      industry: [],
      businessType: [],
      jobTitle: [],
      accuracy: [],
      source: [],
      channels: [],
    }
    setSelectedFilters(resetFilters)
    onFilter(resetFilters)
  }

  useEffect(() => {
    if (data) {
      // Generate unique options dynamically from data
      const uniqueIndustries = Array.from(new Set(data.map((lead) => lead.industry))).map((industry) => ({
        value: industry,
        label: industry,
      }))
      const uniqueLocations = Array.from(new Set(data.map((lead) => lead.location))).map((location) => ({
        value: location,
        label: location,
      }))
      const uniqueBusinessTypes = Array.from(new Set(data.flatMap((lead) => lead.businessType))).map(
        (businessType) => ({
          value: businessType,
          label: businessType,
        }),
      )
      const uniqueJobTitles = Array.from(new Set(data.map((lead) => lead.jobTitle))).map((jobTitle) => ({
        value: jobTitle,
        label: jobTitle,
      }))
      const uniqueSources = Array.from(new Set(data.map((lead) => lead.source))).map((source) => ({
        value: source,
        title: source,
        key: source,
      }))
      const uniqueCompanies = Array.from(new Set(data.map((lead) => lead.company))).map((company) => ({
        value: company,
        label: company,
        key: company,
      }))
      let availableChannels = {
        facebook: false,
        linkedin: false,
        instagram: false,
        twitter: false,
        discord: false,
      }

      data.forEach((lead) => {
        if (lead.facebook) availableChannels.facebook = true
        if (lead.linkedin) availableChannels.linkedin = true
        if (lead.instagram) availableChannels.instagram = true
        if (lead.twitter) availableChannels.twitter = true
        if (lead.discord) availableChannels.discord = true
      })

      const channelsArray = Object.entries(availableChannels)
        .filter(([_, isAvailable]) => isAvailable)
        .map(([channel, _]) => ({
          label: channel.charAt(0).toUpperCase() + channel.slice(1),
          value: channel,
        }))

      setCompanyOptions(uniqueCompanies)
      setIndustryOptions(uniqueIndustries)
      setLocationOptions(uniqueLocations)
      setBusinessTypeOptions(uniqueBusinessTypes)
      setJobTitleOptions(uniqueJobTitles)
      setSourceOptions(uniqueSources)
      setChannelsOptions(channelsArray)
    }
  }, [data])

  return (
    <div className='LeadsFilterModal'>
      <div className={styles.header}>
        <p>Filters List</p>
        <Space>
          {/* <span style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
            Cancel
          </span> */}
          <span onClick={handleClearFilters} style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }}>
            Clear
          </span>
          <Divider type='vertical' style={{ margin: 0 }} />
          <span onClick={handleSaveFilters} style={{ fontWeight: 600, color: '#7043ff', cursor: 'pointer' }}>
            Apply Filters
          </span>
        </Space>
      </div>
      <Collapse className='FilterCollapse' accordion>
        <Panel header='Company' key='1'>
          <div className='filter-item' style={{ overflowY: 'scroll', maxHeight: '260px' }}>
            {companyOptions.map((item, i) => (
              <Checkbox
                checked={selectedFilters?.company.includes(item.value)}
                onChange={(e) => handleFilterChange('company', e.target.checked, item.value)}
                key={i}>
                {item.label} <span className='count'>{item.count}</span>
              </Checkbox>
            ))}
            <Button className='see_more'>See More</Button>
          </div>
        </Panel>
        <Panel header='Location' key='2'>
          <div className='filter-item'>
            <Select
              maxTagCount={3}
              className='tagsList'
              size={'middle'}
              style={{ width: '100%' }}
              placeholder='Choose Location'
              mode={'multiple'}
              showSearch={true}
              filterOption={filterOption}
              value={selectedFilters.location}
              options={locationOptions}
              onChange={(selectedValues) => handleFilterSelectChange('location', selectedValues)}
            />
          </div>
        </Panel>
        <Panel header='Industry' key='3'>
          <div className='filter-item'>
            <Select
              maxTagCount={3}
              showSearch
              className='tagsList'
              size={'middle'}
              mode={'multiple'}
              style={{ width: '100%' }}
              placeholder='Search to Select'
              optionFilterProp='children'
              value={selectedFilters.industry}
              // filterOption={filterOption}
              options={industryOptions}
              onChange={(selectedValues) => handleFilterSelectChange('industry', selectedValues)}
            />
          </div>
        </Panel>
        <Panel header='Business type' key='4'>
          <div className='filter-item'>
            <Select
              dropdownStyle={{ textTransform: 'capitalize' }}
              maxTagCount={3}
              showSearch
              className='tagsList'
              mode={'multiple'}
              size={'middle'}
              style={{ width: '100%' }}
              placeholder='Search to Select'
              optionFilterProp='children'
              filterOption={filterOption}
              value={selectedFilters.businessType}
              options={businessTypeOptions}
              onChange={(selectedValues) => handleFilterSelectChange('businessType', selectedValues)}
            />
          </div>
        </Panel>
        {showFilter && (
          <Panel header='Job Title' key='5'>
            <div className='filter-item'>
              <Select
                maxTagCount={3}
                showSearch
                className='tagsList'
                mode={'multiple'}
                size={'middle'}
                style={{ width: '100%' }}
                placeholder='Search to Select'
                optionFilterProp='children'
                filterOption={filterOption}
                value={selectedFilters.jobTitle}
                options={jobTitleOptions}
                onChange={(selectedValues) => handleFilterSelectChange('jobTitle', selectedValues)}
              />
            </div>
          </Panel>
        )}
        {/* {showFilter && (
          <Panel header='Accuracy' key='6'>
            <div className='filter-item'>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={selectedFilters.accuracy}
                onChange={(selectedValues) => handleFilterSelectChange('accuracy', selectedValues)}>
                <Space direction='vertical'>
                  {accuracyRanges.map((range) => (
                    <Checkbox key={range.value} value={range.value}>
                      {range.label}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>{' '}
            </div>
          </Panel>
        )} */}
        <Panel header='Source' key='7'>
          <div className='filter-item'>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={selectedFilters.source}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder='Please select'
              allowClear
              multiple
              treeDefaultExpandAll
              onChange={(value) => handleTreeSelectChange('source', value)}>
              {sourceOptions.map((option) => (
                <TreeNode value={option.value} title={option.title} key={option.key} />
              ))}
            </TreeSelect>{' '}
          </div>
        </Panel>
        <Panel header='Channels' key='8'>
          <div className='filter-item'>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={selectedFilters.channels}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder='Please select'
              allowClear
              multiple
              treeDefaultExpandAll
              onChange={(value) => handleTreeSelectChange('channels', value)}>
              {channelsOptions.map((option) => (
                <TreeNode value={option.value} title={option.label} key={option.key} />
              ))}
            </TreeSelect>{' '}
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default LeadsFilter
