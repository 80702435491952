import styles from './OnBoardingPaymentPage.module.scss'
import { post, get } from 'common/api/axios'

import LoadingBox from '../../LoadingBox/LoadingBox'
import stripeLogo from 'common/assets/svg/stripe_logo.svg'
import info_icon from 'common/assets/svg/info_icon.svg'

import { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js'
import { Button, Input, List, Tag, message, Popover } from 'antd'
import { CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons'
import { useLazyApplyDiscountCodeQuery, useLazyGetPaymentDetailsQuery } from 'features/settings/state/api/SettingsApi'

interface Props {
  handleClientSecret?: any
  setDiscountCodeForPayment?: any
  outreachSuite?: any
}

const OnBoardingPaymentPage = ({ handleClientSecret, setDiscountCodeForPayment, outreachSuite }: Props) => {
  const [clientSecret, setClientSecret] = useState('')
  const [stripePromise, settripePromise] = useState<any>('')
  const [existingCustomer, setExistingCustomer] = useState(false)
  const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  const [refetchApplyDiscountCode] = useLazyApplyDiscountCodeQuery()
  const [discountCode, setDiscountCode] = useState('')
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false)
  const [isApplyedDiscount, setIsApplyedDiscount] = useState<any>(null)
  const [totalPrice, setTotalPrice] = useState(19.9)
  const [userHasDiscountCode, setUserHasDiscountCode] = useState(false)
  const defaultCreditCard = paymentData?.last4

  const handleDiscountCodeChange = (e: any) => {
    setDiscountCode(e.target.value.toLocaleUpperCase())
  }

  const applyDiscountCode = async () => {
    try {
      setIsApplyedDiscount(null)
      setIsApplyingDiscount(true)
      const { data } = await refetchApplyDiscountCode({ product: 'managed_account', discountCode: discountCode })

      if (data?.percent_off) {
        message.success('Discount Code applied succesfully')
        setDiscountCodeForPayment(data)
        setIsApplyingDiscount(false)
        setTotalPrice((19.9 * (100 - data?.percent_off)) / 100)
        setTimeout(() => {
          setIsApplyedDiscount(discountCode)
        }, 300)
      } else {
        message.error('Discount Code is not valid.')
        setTotalPrice(19.9)
        setIsApplyingDiscount(false)
        setIsApplyedDiscount(null)
      }
    } catch (error) {
      message.error('Failed to apply discount code')
      setTotalPrice(19.9)
      setIsApplyingDiscount(false)
      setIsApplyedDiscount(null)
    } finally {
      setIsApplyingDiscount(false)
    }
  }

  const handleRemoveDiscountCode = async () => {
    try {
      setTotalPrice(19.9)
      setIsApplyedDiscount(null)
      setDiscountCode('')
      // ADD LOGIC FOR REMOVING FROM BACKEND
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const createCheckoutSession = async () => {
      const data: any = await post('/user/telegramAccount', { product_name: 'managed_telegram_account' })

      if (data.data.existingCustomer) {
        setExistingCustomer(data.data.existingCustomer)
      } else {
        setClientSecret(data.data.clientSecret.replaceAll('"', ''))
        handleClientSecret(data.data.session.id.replaceAll('"', ''))
        setExistingCustomer(data.data.existingCustomer)
      }
    }
    createCheckoutSession()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { publishableKey } = (await get('/user/stripeConfig')).data
      settripePromise(loadStripe(publishableKey))
    }

    fetchData()
  }, [])

  useEffect(() => {
    const getCard = async () => {
      await refetchGetPaymentDetails()
    }
    getCard()
  }, [])

  const options = { clientSecret }
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Choose Outreach Method'}</p>
        <section>
          Choose a dedicated account (a customized account for your sales goals) or use your own Telegram account
          <br />
        </section>
      </div>
      {existingCustomer ? (
        <div className={styles.paymentSummaryModal}>
          <List className={styles.summaryList}>
            <List.Item className={styles.listItem}>
              <div className={styles.summaryItem}>
                <span>
                  Warmed Telegram Account x 1<span style={{ fontWeight: 400, color: '#666' }}>, Every Month</span>
                </span>
              </div>
            </List.Item>
            {userHasDiscountCode && (
              <List.Item className={styles.listItem}>
                <div className={styles.discountCodeInput}>
                  <Input
                    placeholder='Enter discount code'
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                    disabled={isApplyingDiscount}
                  />
                  <Button
                    type='primary'
                    onClick={applyDiscountCode}
                    loading={isApplyingDiscount}
                    disabled={!discountCode.trim()}>
                    Apply
                  </Button>
                  {isApplyedDiscount && (
                    <Tag
                      key={isApplyedDiscount}
                      closable={true}
                      style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}
                      onClose={handleRemoveDiscountCode}>
                      {isApplyedDiscount?.toLocaleUpperCase()}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
            <List.Item className={styles.listItem}>
              <div className={styles.summaryTotal}>
                <span>Total Price: </span>
                {isApplyedDiscount ? (
                  <span>
                    <span style={{ textDecoration: 'line-through', marginRight: '5px', fontWeight: 500 }}>$19.90</span>$
                    {totalPrice.toFixed(2)}
                  </span>
                ) : (
                  <span>$19.90{outreachSuite % 5 === 0 && ' + $99'}</span>
                )}

                {outreachSuite % 5 === 0 && (
                  <Popover
                    className={styles.outreachSuitePopover}
                    placement='top'
                    trigger='hover'
                    content='Every 5 Warmed TG accounts require an Outreach Suite'>
                    <img src={info_icon} alt={''} />
                  </Popover>
                )}
              </div>
            </List.Item>
          </List>
          <div className={styles.cardInfoSection}>
            <CreditCardOutlined className={styles.cardIcon} />
            <span className={styles.cardText}>Paying with •••• •••• •••• {defaultCreditCard?.slice(-4)}</span>
          </div>
          {!userHasDiscountCode && (
            <div className={styles.hasDiscountCode}>
              <span onClick={() => setUserHasDiscountCode(true)}>I have a special discount code.</span>
            </div>
          )}
          <div className={styles.confirmationSection}>
            <CheckCircleOutlined className={styles.confirmationIcon} />
            <p className={styles.confirmationText}>Review your order details before proceeding to payment.</p>
          </div>
          <div className={styles.poweredByStripe}>
            <span className={styles.poweredBy}>Powered by</span>
            <img src={stripeLogo} alt='Stripe' className={styles.stripeLogo} />
          </div>
        </div>
      ) : (
        clientSecret && (
          <div id='checkout' style={{ overflow: 'auto', width: '100%', height: '470px' }}>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )
      )}
      {!clientSecret && !existingCustomer && <LoadingBox />}
    </div>
  )
}

export default OnBoardingPaymentPage
