import React, { useState } from 'react'
import { Modal, Radio, Button, Typography } from 'antd'
import styles from './SelectPlanModal.module.scss'

const { Title } = Typography

interface SelectPlanModalProps {
  open: boolean
  onCancel: () => void
  currentPlan: string
}

const SelectPlanModal: React.FC<SelectPlanModalProps> = ({ open, onCancel, currentPlan }) => {
  // eslint-disable-next-line
  const [selectedPlan, setSelectedPlan] = useState('')

  // const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedPlan(e.target.value)
  // }

  const handleUpgrade = () => {
    // Implement logic for upgrading to the selected plan
    onCancel()
  }

  return (
    <Modal
      open={open}
      title='Select Your Plan'
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='upgrade' type='primary' onClick={handleUpgrade} disabled={!selectedPlan}>
          Upgrade
        </Button>,
      ]}
      className={styles.selectPlanModal}>
      <div className={styles.currentPlan}>
        <Title level={4}>Your Current Plan</Title>
        <p>{currentPlan}</p>
      </div>
      <div className={styles.selectPlan}>
        <Title level={4}>Select a New Plan</Title>
        <Radio.Group value={selectedPlan}>
          <Radio.Button value='Startups' className={styles.planButton}>
            Startups
          </Radio.Button>
          <Radio.Button value='Premium' className={styles.planButton}>
            Premium
          </Radio.Button>
          <Radio.Button value='Advanced' className={styles.planButton}>
            Advanced
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.billingCycle}>
        <Title level={4}>Select Billing Cycle</Title>
        <Radio.Group onChange={() => {}} value=''>
          <Radio.Button value='monthly' className={styles.cycleButton}>
            Monthly
          </Radio.Button>
          <Radio.Button value='sixMonths' className={styles.cycleButton}>
            Six Months
          </Radio.Button>
          <Radio.Button value='yearly' className={styles.cycleButton}>
            Yearly
          </Radio.Button>
        </Radio.Group>
      </div>
    </Modal>
  )
}

export default SelectPlanModal
