import React from 'react'
import { AppstoreAddOutlined } from '@ant-design/icons'

import Category from './Category'
import control_icon from 'common/assets/svg/control_icon.svg'
import styles from './Category.module.scss'

interface CategorizerProps {
  data: any
}

const Categorizer: React.FC<CategorizerProps> = ({ data }) => {
  return (
    <div className={styles['categorizer-component']}>
      <div className={styles['categorizer-component__header']}>
        <div className={styles['categorizer-component__header__title']}>
          <AppstoreAddOutlined style={{ color: '#7043FF', marginRight: '10px' }} />
          <span>AI Categorizer</span>
        </div>
        <img src={control_icon} alt='' />
      </div>
      <div className={styles['categorizer-component__body']}>
        {data?.length > 0 && data.map((item: any, index: number) => <Category key={index} data={item} />)}
      </div>
    </div>
  )
}

export default Categorizer
