import React, { useState } from 'react'
import { Select } from 'antd'
import styles from './SocialMediaAccounts.module.scss'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_logo from 'common/assets/svg/instagram_logo.svg'
import twitter_logo from 'common/assets/svg/twitter_logo.svg'
import discord_logo from 'common/assets/svg/discord_logo.svg'
import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
import telegram_logo from 'common/assets/svg/telegram_logo.svg'
import { SET_NODES_DATA, SET_SELECTED_PLATFORMS_ACCOUNTS } from '../../state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'
import { post } from 'common/api/axios'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import { SET_BUILDER_DATA } from '../../Builder/Slice/CampaignBuilderSlice'
import { getSenderByPlatform } from 'common/utils/campaignHelpers'

interface SocialMediaAccountsProps {
  platform: any
  selectId: string
  selectOptions: JSX.Element
  setCheckLocalStorage: any
}

const platformIcons: any = {
  facebook: facebook_icon,
  instagram: instagram_logo,
  twitter: twitter_logo,
  discord: discord_logo,
  linkedin: linkedin_logo,
  telegram: telegram_logo,
}

const SocialMediaAccounts: React.FC<SocialMediaAccountsProps> = ({
  platform,
  selectId,
  selectOptions,
  setCheckLocalStorage,
}) => {
  const dispatch = useAppDispatch()
  const { selected_platforms_accounts, nodes_data } = useAppSelector((state) => state.outreachAICampaign)
  const { builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const [openAddTelegramModal, setOpenTelegramModal] = useState(false)
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)

  //   const handleUpdateSender = (value: any) => {
  //     const currentNodeId = currentSideBar.nodeId
  //     const existingNodeData = findStepById(nodesData, currentNodeId)
  //     const allSenders = getSenderByPlatform(selected_platforms_accounts)
  //     const senderData = allSenders.filter((s: any) => value.includes(s.username))

  //     dispatch(SET_SELECTED_SENDER(senderData))

  //     const updatedNodeData = {
  //       ...existingNodeData,
  //       data: {
  //         ...existingNodeData.data,
  //         sender: senderData,
  //       },
  //     }
  //     dispatch(SET_NODES_DATA(updatedNodeData))
  //   }

  const handleSelectionChange = (selectedAccounts: string[]) => {
    post('/log', {
      action: `Select account`,
      data: { [platform]: selectedAccounts },
    })
    const updatedSelectedPlatformsAccounts = {
      ...selected_platforms_accounts,
      [platform]: selectedAccounts,
    }
    dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(updatedSelectedPlatformsAccounts))

    const allSenders = getSenderByPlatform(updatedSelectedPlatformsAccounts)
    const senderData = allSenders.filter((s) => {
      if (updatedSelectedPlatformsAccounts?.linkedin?.length > 0) {
        // Change this when adding multi-channel campaigns
        return selectedAccounts.includes(s.email)
      } else {
        return selectedAccounts.includes(s.username)
      }
    })

    const updatedNodesData = nodes_data.map((node: any) => {
      const newNode = { ...node }

      newNode.data = { ...newNode.data, sender: senderData }

      return newNode
    })
    dispatch(SET_NODES_DATA(updatedNodesData))

    if (Object.keys(builder_data)?.length > 0) {
      const updatedNodesData = builder_data?.nodes?.map((node: any) => {
        const newNode = { ...node }

        newNode.data = { ...newNode.data, sender: senderData }

        return newNode
      })
      dispatch(SET_BUILDER_DATA({ nodes: updatedNodesData, edges: builder_data.edges }))
    }
  }

  const handleAddAccountClick = () => {
    post('/log', {
      action: `Add Account`,
    })
    if (platform === 'telegram') {
      setOpenTelegramModal(true)
    } else if (['instagram', 'facebook', 'linkedin']?.includes(platform)) {
      setOpenSocialConnectionModal(true)
    }
  }

  return (
    <div className={`${styles.socialMediaContainer}`}>
      <div className={styles.top}>
        <div className={styles.title}>
          <img src={platformIcons[platform]} alt='' />
          <p>{platform === 'twitter' ? 'X (Twitter)' : platform[0].toLocaleUpperCase() + platform.slice(1)} Accounts</p>
        </div>
        <div className={styles.addAccount}>
          <label style={{ cursor: 'pointer' }} onClick={handleAddAccountClick}>
            Add Account
          </label>
        </div>
      </div>
      <div className={styles.bottom}>
        <Select
          key={selectId}
          id={selectId}
          className='selectSocialAccounts'
          size='large'
          style={{ width: '100%' }}
          placeholder='Select account'
          mode='multiple'
          onChange={handleSelectionChange}
          defaultValue={selected_platforms_accounts[platform]}>
          {selectOptions}
        </Select>
      </div>
      {openAddTelegramModal && (
        <OutreachConnection open={openAddTelegramModal} onClose={() => setOpenTelegramModal(false)} />
      )}
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={platform}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
    </div>
  )
}

export default SocialMediaAccounts
