import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const CompaniesApi: any = createApi({
  reducerPath: 'CompaniesApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getCompanies: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies?current=${params.current}&pageSize=${params.pageSize}`,
        body: params.body,
      }),
    }),

    getCompaniesCSV: builder.mutation({
      query: (params: { companiesId: string }) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/export`,
        body: params,
      }),
    }),

    getCompaniesHubSpot: builder.mutation({
      query: (params: { companiesId: string; do_override_if_exists: boolean }) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/hubspot`,
        body: params,
      }),
    }),

    getRateData: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/rate_data`,
        body: params,
      }),
    }),

    getCompany: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${params}`,
      }),
    }),
    getCompaniesByIds: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/byids`,
        body: { companyIds: params },
      }),
    }),
    getTags: builder.mutation({
      query: (params: string) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/tags?search=${params}`,
      }),
    }),
    getTracking: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/tracking?key=${params.key}&company_id=${params.company_id}`,
      }),
    }),
    getTrackingSocialLinks: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/followsociallinks?company_id=${params.company_id}&social_link=${params.social_link}`,
      }),
    }),
    addNewList: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/lists/add`,
        body: params,
      }),
    }),
    getLists: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/lists/get`,
      }),
    }),
    updateList: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/lists/update`,
        body: params,
      }),
    }),
    deleteList: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/lists/delete`,
        body: params,
      }),
    }),
    renameList: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/lists/rename`,
        body: params,
      }),
    }),
    getFundingRoundType: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/funding_type`,
      }),
    }),
    getCompaniesTelegramContacts: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/companies/telegram-contacts`,
      }),
    }),
    getSaveFilters: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/filters/get`,
        body: params,
      }),
    }),
    deleteSaveFilters: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/filters/delete`,
        body: params,
      }),
    }),
    addSaveFilters: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/filters/add`,
        body: params,
      }),
    }),
    updateSaveFilters: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/companies/filters/update`,
        body: params,
      }),
    }),
    getCompanyNames: builder.mutation({
      query: (params: string) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/companyNames?search=${params}`,
      }),
    }),
  }),
})

export const {
  useGetCompaniesMutation,
  useGetCompaniesCSVMutation,
  useGetCompaniesHubSpotMutation,
  useGetRateDataMutation,
  useGetCompanyMutation,
  useLazyGetCompaniesByIdsQuery,
  useGetTagsMutation,
  useLazyGetTrackingQuery,
  useLazyGetTrackingSocialLinksQuery,
  useLazyAddNewListQuery,
  useLazyGetListsQuery,
  useLazyUpdateListQuery,
  useLazyDeleteListQuery,
  useLazyRenameListQuery,
  useLazyGetFundingRoundTypeQuery,
  useLazyGetCompaniesTelegramContactsQuery,
  useLazyGetSaveFiltersQuery,
  useLazyDeleteSaveFiltersQuery,
  useLazyAddSaveFiltersQuery,
  useLazyUpdateSaveFiltersQuery,
  useGetCompanyNamesMutation,
} = CompaniesApi
