import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

import { ContactsApi } from 'features/contacts/state/api/ContactsApi'
import { CompaniesApi } from 'features/intent-signals/state/api/CompaniesApi'
import { OutreachApi } from 'features/Outreach/state/api/OutreachApi'
import { companiesReducer } from 'features/intent-signals/state/slice/companiesSlice'
import { SettingsApi } from 'features/settings/state/api/SettingsApi'
import { settingsReducer } from 'features/settings/state/slice/settingsSlice'

import { contactsReducer } from 'features/contacts/state/slice/contactsSlice'
import { eventsReducer } from 'features/events/state/slice/events.slice'
import { filtersReducer } from './filtersSlice/filtersSlice'
import { paginationReducer } from './paginationSlice/paginationSlice'
import { adminCompanyReducer } from '../features/company-admin-pannel/state/slice/adminCompanySlice'
import { outreachedReducer } from 'features/Outreach/state/sendMessageSlice'
import { outreachedCampaignReducer } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import { EventsApi } from '../features/events/state/api/EventsApi'
import { AdminCompanyApi } from '../features/company-admin-pannel/state/api/AdminCompanyApi'
import { UserApi } from 'common/api/UserApi'
import { outreachAICampaignReducer } from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { SocialConnectionApi } from 'common/components/SocialConnection/state/api/SocialConnectionApi'
import { OutreachAIApi } from 'common/components/OutreachAI/state/api/OutreachAI-API'
import { ChatApi } from 'common/components/Chat/state/api/Chat-API'
import { chatSliceReducer } from 'common/components/Chat/state/chatSlice'
import { CampaignBuilderReducer } from 'common/components/OutreachAI/Builder/Slice/CampaignBuilderSlice'

export const store = configureStore({
  reducer: {
    [CompaniesApi.reducerPath]: CompaniesApi.reducer,
    [ContactsApi.reducerPath]: ContactsApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [OutreachApi.reducerPath]: OutreachApi.reducer,
    [OutreachAIApi.reducerPath]: OutreachAIApi.reducer,
    [EventsApi.reducerPath]: EventsApi.reducer,
    [AdminCompanyApi.reducerPath]: AdminCompanyApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [SocialConnectionApi.reducerPath]: SocialConnectionApi.reducer,
    pagination: paginationReducer,
    companies: companiesReducer,
    contacts: contactsReducer,
    filters: filtersReducer,
    events: eventsReducer,
    settings: settingsReducer,
    company_admin_pannel: adminCompanyReducer,
    outreached: outreachedReducer,
    outreachCampaign: outreachedCampaignReducer,
    outreachAICampaign: outreachAICampaignReducer,
    CampaignBuilder: CampaignBuilderReducer,
    chat: chatSliceReducer,
  },
  // Disabled serializableCheck because it failed the image imported by user for profilePic.
  // A non-serializable value was detected in the state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      CompaniesApi.middleware,
      ContactsApi.middleware,
      SettingsApi.middleware,
      OutreachApi.middleware,
      OutreachAIApi.middleware,
      EventsApi.middleware,
      AdminCompanyApi.middleware,
      UserApi.middleware,
      SocialConnectionApi.middleware,
      ChatApi.middleware,
    ]),
})

setupListeners(store.dispatch)
