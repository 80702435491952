import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import SendMessage from './SendMessage'
import OutreachActions from './OutreachActions'

import styles from '../ExposedContacts.module.scss'

const BottomMenu = ({ selectedContacts }: any) => (
  <div className={styles.bottomMenuContainer}>
    <div>
      {`To avoid spam, please do not message more than 30 unique contacts per day   `}
      <Tooltip
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
        title={`According to Telegram rules, messaging more than 30 unique contacts in a day may risk haveing your account flagged as spam, please ensure that you don't exceed this daily limit. `}>
        {<InfoCircleOutlined />}
      </Tooltip>
    </div>
    <div className={styles.bottomMenuButtons}>
      <div style={{ marginRight: '8px' }}>
        <OutreachActions selectedContacts={selectedContacts} />
      </div>
      <SendMessage contactsList={selectedContacts} />
    </div>
  </div>
)

export default BottomMenu
