import React, { useState } from 'react'
import { Input, Card, Empty } from 'antd'
import styles from './LoadMessagesDrawer.module.scss'

interface LoadMessagesDrawerProps {
  messages: string[]
  onReplaceComposer: (text: string) => void
}

const LoadMessagesDrawer: React.FC<LoadMessagesDrawerProps> = ({ messages, onReplaceComposer }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const filteredMessages = messages?.filter((message) => message?.toLowerCase()?.includes(searchQuery.toLowerCase()))

  return (
    <div className={styles.loadMessagesContainer}>
      <h3>Available Messages ({filteredMessages.length})</h3>
      <span>Select the message you'd like to load for the AI Responder</span>
      <Input placeholder='Search for messages' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      {filteredMessages.length > 0 ? (
        filteredMessages.map((message, index) => (
          <Card
            key={index}
            hoverable
            className={styles.card}
            onClick={() => {
              onReplaceComposer(message)
            }}>
            <div className={styles.cardContent}>{message}</div>
            <div className={styles.overlay}>
              <span className={styles.addButton}>Add Message</span>
            </div>
          </Card>
        ))
      ) : (
        <Empty description='No messages found' />
      )}
    </div>
  )
}

export default LoadMessagesDrawer
