import React from 'react'
import { Spin } from 'antd'
import styles from './LoadingOverlay.module.scss'
import { LoadingOutlined } from '@ant-design/icons'

interface LoadingOverlayProps {
  isLoading: boolean
  text?: string
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading, text }) => {
  if (!isLoading) return <></>

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </div>
  )
}

export default LoadingOverlay
