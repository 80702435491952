import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, message } from 'antd'
import styles from './FlowPreview.module.scss'
import lightning_bold_icon from 'common/assets/svg/lightning_bold_icon.svg'
import ReactFlow, { Background, useNodesState, useEdgesState, useReactFlow } from 'reactflow'
import 'reactflow/dist/style.css'
import CustomNode from '../Canvas/CustomNode'
import CustomCollapseNode from '../Canvas/CustomCollapseNode'
import { useAppDispatch, useAppSelector } from 'state'
import { getNodesAndEdges } from './getNodesAndEdges'
import CustomControls from './UI/CustomControls'
import {
  SET_NODES_DATA,
  SET_EDGES_DATA,
  SET_SELECTED_NODE,
  SET_DEFAULT_TEMPLATES,
  SET_FLOW_DATA,
} from '../state/outreachAICampaignSlice'
import { post } from 'common/api/axios'
import FlowPreviewStrip from './FlowPreviewStrip'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import InitialNode from '../Builder/UI/InitialNode'
import PlaceholderNode from '../Builder/UI/PlaceholderNode'
import CustomCampaignModal from '../Campaign/CustomCampaign/CustomCampaignModal'
import { useLazyGetMyTemplatesQuery, useLazyUpdateCampaignAiQuery } from '../state/api/OutreachAI-API'
import { RESET_BUILDER, SET_SELECTED_BUILDER_NODE, SET_USE_CUSTOM_FLOW } from '../Builder/Slice/CampaignBuilderSlice'
import { getShouldContainMessages } from 'common/utils/campaignHelpers'
import { FalseEdge, TrueEdge } from '../Canvas/CustomEdge'
import { transformToNewStructure } from './transformStepsUtil'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
import { useLocation } from 'react-router-dom'

const DEBOUNCE_DELAY = 600

const hide = (hidden: any) => (nodeOrEdge: any) => {
  nodeOrEdge.hidden = hidden
  return nodeOrEdge
}

const FlowPreview: React.FC = () => {
  const { navigateWithCampaignId } = useCampaignNavigation()
  const dispatch = useAppDispatch()
  const { fitView } = useReactFlow()
  const [getMyTemplates, { data: templates }] = useLazyGetMyTemplatesQuery()
  const [openCustomCampaignModal, setOpenCustomCampaignModal] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null)
  const {
    prompt,
    active_platforms,
    nodes_data,
    // saved_prompt_params,
    selected_platforms_accounts,
    loading_flow,
    // skip_prompt,
    select_custom_template,
    default_templates,
    is_campaign_running_already,
    ai_campaign_name,
    trigger,
    campaign_id,
    steps,
  } = useAppSelector((state) => state.outreachAICampaign)
  const prevPromptIdRef = useRef(prompt.id)
  const [defaultTemplates, setDefaultTemplates] = useState(default_templates)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const { builder_data, use_custom_flow } = useAppSelector((state) => state.CampaignBuilder)
  const { title: promptTitle } = prompt
  const isCustom = promptTitle === 'Custom'
  const atLeastOneTrue = Object.values(active_platforms).some((value) => value === true) && prompt?.id //&& !generate_messages
  const [updateCampaign] = useLazyUpdateCampaignAiQuery()
  const location = useLocation()
  const path = location.pathname

  const { nodes: initialNodes, edges: initialEdges } = getNodesAndEdges(
    prompt.id,
    active_platforms,
    select_custom_template,
    use_custom_flow ? builder_data : {},
    isCustom,
    defaultTemplates,
    true,
  )
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
    (value) => Array.isArray(value) && value.length > 0,
  )

  const validator = () => {
    // const allNonEmpty = Object.values(saved_prompt_params).every(
    //   (value) => typeof value === 'string' && value.trim() !== '',
    // )
    const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
      (value) => Array.isArray(value) && value.length > 0,
    )

    if (isCustom && !builder_data?.nodes && nodes_data?.length === 0) return false

    return !!prompt.id && atLeastOneNonEmpty && !loading_flow
  }

  const onSelectionChange = useCallback((elements: any) => {
    const nodes = elements?.nodes
    // if (nodes[0]?.type === 'placeholderNode') {
    //   // nodes[0].selected = false
    //   setNodes(() =>
    //     nodes.map((nd: any) => {
    //       return { ...nd, selected: false }
    //     }),
    //   )
    //   navigate('/outreach/create-new-campaign/campaign-builder')
    // }
    if (nodes && nodes.length > 0) {
      dispatch(SET_SELECTED_NODE(nodes[0]))
    }
  }, [])

  const nodeTypes: any = {
    customNode: CustomNode,
    initialNode: InitialNode,
    placeholderNode: () => <PlaceholderNode hideTitle={true} />,
    customCollapseNode: CustomCollapseNode,
  }

  const edgeTypes: any = { trueEdge: TrueEdge, falseEdge: FalseEdge }

  const getParentNodeId = (nodeId: string, edges: any): string | null => {
    const parentEdge = edges.find((edge: any) => edge.target === nodeId)
    if (parentEdge) {
      return parentEdge.source
    }
    return null
  }

  const initializeNodesData = (nodes: any, edges: any): any => {
    return nodes.map((node: any) => ({
      step_id: node.id,
      previous_step_id: getParentNodeId(node.id, edges),
      type: node.data.type,
      // ...(node.data?.message_id !== undefined && { message_id: node.data.message_id }),
      message_id: node?.data?.message_id ? node?.data?.message_id : '',
      // extraData: { defaultAmount: node?.data?.defaultAmount },
      ...(node.data?.defaultAmount !== undefined && { extraData: { defaultAmount: node?.data?.defaultAmount } }),
      ...(getShouldContainMessages(node.data.type, truePlatforms) && {
        data: { messages: node.data?.messages, sender: node.data?.sender },
      }),
      ...(node.data.type === 'ai_responder' && { data: node.data }),
      ...(steps?.length > 0 && node?.data?.unit && { data: { ...node.data } }), // handle refresh
      // ...(node.data.builder && node.data.amount && { data: { messages: node.data?.amount, sender: node.data?.unit } }),
    }))
  }

  const handleNavigateBuilder = () => {
    navigateWithCampaignId('/outreach/create-new-campaign/campaign-builder', { state: { prevPath: true } })
  }

  const handleEditManually = () => {
    post('/log', {
      action: `Edit Manually`,
    })
    if (isCustom && !is_campaign_running_already) {
      // Load the nodes & edges with placeholders
      navigateWithCampaignId(`/outreach/create-new-campaign/campaign-builder`, {
        state: { prevPath: true, path: path },
      })
    } else {
      navigateWithCampaignId(`/outreach/create-new-campaign/edit-manually`, { state: { prevPath: true, path: path } })
    }
  }

  const saveChanges = async () => {
    const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
    await updateCampaign({
      campaign_id,
      campaign_name: ai_campaign_name,
      ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
      steps: JSON.stringify(nodes_data),
    })
  }

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const timeout = setTimeout(() => {
      if (trigger > 0) {
        saveChanges()
      }
    }, DEBOUNCE_DELAY)

    setDebounceTimeout(timeout)

    return () => {
      clearTimeout(timeout)
    }
  }, [nodes_data])

  useEffect(() => {
    const getTemplates = async () => {
      await getMyTemplates()
    }
    getTemplates()
  }, [])

  useEffect(() => {
    if (prompt.id) {
      const { nodes: newNodes, edges: newEdges } = getNodesAndEdges(
        prompt.id,
        active_platforms,
        select_custom_template,
        use_custom_flow ? builder_data : {},
        isCustom,
        defaultTemplates,
        true,
      )

      const nodesWithSteps =
        steps?.length > 0
          ? newNodes.map((node) => {
              const stepsData = steps.find((step: any) => step.step_id === node.id)
              return {
                ...node,
                message_id: stepsData?.message_id,
                data: { ...node.data, message_id: stepsData?.message_id, ...stepsData?.data },
              }
            })
          : newNodes

      dispatch(SET_SELECTED_NODE({}))

      if (!isCustom) {
        dispatch(SET_FLOW_DATA({ nodes: nodesWithSteps, edges: newEdges }))
      } else {
        dispatch(SET_FLOW_DATA({}))
      }

      // hidden: isCustom ? !atLeastOneNonEmpty : true
      const updatedNodes = nodesWithSteps.map((node) => (hide(!atLeastOneTrue) ? { ...node, hidden: false } : node))
      const updatedEdges = newEdges.map((edge: any) => (hide(!atLeastOneTrue) ? { ...edge, hidden: false } : edge))

      setNodes(updatedNodes)
      setEdges(updatedEdges)

      if ((nodes_data && nodes_data?.length === 0) || prevPromptIdRef.current !== prompt.id) {
        prevPromptIdRef.current = prompt.id
        const initNodes = initializeNodesData(nodesWithSteps, newEdges)
        dispatch(SET_NODES_DATA(initNodes))
        dispatch(SET_EDGES_DATA(newEdges))
      }
    }
  }, [prompt.id, active_platforms, use_custom_flow])

  useEffect(() => {
    setNodes((nds) => nds.map(hide(isCustom ? !atLeastOneNonEmpty : !atLeastOneTrue)))
    setEdges((eds) => eds.map(hide(isCustom ? !atLeastOneNonEmpty : !atLeastOneTrue)))
  }, [atLeastOneTrue, atLeastOneNonEmpty, isCustom])

  useEffect(() => {
    if (!use_custom_flow && builder_data?.nodes?.length > 0) {
      console.log('Unsaved Changes Were Made')
      dispatch(RESET_BUILDER())
    }
  }, [use_custom_flow])

  useEffect(() => {
    setTimeout(() => fitView(), 250)
    dispatch(SET_SELECTED_BUILDER_NODE({}))
  }, [prompt])

  useEffect(() => {
    if (templates && default_templates?.length === 0) {
      const temps = templates?.filter((temp: any) => temp?.type === 'default')
      setDefaultTemplates(temps)
      dispatch(SET_DEFAULT_TEMPLATES(temps))
    }
  }, [templates, default_templates])

  const header = (
    <div className={styles.header}>
      <div className={styles.leftSide}>
        <img src={lightning_bold_icon} alt='' />
        <div className={styles.text}>
          <span>Flow Preview</span>
          <span className={styles.body}>Generated by AI according to your settings</span>
        </div>
      </div>
      <div className={styles.rightSide}>
        <Button disabled={!validator()} onClick={handleEditManually}>
          Edit Manually
        </Button>
      </div>
    </div>
  )

  return (
    <Card className={`${styles['flow-preview']} flowPreview flowView`} title={header} bordered={false}>
      {promptTitle === 'Custom' && !use_custom_flow && nodes_data?.length === 0 && (
        <div className={styles.buildYourOwn}>
          <div className={styles.buttons}>
            <Button disabled={!atLeastOneNonEmpty} type='primary' onClick={() => setOpenCustomCampaignModal(true)}>
              Load a Template
            </Button>
            <Button disabled={!atLeastOneNonEmpty} type='primary' onClick={handleNavigateBuilder}>
              Start from Scratch
            </Button>
          </div>
        </div>
      )}
      {!atLeastOneTrue && !loading_flow && promptTitle !== 'Custom' ? (
        <div className={styles.complete}>
          <p>Complete the prompt and choose your channel to preview the flow.</p>
        </div>
      ) : (
        <></>
      )}
      {loading_flow && (
        <div className={styles.loading}>
          <LoadingBox />
        </div>
      )}
      {/* <div style={{ height: '70px' }}></div> */}
      <div style={{ height: '91%' }}>
        <ReactFlow
          // ref={reactFlowWrapper}
          className={nodes[0]?.type === 'initialNode' ? 'flowBuilder' : ''}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onSelectionChange={onSelectionChange}
          // onSelectionChange={selectNode}
          fitView
          // onNodeClick={onNodeClick}
          // onNodeMouseEnter={() => {}}
          onLoad={() =>
            setTimeout(() => {
              if (!isLoaded) {
                setIsLoaded(true)
                return fitView()
              }
            }, 100)
          }
          style={{ width: '100%', height: '100%' }}
          defaultViewport={{
            x: window.innerWidth * 0.15,
            y: window.innerHeight * 0.15,
            zoom: 1,
          }}
          proOptions={{ hideAttribution: true }}>
          <Background />
          <CustomControls />
        </ReactFlow>
      </div>
      <FlowPreviewStrip />
      <CustomCampaignModal
        myTemplates={templates}
        open={openCustomCampaignModal}
        onConfirm={(name) => {
          setOpenCustomCampaignModal(false)
          dispatch(SET_USE_CUSTOM_FLOW(true))
          message.success(`${name} Selected`)
        }}
        onCancel={() => {
          setOpenCustomCampaignModal(false)
        }}
      />
    </Card>
  )
}

export default FlowPreview
