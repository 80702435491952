// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react'
import { Collapse, message, theme } from 'antd'
import styles from './CustomCollapse.module.scss'
import leads_light from 'common/assets/svg/leads_light.svg'
import leads_strong from 'common/assets/svg/leads_strong.svg'
import threeusers_light from 'common/assets/svg/threeusers_light.svg'
import threeusers_strong from 'common/assets/svg/threeusers_strong.svg'
import lightbulb_light from 'common/assets/svg/lightbulb_light.svg'
import lightbulb_strong from 'common/assets/svg/lightbulb_strong.svg'
import scheduling_light from 'common/assets/svg/scheduling_light.svg'
import scheduling_strong from 'common/assets/svg/scheduling_strong.svg'
import build_sequence_light from 'common/assets/svg/build_sequence_light.svg'
import build_sequence_strong from 'common/assets/svg/build_sequence_strong.svg'
import completed_icon from 'common/assets/svg/completeIcon.svg'
import uncompleted_icon from 'common/assets/svg/uncompleted_icon.svg'
import OutreachAndChannels from './OutreachAndChannels/OutreachAndChannels'
import Leads from './Leads/Leads'
import CampaignGenerateReport from 'common/components/OutreachAI/Campaign/CampaignGenerateReport.module'
import SchedulingAndConfiguration from './SchedulingAndConfiguration/SchedulingAndConfiguration'
import { useLazyGetAllLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAppDispatch, useAppSelector } from 'state'
import { useLazyCreateCampaignQuery } from '../state/api/OutreachAI-API'
import { transformToNewStructure } from './transformStepsUtil'
import {
  SET_CAMPAIGN_ID_AI,
  SET_CURRENT_COLLAPSE_KEY,
  SET_GENERATE_MESSAGES,
  SET_LAODING_FLOW,
  SET_NODES_DATA,
  SET_SKIP_PROMPT,
} from '../state/outreachAICampaignSlice'
import { post } from 'common/api/axios'
import CampaignType from '../Campaign/CampaignType'
import { getSenderByPlatform } from 'common/utils/userHelpers'
import { useLocation } from 'react-router-dom'

const { Panel } = Collapse

const CustomCollapse: React.FC = () => {
  // eslint-disable-next-line
  const [refetchGetAllLeads] = useLazyGetAllLeadsQuery()
  const [createCampaign] = useLazyCreateCampaignQuery()
  const { token } = theme.useToken()
  const { custom_flow_id } = useAppSelector((state) => state.CampaignBuilder)
  const location = useLocation()
  const {
    saved_prompt_params,
    nodes_data,
    selected_leads,
    active_platforms,
    selected_platforms_accounts,
    ai_campaign_name,
    campaign_id,
    current_collapse_key,
    prompt,
    block_editing,
    loading_flow,
    campaign_settings_ai,
    skip_prompt,
    is_campaign_running_already,
    select_custom_template,
    are_leads_from_groups,
    ai_leads_type,
    setup_method,
  } = useAppSelector((state) => state.outreachAICampaign)

  const isCampaignRunning = useRef(false)

  // const block_editing = false
  const dispatch = useAppDispatch()
  const [activeKey, setActiveKey] = useState(current_collapse_key)
  const allNonEmpty = Object.values(saved_prompt_params).every(
    (value) => typeof value === 'string' && value.trim() !== '',
  )
  const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
    (value) => Array.isArray(value) && value.length > 0,
  )
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  // SET_CAMPAIGN_STATUS

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    // textAlign: 'start',
    // background: token.colorFillAlter,
    backgroundColor: 'white',
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }

  const generateHeader = (key: string, text: string) => {
    let image
    let editBtn
    let completeIcon
    let enableEdit = false
    switch (key) {
      case '1':
        image = activeKey === '1' ? threeusers_strong : threeusers_light
        editBtn = activeKey === '1' || is_campaign_running_already === true ? '' : 'Edit'
        completeIcon = activeKey === '1' ? '' : atLeastOneNonEmpty ? completed_icon : uncompleted_icon
        enableEdit = atLeastOneNonEmpty
        break
      case '2':
        image = activeKey === '2' ? build_sequence_strong : build_sequence_light
        editBtn = activeKey === '2' || is_campaign_running_already === true ? '' : 'Edit'
        completeIcon = activeKey === '2' ? '' : prompt.id ? completed_icon : uncompleted_icon
        enableEdit = !!prompt.id
        break
      case '3':
        image = activeKey === '3' ? lightbulb_strong : lightbulb_light
        editBtn = activeKey === '3' ? '' : 'Edit'
        // editBtn = activeKey === '3' || block_editing || is_campaign_running_already === true ? '' : 'Edit'
        completeIcon =
          activeKey === '3'
            ? ''
            : (allNonEmpty && setup_method === 'ai') || setup_method === 'manually'
            ? completed_icon
            : uncompleted_icon
        enableEdit = !!prompt.id
        break
      case '4':
        image = activeKey === '4' ? leads_strong : leads_light
        editBtn = activeKey === '4' || block_editing || is_campaign_running_already === true ? '' : 'Edit'
        editBtn = activeKey === '4' ? '' : 'Edit'
        completeIcon = activeKey === '4' ? '' : selected_leads?.length > 0 ? completed_icon : uncompleted_icon
        enableEdit = (allNonEmpty && setup_method === 'ai') || setup_method === 'manually'
        break
      case '5':
        image = activeKey === '5' ? scheduling_strong : scheduling_light
        editBtn = activeKey === '5' ? '' : 'Edit'
        completeIcon =
          activeKey === '5' ? '' : selected_leads?.length > 0 && atLeastOneNonEmpty ? completed_icon : uncompleted_icon
        enableEdit = selected_leads?.length > 0
        break
      default:
        image = leads_light
    }

    return (
      <div className={styles.container} onClick={(e: any) => e.stopPropagation()}>
        <div className={styles.leftSide}>
          <img src={image} alt='' />
          <span className={styles.headerText}>{text}</span>
        </div>
        <div className={styles.rightSide}>
          <span
            className={enableEdit ? '' : styles.disable}
            style={{ cursor: 'pointer' }}
            onClick={() => enableEdit && handleClick(key, activeKey)}>
            {editBtn}
          </span>
          <img src={completeIcon} alt='' className={styles.completeIcon} />
        </div>
      </div>
    )
  }

  async function handleClick(key: string, curKey?: any) {
    if (isCampaignRunning.current) return // Prevent multiple calls
    isCampaignRunning.current = true
    try {
      if (loading_flow || !campaign_id) return
      // if (promptTitle === 'Custom' && curKey === '2' && !use_custom_flow) {
      //   return navigate('/outreach/create-new-campaign/campaign-builder')
      // }
      // if (block_editing) {
      //   if (key === '1' || key === '2') {
      //     return
      //   }
      // }
      setActiveKey(key)
      dispatch(SET_CURRENT_COLLAPSE_KEY(key))
      const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
      const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
        if (value.length > 0) {
          acc[key] = value
        }
        return acc
      }, {})

      // const shouldGen =
      //   generate_messages && setup_method === 'ai' && curKey === '2' && !isCustom && Object.keys(platforms)?.length > 0
      const shouldGen = false

      if (shouldGen) {
        dispatch(SET_LAODING_FLOW(true))
      }

      const allSenders = getSenderByPlatform(selected_platforms_accounts)

      const { data, error } = await createCampaign({
        campaign_id: campaign_id,
        campaign_name: ai_campaign_name || 'New Campaign',
        prompt_params: JSON.stringify(saved_prompt_params),
        platforms: platforms,
        ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
        steps: JSON.stringify(nodes_data),
        summarytSettings: {
          selected_platforms_accounts,
          prompt,
          saved_prompt_params,
          active_platforms,
          campaign_settings_ai,
          skip_prompt,
          are_leads_from_groups,
          select_custom_template,
          ai_leads_type,
          setup_method,
          allSenders,
          current_collapse_key: +key,
        },
        generateMessages: shouldGen,
        custom_flow_id: custom_flow_id,
      })

      if (error) {
        console.log(error)
        message.error(`This campaign can't be saved. Please contact support.`)
      }

      if (data?.messages) {
        const updatedNodesData = nodes_data.map((node: any) => {
          const newNode = { ...node }

          if (node.type === 'send_message') {
            newNode.data = { ...newNode.data, messages: data.messages }
          }

          if (node.type.startsWith('comment')) {
            newNode.data = { ...newNode.data, messages: data.comments }
          }

          return newNode
        })
        const newSteps = transformToNewStructure(updatedNodesData, truePlatforms[0])

        await createCampaign({
          campaign_id: campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          prompt_params: JSON.stringify(saved_prompt_params),
          platforms: platforms,
          ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
          steps: JSON.stringify(updatedNodesData),
          summarytSettings: {
            selected_platforms_accounts,
            prompt,
            saved_prompt_params,
            active_platforms,
            campaign_settings_ai,
            skip_prompt,
            are_leads_from_groups,
            select_custom_template,
            ai_leads_type,
            setup_method,
          },
          generateMessages: shouldGen,
          // generateMessages: generate_messages && curKey === '3',
          custom_flow_id: custom_flow_id,
        })
        dispatch(SET_NODES_DATA(updatedNodesData))
        dispatch(SET_GENERATE_MESSAGES(false))
      }
      dispatch(SET_LAODING_FLOW(false))
    } finally {
      isCampaignRunning.current = false
    }
  }

  const nextBtnTypes: any = {
    1: { next: 'Outreach and Channels next button', back: '' },
    2: { next: 'Build Sequence next button', back: 'Build Sequence back button' },
    3: { next: 'Campaign Type next button', back: 'Campaign Type back button' },
    4: { next: 'Campaign Leads next button', back: 'Campaign Leads back button' },
    5: { next: '', back: 'Scheduling and Configuration back button' },
  }

  const logMixPanel = (type: string, level: number) => {
    const caption = nextBtnTypes[level][type]
    post('/log', {
      action: `next or back buttons`,
      data: { caption, type },
    })
  }

  useEffect(() => {
    // if (is_campaign_running_already) {
    //   setActiveKey(current_collapse_key)
    // }
    setActiveKey(current_collapse_key)
    handleClick(current_collapse_key)
  }, [current_collapse_key, selected_leads])

  useEffect(() => {
    if (!campaign_id) {
      const params = new URLSearchParams(location.search)
      const urlCampaignId = params.get('campaign_id')
      dispatch(SET_CAMPAIGN_ID_AI(urlCampaignId))
    }
  }, [campaign_id])

  // onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void =>
  //   handleClick(event.target.value)
  // }

  // useEffect(() => {
  //   if (block_editing) {
  //     setActiveKey('3')
  //     dispatch(SET_CURRENT_COLLAPSE_KEY('3'))
  //   }
  // }, [block_editing])
  //daniel
  return (
    <Collapse
      className={`${styles['custom-collapse']} customCollapseOutreach`}
      activeKey={activeKey}
      //onChange={(key) => setActiveKey(key.toString())}
      onChange={(key) => handleClick(key.toString())}
      accordion>
      <Panel header={generateHeader('1', 'Outreach and Channels')} key='1' style={panelStyle} showArrow={false}>
        <OutreachAndChannels
          // onBack={() => {
          //   handleClick('1')
          //   logMixPanel('back', 1)
          // }}
          onNext={() => {
            handleClick('2')
            logMixPanel('next', 1)
          }}
        />
      </Panel>
      <Panel header={generateHeader('2', 'Build Sequence')} key='2' style={panelStyle} showArrow={false}>
        <CampaignGenerateReport
          currentKey={activeKey}
          setCurrentKey={setActiveKey}
          onBack={() => {
            handleClick('1')
            logMixPanel('back', 2)
          }}
          onNext={() => {
            handleClick('3', activeKey)
            logMixPanel('next', 2)
            post('/log', {
              action: `Who is the persona you are targeting`,
              data: saved_prompt_params,
            })
          }}
          onSkip={() => {
            handleClick('3')
            dispatch(SET_SKIP_PROMPT(true))
            // logMixPanel('next', 1)
          }}
        />
      </Panel>
      <Panel header={generateHeader('3', 'Campaign Type')} key='3' style={panelStyle} showArrow={false}>
        <CampaignType
          currentKey={activeKey}
          setCurrentKey={setActiveKey}
          onBack={
            is_campaign_running_already
              ? undefined
              : () => {
                  handleClick('2')
                  logMixPanel('back', 3)
                }
          }
          onNext={() => {
            handleClick('4', activeKey)
            logMixPanel('next', 3)
            // post('/log', {
            //   action: `Who is the persona you are targeting`,
            //   data: saved_prompt_params,
            // })
          }}
        />
      </Panel>
      <Panel header={generateHeader('4', 'Campaign Leads')} key='4' style={panelStyle} showArrow={false}>
        <Leads
          onBack={() => {
            handleClick('3')
            logMixPanel('back', 4)
          }}
          onNext={() => {
            handleClick('5')
            logMixPanel('next', 4)
          }}
        />
      </Panel>
      <Panel header={generateHeader('5', 'Scheduling and Configuration')} key='5' style={panelStyle} showArrow={false}>
        <SchedulingAndConfiguration
          onBack={() => {
            handleClick('4')
            logMixPanel('back', 5)
          }}
        />
      </Panel>
    </Collapse>
  )
}

export default CustomCollapse
