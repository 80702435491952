import React, { useEffect, useState } from 'react'
import styles from './CustomNode.module.scss'
import one_line_purple from 'common/assets/svg/one_line_purple.svg'
import purple_line_exit from 'common/assets/svg/purple_line_exit.svg'
// import true_false_lines ›from 'common/assets/svg/true_false_lines.svg'
import true_false_double_exit from 'common/assets/svg/true_false_double_exit.svg'
import true_false_left_exit from 'common/assets/svg/true_false_left_exit.svg'
import step_send_tg from 'common/assets/svg/step_send_tg.svg'
import step_enrich_hubspot from 'common/assets/svg/step_enrich_hubspot.svg'
import condition_is_message_replied from 'common/assets/svg/condition_is_message_replied.svg'
import condition_wait from 'common/assets/svg/condition_wait.svg'
import { Popover } from 'antd'
import MessageRepliedTime from './UI/MessageRepliedTime'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SELECTED_NODE } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

interface CustomNodeProps {
  id: string
  type?: string // 'single' or 'multi'
  data: {
    label: string
    action?: string
    childType?: string
    isLeftLast?: string
    isLast?: boolean
    isLastExit?: boolean
  }
  position?: {
    x: number
    y: number
  }
  setCurrentSideBar?: any
  storedData?: any
  style?: React.CSSProperties
}

const CustomNode: React.FC<CustomNodeProps> = ({ id, type, data, position, setCurrentSideBar, storedData, style }) => {
  const dispatch = useAppDispatch()
  const { selected_node, sequence_data, load_animation } = useAppSelector((state) => state.outreachCampaign)
  // const [animationPlayed, setAnimationPlayed] = useState(false)
  const defaultUnits = sequence_data.steps?.[id]?.data?.units
  const defaultDuration = sequence_data.steps?.[id]?.data?.duration
  const [timeToReply, setTimeToReply] = useState<any>(
    defaultUnits ? { duration: defaultDuration, units: defaultUnits } : { duration: 1, units: 'days' },
  )
  const [showRedOutline, setShowRedOutline] = useState(false)
  const image =
    data.action === 'send_tg_message'
      ? step_send_tg
      : data.action === 'enrich_hubspot'
      ? step_enrich_hubspot
      : data.action === 'is_message_replied'
      ? condition_is_message_replied
      : data.action === 'is_wait'
      ? condition_wait
      : ''

  const handleOnClick = () => {
    setCurrentSideBar({ type: data.action, nodeId: id })
    dispatch(SET_SELECTED_NODE(id))
  }

  const validateNode = (type: any) => {
    const node = sequence_data?.steps?.[id]
    const data = node?.data

    if (type === 'send_telegram_message') {
      return !!(data?.sender?.length === 0) && !!(data?.message?.length === 0)
    } else if (type === 'is_wait' || type === 'is_message_replied') {
      const zero = data?.duration === 0
      return (!!data?.duration || zero) && !!data?.units
    } else {
      return true
    }
  }

  const handleAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    target.classList.remove(styles.nodeSnakeEffect)
    // dispatch(SET_LOAD_ANIMATION(false))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRedOutline(true)
      // setAnimationPlayed(true)
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  const isWait = (
    <div className={styles.container} style={{ left: position?.x, top: position?.y }}>
      <Popover
        placement='right'
        trigger='click'
        content={<MessageRepliedTime id={id} action={data.action} setTimeToReply={setTimeToReply} isWait={true} />}>
        <div
          className={`${styles.node} ${type} ${load_animation ? styles.nodeSnakeEffect : ''}`}
          id={id}
          style={{
            ...style,
            outline:
              id === selected_node
                ? '1px solid #7043ff'
                : !validateNode('is_wait') && showRedOutline
                ? '1px solid red'
                : '',
          }}
          onClick={setCurrentSideBar && handleOnClick}
          onAnimationEnd={handleAnimationEnd}>
          <img src={image} alt='' />
          <div className={styles.wait}>
            {data.label}
            <span style={{ color: '#7043ff' }}>
              {timeToReply?.duration || timeToReply?.duration === 0 ? timeToReply.duration : 'X'}{' '}
              {timeToReply.duration === 1 ? timeToReply.units.slice(0, -1) : timeToReply.units}
            </span>
          </div>
        </div>
      </Popover>
      {data.childType === 'single' && !data.isLast && <img src={one_line_purple} alt='' />}
      {data.childType === 'single' && data.isLastExit && <img src={purple_line_exit} alt='' />}
      {data.childType === 'multi' && !data.isLast && !data.isLeftLast && <img src={true_false_double_exit} alt='' />}
      {data.childType === 'multi' && data.isLeftLast && <img src={true_false_left_exit} alt='' />}
    </div>
  )

  const messageReplied = (
    <div className={styles.container} style={{ left: position?.x, top: position?.y }}>
      <Popover
        placement='right'
        trigger='click'
        content={<MessageRepliedTime id={id} action={data.action} setTimeToReply={setTimeToReply} />}>
        <div
          className={`${styles.node} ${type} ${load_animation ? styles.nodeSnakeEffect : ''}`}
          style={{
            ...style,
            outline:
              id === selected_node
                ? '1px solid #7043ff'
                : !validateNode('is_message_replied') && showRedOutline
                ? '1px solid red'
                : '',
          }}
          id={id}
          onClick={setCurrentSideBar && handleOnClick}
          onAnimationEnd={handleAnimationEnd}>
          <img src={image} alt='' />
          <div className={styles.within}>
            {data.label}
            <span>
              Within:{' '}
              <span style={{ color: '#7043ff' }}>
                {timeToReply.duration || timeToReply.duration === 0 ? timeToReply.duration : 'X'}{' '}
                {timeToReply.duration === 1 ? timeToReply.units.slice(0, -1) : timeToReply.units}
              </span>
            </span>
          </div>
        </div>
      </Popover>
      {data.childType === 'single' && !data.isLast && <img src={one_line_purple} alt='' />}
      {data.childType === 'single' && data.isLastExit && <img src={purple_line_exit} alt='' />}
      {data.childType === 'multi' && !data.isLast && !data.isLeftLast && <img src={true_false_double_exit} alt='' />}
      {data.childType === 'multi' && data.isLeftLast && <img src={true_false_left_exit} alt='' />}
    </div>
  )

  const redOutline = sequence_data?.steps?.[id]?.data?.messages?.filter((m: string) => m && m.trim() !== '')?.length > 0

  return (
    <>
      {data.action === 'is_message_replied' ? (
        messageReplied
      ) : data.action === 'is_wait' ? (
        isWait
      ) : (
        <div className={styles.container} style={{ left: position?.x, top: position?.y }}>
          <div
            className={`${styles.node} ${type} ${load_animation ? styles.nodeSnakeEffect : ''}`}
            id={id}
            style={{
              ...style,
              outline:
                id === selected_node
                  ? '1px solid #7043ff'
                  : (!sequence_data?.steps?.hasOwnProperty(id) || !redOutline) && showRedOutline
                  ? '1px solid red'
                  : '',
            }}
            onClick={setCurrentSideBar && handleOnClick}
            onAnimationEnd={handleAnimationEnd}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <img src={image} alt='' />
            {data.label}
          </div>
          {data.childType === 'single' && !data.isLast && <img src={one_line_purple} alt='' />}
          {data.childType === 'single' && data.isLastExit && <img src={purple_line_exit} alt='' />}
          {data.childType === 'multi' && !data.isLast && !data.isLeftLast && (
            <img src={true_false_double_exit} alt='' />
          )}
          {data.childType === 'multi' && data.isLeftLast && <img src={true_false_left_exit} alt='' />}
        </div>
      )}
    </>
  )
}

export default CustomNode
