import React from 'react'
import styles from './PlatformTag.module.scss'
import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/xtwit_icon.svg'

const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform.toLocaleLowerCase()
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

interface PlatformTagProps {
  platform: string
  selectedPlatform: string
}

const PlatformTag: React.FC<PlatformTagProps> = ({ platform, selectedPlatform }) => {
  const platformString =
    platform === 'twitter'
      ? 'X (Twitter)'
      : `${platform[0].toLocaleUpperCase() + platform.slice(1).toLocaleLowerCase()}`
  return (
    <div className={`${styles.platformTag} ${styles[platform]} ${selectedPlatform === platform && styles.border}`}>
      <img src={getLogoByPlatform(platform)} alt='' />
      <span>{platformString}</span>
    </div>
  )
}

export default PlatformTag
