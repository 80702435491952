import React from 'react'
import { Select } from 'antd'
import styles from './CustomMeetingBook.module.scss'
import like_outcome from 'common/assets/svg/like_outcome.svg'
import dislike_outcome from 'common/assets/svg/dislike_outcome.svg'
import booked_outcome from 'common/assets/svg/booked_outcome.svg'
// import out_of_office_outcome from 'common/assets/svg/out_of_office_outcome.svg'
import closed_outcome from 'common/assets/svg/closed_outcome.svg'
import not_now_outcome from 'common/assets/svg/not_now_outcome.svg'
import do_not_contact_outcome from 'common/assets/svg/do_not_contact_outcome.svg'

interface CustomMeetingBookProps {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
}

const CustomMeetingBook: React.FC<CustomMeetingBookProps> = ({ defaultValue, value, onChange }) => {
  const options: any = {
    interested: (
      <div className={styles.outcome}>
        <img src={like_outcome} alt='' className={styles.outcomeImage} />
        <span>Interested</span>
      </div>
    ),
    not_interested: (
      <div className={styles.outcome}>
        <img src={dislike_outcome} alt='' className={styles.outcomeImage} />
        <span>Not Interested</span>
      </div>
    ),
    booked: (
      <div className={styles.outcome}>
        <img src={booked_outcome} alt='' className={styles.outcomeImage} />
        <span>Meeting Booked</span>
      </div>
    ),
    // not_right_contact: (
    //   <div className={styles.outcome}>
    //     <img src={out_of_office_outcome} alt='' className={styles.outcomeImage} />
    //     <span>Not Right Contact</span>
    //   </div>
    // ),
    closed: (
      <div className={styles.outcome}>
        <img src={closed_outcome} alt='' className={styles.outcomeImage} />
        <span>Closed</span>
      </div>
    ),
    not_now: (
      <div className={styles.outcome}>
        <img src={not_now_outcome} alt='' className={styles.outcomeImage} />
        <span>Not Now</span>
      </div>
    ),
    do_not_contact: (
      <div className={styles.outcome}>
        <img src={do_not_contact_outcome} alt='' className={styles.outcomeImage} />
        <span>Do Not Contact</span>
      </div>
    ),
  }

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      className='selectPriority'
      size='small'
      defaultValue={defaultValue}
      value={value}
      style={{ width: 165 }}
      placeholder='Meeting Book'
      onChange={handleChange}>
      {Object.keys(options).map((key) => (
        <Select.Option key={key} value={key}>
          {options[key]}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CustomMeetingBook
