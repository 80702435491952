import React, { useEffect, useState } from 'react'
import styles from './MessageRepliedTime.module.scss'
import { InputNumber, Radio } from 'antd'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_MESSAGE_REPLIED, SET_WAIT } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

interface MessageRepliedTimeProps {
  id?: any
  action?: any
  setTimeToReply?: any
  isWait?: boolean
}

const MessageRepliedTime: React.FC<MessageRepliedTimeProps> = ({ id, action, setTimeToReply, isWait }) => {
  const { sequence_data, edit_mode, enable_editing } = useAppSelector((state) => state.outreachCampaign)
  const defaultUnits = sequence_data.steps?.[id]?.data?.units
  const defaultDuration = sequence_data.steps?.[id]?.data?.duration
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<any>()

  const onChangeDuration = (values: any) => {
    setTimeToReply((prev: any) => {
      action === 'is_message_replied' && dispatch(SET_MESSAGE_REPLIED({ ...prev, nodeId: id, duration: values }))
      action === 'is_wait' && dispatch(SET_WAIT({ ...prev, nodeId: id, duration: values }))
      return { ...prev, duration: values }
    })

    if (!values && values !== 0) {
      setStatus('error')
    } else {
      setStatus(undefined)
    }
  }

  const onChangeUnits = (values: any) => {
    setTimeToReply((prev: any) => {
      action === 'is_message_replied' &&
        dispatch(SET_MESSAGE_REPLIED({ ...prev, nodeId: id, units: values.target.value }))
      action === 'is_wait' && dispatch(SET_WAIT({ ...prev, nodeId: id, units: values.target.value }))
      return { ...prev, units: values.target.value }
    })
  }

  useEffect(() => {
    // if (defaultDuration && defaultDuration)
    setTimeToReply((prev: any) => {
      action === 'is_message_replied' &&
        dispatch(SET_MESSAGE_REPLIED({ ...prev, nodeId: id, duration: defaultDuration, units: defaultUnits }))
      action === 'is_wait' &&
        dispatch(SET_WAIT({ ...prev, nodeId: id, duration: defaultDuration, units: defaultUnits }))
      return { ...prev, duration: defaultDuration, units: defaultUnits }
    })
  }, [])

  return (
    <div className={styles.container}>
      <InputNumber
        min={isWait ? 0 : 1}
        max={24}
        step={1}
        defaultValue={defaultDuration ? defaultDuration : 1}
        controls={false}
        onChange={onChangeDuration}
        style={{ width: '50px', marginRight: '10px' }}
        status={status}
        disabled={edit_mode && !enable_editing}
      />
      <Radio.Group
        defaultValue={defaultUnits ? defaultUnits : 'days'}
        buttonStyle='solid'
        onChange={onChangeUnits}
        disabled={edit_mode && !enable_editing}>
        <Radio.Button value='hours'>Hours</Radio.Button>
        <Radio.Button value='days'>Days</Radio.Button>
        <Radio.Button value='weeks'>Weeks</Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default MessageRepliedTime
