import { IFilter } from 'common/interfaces/IFilters'
import { countriesWithPhonePrefix } from './countires'
import ConnectPlusFilter from 'common/components/ConnectPlusFilter/ConnectPlusFilter'
import intentsFilter from 'common/assets/svg/intentsFilter.svg'
import PulseAnimation from 'common/components/PulseAnimation/pulseAnimation'
import companiesFilter from 'common/assets/svg/companiesFilter.svg'
import listsFilter from 'common/assets/svg/listsFilter.svg'
// import locationsFilter from 'common/assets/svg/locationsFilter.svg'
import employeesFilter from 'common/assets/svg/employees.svg'
import onchainFilter from 'common/assets/svg/onchainFilter.svg'
import techFilter from 'common/assets/svg/techFilter.svg'
import web2Filter from 'common/assets/svg/web2Filter.svg'
import revenue from 'common/assets/svg/revenue.svg'
import funding_filter from 'common/assets/svg/funding_filter.svg'

import { chains } from './chainFilter'
// import { usersBlockedList } from './userData'
import { fundingDates } from './fundingDates'

const trafficCountries = countriesWithPhonePrefix.map(({ label, countryCode }) => ({
  label,
  value: countryCode?.toLowerCase() || '',
}))

type milestonesMapType = {
  [propKey: string]: { label: string; color: string; value: string }
}

export const milestonesMap: milestonesMapType = {
  'Recently Raised': { label: 'Recently Funded', color: '#3ec7a8', value: 'Recently Raised' },
  'Launching product': { label: 'Fundraising', color: '#5fbcf8', value: 'Launching product' },
  'Soon to be launched': { label: 'Preparing to Launch', color: '#ff4fe6', value: 'Soon to be launched' },
}

const chainsOptions = chains.map((chain) => {
  return {
    label: chain === 'select-all' ? 'Select All' : chain === 'multi-chains' ? 'MULTICHAIN' : chain.toUpperCase(),
    value: chain,
  }
})

type interestsMapType = {
  [propKey: string]: { label: string; color: string; value: string; comingSoon?: boolean }
}

export const interestsMap: interestsMapType = {
  'Security Servicies': {
    label: 'Security Servicies',
    color: '#3ec7a8',
    value: 'Security and Audits',
    // comingSoon: !user?.addons?.security,
  },
  Appchains: {
    label: 'Appchains',
    color: '#3ec7a8',
    value: 'Appchains',
    // comingSoon: !user?.addons?.appchains,
  },
  'RPC & Data': {
    label: 'RPC & Data',
    color: '#3ec7a8',
    value: 'RPC & Data',
    // comingSoon: !user?.addons?.rpc_data,
  },
  'Payment & remittence': {
    label: 'Payment & Remittence',
    color: '#3ec7a8',
    value: 'Payment & Remittence',
    // comingSoon: !user?.addons?.payment_remittence,
  },
  'Agencies & Outsource': {
    label: 'Agencies & Outsource',
    color: '#3ec7a8',
    value: 'Agencies & Outsource',
    // comingSoon: !user?.addons?.agencies_outsource,
  },
}

// let userDetails = null
// if (localStorage.getItem('user') !== null) {
//   userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
// }
const user = JSON.parse(localStorage.getItem('user') || '{}')

export const contactsFilter: IFilter[] = [
  {
    label: 'Lists',
    image: listsFilter,
    key: 'lists',
    value: '',
    type: 'listsFilter',
  },
  // {
  //   label: 'Job Title',
  //   key: 'jobTitle_old',
  //   value: '',
  //   type: 'sidelist',
  // },
  {
    label: 'Employees',
    image: employeesFilter,
    tooltip: `Start filtering by People information (Job title, Department and Seniority).`,
    // tooltipUnderline: 'Premium Plan.',
    key: 'Employees',
    value: '',
    type: 'expandGroupe',
    new: true,
    children: [
      {
        label: 'Job Title',
        type: 'jobTitle',
        key: 'jobTitle',
        // comingSoon: true,
      },
      {
        label: 'Locations',
        key: 'location',
        type: 'contactLocation',
        new: true,
      },
      {
        label: 'Management level',
        type: 'three',
        key: 'managementLevel',
        threeContent: [
          {
            title: 'Owners',
            key: 'managementLevel.owner',
          },
          {
            title: 'Founders',
            key: 'managementLevel.founder',
          },
          {
            title: 'C - SUITE',
            key: 'managementLevel.suite',
          },

          {
            title: 'Directors',
            key: 'managementLevel.director',
          },
          {
            title: 'VP',
            key: 'managementLevel.vp',
          },
          {
            title: 'Managers',
            key: 'managementLevel.manager',
          },
          {
            title: 'Partners',
            key: 'managementLevel.partner',
          },
          {
            title: 'Head',
            key: 'managementLevel.head',
          },
          {
            title: 'Senior',
            key: 'managementLevel.senior',
          },
          {
            title: 'Entry Level',
            key: 'managementLevel.entry',
          },
          {
            title: 'Interns',
            key: 'managementLevel.intern',
          },
        ],
      },
      {
        label: 'Departments',
        type: 'three',
        key: 'departments',
        threeContent: [
          {
            title: 'Design',
            key: 'departments.design',
          },
          {
            title: 'Consulting',
            key: 'departments.consulting',
          },
          {
            title: 'C - SUITE',
            key: 'departments.suite',
          },

          {
            title: 'Education',
            key: 'departments.education',
          },
          {
            title: 'Engineering Technical',
            key: 'departments.engineering',
          },
          {
            title: 'Finance',
            key: 'departments.finance',
          },
          {
            title: 'Human Resources',
            key: 'departments.human',
          },
          {
            title: 'Information Technology',
            key: 'departments.information',
          },
          {
            title: 'Legal',
            key: 'departments.legal',
          },
          {
            title: 'Marketing',
            key: 'departments.marketing',
          },
          {
            title: 'Operations',
            key: 'departments.operations',
          },
          {
            title: 'Sales',
            key: 'departments.sales',
          },
          {
            title: 'Medical Health',
            key: 'departments.medical',
          },
        ],
      },
    ],
  },
  // {
  //   label: 'Companies',
  //   image: companiesFilter,
  //   key: 'companies',
  //   value: '',
  //   type: 'companiesList',
  // },
  {
    label: 'Companies',
    image: companiesFilter,
    tooltip: `Start filtering by company information (Vertical, Milestones, Headcount and more). `,
    key: 'companies_new',
    value: '',
    type: 'expandGroupe',
    new: true,
    children: [
      // {
      //   label: 'Company Name',
      //   type: 'input',
      //   key: 'companies.companyName',
      // },
      {
        label: `Company's HQ`,
        // image: locationsFilter,
        key: 'companyHQ',
        // value: '',
        type: 'companyHQ',
        new: true,
      },
      {
        label: 'Companies',
        type: 'companies',
        key: 'companies',
        // comingSoon: true,
      },
      {
        label: 'Headcount',
        type: 'headcountList',
        key: 'companies.headcount',
        // co
      },
      {
        label: 'Vertical',
        type: 'three',
        key: 'companies.vertical',
        threeContent: [
          {
            title: 'Web3 Infrastructure',
            key: 'companies.vertical.web3Infrastructure',
          },
          {
            title: 'Developer Tools',
            key: 'companies.vertical.developerTools',
          },
          {
            title: 'Exchange',
            key: 'companies.vertical.exchange',
          },
          {
            title: 'DEX',
            key: 'companies.vertical.dex',
          },
          {
            title: 'DAO',
            key: 'companies.vertical.dao',
          },
          {
            title: 'DeFi',
            key: 'companies.vertical.defi',
          },
          {
            title: 'Data Provider',
            key: 'companies.vertical.dataProvider',
          },
          {
            title: 'Wallet',
            key: 'companies.vertical.wallet',
          },
          {
            title: 'NFT',
            key: 'companies.vertical.nft',
          },
          {
            title: 'Gaming',
            key: 'companies.vertical.gaming',
          },
          {
            title: 'Gambling',
            key: 'companies.vertical.gambling',
          },
          {
            title: 'L1',
            key: 'companies.vertical.l1',
          },
          {
            title: 'L2',
            key: 'companies.vertical.l2',
          },
          {
            title: 'Identity',
            key: 'companies.vertical.identity',
          },
          {
            title: 'Security and Audits',
            key: 'companies.vertical.securityAndAudits',
          },
          {
            title: 'Asset Management',
            key: 'companies.vertical.assetManagement',
          },
          {
            title: 'Mining Companies',
            key: 'companies.vertical.miningCompanies',
          },
          {
            title: 'Outsource & Agencies',
            key: 'companies.vertical.outsourceAndAgencies',
          },
          {
            title: 'Banking & Financial Institutions',
            key: 'companies.vertical.bankingAndFinancialInstitutions',
          },
          {
            title: 'NFT Marketplaces',
            key: 'companies.vertical.nftMarketplaces',
          },
          {
            title: 'Payments & Remittance',
            key: 'companies.vertical.paymentsRemittance',
          },
          {
            title: 'Data Platform & Analytics',
            key: 'companies.vertical.dataPlatformAnalytics',
          },
          {
            title: 'Web2 Companies',
            key: 'companies.vertical.web2Companies',
          },
          {
            title: 'Venture Capital',
            key: 'companies.vertical.VentureCapital',
          },
          {
            title: 'Social',
            key: 'companies.vertical.social',
          },
          {
            title: 'Oracles',
            key: 'companies.vertical.oracles',
          },
          {
            title: 'Supply Chain Management',
            key: 'companies.vertical.supplyChainManagement',
          },
          {
            title: 'Privacy',
            key: 'companies.vertical.privacy',
          },
          {
            title: 'Bridges',
            key: 'companies.vertical.bridges',
          },
        ],
      },
      // {
      //   label: 'Financials',
      //   type: 'alceva',
      //   key: 'companies.financials',
      //   comingSoon: true,
      // },
      {
        label: 'Tags',
        type: 'tags',
        key: 'companies.tags',
        // comingSoon: true,
      },
      {
        label: 'Milestones',
        type: 'checkbox',
        key: 'companies.milestones',
        new: true,
      },
      {
        label: 'Founding Year',
        type: 'rangeYears',
        key: 'companies.foundingYear',
        children: [
          {
            label: '',
            type: 'number',
            key: 'companies.foundingYear.foundingYearMin',
          },
          {
            label: '',
            type: 'number',
            key: 'companies.foundingYear.foundingYearMax',
          },
        ],
      },
    ],
  },
  // {
  //   label: 'Locations',
  //   image: locationsFilter,
  //   key: 'location',
  //   value: '',
  //   type: 'expandList',
  //   children: [
  //     {
  //       label: 'Country',
  //       type: 'select',
  //       mode: 'multiple',
  //       key: 'contactsCountry',
  //       options: countriesWithPhonePrefix,
  //       haveReset: true,
  //       searchable: true,
  //     },
  //   ],
  // },
  {
    label: 'On - Chain',
    image: onchainFilter,
    tooltip: `This feature allows you to filter companies based on their On-Chain stats ( Volume, TVL, Chain and more) . Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    key: 'onChain',
    comingSoon: !user?.addons?.onChain_filter,
    value: '',
    type: 'expandList',
    children: [
      {
        label: 'Chains',
        type: 'select',
        mode: 'multiple',
        key: 'onChain.chains',
        options: chainsOptions,
        searchable: true,
        haveReset: true,
      },
      {
        label: 'On-Chain Revenue',
        type: 'range',
        haveReset: true,
        key: 'onChain.revenue',
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.revenue.revenueMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.revenue.revenueMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
      // {
      //   label: 'Active wallets',
      //   key: 'onChain.activeWallets',
      //   type: 'tabs',
      //   section: 'activeWallets',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'monthly',
      //       type: 'range,',
      //       key: 'onChain.activeWallets.monthly',
      //       children: [
      //         {
      //           defaultValue: 10,
      //           steps: 10,
      //           label: '',
      //           type: 'number',
      //           key: 'onChain.activeWallets.monthly.monthlyMin',
      //         },
      //         {
      //           defaultValue: 100,
      //           label: '',
      //           steps: 10,
      //           type: 'number',
      //           key: 'onChain.activeWallets.monthly.monthlyMax',
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        label: 'Volume (USD)',
        type: 'range',
        haveReset: true,
        key: 'onChain.volume',
        comingSoon: !user?.addons?.volume,
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.volume.volumeMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.volume.volumeMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
      {
        label: 'TVL',
        type: 'range',
        haveReset: true,
        key: 'onChain.tvl',
        comingSoon: !user?.addons?.tvl,
        children: [
          {
            label: '',
            type: 'number',
            key: 'onChain.tvl.tvlMin',
            hasCurrency: true,
            defaultValue: 500000,
            steps: 500000,
          },
          {
            label: '',
            type: 'number',
            key: 'onChain.tvl.tvlMax',
            hasCurrency: true,
            defaultValue: 1000000,
            steps: 500000,
          },
        ],
      },
    ],
  },
  {
    label: 'Signals',
    beta: true,
    image: intentsFilter,
    tooltip: `This feature allows you to filter companies based on signals on their needs and buying signals of products they look for. Available in the `,
    tooltipUnderline: 'Custom Plan.',
    key: 'intents',
    value: '',
    comingSoon: !user?.addons?.signals,
    // type: 'list',
    type: 'expandGroupe',
    children: [
      {
        label: 'Interests',
        type: 'interestsCheckbox',
        key: 'signals.interests',
        subType: 'comingSoon',
        new: true,
        comingSoon: !user?.addons?.interests,
      },
      {
        label: 'Milestones',
        type: 'checkbox',
        key: 'companies.milestones',
        // new: true,
      },
      {
        label: 'Sentiments',
        type: 'sentimentsCheckbox',
        key: 'signals.sentiments',
        comingSoon: true,
        subType: 'comingSoon',
      },
    ],
  },
  {
    label: 'Tech Stack & Tools',
    image: techFilter,
    tooltip: `This feature allows you to filter companies based on the tools and technologies they are using for better competitive analysis. Available in the `,
    tooltipUnderline: 'Market Dominance Plan.',
    key: 'techstacktools',
    value: '',
    type: 'sidelist',
    comingSoon: !user?.addons?.tech_stack,
    // comingSoon:
    //   userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
  },
  {
    label: 'Web2 Metrics',
    image: web2Filter,
    tooltip: `This feature allows you to filter companies based on their traffic sources and social media volume. Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    key: 'web2metrics',
    value: '',
    type: 'expandList',
    comingSoon: !user?.addons?.web2_metrics,
    // comingSoon:
    //   userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      {
        label: 'Traffic By Country',
        type: 'select',
        searchable: true,
        key: 'web2metrics.trafficByCountry',
        options: trafficCountries,
        haveReset: true,
      },
      {
        label: 'Visitors per month',
        type: 'range',
        haveReset: true,
        key: 'web2metrics.visitorsPerMonth',
        children: [
          {
            label: '',
            type: 'number',
            key: 'web2metrics.visitorsPerMonth.visitorsPerMonthMin',
            defaultValue: 100,
            steps: 1000,
          },
          {
            label: '',
            type: 'number',
            key: 'web2metrics.visitorsPerMonth.visitorsPerMonthMax',
            defaultValue: 1000,
            steps: 1000,
          },
        ],
      },
      {
        label: 'Social Strength',
        key: 'web2metrics.socialStrength',
        type: 'tabs',
        section: 'socialStrength',
        haveReset: true,
        children: [
          {
            label: 'Twitter',
            type: 'range,',
            key: 'web2metrics.socialStrength.twitter',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.twitter.twitterMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.twitter.twitterMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
          {
            label: 'Discord',
            type: 'range,',
            key: 'web2metrics.socialStrength.discord',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.discord.discordMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.discord.discordMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
          {
            label: 'Telegram',
            type: 'range,',
            key: 'web2metrics.socialStrength.telegram',
            children: [
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.telegram.telegramMin',
                defaultValue: 100,
                steps: 100,
              },
              {
                label: '',
                type: 'number',
                key: 'web2metrics.socialStrength.telegram.telegramMax',
                steps: 100,
                defaultValue: 1000,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Revenue',
    image: revenue,
    tooltip: `This feature allows you to filter companies based on their annual revenue . Available in the `,
    tooltipUnderline: 'Connect+ Plan.',
    key: 'revenue',
    value: '',
    // new: true,
    type: 'expandList',
    comingSoon: !user?.addons?.revenue_filter,
    // comingSoon:
    //   userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      {
        label: 'Revenue',
        key: 'revenue.revenue',
        type: 'tabs',
        section: 'revenue',
        haveReset: true,
        children: [
          {
            label: 'Total Revenue',
            type: 'range,',
            key: 'revenue.revenue.total_revenue',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.total_revenue.total_revenueMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.total_revenue.total_revenueMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
          {
            label: 'On-Chain',
            type: 'range,',
            key: 'revenue.revenue.onchain',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.onchain.onchainMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.onchain.onchainMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
          {
            label: 'Off-Chain',
            type: 'range,',
            key: 'revenue.revenue.offchain',
            children: [
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.offchain.offchainMin',
                hasCurrency: true,
                defaultValue: 500000,
                steps: 500000,
              },
              {
                label: '',
                type: 'number',
                key: 'revenue.revenue.offchain.offchainMax',
                hasCurrency: true,
                defaultValue: 1000000,
                steps: 500000,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Funding',
    image: funding_filter,
    tooltip: `This feature allows you to filter companies based on their funding dates. Available in the `,
    tooltipUnderline: 'Market Dominance Plan.',
    key: 'funding',
    value: '',
    type: 'expandList',
    // new: true,
    comingSoon: !user?.addons?.funding_filter,

    // comingSoon:
    //   userDetails && userDetails !== 'null' && usersBlockedList.includes(JSON.parse(userDetails).email) && true,
    children: [
      // {
      //   label: 'Funding Dates',
      //   key: 'funding.dates',
      //   type: 'tabs_select',
      //   section: 'funding',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'Last Round',
      //       type: 'expandList,',
      //       key: 'funding.dates.last_round',
      //       children: [
      //         {
      //           label: '',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.dates.last_round.last_round_option',
      //           options: fundingPeriod,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Any Round',
      //       type: 'range,',
      //       key: 'funding.dates.any_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.dates.any_round.any_round_option',
      //           options: fundingPeriod,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        label: 'Funding Date',
        type: 'select',
        searchable: false,
        key: 'funding.fundingDate',
        options: fundingDates,
        haveReset: true,
      },
      {
        label: 'Last Funding Amount',
        type: 'range',
        haveReset: true,
        key: 'funding.lastFundingAmount',
        children: [
          {
            label: '',
            type: 'number',
            key: 'funding.lastFundingAmount.lastFundingAmountMin',
            defaultValue: 0,
            steps: 500000,
            hasCurrency: true,
          },
          {
            label: '',
            type: 'number',
            key: 'funding.lastFundingAmount.lastFundingAmountMax',
            defaultValue: 500000,
            steps: 500000,
            hasCurrency: true,
          },
        ],
      },
      {
        label: 'Total Funding Amount',
        type: 'range',
        haveReset: true,
        key: 'funding.totalFundingAmount',
        children: [
          {
            label: '',
            type: 'number',
            key: 'funding.totalFundingAmount.totalFundingAmountMin',
            defaultValue: 0,
            steps: 500000,
            hasCurrency: true,
          },
          {
            label: '',
            type: 'number',
            key: 'funding.totalFundingAmount.totalFundingAmountMax',
            defaultValue: 500000,
            steps: 500000,
            hasCurrency: true,
          },
        ],
      },
      // {
      //   label: 'Funding Round Type',
      //   key: 'funding.round_type',
      //   type: 'tabs_checkbox',
      //   section: 'funding',
      //   haveReset: true,
      //   children: [
      //     {
      //       label: 'Last Round',
      //       type: 'expandList,',
      //       key: 'funding.round_type.last_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.round_type.any_round.last_round_items',
      //           options: countriesWithPhonePrefix,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Any Round',
      //       type: 'range,',
      //       key: 'funding.round_type.any_round',
      //       children: [
      //         {
      //           label: 'Choose option',
      //           type: 'select',
      //           mode: 'multiple',
      //           key: 'funding.round_type.any_round.any_round_items',
      //           options: countriesWithPhonePrefix,
      //           haveReset: true,
      //           searchable: true,
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  /*
  // {
  //   label: 'Management level',
  //   key: 'managementLevel',
  //   value: '',
  //   type: 'expandList',
  //   children: [
  //     {
  //       type: 'three',
  //       key: 'managementLevel',
  //       threeContent: [
  //         {
  //           title: 'Owners',
  //           key: 'managementLevel.owner',
  //         },
  //         {
  //           title: 'Founders',
  //           key: 'managementLevel.founder',
  //         },
  //         {
  //           title: 'C - SUITE',
  //           key: 'managementLevel.suite',
  //         },
  //         {
  //           title: 'Directors',
  //           key: 'managementLevel.director',
  //         },
  //         {
  //           title: 'VP',
  //           key: 'managementLevel.vp',
  //         },
  //         {
  //           title: 'Managers',
  //           key: 'managementLevel.manager',
  //         },
  //         {
  //           title: 'Partners',
  //           key: 'managementLevel.partner',
  //         },
  //         {
  //           title: 'Head',
  //           key: 'managementLevel.head',
  //         },
  //         {
  //           title: 'Senior',
  //           key: 'managementLevel.senior',
  //         },
  //         {
  //           title: 'Entry Level',
  //           key: 'managementLevel.entry',
  //         },
  //         {
  //           title: 'Interns',
  //           key: 'managementLevel.intern',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: 'Departments',
  //   key: 'departments',
  //   value: '',
  //   type: 'expandList',
  //   children: [
  //     {
  //       type: 'three',
  //       key: 'departments',
  //       threeContent: [
  //         {
  //           title: 'Design',
  //           key: 'departments.design',
  //         },
  //         {
  //           title: 'Consulting',
  //           key: 'departments.consulting',
  //         },
  //         {
  //           title: 'C - SUITE',
  //           key: 'departments.suite',
  //         },

  //         {
  //           title: 'Education',
  //           key: 'departments.education',
  //         },
  //         {
  //           title: 'Engineering Technical',
  //           key: 'departments.engineering',
  //         },
  //         {
  //           title: 'Finance',
  //           key: 'departments.finance',
  //         },
  //         {
  //           title: 'Human Resources',
  //           key: 'departments.human',
  //         },
  //         {
  //           title: 'Information Technology',
  //           key: 'departments.information',
  //         },
  //         {
  //           title: 'Legal',
  //           key: 'departments.legal',
  //         },
  //         {
  //           title: 'Marketing',
  //           key: 'departments.marketing',
  //         },
  //         {
  //           title: 'Operations',
  //           key: 'departments.operations',
  //         },
  //         {
  //           title: 'Sales',
  //           key: 'departments.sales',
  //         },
  //         {
  //           title: 'Medical Health',
  //           key: 'departments.medical',
  //         },
  //       ],
  //     },
  //   ],
  // },
  */
  // {
  //   label: 'Communities',
  //   key: 'communities',
  //   value: '',
  //   comingSoon: true,
  //   type: 'list',
  // },
  {
    label: 'Connect +',
    key: 'connect_plus',
    value: '',
    open: true,
    image: PulseAnimation,
    component: <ConnectPlusFilter route={'contacts'} />,
    className: 'purple',
  },
]
