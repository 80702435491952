import React from 'react'
import premium_tasks_screen from 'common/assets/svg/premium_task_screen.svg'
import styles from './Tasks.module.scss'

const Tasks: React.FC = () => {
  return (
    <div className='blur-overlay'>
      <div className={styles.container}>
        <img src={premium_tasks_screen} alt='' className={styles.fullscreenImage} />
      </div>
    </div>
  )
}

export default Tasks
