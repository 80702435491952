import React from 'react'
import styles from './CustomLastStepTag.module.scss'
import outrach_recipients from 'common/assets/svg/outrach_recipients.svg'
import followup_recipients from 'common/assets/svg/followup_recipients.svg'
import step_like_post from 'common/assets/svg/step_like_post.svg'
import step_send_message from 'common/assets/svg/step_send_message.svg'

interface CustomLastStepTagProps {
  tag: string
  message_level?: number
}

const CustomLastStepTag: React.FC<CustomLastStepTagProps> = ({ tag, message_level }) => {
  const tagMappings: Record<string, { text: string; image: any; number?: number }> = {
    telegram_outreach: { text: 'outreach', image: outrach_recipients },
    telegram_followup: { text: 'followup', image: followup_recipients, number: message_level },
    instagram_follow: { text: 'IG follow', image: followup_recipients, number: message_level },
    instagram_unfollow: { text: 'IG unfollow', image: followup_recipients, number: message_level },
    instagram_send_message: { text: 'IG DM', image: step_send_message, number: message_level },
    instagram_send_comment: { text: 'comment', image: outrach_recipients, number: message_level },
    instagram_like_post: { text: 'like', image: step_like_post, number: message_level },
    facebook_follow: { text: 'FB follow', image: followup_recipients, number: message_level },
    facebook_unfollow: { text: 'FB unfollow', image: followup_recipients, number: message_level },
    facebook_send_message: { text: 'outreach', image: outrach_recipients, number: message_level },
    facebook_like_post: { text: 'like', image: step_like_post, number: message_level },
    linkedin_follow: { text: 'Follow', image: followup_recipients, number: message_level },
    linkedin_unfollow: { text: 'Unfollow', image: followup_recipients, number: message_level },
    linkedin_send_message: { text: 'outreach', image: outrach_recipients, number: message_level },
    linkedin_like_post: { text: 'like', image: step_like_post, number: message_level },
    linkedin_connect: { text: 'Connect', image: followup_recipients, number: message_level },
    connect_lead_with_note: { text: 'Connect', image: followup_recipients, number: message_level },
    linkedin_disconnect: { text: 'Disconnect', image: followup_recipients, number: message_level },
    // first_telegram_followup: { text: 'followup', image: followup_recipients, number: 1 },
    // second_telegram_followup: { text: 'followup', image: followup_recipients, number: 2 },
    // third_telegram_followup: { text: 'followup', image: followup_recipients, number: 3 },
  }

  const { text, image, number } = tagMappings[tag] || { text: '', image: undefined, number: undefined }

  return (
    <div className={styles.container}>
      <div className={`${styles.tag} ${styles[text]}`}>
        {image && <img src={image} alt={text} />}
        <span style={{ width: '100%', textAlign: 'center' }}>
          {text?.toLocaleUpperCase()} {number && <span>({number})</span>}
        </span>
      </div>
    </div>
  )
}

export default CustomLastStepTag
