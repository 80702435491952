import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input, Form, message, Button, Statistic, Carousel } from 'antd'
import styles from './SocialConnectionModal.module.scss'
// import axios from 'axios'
import {
  useLazyCheckSignInStatusQuery,
  useLazyInstagramGetAccountStatusQuery,
  useLazyInstagramInstagrapiLoginQuery,
  useLazyInstagramLoginQuery,
  useLazyInstagramSendVerificationCodeQuery,
  useLazyLinkedinLoginQuery,
  useLazyLinkedinSendVerificationCodeQuery,
  useLazyLinkedinGetAccountStatusQuery,
  useLazyFacebookLoginQuery,
  useLazyFacebookSendVerificationCodeQuery,
  useLazyFacebookSendPINCodeQuery,
  useLazyFacebookGetAccountStatusQuery,
} from './state/api/SocialConnectionApi'
import facebook_icon from 'common/assets/svg/facebook_logo.svg'
import telegram_icon from 'common/assets/svg/telegram_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import LoadingBox from '../LoadingBox/LoadingBox'
// import VerificationCode from './UI/VerificationCode'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
// import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { useLocalStorage } from 'usehooks-ts'
import { statusMessages } from './SocialUtils'
// import FlagInput from '../OutreachConnection/FlagInput'

const { Countdown } = Statistic

interface SocialConnectionModalProps {
  open: boolean
  onClose: () => void
  platform: 'instagram' | 'facebook' | 'linkedin'
  setCheckLocalStorage?: any
}

const platformConfigs: any = {
  instagram: {
    icon: instagram_icon,
    loginQuery: useLazyInstagramLoginQuery,
    verificationQuery: useLazyInstagramSendVerificationCodeQuery,
    twoFAQuery: useLazyInstagramInstagrapiLoginQuery,
    accountStatusQuery: useLazyInstagramGetAccountStatusQuery,
  },
  linkedin: {
    icon: linkedin_icon,
    loginQuery: useLazyLinkedinLoginQuery,
    verificationQuery: useLazyLinkedinSendVerificationCodeQuery,
    twoFAQuery: useLazyLinkedinLoginQuery, // change later
    accountStatusQuery: useLazyLinkedinGetAccountStatusQuery,

    // getAccountStatusQuery: {},
  },
  facebook: {
    icon: facebook_icon,
    loginQuery: useLazyFacebookLoginQuery,
    verificationQuery: useLazyFacebookSendVerificationCodeQuery,
    twoFAQuery: useLazyFacebookLoginQuery,
    accountStatusQuery: useLazyFacebookGetAccountStatusQuery,
  },
  telegram: {
    icon: telegram_icon,
    loginQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    verificationQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    twoFAQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    accountStatusQuery: () => {
      return [() => {}, { data: [], isLoading: false }]
    },
    // loginQuery: useLazyTelegramLoginQuery,
    // verificationQuery: useLazyTelegramSendVerificationCodeQuery,
  },
  discord: {
    icon: discord_icon,
  },
}

const SocialConnectionModal: React.FC<SocialConnectionModalProps> = ({
  open,
  onClose,
  platform,
  setCheckLocalStorage,
}) => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const addons = user?.addons
  const total_number_of_accounts_connected = addons?.total_number_of_accounts_connected || 0
  const [numberOfConnectedAccounts, setNumberOfConnectedAccounts] = useState(total_number_of_accounts_connected)
  const platformUpperCase = platform[0].toLocaleUpperCase() + platform.slice(1)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [form] = Form.useForm()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [disabledButton, setDisabledButton] = useState(false)
  const carouselRef = useRef<any>()
  const inputRef = useRef<any>(null)
  // eslint-disable-next-line
  // const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [PINCode, setPINCode] = useState('')
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [countryCode, setCountryCode] = useState('US')
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const { refetchUserLite } = useAuth()

  const { icon, loginQuery, verificationQuery, twoFAQuery, accountStatusQuery } = platformConfigs[platform]
  const [login, { data: loginData, isLoading: isLoginLoading }] = loginQuery()
  const [sendPINCode] = useLazyFacebookSendPINCodeQuery()
  // eslint-disable-next-line
  const [sendVerificationCode, { isLoading: isVerificationLoading }] = verificationQuery()
  const [twoFALogin] = twoFAQuery()
  // eslint-disable-next-line
  const [getAccountStatus, { isLoading: isAccountStatusLoading }] = accountStatusQuery()
  const [checkSignInStatus] = useLazyCheckSignInStatusQuery()
  // const [operationId, setOperationId] = useState('')
  // eslint-disable-next-line
  const [signInStatus, setSignInStatus] = useState('')
  const [openModalCheckSignedInDevices, setOpenModalCheckSignedInDevices] = useState(false)
  const [newOpId, setNewOpId] = useState('')
  const [nowDate, setNowDate] = useState(Date.now())
  const [error, setError] = useState('')

  const pollOperationStatus = async (operationId: any, maxRetries = 50, ms = 5000) => {
    let attempts = 0
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        if (attempts >= maxRetries) {
          clearInterval(interval)
          setLoading(false)
          setError('Session expired. Plesae try again or contact support.')
          message.error('Session expired. Plesae try again or contact support.')
          reject(new Error('Max retries reached'))
          return
        }
        try {
          const statusResponse = await checkSignInStatus({ operationId })
          const { status, message, error_message, status_code } = statusResponse.data
          if (status !== 'processing') {
            clearInterval(interval)
            resolve({ status: status, message: message, error_message: error_message, status_code: status_code }) // Resolve with the final message
          }
        } catch (error) {
          clearInterval(interval)
          reject(error) // Reject with the error
        }
        attempts++
      }, ms) // Poll every 5 seconds
    })
  }

  const handleOk = () => {
    switch (platform) {
      case 'instagram':
        return handleInstagramOk()
      case 'linkedin':
        return handleLinkedinOk()
      case 'facebook':
        return handleFacebookOk()
    }
  }

  const handleInstagramOk = async () => {
    setError('')
    if (currentStep === 2) {
      try {
        const values = await form.validateFields()
        setUsername(values?.username || '')
        setPassword(values?.password)
        setEmail(values?.email || '')
        setLoading(true)
        // await login({ ...values, country_code: countryCode })
        const { data } = await login({ ...values, country_code: countryCode })
        const pollData: any = await pollOperationStatus(data.opId)
        const { message: pollMessage, status_code } = pollData

        if (pollMessage === 'Signed in' || status_code === 'active') {
          const { data } = await getAccountStatus({ username: values?.username })
          if (data.id) {
            setUser(data)
          }
          setCurrentStep(currentStep + 2)
        } else if (pollMessage === 'require_authentication_code' || pollMessage === 'require_2fa_code') {
          setCurrentStep(currentStep + 1)
        } else {
          const userMessage = statusMessages[status_code] || 'Something went wrong. Please try again.'
          message.error(userMessage)
          setError(userMessage)
        }

        setLoading(false)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    } else if (currentStep === 3) {
      try {
        setLoading(true)
        switch (platform) {
          case 'instagram':
            if (signInStatus === 'require_2fa_code') {
              const { data } = await twoFALogin({
                username: username,
                password: password,
                country_code: countryCode,
                two_factor_code: verificationCode,
              })
              if (data.message === 'Signed in') {
                const { data } = await getAccountStatus({ username: username })
                if (data.id) {
                  setUser(data)
                }
                message.success(`Signed in to ${username}`)
                onClose()
              }
              if (data.message === 'Failed to update verification code') {
                setCurrentStep(currentStep)
              }
            } else {
              const { data } = await sendVerificationCode({
                username: username,
                verificationCode: verificationCode,
              })

              if (data.message === 'Signed in') {
                const { data } = await getAccountStatus({ username: username })
                if (data.id) {
                  setUser(data)
                }
                message.success(`Signed in to ${username}`)
                onClose()
              } else if (data.id) {
                setUser(data)
                message.success(`Signed in to ${username}`)
                onClose()
              } else if (data.message === 'Failed to update verification code') {
                setCurrentStep(currentStep)
              }
            }
            break
          default:
            break
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setCurrentStep(currentStep)
        message.error('Something went wrong.')
        setError('Something went wrong.')
      }
    } else if (currentStep === 4) {
      const { data: userData } = await refetchGetUserDetails()
      setUser(userData)
      onClose()
    } else if (currentStep === 14) {
      const { data: userData } = await refetchGetUserDetails()
      setUser(userData)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleLinkedinOk = async () => {
    setError('')
    if (currentStep === 2) {
      try {
        const values = await form.validateFields()
        setUsername(values?.username || '')
        setPassword(values?.password)
        setEmail(values?.email || '')
        setLoading(true)
        // await login({ ...values, country_code: countryCode })
        const { data } = await login({ ...values, country_code: countryCode })
        const pollData: any = await pollOperationStatus(data.opId, 50)
        const { message: pollMessage, status_code } = pollData

        if (pollMessage === 'Signed in') {
          const { data } = await getAccountStatus({ email: values?.email })
          if (data.id) {
            setUser(data)
          }
          setCurrentStep(currentStep + 2)
        } else if (pollMessage === 'Already signed in before') {
          message.error('You already signed in with this account!')
        } else if (pollMessage === 'require_authentication_code' || pollMessage === 'require_2fa_code') {
          setCurrentStep(currentStep + 1)
        } else if (pollMessage === 'require_register_phone') {
          setCurrentStep(5)
        } else if (pollMessage === 'require_another_authentication_code') {
          setCurrentStep(6)
        } else if (pollMessage === 'require_check_signedin_app') {
          setOpenModalCheckSignedInDevices(true)
        } else {
          const userMessage = statusMessages[status_code] || 'Something went wrong. Please try again.'
          message.error(userMessage)
          setError(userMessage)
        }
        setLoading(false)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    } else if (currentStep === 3 || currentStep === 5 || currentStep === 6) {
      try {
        setLoading(true)
        const { data: linkedinVerificationData } = await sendVerificationCode({
          email: email,
          verificationCode: verificationCode,
        })
        if (
          linkedinVerificationData.message === 'Failed to update verification code' ||
          linkedinVerificationData.message === 'Something went wrong'
        ) {
          setCurrentStep(currentStep)
          message.error('Please try again.')
        } else if (linkedinVerificationData?.account_status === 'require_another_authentication_code') {
          setCurrentStep(6)
        } else if (linkedinVerificationData.id) {
          setUser(linkedinVerificationData)
          setCurrentStep(4)
        } else {
          setCurrentStep(4)
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setCurrentStep(currentStep)
        message.error('Something went wrong.')
        setError('Something went wrong. Please try again.')
      }
    } else if (currentStep === 4) {
      const { data: userData } = await refetchGetUserDetails()
      refetchUserLite(userData)
      setUser(userData)
      setCheckLocalStorage && setCheckLocalStorage((x: any) => !x)
      onClose()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleFacebookOk = async () => {
    setError('')
    if (currentStep === 2) {
      try {
        const values = await form.validateFields()
        setUsername(values?.username || '')
        setPassword(values?.password)
        setEmail(values?.email || '')
        setLoading(true)
        // await login({ ...values, country_code: countryCode })
        const { data } = await login({ ...values, country_code: countryCode })
        setNewOpId(data.opId)

        const pollData: any = await pollOperationStatus(data.opId, 50)
        const { message: pollMessage, status_code } = pollData

        if (
          status_code === 'success' ||
          pollMessage === 'Signed in' ||
          pollMessage === 'Signed in status: active' ||
          pollMessage === 'Sign in status: active'
        ) {
          const { data } = await getAccountStatus({ username: values?.username })
          if (data.id) {
            setUser(data)
          }
          setCurrentStep(currentStep + 2)
        } else if (pollMessage === 'Already signed in before') {
          message.error('You already signed in with this account!')
        } else if (pollMessage === 'require_authentication_code' || pollMessage === 'require_2fa_code') {
          setCurrentStep(currentStep + 1)
        } else if (pollMessage === 'require_register_phone') {
          setCurrentStep(5)
        } else if (pollMessage === 'verify_on_app') {
          setNowDate(Date.now())
          setDisabledButton(true)
          setCurrentStep(7)
        } else if (status_code === 'pin_code_required') {
          setCurrentStep(8)
        } else {
          const userMessage = statusMessages[status_code] || 'Something went wrong. Please try again.'
          message.error(userMessage)
          setError(userMessage)
        }
        setLoading(false)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    } else if (currentStep === 3 || currentStep === 5) {
      try {
        setLoading(true)
        const { data: facebookVerificationData, error } = await sendVerificationCode({
          username: username,
          verificationCode: verificationCode,
        })

        if (facebookVerificationData?.message === 'Failed to update verification code' || error) {
          setCurrentStep(currentStep)
          if (error?.data?.account_status === 'authentication_code_expired') {
            message.error('Verification code expired or incorrect. Please login again.')
            setError('Verification code expired or incorrect. Please login again.')
          } else {
            message.error('Something went wrong. Please login again.')
            setError('Something went wrong. Please login again.')
          }
        } else if (facebookVerificationData.id) {
          setUser(facebookVerificationData)
          setCurrentStep(currentStep + 1)
        } else {
          setCurrentStep(currentStep + 1)
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setCurrentStep(currentStep)
        message.error('Something went wrong.')
        setError('Something went wrong. Please try again.')
      }
    } else if (currentStep === 4) {
      const { data: userData } = await refetchGetUserDetails()
      refetchUserLite(userData)
      setUser(userData)
      setCheckLocalStorage && setCheckLocalStorage((x: any) => !x)
      onClose()
    } else if (currentStep === 7) {
      setLoading(true)
      const { data } = await getAccountStatus({ username: username })
      if (data?.account_status === 'active') {
        setLoading(false)
        message.success('Account Approved')
        setCurrentStep(4)
      } else if (data.id) {
        setUser(data)
        setCurrentStep(4)
      } else {
        message.error('Something went wrong. Please try again.')
        setError('Something went wrong. Please try again.')
        setLoading(false)
      }
    } else if (currentStep === 8) {
      setLoading(true)
      const { data, error } = await sendPINCode({ username: username, PINCode: PINCode })
      if (data?.account_status === 'active') {
        setLoading(false)
        message.success('PIN Approved')
        setCurrentStep(4)
      } else if (data.id) {
        setUser(data)
        setLoading(false)
        message.success('PIN Approved')
        setCurrentStep(4)
      } else {
        if (error?.data) {
          const errMessage = error?.data?.error_message || 'Failed to update your PIN code. Please contact support.'
          message.error(errMessage)
          setError(errMessage)
          setLoading(false)
        } else {
          message.error('Something went wrong. Please try again.')
          setError('Something went wrong. Please try again.')
          setLoading(false)
        }
      }
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleCancel = () => {
    onClose()
    setCurrentStep(1)
    form.resetFields()
  }

  useEffect(() => {
    const fetchGeoInfo = async () => {
      try {
        // const response = await axios.get('http://ip-api.com/json/')
        // setCountryCode(response.data.countryCode)
        setCountryCode('US')
      } catch (error) {
        console.error('Failed to fetch country code:', error)
      }
    }

    fetchGeoInfo()
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, verificationCode, PINCode])

  const messages = [
    `Logging in to your ${platformUpperCase}, please follow the instructions`,
    'All credentials are encrypted and secured',
    `Background login to ${platformUpperCase} can take a while, thank you for your patience`,
    `Preparing your ${platformUpperCase} account`,
    'In the first few days, the account will be in the warming stage for safety',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselIndex < 5) {
        setCarouselIndex((prevIndex) => {
          const newIndex = prevIndex + 1
          if (newIndex < messages.length) {
            if (carouselRef.current) {
              // console.log(carouselRef.current)
              carouselRef.current.goTo(newIndex) // Ensure the method to update is properly called
            }
            return newIndex
          }
          return prevIndex
        })
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goTo(carouselIndex) // Trigger the carousel to go to the current index
    }
  }, [carouselIndex]) // Add a separate effect to handle the state change

  useEffect(() => {
    if (currentStep === 7) {
      const intervalId = setInterval(async () => {
        const { data: poll } = await getAccountStatus({ username: username })
        if (poll?.account_status === 'active') {
          setLoading(false)
          message.success('Account Approved')
          setCurrentStep(4)
          clearInterval(intervalId)
        } else if (poll?.account_status === 'require_authentication_code') {
          setLoading(false)
          setDisabledButton(false)
          setCurrentStep(3)
          clearInterval(intervalId)
        } else if (poll.id && poll.email) {
          setUser(poll)
          setCurrentStep(4)
        }
      }, 5000)

      // Clear the interval when the component is unmounted or when currentStep changes
      return () => clearInterval(intervalId)
    }
  }, [currentStep, newOpId])

  const handleCountdownFinish = () => {
    console.log('Expired')
    if (currentStep === 7) {
      setCurrentStep(3)
      setDisabledButton(false)
    }
  }

  const StepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className={styles.firstScreen}>
            <img src={icon} alt='' />
            <p>Let's connect your {platformUpperCase} account to Convrt!</p>
          </div>
        )
      case 2:
        return isLoginLoading || loading ? (
          <div className={styles.carousel}>
            <div className={styles.loading}>
              <LoadingBox />
            </div>
            <Carousel
              autoplay={true}
              autoplaySpeed={5000}
              dots={false}
              ref={carouselRef}
              effect='scrollx'
              style={{ textAlign: 'center' }}
              // style={{ height: 22, lineHeight: 'normal' }}
            >
              {messages.map((message, index) => (
                <p key={index} style={{ color: '#666 !important' }}>
                  {message}
                </p>
              ))}
            </Carousel>
          </div>
        ) : (
          <Form form={form} layout='vertical' className={`socialConnectionForm ${styles.stepContent}`}>
            {platform === 'linkedin' && (
              <div className={styles.inputContainer}>
                <span>Email Address</span>
                <Form.Item name='email' rules={[{ required: true, message: 'Please input your email!' }]} hasFeedback>
                  <Input style={{ width: 300, margin: '0px !important' }} placeholder='Email' />
                </Form.Item>
              </div>
            )}
            {(platform === 'instagram' || platform === 'facebook') && (
              <div className={styles.inputContainer}>
                <span>Username</span>
                <Form.Item
                  name='username'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                  hasFeedback>
                  <Input style={{ width: 300 }} placeholder='Username' />
                </Form.Item>
              </div>
            )}
            <div className={styles.inputContainer}>
              <span>Password</span>
              <Form.Item
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
                hasFeedback>
                <Input.Password style={{ width: 300 }} placeholder='Password' />
              </Form.Item>
            </div>
            {/* <div className={styles.inputContainer}>
              <span>{platformUpperCase}'s Country</span>
              <Form.Item
                name='countryCode'
                rules={[{ required: true, message: 'Please select a country!' }]}
                initialValue={countryCode}
                hasFeedback>
                <FlagInput
                  selectStyle={{ width: 300 }}
                  labelCountryCode
                  defaultCountryCodeValue={countryCode}
                  onChange={(e: any) => {
                    setCountryCode(e)
                  }}
                />
              </Form.Item>
            </div> */}
          </Form>
        )
      case 3:
        return true || loginData.message === 'require_authentication_code' ? (
          <div className={styles.digitCode}>
            <p>Please insert your 6-digits code you received from {platformUpperCase}</p>
            {/* <VerificationCode numInputs={6} onCompletion={setVerificationCode} /> */}
            <Input
              ref={inputRef}
              style={{ width: '100%', marginTop: '20px' }}
              maxLength={6}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter 6-digit code'
            />
          </div>
        ) : (
          <div>Please follow further instructions for verification.</div>
        )
      case 4:
        return <div>Your account is now successfully connected!</div>
      case 5:
        return (
          <div className={styles.digitCode}>
            <p>Please enter your phone number for confirmation</p>
            <Input
              style={{ width: '100%', marginTop: '20px' }}
              value={verificationCode}
              defaultValue={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter your phone number'
            />
          </div>
        )
      case 6:
        return (
          <div className={styles.digitCode}>
            <p>
              Please insert your <span style={{ fontWeight: 600 }}>NEW</span> 6-digits code you received from{' '}
              {platformUpperCase}
            </p>
            <Input
              ref={inputRef}
              style={{ width: '100%', marginTop: '20px' }}
              maxLength={6}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter 6-digit code'
            />
          </div>
        )
      case 7:
        return (
          <div className={styles.digitCode}>
            <span>
              Check your {platformUpperCase} notifications on your other devices and approve the login to continue.
            </span>
            <div className={styles.timer}>
              <p>Please verify within</p>
              <Countdown
                className='loginTimer'
                value={nowDate + 100 * 1600}
                format='mm:ss'
                onFinish={handleCountdownFinish}
              />
              <p>minutes. Otherwise, we will try another method.</p>
            </div>
          </div>
        )
      case 8:
        return (
          <div className={styles.digitCode}>
            <p>Please insert your 6-digits PIN code you received from {platformUpperCase}</p>
            <Input
              ref={inputRef}
              style={{ width: '100%', marginTop: '20px' }}
              maxLength={6}
              value={PINCode}
              onChange={(e) => setPINCode(e.target.value)}
              placeholder='Enter 6-digit PIN code'
            />
          </div>
        )

      default:
        return null
    }
  }
  const handleContactSupport = () => {
    window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
  }

  useEffect(() => {
    const { facebook, instagram, linkedin } = user
    const length = facebook?.length + instagram?.length + linkedin?.length
    setNumberOfConnectedAccounts(length)
  }, [user])

  if (total_number_of_accounts_connected <= numberOfConnectedAccounts) {
    return (
      <Modal
        title={`Connect Your ${platformUpperCase} Account`}
        className='socialConnectionModal'
        centered
        open={open}
        onCancel={handleCancel}
        footer={null} // Custom footer
      >
        <div className={styles.container}>
          <p style={{ margin: '0px' }}>
            Your current plan allows connecting up to {total_number_of_accounts_connected} accounts to Convrt.
          </p>
          <p style={{ margin: '0px' }}>You already have {numberOfConnectedAccounts} active accounts.</p>
          <p style={{ margin: '0px' }}>
            If you need assistance or have questions, feel free to contact our support team.
          </p>
        </div>

        <div className={styles.actions}>
          <Button className={styles.cancelButton} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type='primary' className={styles.contactSupportButton} onClick={handleContactSupport}>
            Upgrade Plan
          </Button>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <Modal
        title={`Connect Your ${platformUpperCase} Account`}
        className='socialConnectionModal'
        closable={false}
        centered
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Next'
        cancelText='Cancel'
        footer={false}>
        <div className={styles.container}>
          <StepContent />
          {error && (
            <div className={styles.error}>
              <span>{error}</span>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          {/* <Button className={styles.cancelButton}> */}
          <Button className={styles.cancelButton} onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleOk} loading={loading} disabled={disabledButton}>
            Next
          </Button>
        </div>
        <Modal
          title={`Check your ${platformUpperCase} app`}
          className='socialConnectionModal'
          closable={false}
          centered
          open={openModalCheckSignedInDevices}
          style={{ maxWidth: '400px' }}
          footer={false}>
          <div className={styles.digitCode}>
            <p>
              We sent a notification to your signed in devices. Open your Linkedin App and tap Yes to confirm your sign
              in attemp .
            </p>

            <Button
              type='primary'
              style={{ marginLeft: 'auto', width: '100%' }}
              onClick={() => setOpenModalCheckSignedInDevices(false)}>
              Close
            </Button>
          </div>
        </Modal>
      </Modal>
    </>
  )
}

export default SocialConnectionModal
