export const statusMessages: Record<string, string> = {
  success: 'Successfully logged in.',
  general_error: 'An unexpected error occurred during the sign-in process.',
  no_client_email: 'No client email found for the associated Facebook account.',
  associated_with_another_client: 'The account is associated with a different client.',
  no_available_proxy: 'Something went wrong. Please contact support.',
  //   no_available_proxy: 'No proxy available to assign to the Facebook account.',
  failed_to_start_facebook_session: 'Failed to start a Facebook session. Please try again.',
  incorrect_password: 'The password you entered is incorrect.',
  account_recover: 'The account is in recovery mode.',
  authenticate_another_case: 'Authentication requires a different method that is not supported yet.',
  logged_out: 'Failed to log in. Contact Support.',
  verification_failed: 'Login failed after verification. Maximum retries reached.',
  pin_code_required: 'A PIN code is required to proceed. Please enter the PIN.',
}
