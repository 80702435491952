import React from 'react'
import styles from './LeadsOverview.module.scss'
import { Card, Skeleton } from 'antd'

interface LeadsOverviewProps {
  paused: number
  continuing: number
  completed: number
  loading?: boolean
}

const LeadsOverview: React.FC<LeadsOverviewProps> = ({ paused, continuing, completed, loading }) => {
  const total = paused + continuing + completed

  const pausedPercentage = (paused / total) * 100
  const continuingPercentage = (continuing / total) * 100
  const completedPercentage = (completed / total) * 100

  const nothingPercentage = pausedPercentage + continuingPercentage + completedPercentage > 0 ? 0 : 100

  return (
    <Card className={`campaignStatsCard ${styles.card}`}>
      <h3>Leads Overview</h3>
      <div className={styles.progressBar}>
        {loading && <div className={styles.loadingBar}></div>}
        {!loading &&
          (nothingPercentage ? (
            <div className={styles.paused} style={{ width: `${nothingPercentage}%`, borderRadius: '12px' }}></div>
          ) : (
            <>
              <div className={styles.paused} style={{ width: `${pausedPercentage}%` }}></div>
              <div className={styles.continuing} style={{ width: `${continuingPercentage}%` }}></div>
              <div className={styles.completed} style={{ width: `${completedPercentage}%` }}></div>
            </>
          ))}
      </div>
      <div className={styles.labels}>
        <div className={styles.text}>
          <span className={styles.header}>
            Ready<span className={styles.hide}> to Start</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#DFE1E6' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                paused?.toLocaleString()
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Continuing <span className={styles.hide}></span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: 'rgba(25, 188, 254, 0.50)' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                continuing?.toLocaleString()
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Completed <span className={styles.hide}></span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#19BCFE' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                completed?.toLocaleString()
              )}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default LeadsOverview
