import React, { useEffect, useState } from 'react'
import { Mentions, Button, Modal, message as popUpMessage, Form, Select } from 'antd'

import { useAuth } from 'common/hooks/useAuth.hooks'
import send_message from '../../../common/assets/svg/send_message.svg'
import { useTelegram } from 'common/providers/TelegramProvider'
import { useLazyGetCreditsQuery } from 'features/settings/state/api/SettingsApi'
import { useLazyGetSenderListQuery } from 'features/Outreach/state/api/OutreachApi'
import { post } from 'common/api/axios'
import { SET_IS_OUTREACHED, SET_IS_SENT } from '../state/sendMessageSlice'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

import styles from './SendMessageModal.module.scss'
import { useAppDispatch } from 'state'
import { SET_DELETE_CONTACTS_SELECTED_ROWS } from 'features/contacts/state/slice/contactsSlice'

interface SendMessageModalProps {
  open: boolean
  onClose: () => void
  contactsList: any[]
}

const SendMessageModal: React.FC<SendMessageModalProps> = ({ open, onClose: handleClose, contactsList }) => {
  const { Option } = Select

  const [refetchGetCredits] = useLazyGetCreditsQuery()
  const [refetcGetSenderList, { data, isLoading }] = useLazyGetSenderListQuery()
  const [defaultSelect, setDefaultSelect] = useState<string | number>()
  const { userDetails } = useAuth()
  const { sendMessages } = useTelegram()
  const [form] = Form.useForm()
  const [disableButton, setDisableButton] = useState(true)
  const [outOfCredits, setOutOfCredits] = useState(false)

  const contactsNames = contactsList
    ?.filter(({ telegram_account }) => !!telegram_account)
    ?.map((obj: any) => obj.full_name)

  const formatContactList = (contacts: any) => {
    const firstThreeNames = contactsNames.slice(0, 4)
    const remainingCount = Math.max(0, contacts.length - 4)

    return (
      <>
        <span className={styles.textList}>{`To: ${firstThreeNames.join(', ')}`}</span>
        {remainingCount > 0 && <span className={styles.textPlus}>{` +${remainingCount}`}</span>}
      </>
    )
  }

  const optionLabels = [
    'city',
    'email',
    'state',
    'country',
    'first_name',
    'full_name',
    'seniority',
    'job_title',
    'company_name',
    'job_function',
  ]

  const options = optionLabels.map((value) => ({
    value,
    label: value,
  }))

  function replacePlaceholders(message: string, contact: any): string {
    const regex = /@(\w+)/g
    return message.replace(regex, (match, placeholder) => {
      const option = options.find((opt) => opt.value === placeholder)
      if (option && contact.hasOwnProperty(placeholder)) {
        return contact[placeholder]
      } else if (placeholder === 'first_name') {
        return contact['full_name'].split(' ')[0]
      }
      return match
    })
  }

  const handleFormChange = () => {
    if (form.getFieldValue('message') !== undefined && form.getFieldValue('sender') !== undefined) {
      setDisableButton(form.getFieldValue('message')?.trim().length === 0)
    }
  }
  const dispatch = useAppDispatch()

  const onFinish = async () => {
    dispatch(SET_IS_OUTREACHED(contactsList))
    dispatch(SET_IS_SENT(true))
    dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))

    try {
      const values = await form.validateFields()
      const message = values.message
      // const sender = values.sender

      const creditsResponse = await refetchGetCredits()

      if (!creditsResponse?.data?.response?.credits?.contacts_outreached_left) return setOutOfCredits(true)

      if (!creditsResponse?.data?.response?.credits?.send_tg_message)
        return popUpMessage.error(`You're out of message credits`)

      //return popUpMessage.error(`You're out of message credits`)

      const localUserDetails = localStorage.getItem('user') || userDetails
      const oUserDetails = JSON.parse(localUserDetails)

      const resultObject = oUserDetails?.outreach.find((item: any) => item.id === defaultSelect)

      const contactListToSend = contactsList
        ?.filter(({ telegram_account }) => !!telegram_account)
        ?.map((contact) => {
          const updatedMessage = replacePlaceholders(message, contact)
          return {
            ...resultObject,
            message: updatedMessage,
            recipient: contact?.telegram_account,
            contact_id: contact?.index,
            full_name: contact?.full_name,
          }
        })

      handleClose()

      post('/log', {
        action: 'Press Send Message Button',
      })

      await sendMessages(
        contactListToSend,
        // `${userDetails?.outreach?.area_code}${userDetails?.outreach?.number}`,
        `${userDetails?.outreach?.[Number(defaultSelect) - 1 || 0]?.area_code}${
          userDetails?.outreach?.[Number(defaultSelect) - 1 || 0]?.number
        }`,
        false,
      )
    } catch (errInfo) {
      console.error('Error:', errInfo)
    }
  }

  const renderOption = (item: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <img
        src={item.img}
        alt={item.name}
        style={{ width: '24px', height: '24px', margin: '3px 10px 3px 0px', borderRadius: '15px' }}
      />
      {item.name != null ? item.name : item.area_code + item.number} */}
      {item.area_code + item.number}
    </div>
  )

  useEffect(() => {
    const getRefetcGetSenderList = async () => {
      const senderList = await refetcGetSenderList()
      for (let [, item] of senderList?.data?.response?.entries()) {
        if (item?.cooldown === 0 || item?.cooldown === false) {
          setDefaultSelect(item?.id)
          form.setFieldsValue({ sender: item?.id })
          return
        }
      }
    }
    getRefetcGetSenderList()
  }, [])

  return (
    <div className={styles.modal}>
      <Modal
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={handleClose}
        width={800}
        bodyStyle={{
          height: 350,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div className={styles.container}>
          <div className={styles.header}>
            <img src={send_message} alt='' />
            <span>{'Send Telegram Message'}</span>
          </div>
          <div className={styles.textField}>
            <div
              className={styles.inputText}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <Form
                id='messageForm'
                name='messageForm'
                form={form}
                layout='horizontal'
                style={{ width: '100%' }}
                onFieldsChange={handleFormChange}
                onFinish={onFinish}>
                <div>Sender:</div>
                <Form.Item name='sender'>
                  <div style={{ display: 'none' }}> {defaultSelect}</div>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Select an option'
                    loading={isLoading}
                    defaultValue={defaultSelect}
                    value={defaultSelect}
                    onChange={(value) => {
                      setDefaultSelect(value)
                    }}>
                    {data?.response.map((item: any) => (
                      <Option key={item.id} value={item.id} disabled={item.cooldown}>
                        {renderOption(item)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div>Message Content:</div>
                <Form.Item name='message'>
                  <Mentions
                    className={styles.inputText}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    maxLength={5000}
                    style={{ padding: '15px 0px 0px 15px' }}
                    placeholder='You can use @ to ref user data here'
                    options={options}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={styles.footer}>
            <div>{formatContactList(contactsNames)}</div>
            <Button
              className={styles.Button}
              form='messageForm'
              key='submit'
              htmlType='submit'
              disabled={disableButton}>
              {'Send'}
            </Button>
          </div>
        </div>
      </Modal>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          location='exposedContacts'
          type='limit'
          caption={'contacts outreached'}
          isSubCaption={false}
        />
      )}
    </div>
  )
}

export default SendMessageModal
