import { useState } from 'react'
import { Divider, Modal, message } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useAuth } from 'common/hooks/useAuth.hooks'
import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'
import { outreach } from 'common/constants/outreach.constants'
import { useLazyGetCheckRepliesQuery } from 'features/Outreach/state/api/OutreachApi'
import { post } from 'common/api/axios'

import styles from './MessagesStatus.module.scss'

const MessagesStatus = ({ messages, refetch, counts: totalCounts }: { messages: any; refetch: any; counts?: any }) => {
  const [loading, setLoading] = useState(false)
  const [openContactSales, setOpenContactSales] = useState(false)
  const [refetcGetCheckReplies, { isError }] = useLazyGetCheckRepliesQuery()

  const { userDetails } = useAuth()

  const counts = messages?.reduce(
    (counts: any, message: any) => {
      Object.keys(counts).forEach((key) => {
        if (message[key]) counts[key] = [...counts[key], message?.contact_id]
      })

      return counts
    },
    { sent: [], seen: [], replied: [] },
  )

  const handleSync = async () => {
    post('/log', {
      action: 'See Pricing Plans',
    })
    if (!userDetails?.addons?.outreach) return setOpenContactSales(true)

    try {
      setLoading(true)

      const usernames = messages.reduce((acc: any, item: any) => {
        if (item.user_telegram_id !== null) {
          const existingUser = acc.find((user: any) => user.id === item.user_telegram_id)

          if (existingUser) {
            existingUser.usernames.push(item.contact_telegram)
            existingUser.contact_id.push(item.contact_id)
          } else {
            acc.push({
              id: item.user_telegram_id,
              usernames: [item.contact_telegram],
              contact_id: [item.contact_id],
            })
          }
        }

        return acc
      }, [])

      const resultObject = userDetails?.outreach.reduce((result: any, item: any) => {
        const user_telegram_id = item.area_code + item.number
        const matchingItem = usernames.find((item: any) => item.id === user_telegram_id)

        if (matchingItem) {
          result[user_telegram_id] = matchingItem
        }

        return result
      }, {})

      await refetcGetCheckReplies({
        outreach: userDetails?.outreach,
        max_messages_to_check: 6,
        usernames: resultObject,
      })

      if (isError) message.error('An error has occured while syncing')

      await refetch()
    } catch (e) {
      message.error('Something went wrong')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div style={{ display: 'flex' }}>
          <span className={styles.title}>{`Messages Sent:`}</span>
          <span className={styles.value}>{counts?.sent?.length}</span>
          <Divider className={styles.divider} type={'vertical'} />
          <span className={styles.title}>{`Messages Seen:`}</span>
          <span className={styles.value}>{counts?.seen?.length}</span>
          <Divider className={styles.divider} type={'vertical'} />
          <span className={styles.title}>{`Contacts Replied:`}</span>
          <span className={styles.value}>{new Set(counts?.replied)?.size}</span>
          <Divider className={styles.divider} type={'vertical'} />
          {loading ? (
            <LoadingBox className={styles.loading} />
          ) : (
            !!Object.keys(userDetails?.outreach || {})?.length && (
              <span className={styles.sync} onClick={handleSync}>
                <span className={styles.syncIcon}>
                  <SyncOutlined />
                </span>
                {'Sync'}
              </span>
            )
          )}
        </div>
        <div>
          <span className={styles.title}>{`Contacts:`}</span>
          <span className={styles.value}>{totalCounts?.contactsCount}</span>
          <Divider className={styles.divider} type={'vertical'} />
          <span className={styles.title}>{`Companies:`}</span>
          <span className={styles.value}>{totalCounts?.companiesCount}</span>
        </div>
      </div>
      {openContactSales && (
        <Modal
          open={openContactSales}
          onCancel={() => setOpenContactSales(false)}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <BlurPaywall
              addon={'Telegram messaging'}
              component={[]}
              onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
              customClass='contactSalesNoMargin'
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default MessagesStatus
