import React from 'react'
import { Modal, Button } from 'antd'
import leads_modal_icon from 'common/assets/svg/leads_modal_icon.svg'
import styles from './ImportLeadsModal.module.scss'

interface ImportLeadsModalProps {
  open: boolean
  onClose: () => void
  onClickCSV?: () => void
  onClickList?: () => void
}

const ImportLeadsModal: React.FC<ImportLeadsModalProps> = ({ open, onClose, onClickCSV, onClickList }) => {
  return (
    <Modal
      style={{ borderRadius: '20px' }}
      className='importLeadsModal'
      title={null}
      open={open}
      footer={null}
      closable={false}
      centered
      onOk={onClose}
      onCancel={onClose}
      width={700}>
      <div className={styles.campaignSuccessContent}>
        <img src={leads_modal_icon} alt='' />
        <h1>Add Leads To Your Campaign</h1>
        <p style={{ color: '#5E6C84', fontSize: '18px' }}>
          Import campaign leads by uploading a CSV file
          {/* Import campaign leads from your Convrt lists or by uploading a CSV file */}
        </p>
        <div className={styles.campaignSuccessButtons}>
          {/* <Button type='primary' block onClick={onClickList} style={{ marginRight: '15px' }}>
            Import Leads From Lists
          </Button> */}
          <Button style={{ width: '300px', margin: 'auto' }} type='primary' block onClick={onClickCSV}>
            Import Leads From CSV
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ImportLeadsModal
