import React, { useEffect, useState } from 'react'
import { AutoComplete, Select } from 'antd'
import styles from './AIGerneratePrompt.module.scss'
import aILogo from 'common/assets/png/ai_logo.png'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_SETUP_METHOD,
  SET_GENERATE_MESSAGES,
  SET_INITIAL_AI_DATA,
  SET_INITIAL_AI_COMPANIES_DATA,
  SET_SAVED_PROMPT_PARAMS,
  SET_SKIP_PROMPT,
  SET_SELECTED_NODE,
  RESET_SAVED_PROMPT_PARAMS,
} from '../state/outreachAICampaignSlice'
import { countriesWithPhonePrefix } from 'common/static-data/countires'
import stars from 'common/assets/svg/stars.svg'
// import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyGetMyTemplatesQuery } from '../state/api/OutreachAI-API'
import { RESET_BUILDER } from '../Builder/Slice/CampaignBuilderSlice'
import SelectableCards from './UI/SelectableCards'
import { industries, personas } from '../Helpers/CampaignConstants'
import CustomCollapseFooter from '../CreateCampaign/UI/CustomCollapseFooter'

interface CampaignTypeProps {
  currentKey: any
  setCurrentKey: any
  onBack?: () => void
  onNext?: () => void
  onSkip?: () => void
}
const CampaignType: React.FC<CampaignTypeProps> = ({ currentKey, setCurrentKey, onBack, onNext, onSkip }) => {
  // const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  // const [deleteCampaignData] = useLazyDeleteCampaignDataQuery()
  const [getMyTemplates] = useLazyGetMyTemplatesQuery()
  const [confirmedReset, setConfirmedReset] = useState(false)
  const { saved_prompt_params, select_custom_template, setup_method } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  // const [modalAction, setModalAction] = useState('')
  const countreis = countriesWithPhonePrefix.map((country) => ({ value: country.label }))
  const industryValues = industries.map((industry) => ({ value: industry }))
  const personasValues = personas.map((persona) => ({ value: persona }))
  const dispatch = useAppDispatch()

  const showPrompt =
    setup_method === 'ai' ||
    (!(saved_prompt_params?.industry === '') &&
      !(saved_prompt_params?.location === '') &&
      !(saved_prompt_params?.target_persone === ''))

  // const openModalWithAction = (action: any) => {
  //   setModalAction(action)
  //   setOpenAreYouSureModal(true)
  // }

  const handleSaveAutocomplete = (text: any, param: string) => {
    dispatch(SET_SAVED_PROMPT_PARAMS({ [param]: text }))
  }

  const handleSaveSelect = (arr: any, param: string) => {
    dispatch(SET_SAVED_PROMPT_PARAMS({ [param]: arr.join(', ') }))
  }

  const handleResetCampaign = async () => {
    // dispatch(RESET_SELECTED_LEADS([]))
    dispatch(SET_INITIAL_AI_DATA([]))
    dispatch(SET_INITIAL_AI_COMPANIES_DATA([]))
    // dispatch(SET_RESET_CAMPAIGN_DATA(true))
    // dispatch(SET_ARE_LEADS_FROM_GROUPS(false))
    dispatch(SET_GENERATE_MESSAGES(true))
    dispatch(SET_SETUP_METHOD(''))
    dispatch(SET_SELECTED_NODE(''))
    dispatch(RESET_SAVED_PROMPT_PARAMS({}))
    // await deleteCampaignData({ campaign_id: campaign_id })
  }

  const splitStringToArray = (inputString: any) => {
    if (typeof inputString === 'string' && inputString.trim() !== '') {
      return inputString.split(',').map((item) => item.trim())
    }
    return []
  }

  const handleSetupWithAI = () => {
    dispatch(SET_SETUP_METHOD('ai'))
    window.scrollBy({ top: 20, behavior: 'smooth' })
  }

  const handleSetupManually = () => {
    dispatch(SET_SETUP_METHOD('manually'))
    dispatch(SET_SKIP_PROMPT(true))
  }

  const handleReset = () => {
    // openModalWithAction('reset')
    handleResetCampaign()
  }

  // useEffect(() => {
  //   const allNonEmpty = Object.entries(saved_prompt_params).every(([key, value]) => {
  //     return typeof value === 'string' && value.trim() !== ''
  //   })

  //   if ((allNonEmpty || builder_data.nodes || nodes_data?.length > 0) && currentKey === '3' && setup_method !== '') {
  //     setModalAction('')
  //     setOpenAreYouSureModal(true)
  //   }
  // }, [currentKey])

  useEffect(() => {
    if (confirmedReset) {
      handleResetCampaign()
      dispatch(RESET_BUILDER())
      setConfirmedReset(false)
    }
  }, [saved_prompt_params, confirmedReset])

  //
  // useEffect(() => {
  //   dispatch(SET_INITIAL_AI_DATA([]))
  //   dispatch(SET_INITIAL_AI_COMPANIES_DATA([]))
  // }, [saved_prompt_params])

  useEffect(() => {
    const getTemplates = async () => {
      await getMyTemplates()
    }
    getTemplates()
  }, [])

  // const modalContent =
  //   modalAction === 'reset'
  //     ? {
  //         title: 'Are you sure you want to reset this section?',
  //         message: 'This action will erase all selected leads, messages, and your prompt.',
  //         onConfirm: async () => {
  //           // Default action or another specific action
  //           handleResetCampaign()
  //           setOpenAreYouSureModal(false)
  //         },
  //         onClose: () => {
  //           setOpenAreYouSureModal(false)
  //         },
  //       }
  //     : {
  //         title: 'Are you sure you want to reset this section?',
  //         message: 'This action will erase your prompt.',
  //         onConfirm: async () => {
  //           // Default action or another specific action
  //           handleResetCampaign()
  //           setOpenAreYouSureModal(false)
  //         },
  //         onClose: () => {
  //           setOpenAreYouSureModal(false)
  //           setCurrentKey('4')
  //         },
  //       }

  return (
    <div className={styles.CampaignGenerateReport}>
      {!showPrompt && (
        <div className={styles.initialContainer}>
          <div className={styles.title}>
            <img src={stars} alt='' />
            <p style={{ fontSize: '18px', color: '#091E4299', margin: 0 }}>How do you want to start?</p>
          </div>
          <div className={styles.cards}>
            <SelectableCards onSetupWithAI={handleSetupWithAI} onSetupManually={handleSetupManually} />
          </div>
        </div>
      )}
      <div>
        {showPrompt && (
          <>
            <div className={styles.title}>
              <img src={aILogo} alt='' />
              <p style={{ fontSize: '18px', color: '#091E4299', margin: 0 }}>What would you like to Achieve?</p>
            </div>
            <div className={styles.textAreaDesign}>
              <div className={`${styles.textAreaBodyDesign} ${styles.textAreaBodyDesignContent}`}>
                <h1>I want to generate</h1>
                <h1 className={styles.cardDetailTitle}>
                  {saved_prompt_params?.campaign_type}
                  {select_custom_template?.name && ` (${select_custom_template?.name})`}
                </h1>
                <h1>campaign with</h1>
                <span>
                  <Select
                    // suffixIcon={<img src={dropDown} alt='' />}
                    mode='multiple'
                    className='autoComplete'
                    style={{ fontSize: '12px', minWidth: 280 }}
                    id={'campaignwith'}
                    options={personasValues}
                    onChange={(arr) => handleSaveSelect(arr, 'target_persona')}
                    placeholder='Who is the persona you are targeting?'
                    allowClear={false}
                    dropdownMatchSelectWidth={true}
                    defaultValue={splitStringToArray(saved_prompt_params?.target_persona)}
                    value={splitStringToArray(saved_prompt_params?.target_persona)}
                    filterOption={(inputValue, option) =>
                      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </span>
                <h1>In</h1>
                <span>
                  <Select
                    // suffixIcon={<img src={dropDown} alt='' />}
                    mode='multiple'
                    className='autoComplete'
                    id={'in'}
                    options={industryValues}
                    onChange={(arr) => handleSaveSelect(arr, 'industry')}
                    placeholder='What type of companies/industry'
                    allowClear={false}
                    defaultValue={splitStringToArray(saved_prompt_params?.industry)}
                    value={splitStringToArray(saved_prompt_params?.industry)}
                    filterOption={(inputValue, option) =>
                      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </span>
                <h1>Located In</h1>
                <span>
                  <Select
                    // suffixIcon={<img src={dropDown} alt='' />}
                    mode='multiple'
                    className='autoComplete'
                    id={'LocatedIn'}
                    options={countreis}
                    onChange={(arr) => handleSaveSelect(arr, 'location')}
                    placeholder='Where they are located?'
                    allowClear={false}
                    defaultValue={splitStringToArray(saved_prompt_params?.location)}
                    value={splitStringToArray(saved_prompt_params?.location)}
                    filterOption={(inputValue, option) =>
                      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    // defaultValue={splitStringToArray(saved_prompt_params?.location)}
                  />
                </span>
                <h1>That we can help them to</h1>
                <AutoComplete
                  className='autoComplete'
                  defaultValue={saved_prompt_params?.goal}
                  value={saved_prompt_params?.goal}
                  id={'helpthem'}
                  onChange={(text) => handleSaveAutocomplete(text, 'goal')}
                  placeholder='What you help them achieve/do'
                  allowClear={false}
                />
                <h1>By</h1>
                <AutoComplete
                  className='autoComplete'
                  defaultValue={saved_prompt_params?.expertise}
                  value={saved_prompt_params?.expertise}
                  id={'by'}
                  onChange={(text) => handleSaveAutocomplete(text, 'expertise')}
                  placeholder='How you help them achieve/do'
                  allowClear={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <CustomCollapseFooter id='3' onBack={onBack} onNext={onNext} onSkip={onSkip} onReset={handleReset} />
      {/* <AreYouSureModal
        open={openAreYouSureModal}
        onClose={modalContent.onClose}
        onConfirm={modalContent.onConfirm}
        title={modalContent.title}
        message={modalContent.message}
        confirmText={'I understand'}
      /> */}
    </div>
  )
}

export default CampaignType
