import React from 'react'
import { useReactFlow } from 'reactflow'
import center_icon from 'common/assets/svg/center_icon.svg'
import zoom_in_icon from 'common/assets/svg/zoom_in_icon.svg'
import zoom_out_icon from 'common/assets/svg/zoom_out_icon.svg'
import reset_flow_icon from 'common/assets/svg/reset_flow_icon.svg'
import styles from './CustomControls.module.scss'
// import { DeleteOutlined } from '@ant-design/icons'

interface CustomControlsProps {
  onReset?: any
}

const CustomControls: React.FC<CustomControlsProps> = ({ onReset }) => {
  const { zoomIn, zoomOut }: any = useReactFlow()
  const { fitView }: any = useReactFlow()
  return (
    <div className={styles.controls} style={{ width: onReset ? '150px' : '120px' }}>
      <img src={zoom_in_icon} alt='' onClick={zoomIn} />
      <img src={zoom_out_icon} alt='' onClick={zoomOut} />
      <img src={center_icon} alt='' onClick={fitView} />
      {onReset && <img src={reset_flow_icon} alt='' onClick={onReset} />}
    </div>
  )
}

export default CustomControls
