interface PlatformData {
  [key: string]: {
    read: number
    groups: number
    unread: number
    sent: number
    replied: number
    readSent: number
    readReplied: number
    groupsSent: number
    groupsReplied: number
    unreadSent: number
    unreadReplied: number
  }
}

interface DialogsData {
  [platform: string]: {
    accountsReadUnreadSentReplied?: PlatformData
  }
}

export function getAggregatedData(dialogsData: DialogsData, selected_account: any, selected_chat_platform: string) {
  let accountsReadUnreadSentReplied: any = {}

  if (selected_account?.account_id === 'view_all_channels') {
    for (const platform in dialogsData) {
      const platformData: any = dialogsData[platform]?.accountsReadUnreadSentReplied
      if (platformData) {
        for (const key in platformData) {
          if (!accountsReadUnreadSentReplied[key]) {
            accountsReadUnreadSentReplied[key] = {}
          }
          for (const subKey in platformData[key]) {
            if (accountsReadUnreadSentReplied[key][subKey]) {
              accountsReadUnreadSentReplied[key][subKey] += platformData[key][subKey]
            } else {
              accountsReadUnreadSentReplied[key][subKey] = platformData[key][subKey]
            }
          }
        }
      }
    }
  } else {
    accountsReadUnreadSentReplied = dialogsData?.[selected_chat_platform]?.accountsReadUnreadSentReplied ?? {}
  }

  return accountsReadUnreadSentReplied
}

export function getMessageCounts(
  accountsReadUnreadSentReplied: PlatformData,
  selected_account: any,
  filter: string,
  type: string,
) {
  let sentMessagesCount
  let repliedMessagesCount

  const dataKey =
    selected_account?.account_id === 'all_data' || selected_account?.account_id === 'view_all_channels'
      ? 'all_data'
      : selected_account[type]

  if (filter === 'read') {
    sentMessagesCount = accountsReadUnreadSentReplied[dataKey]?.readSent
    repliedMessagesCount = accountsReadUnreadSentReplied[dataKey]?.readReplied
  } else if (filter === 'unread') {
    sentMessagesCount = accountsReadUnreadSentReplied[dataKey]?.unreadSent
    repliedMessagesCount = accountsReadUnreadSentReplied[dataKey]?.unreadReplied
  } else if (filter === 'groups') {
    sentMessagesCount = accountsReadUnreadSentReplied[dataKey]?.groupsSent
    repliedMessagesCount = accountsReadUnreadSentReplied[dataKey]?.groupsReplied
  } else {
    sentMessagesCount = accountsReadUnreadSentReplied[dataKey]?.sent
    repliedMessagesCount = accountsReadUnreadSentReplied[dataKey]?.replied
  }

  return { sentMessagesCount, repliedMessagesCount }
}

export function getReadUnreadSentRepliedCounts(
  dialogsData: DialogsData,
  selected_account: any,
  selected_chat_platform: string,
) {
  let accountsReadUnreadSentReplied: any = {}

  if (selected_account?.account_id === 'view_all_channels') {
    for (const platform in dialogsData) {
      const platformData: any = dialogsData[platform]?.accountsReadUnreadSentReplied
      if (platformData) {
        for (const key in platformData) {
          if (!accountsReadUnreadSentReplied[key]) {
            accountsReadUnreadSentReplied[key] = {}
          }
          for (const subKey in platformData[key]) {
            accountsReadUnreadSentReplied[key][subKey] =
              (accountsReadUnreadSentReplied[key][subKey] || 0) + platformData[key][subKey]
          }
        }
      }
    }
  } else {
    accountsReadUnreadSentReplied = dialogsData?.[selected_chat_platform]?.accountsReadUnreadSentReplied ?? {}
  }

  return accountsReadUnreadSentReplied
}

export function getMessageCountsForTabs(
  accountsReadUnreadSentReplied: PlatformData,
  selected_account: any,
  filter: string,
  type: string,
) {
  const dataKey =
    selected_account?.account_id === 'all_data' || selected_account?.account_id === 'view_all_channels'
      ? 'all_data'
      : selected_account[type]

  const counts = {
    read: accountsReadUnreadSentReplied[dataKey]?.read || 0,
    groups: accountsReadUnreadSentReplied[dataKey]?.groups || 0,
    unread: accountsReadUnreadSentReplied[dataKey]?.unread || 0,
  }

  return counts
}
