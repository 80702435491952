import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
// import styles from './FlowPreviewStrip.module.scss'
// import Enrich_img from 'common/assets/png/Enrich_img.png'
import { useAppDispatch, useAppSelector } from 'state'
import { Carousel } from 'antd'
import { SET_NODES_DATA, SET_TRIGGER } from '../state/outreachAICampaignSlice'
import {
  SET_BUILDER_DATA,
  SET_BUILDER_NODES,
  SET_UPDATED_BUILDER_DATA,
  SET_UPDATED_BUILDER_NODES,
} from '../Builder/Slice/CampaignBuilderSlice'
import { getSenderByPlatform } from 'common/utils/campaignHelpers'

const FlowPreviewStrip: React.FC = () => {
  const { nodes_data, selected_node, selected_platforms_accounts, trigger } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const [allMessagesWithTypeAndPlace, setAllMessagesWithTypeAndPlace] = useState([])
  const { builder_data, builder_nodes } = useAppSelector((state) => state.CampaignBuilder)
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useAppDispatch()

  const contentStyle: React.CSSProperties = {
    height: '120px',
    color: '#7043ff',
    // lineHeight: '30px',
    textAlign: 'left',
    background: 'white',
    // background: 'red',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // margin: 0,
    padding: '0px 30px',
    // borderRadius: '12px',
  }

  const mappingFunc = (type: string) => {
    const typeMapping: any = {
      comment_on_photo: 'Comment',
      comment_on_story: 'Comment',
      comment_on_post: 'Comment',
      send_message: 'Message',
      connect_lead_with_note: 'Message',
    }
    return typeMapping[type] || type
  }
  // const allMessagesWithTypeAndPlace = nodes_data.reduce((acc: any, node: any, index: any) => {
  //   if (node.data && node.data.messages && node.step_id === selected_node.id) {
  //     const messagesWithTypeAndPlace = node.data.messages.map((message: any, messageIndex: any) => ({
  //       message,
  //       type: mappingFunc(node?.type),
  //       place: String.fromCharCode(65 + messageIndex),
  //       step_id: node?.step_id,
  //     }))
  //     acc.push(...messagesWithTypeAndPlace)
  //   }
  //   return acc
  // }, [])

  useEffect(() => {
    const arr = nodes_data.reduce((acc: any, node: any, index: any) => {
      if (node.data && node.data.messages && node.step_id === selected_node.id) {
        const messagesWithTypeAndPlace = node.data.messages.map((message: any, messageIndex: any) => ({
          message,
          type: mappingFunc(node?.type),
          place: String.fromCharCode(65 + messageIndex),
          step_id: node?.step_id,
        }))
        acc.push(...messagesWithTypeAndPlace)
      }
      return acc
    }, [])
    setAllMessagesWithTypeAndPlace(arr)
  }, [nodes_data, selected_node])

  const handleUpdateNodeData = (step_id: any, place: any, newMessage: any) => {
    const indexes: any = { A: 0, B: 1, C: 2 }
    const index = indexes[place]
    const messages = nodes_data.filter((n: any) => n.step_id === step_id)?.[0]?.data?.messages
    const updatedMessages = [...messages]
    updatedMessages[index] = newMessage

    // const filteredMessage = updatedMessages.filter((m: string) => m && m.trim() !== '')

    const allSenders = getSenderByPlatform(selected_platforms_accounts)

    const updatedNodesData = nodes_data.map((node: any) => {
      const newNode = { ...node }
      if (node.step_id === step_id) {
        newNode.data = { ...newNode.data, messages: updatedMessages, sender: allSenders }
      }
      return newNode
    })

    dispatch(SET_NODES_DATA(updatedNodesData))
    if (trigger < 1) {
      dispatch(SET_TRIGGER(trigger + 1))
    }

    if (builder_data?.nodes?.length > 0) {
      const updatedNodes = builder_data?.nodes?.map((node: any) => {
        const newNode = { ...node }
        if (node.id === step_id) {
          newNode.data = { ...newNode.data, messages: updatedMessages, sender: allSenders }
        }
        return newNode
      })
      dispatch(SET_BUILDER_DATA({ nodes: updatedNodes, edges: builder_data.edges }))
      dispatch(SET_UPDATED_BUILDER_DATA({ nodes: updatedNodes, edges: builder_data.edges }))

      const builderNodes = builder_nodes?.map((node: any) => {
        const newNode = { ...node }
        if (node.id === step_id) {
          newNode.data = { ...newNode.data, messages: updatedMessages, sender: allSenders }
        }
        return newNode
      })
      dispatch(SET_BUILDER_NODES(builderNodes))
      dispatch(SET_UPDATED_BUILDER_NODES(builderNodes))
    }
  }

  if (allMessagesWithTypeAndPlace?.length === 0) return <></>

  if (mappingFunc(selected_node?.data?.type) !== 'Message' && mappingFunc(selected_node?.data?.type) !== 'Comment') {
    return <></>
  }

  return (
    <Carousel className='flowPreviewCarousel' dotPosition='left'>
      {allMessagesWithTypeAndPlace.map((item: any, index: any) => {
        return (
          <div key={index}>
            <div style={contentStyle}>
              <span style={{ fontWeight: 600, marginLeft: '3px', marginBottom: '7px' }}>
                {item.type} {item.place}
              </span>
              <Input.TextArea
                autoSize={isFocused ? { minRows: 3, maxRows: 3 } : { minRows: 1, maxRows: 1 }}
                onChange={(e) => handleUpdateNodeData(item.step_id, item.place, e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                defaultValue={item.message || ''}
                value={item.message || ''}
                size='large'
                style={{
                  borderColor: !item.message ? 'red' : undefined,
                  // Apply additional styles as needed
                }}
              />
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}

export default FlowPreviewStrip

// <div className={styles.container}>
//   <div className={styles.input}>
//     <Input />
//   </div>
//   <div className={styles.text}>
//     <p>There are 4 more options with similar success rates!</p>
//     <img src={Enrich_img} alt='' />
//   </div>
// </div>
