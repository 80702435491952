import React from 'react'
import { Tabs, Button } from 'antd'
import styles from './CampaignSummarySidebar.module.scss'
import ViewLeadsList from './ViewLeadsList'
import CampaignSummaryCanvas from './CampaignSummaryCanvas'
// import { useLocation } from 'react-router-dom'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
// import ConnectPlusOnBoarding from 'common/components/ConnectPlus/ConnectPlusOnBoarding'

// const operations = (
//   <Button
//     onClick={() => {
//       navigate('/outreach/create-new-campaign/edit-manually')
//     }}>
//     Edit
//   </Button>
// )
const CampaignSummaryContent: React.FC = () => {
  // window.location.pathname.split('/')
  // const locationData = window.location.pathname.split('/')
  // const location_campaign_id = locationData[locationData.length - 1]
  const { navigateWithCampaignId } = useCampaignNavigation()
  // const location = useLocation()
  // const params = new URLSearchParams(location.search)
  // const urlCampaignId = params.get('campaign_id')

  const operations = (
    <Button
      onClick={() => {
        navigateWithCampaignId(`/outreach/create-new-campaign/edit-manually/view`, { state: { prevPath: true } })
      }}>
      Edit
    </Button>
  )
  return (
    <div className={`${styles['CampaignSummaryContent']} flex flex-column relative overflow-auto max-h-full`}>
      <Tabs defaultActiveKey='1' tabBarExtraContent={operations} className='CampaignSummaryContent_tabs'>
        <Tabs.TabPane tab='View Campaign Flow' key='1' style={{ position: 'relative' }}>
          <CampaignSummaryCanvas />
        </Tabs.TabPane>
        <Tabs.TabPane tab='View Leads List' key='2'>
          <ViewLeadsList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default CampaignSummaryContent
