import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { post } from 'common/api/axios'

import { useAuth } from 'common/hooks/useAuth.hooks'
import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'
import send_message from '../../../common/assets/svg/send_message.svg'
import SendMessageModal from './SendMessageModal'
// import ConnectPlusModal from 'common/components/ConnectPlus/ConnectPlusModal'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'

import { useTelegram } from 'common/providers/TelegramProvider'
import CooldownModal from './CooldownModal'
import { useAppSelector } from 'state'
// import ConnectPlusErrorModal from 'common/components/ConnectPlus/ConnectPlusErrorModal'

const SendMessage = ({ contactsList }: any) => {
  const { is_send_message } = useAppSelector((state) => state.contacts)

  const [open, setOpen] = useState(false)
  const [actionsPaywallOpen, setActionsPaywallOpen] = useState(false)

  const { cooldown, cooldownSendMessages, setCooldownSendMessages, setCooldown, getCooldown } = useTelegram()
  const { userDetails } = useAuth()

  const disabled = !contactsList?.filter(({ telegram_account }: any) => !!telegram_account)?.length

  const styleImg = { filter: 'grayscale(100%)', opacity: '0.5' }
  const styleButton = { backgroundColor: '#7043ff', color: 'white' }

  const user = localStorage.getItem('user')
  const userData = JSON.parse(user || '{}')
  const outreach = userData?.outreach

  const handleOpen = () => {
    if (!userDetails.addons['outreach']) return setActionsPaywallOpen(true)
    post('/log', {
      action: 'Sending message dialog opened',
    })

    setOpen(true)
  }

  const handleClose = () => {
    getCooldown()
    setOpen(false)
  }

  useEffect(() => {
    if (cooldownSendMessages) {
      setCooldown(true)
      setOpen(true)
      setCooldownSendMessages(false)
    }
  }, [cooldownSendMessages])

  const OutreachModal = cooldown ? CooldownModal : outreach.length !== 0 ? SendMessageModal : OutreachConnection
  //  userDetails?.subscription_type === 'PREMIUM'
  // ? ConnectPlusErrorModal
  // : OutreachConnection

  return (
    <>
      <Button
        className='flex align-items-center gap-2'
        style={disabled ? {} : styleButton}
        disabled={disabled}
        loading={is_send_message}
        onClick={handleOpen}>
        {!is_send_message && (
          <img style={disabled ? styleImg : { filter: 'brightness(0) invert(1)' }} src={send_message} alt='' />
        )}
        {'Send Message'}
      </Button>
      {actionsPaywallOpen ? (
        <Modal
          open={actionsPaywallOpen}
          onCancel={() => setActionsPaywallOpen(false)}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <BlurPaywall addon={'Telegram messaging'} component={[]} customClass='contactSalesNoMargin' />
          </div>
        </Modal>
      ) : (
        open && <OutreachModal open={open} onClose={handleClose} contactsList={contactsList} />
      )}
    </>
  )
}

export default SendMessage
