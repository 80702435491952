import React from 'react'
import { Layout, Spin } from 'antd'
import PageHeader from './PageHeader'
import CampaignSummarySidebar from './CampaignSummarySidebar'
import CampaignSummaryContent from './CampaignSummaryContent'
// import { useAppDispatch, useAppSelector } from 'state'
// import styles from './CampaignSummaryCanvas.module.scss'

const { Content } = Layout

interface CampaignSummaryViewProps {
  isLoading?: boolean
}

const CampaignSummary: React.FC<CampaignSummaryViewProps> = ({ isLoading }) => {
  // const { loading_generate_campaign } = useAppSelector((state) => state.outreachAICampaign)
  const layoutStyle = {
    overflow: 'hidden',
    height: '100%',
    paddingTop: '0px',
    paddingLeft: '0px',
    borderTop: '1px solid #ebecf0',
  }

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  }

  return (
    <Layout>
      <PageHeader />
      <Content>
        <Layout className='flex flex-row' style={layoutStyle}>
          {isLoading && (
            <div style={overlayStyle}>
              <Spin size='large' />
            </div>
          )}
          <CampaignSummarySidebar />
          <CampaignSummaryContent />
        </Layout>
      </Content>
    </Layout>
  )
}

export default CampaignSummary
