import React, { useEffect, useState } from 'react'
import { Modal, Button, Layout, Divider, Tabs } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'
import {
  useLazyGetGroupDetailsQuery,
  // useLazyGetGroupsQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import GroupsSidebar from './GroupsSidebar'
import GroupsContent from './GroupsContent'
import GroupsFilters from './GroupsFilters'
import GroupsTable from './GroupsTable'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_SELECTED_LEADS,
} from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useLazySaveCampaignLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
// import { v4 as uuidv4 } from 'uuid'

const { Header } = Layout
const { TabPane } = Tabs

const sortData = (data: any[], sortKey: string, ascending: boolean = true) => {
  if (!data) return []
  return data?.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) return ascending ? -1 : 1
    if (a[sortKey] > b[sortKey]) return ascending ? 1 : -1
    return 0
  })
}

// const filterOutExistingGroups = (requestedGroups: any[], displayedGroups: any[]) => {
//   const displayedGroupNames = new Set(displayedGroups?.map((group: any) => group.name?.toLowerCase()))

//   return requestedGroups?.filter((group: any) => !displayedGroupNames?.has(group.name?.toLowerCase()))
// }

const filterOutExistingGroups = (requestedGroups: any[], displayedGroups: any[]) => {
  const displayedGroupKeys = new Set(
    displayedGroups?.map((group: any) => `${group.platform?.toLowerCase()}-${group.name?.toLowerCase()}`),
  )

  return requestedGroups?.filter(
    (group: any) => !displayedGroupKeys.has(`${group.platform?.toLowerCase()}-${group.name?.toLowerCase()}`),
  )
}

interface ImportLeadsFromGroupsModalProps {
  open: boolean
  onClose: () => void
  isLoading: boolean
}

// const groupsData1 = Array.from({ length: 8 }, (_, categoryIndex) => ({
//   category: `Test Category ${categoryIndex + 1}`,
//   data: Array.from({ length: 1 + categoryIndex }, (_, dataIndex) => ({
//     name: dataIndex === 0 ? 'Secret TLV' : 'Group name',
//     leads: 2385,
//     platform:
//       dataIndex % 6 === 0
//         ? 'Facebook'
//         : dataIndex % 6 === 1
//         ? 'Instagram'
//         : dataIndex % 6 === 2
//         ? 'Telegram'
//         : dataIndex % 6 === 3
//         ? 'Linkedin'
//         : dataIndex % 6 === 4
//         ? 'Discord'
//         : 'Twitter',
//     locked: dataIndex % 2 === 0,
//     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
//     tags: ['Category name', 'Tag 2', 'Tag 3'],
//   })),
// }))

const ImportLeadsFromGroupsModal: React.FC<ImportLeadsFromGroupsModalProps> = ({ open, onClose, isLoading }) => {
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { initial_group_data: groupsData } = useAppSelector((state) => state.outreachAICampaign)
  const [getGroupDetails, { isFetching: isGroupDataFetching }] = useLazyGetGroupDetailsQuery()
  const [groupData, setGroupData] = useState<any>()
  const [loadingLeads, setLoadingLeads] = useState(false)
  const [showNewLeads, setShowNewLeads] = useState(false)
  // const [searchTerm, setSearchTerm] = useState('a')
  // const [groupsData, setGroupsData] = useState([])
  // const [selectedGroups, setSelectedGroups] = useState<any[]>([])
  // const [excludePresent, setExcludePresent] = useState(false)
  // const [excludeSent, setExcludeSent] = useState(false)
  // const areGroupsExists = groupsData?.some((group: any) => group.type === null || group.type === 'ChatType.SUPERGROUP')
  // const areChannelsExists = groupsData?.some((group: any) => group.type === 'ChatType.CHANNEL')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedChannel, setSelectedChannel] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const { selected_leads, active_platforms, campaign_id } = useAppSelector((state) => state.outreachAICampaign)
  const [filteredData, setFilteredData] = useState(groupData)
  const dispatch = useAppDispatch()
  const [activeTabKey, setActiveTabKey] = useState('1')

  // For now. Change when implementing multi-channel campaigns
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
  const selectedPlatform = truePlatformsArray?.[0]

  const handleCategoryChange = (category: string) => {
    setSelectedChannel('')
    setSelectedCategory((prevCat) => (prevCat === category ? '' : category))
  }

  const handleChannelChange = (channel: string) => {
    setSelectedCategory('')
    setSelectedChannel((prevChannel) => (prevChannel === channel ? '' : channel))
  }

  const onBack = () => {
    setSelectedGroup('')
    setSelectedGroupName('')
    setGroupData([])
  }

  const getGroupsByPlatform = (platform: string, groupsData: any) => {
    return groupsData.data?.filter((group: any) => group.platform.toLowerCase() === platform.toLowerCase())
    // return {
    //   category: platform,
    //   data:,

    //   myGroups: groupsData.flatMap((groupData: any) => groupData?.myGroups),
    //   requestedGroups: groupsData.flatMap((groupData: any) =>
    //     groupData?.requestedGroups.filter((group: any) => group.platform.toLowerCase() === platform.toLowerCase()),
    //   ),
    // }
  }

  const displayedGroups = selectedChannel
    ? getGroupsByPlatform(selectedChannel, groupsData)
    : groupsData?.data?.filter((data: any) => !selectedCategory || data.tags?.includes(selectedCategory))

  const handleAddLeads = async () => {
    setLoadingAdd(true)
    const selectedData = groupData.filter((d: any) => selectedRowKeys.includes(d.key))
    const newLeadsToAdd = selectedData.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    dispatch(SET_SELECTED_LEADS(newLeadsToAdd))

    await saveCampaignLeads({
      leads: newLeadsToAdd,
      campaign_id: campaign_id,
      mode: 'groups',
    })
    setSelectedRowKeys([])
    setLoadingAdd(false)
    onClose()
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    let filtered = groupData

    if (keywords.length > 0) {
      filtered = filtered.filter((group: any) =>
        keywords.some((keyword: any) => group.biography?.toLowerCase().includes(keyword?.toLowerCase())),
      )
    }

    if (conditionedKeywords.length > 0) {
      if (operator === 'AND') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => group.biography?.toLowerCase().includes(keyword?.toLowerCase())),
        )
      } else if (operator === 'AND NOT') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => !group.biography?.toLowerCase().includes(keyword?.toLowerCase())),
        )
      }
    }

    setFilteredData(filtered)
  }

  const handleOnLeads = async () => {
    if (showNewLeads === false) {
      setShowNewLeads(true)
      setLoadingLeads(true)
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: true })
      setGroupData(data)
      setFilteredData(data)
      setLoadingLeads(false)
    } else {
      setShowNewLeads(false)
      const { data } = await getGroupDetails({ group: selectedGroup })
      setGroupData(data)
      setFilteredData(data)
    }
  }

  // useEffect(() => {
  //   const getInitGroups = async (searchTerm: any) => {
  //     const { data } = await getGroups({ group_name: searchTerm, title: searchTerm })
  //     setGroupsData(data.response)
  //   }
  //   getInitGroups(searchTerm)
  // }, [searchTerm, getGroups])

  useEffect(() => {
    const groupDetails = async () => {
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: showNewLeads })
      setGroupData(data)
      setFilteredData(data)
    }

    if (selectedGroup) {
      groupDetails()
    }
  }, [selectedGroup])

  const headerStyle: React.CSSProperties = {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    height: 80,
    paddingInline: 24,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #DFE1E6',
    alignContent: 'center',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  return (
    <Modal className='groupsModal' centered closable={false} open={open} footer={null} onCancel={onClose} width='87%'>
      {selectedGroup ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                Add Leads From {selectedGroupName} <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {selectedRowKeys?.length || 0} leads <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onBack}>
                  Back
                </span>
                <Button loading={loadingAdd} type='primary' onClick={handleAddLeads} disabled={isGroupDataFetching}>
                  Add Leads
                </Button>
              </div>
            </div>
          </Header>
          <Layout>
            <GroupsTable
              selectedGroup={selectedGroupName}
              data={filteredData}
              isLoading={isGroupDataFetching}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              onLeads={handleOnLeads}
              loadingLeads={loadingLeads}
              showNewLeads={showNewLeads}
            />
            <GroupsFilters onFilterChange={handleFilterChange} />
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>Social Hub</span>
              <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                <TabPane tab='Convrt Market' key='1' />
                <TabPane
                  // disabled
                  tab={<span>My Hub</span>}
                  key='2'
                />
              </Tabs>
              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <GroupsSidebar
              categories={groupsData?.categories?.[selectedPlatform]}
              onCategoryChange={handleCategoryChange}
              onChannelChange={handleChannelChange}
              myGroups={activeTabKey === '2'}
            />
            <GroupsContent
              groupsData={sortData(displayedGroups, 'name', true)}
              selectedCategory={selectedCategory}
              setSelectedGroup={setSelectedGroup}
              setSelectedGroupName={setSelectedGroupName}
              myGroupsTab={activeTabKey === '2'}
              myGroupsData={groupsData?.myGroups?.filter((group: any) => group?.trackMode)}
              // requestedGroups={groupsData?.requestedGroups}
              requestedGroups={filterOutExistingGroups(groupsData?.requestedGroups, displayedGroups)}
            />
          </Layout>
        </Layout>
      )}
    </Modal>
  )
}

export default ImportLeadsFromGroupsModal
