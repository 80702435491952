import React, { useEffect, useState } from 'react'
import { Layout, Menu, message } from 'antd'
import { useLocation } from 'react-router-dom'
import {
  ProfileOutlined,
  NotificationOutlined,
  DollarCircleOutlined,
  // TeamOutlined,
  AppstoreAddOutlined,
  ThunderboltOutlined,
  RocketOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import styles from './SettingsPage.module.scss'
import ProfileSettings from './Pages/ProfileSettings'
import SocialSettings from './Pages/SocialSettings'
import Categorizer from './Pages/Categorizer'
import SubscriptionSettings from './Pages/SubscriptionSettings'
import {
  useLazyGetPaymentDetailsQuery,
  useLazyGetSalesforceAccessTokenQuery,
  useLazyGetSalesforceKeysQuery,
  useLazyGetSlackKeysQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetUserSettingsQuery,
  useLazyGetUsersListQuery,
  useLazyRedirectSlackOAuthQuery,
} from './state/api/SettingsApi'
import AdminSettings from './Pages/AdminSettings'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import useHubspot from './Pages/Hooks/useHubspot'
import ICPSettings from './Pages/ICPSettings'

const { Sider, Content } = Layout

const Settings: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [redirectSlackOAuth] = useLazyRedirectSlackOAuthQuery()
  const [getSalesforceAccessToken] = useLazyGetSalesforceAccessTokenQuery()
  const [selectedMenu, setSelectedMenu] = useState('profile')
  const [refetchGetSubscriptionSettings, { data: subscriptionData }] = useLazyGetSubscriptionSettingsQuery()
  const [getUserSettings, { data: settingsData }] = useLazyGetUserSettingsQuery()
  const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  const [refetchUsersList, { data: usersList }] = useLazyGetUsersListQuery()
  const { userDetails, updateUserDetails } = useAuth()
  const { company_admin } = userDetails
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getSlackKeys] = useLazyGetSlackKeysQuery()
  const [getSalesforceKeys] = useLazyGetSalesforceKeysQuery()
  const [slackKey, setSlackKey] = useState('')
  const [salesforceKey, setSalesforceKey] = useState('')
  const { existKey } = useHubspot()

  const handleMenuClick = (selectedKey: string) => {
    setSelectedMenu('')
    setTimeout(() => {
      setSelectedMenu(selectedKey)
    }, 1)
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
    }

    const getSubscriptionSettings = async () => {
      await refetchGetSubscriptionSettings()
    }
    const getPaymentDetails = async () => {
      await refetchGetPaymentDetails()
    }

    const getUsersList = async () => {
      await refetchUsersList()
    }

    const userSettings = async () => {
      await getUserSettings()
    }

    getUserDetails()
    getSubscriptionSettings()
    getPaymentDetails()
    getUsersList()
    updateUserDetails()
    userSettings()
  }, [])

  useEffect(() => {
    const getKeys = async () => {
      const { data: slackData } = await getSlackKeys()
      if (slackData?.access_token) {
        setSlackKey(slackData?.access_token)
        localStorage.setItem('slack_access_token', slackData.access_token)
      }

      const { data: salesforceData } = await getSalesforceKeys()
      if (salesforceData?.access_token) {
        setSalesforceKey(salesforceData?.access_token)
        localStorage.setItem('salesforce_access_token', salesforceData.access_token)
      }
    }
    getKeys()
  }, [])

  const redirectSlack = async (code: any, state?: any) => {
    const { data, error } = await redirectSlackOAuth({ code })
    if (data?.connected) {
      setSlackKey(data.access_token)
      message.success(data?.message)
    } else if (error?.data?.error === 'invalid_code') {
      message.error('Invalid Session')
    } else {
      message.error('Something went wrong connecting with Slack. Please try again.')
    }
  }

  const connectSalesforce = async (code: any) => {
    const codeVerifier = localStorage.getItem('salesforce_code_verifier')
    const { data, error } = await getSalesforceAccessToken({ codeVerifier: codeVerifier, authCode: code })

    if (data?.connected) {
      setSalesforceKey(data.access_token)
      message.success(data?.message)
    } else if (error?.data?.message === 'User already connected to Salesforce') {
      // message.error('Invalid Session')
    } else {
      message.error('Something went wrong connecting with Salesforce. Please try again.')
    }
  }

  const resetKeysStates = (state: string) => {
    switch (state) {
      case 'slack':
        setSlackKey('')
        break
      case 'salesforce':
        setSalesforceKey('')
        break
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const path = window.location.pathname
    const slack = localStorage.getItem('slack_access_token')
    const salesforce = localStorage.getItem('salesforce_access_token')

    if (code && path.includes('slack') && !slack) {
      redirectSlack(code)
    } else if (code && path.includes('salesforce') && !salesforce) {
      connectSalesforce(code)
    }
  }, [location.search])

  return (
    <Layout className={styles.settingsLayout}>
      <Sider width={260} theme='light' className={styles.sidebar}>
        <div className={styles.sidebarHeader}>Settings</div>
        <Menu
          mode='inline'
          style={{ paddingRight: '20px' }}
          selectedKeys={[selectedMenu]}
          onClick={({ key }) => handleMenuClick(key.toString())}>
          <Menu.ItemGroup key='account' title='Account' style={{ marginTop: '20px' }}>
            <Menu.Item key='profile' icon={<ProfileOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key='notifications' icon={<NotificationOutlined />}>
              Social & Integrations
            </Menu.Item>
            {
              <Menu.Item key='categorizer' icon={<AppstoreAddOutlined />}>
                Categorizer
              </Menu.Item>
            }
            <Menu.Item key='icp' icon={<TeamOutlined />}>
              ICP Persona
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key='admin' title='Admin' style={{ marginTop: '20px' }}>
            <Menu.Item key='subscription' icon={<DollarCircleOutlined />}>
              Subscriptions & Billing
            </Menu.Item>
            {company_admin && (
              <Menu.Item key='companyAdmin' icon={<RocketOutlined />}>
                {userDetails.company}'s Cockpit
              </Menu.Item>
            )}
            {!shouldHideCheckout && (
              <Menu.Item
                key='checkout'
                icon={<ThunderboltOutlined />}
                onClick={() => {
                  navigate('/checkout')
                }}>
                Upgrade
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      <Layout>
        <Content className={styles.content}>
          {/* Content for each setting page goes here */}
          {selectedMenu === 'profile' && <ProfileSettings shouldHideCheckout={shouldHideCheckout} />}
          {selectedMenu === 'notifications' && (
            <SocialSettings
              shouldHideCheckout={shouldHideCheckout}
              hubspotKey={existKey}
              slackKey={slackKey}
              salesforceKey={salesforceKey}
              resetKeysStates={resetKeysStates}
            />
          )}
          {selectedMenu === 'categorizer' && <Categorizer shouldHideCheckout={shouldHideCheckout} />}
          {selectedMenu === 'icp' && <ICPSettings data={settingsData?.ICP} />}
          {selectedMenu === 'subscription' && (
            <SubscriptionSettings
              shouldHideCheckout={shouldHideCheckout}
              data={subscriptionData}
              paymentData={paymentData}
            />
          )}
          {selectedMenu === 'companyAdmin' && (
            <AdminSettings shouldHideCheckout={shouldHideCheckout} data={usersList} paymentData={paymentData} />
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
