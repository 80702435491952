import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

import animationData from '../../../common/assets/lottie-animations/Endless.json'

const SpinnerAnimation = () => {
  const lottieContainerRef = useRef(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        loop: true,
        renderer: 'svg',
      })
    }
  }, [])

  return <div ref={lottieContainerRef} />
}

export default SpinnerAnimation
