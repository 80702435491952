import React from 'react'
import { Modal, Button, message } from 'antd'

import removeIcon from 'common/assets/svg/remove_categorizer.svg'
import styles from './AddCategorizer.module.scss'

import { useRemoveCategoryMutation } from '../../../state/api/SettingsApi'

export const RemoveCategorizer = ({ open, onClose, data = {}, onChange }: any) => {
  const { category_name, index } = data

  const [removeCategory, { isLoading }] = useRemoveCategoryMutation()

  const handleRemove = async () => {
    try {
      await removeCategory({ index }).unwrap()

      onChange(data)
      message.success(`${category_name} deleted successfully!`)
      onClose()
    } catch (error) {
      message.error('Failed to delete category. Please try again.')
      console.error(error)
    }
  }

  return (
    <Modal
      className={styles.modalRemove}
      style={{ borderRadius: '24px', textAlign: 'center' }}
      okButtonProps={{ className: 'hidden' }}
      cancelButtonProps={{ className: 'hidden' }}
      centered
      open={open}
      onCancel={onClose}
      width={300}>
      <img style={{ width: '60px' }} src={removeIcon} alt='' />
      <div className={styles.removeTitle}>
        {`Delete `}
        <p>{`"${category_name}"?`}</p>
      </div>
      <div
        className={
          styles.removeNote
        }>{`Are you certain you want to proceed with removing "${category_name}". This process cannot be undone.`}</div>
      <div className={styles.footer}>
        <Button
          size={'large'}
          style={{ color: '#fff', marginBottom: '10px', backgroundColor: '#FF5C5C', borderColor: '#FF5C5C' }}
          onClick={handleRemove}
          loading={isLoading} // Show loading indicator when the mutation is in progress
          disabled={isLoading}>
          Yes, Delete
        </Button>
        <Button
          size={'large'}
          style={{ color: '#5E6C84', backgroundColor: 'white', borderColor: '#DFE1E6' }}
          onClick={onClose}
          disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default RemoveCategorizer
