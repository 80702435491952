import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './styles/global.css'
import './styles/variables.css'

import './index.css'

import { AuthProvider } from 'common/hooks/useAuth.hooks'

import { store } from './state'

import App from './App'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ConnectPlusProvider } from 'common/providers/ConnectPlusProvider'
import { IntercomProvider } from 'react-use-intercom'
import { ShowStartScreenProvider } from 'common/providers/ShowStartScreenProvider'
import { TelegramProvider } from 'common/providers/TelegramProvider'
import { Userpilot } from 'userpilot'
import { ReactFlowProvider } from 'reactflow'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
Userpilot.initialize('NX-56c5de42')

root.render(
  <GoogleOAuthProvider clientId='638589373885-4hb58pi09r7dhpdc14i1icvoj52n2mjj.apps.googleusercontent.com'>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <ConnectPlusProvider>
            <ShowStartScreenProvider>
              <TelegramProvider>
                <IntercomProvider appId='lyydfo93'>
                  <ReactFlowProvider>
                    <App />
                  </ReactFlowProvider>
                </IntercomProvider>
              </TelegramProvider>
            </ShowStartScreenProvider>
          </ConnectPlusProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
)
