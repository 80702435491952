import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import styles from './CreateNewCampaign.module.scss'
import PageHeader from './PageHeader'
import CustomCollapse from './CustomCollapse'
import FlowPreview from './FlowPreview'
import Sider from 'antd/es/layout/Sider'
import { useAppDispatch, useAppSelector } from 'state'
import { v4 as uuidv4 } from 'uuid'
import { SET_CAMPAIGN_ID_AI, SET_CAMPAIGN_SETTINGS_AI } from '../state/outreachAICampaignSlice'
import { useLazyGetCampaignScheduleQuery } from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCampaigns } from '../HomePage/CampaignsTab/Helpers/useCampaigns'
import LoadingOverlay from 'GeneralComponents/LoadingOverlay'

// import { SET_CAMPAIGN_ID } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

// import {
//   SET_CAMPAIGN_ID,

// } from '../state/outreachCampaignSlice'
// import Canvas from '../Canvas/Canvas'

const { Content } = Layout

const CreateNewCampaign: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { loading_generate_campaign, campaign_status } = useAppSelector((state) => state.outreachAICampaign)
  const [loading, setLoading] = useState(false)
  const [refetcGetCampaignSchedule] = useLazyGetCampaignScheduleQuery()

  const siderStyle: React.CSSProperties = {
    lineHeight: '120px',
    color: '#fff',
    marginLeft: '20px',
    background: 'none',
    minWidth: '600px !important',
  }

  const contentStyle: React.CSSProperties = {
    minHeight: 120,
    lineHeight: '120px',
    overflow: 'scroll',

    // background: 'none',
    // backgroundColor: 'white',
  }

  const layoutStyle = {
    overflow: 'hidden',
    height: '100%',
    paddingTop: '30px',
    paddingLeft: '30px',
  }

  // useEffect(() => {
  //   if (!campaign_id) {
  //     const uuid = uuidv4()
  //     dispatch(SET_CAMPAIGN_ID_AI(uuid))
  //     getCampaignsSchedule()
  //   }
  // }, [])

  const { getCampaignSummaryView } = useCampaigns()

  // const handleEditCampaign = async (values: any) => {
  //   const path = `/outreach/create-new-campaign/summary/${values.campaign_id}`

  //   if (values.status === 'draft') {
  //     setLoadingNavigate(true)
  //     await getCampaignSummaryView(values.campaign_id, values.status)
  //     setLoadingNavigate(false)
  //     navigate('/outreach/create-new-campaign', { state: { ...values } })
  //   } else {
  //     navigate(path, { state: { ...values } })
  //   }

  const handleGetData = async (campaign_id: string) => {
    setLoading(true)
    const status = campaign_status || 'draft'
    await getCampaignSummaryView(campaign_id, status)
    setLoading(false)
  }

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   const urlCampaignId = params.get('campaign_id')

  //   if (urlCampaignId) {
  //     dispatch(SET_CAMPAIGN_ID_AI(urlCampaignId))
  //     handleGetData(urlCampaignId)
  //     getCampaignsSchedule()
  //   } else {
  //     const newUuid = uuidv4()
  //     dispatch(SET_CAMPAIGN_ID_AI(newUuid))
  //     navigate(`?campaign_id=${newUuid}`, { replace: true })
  //     getCampaignsSchedule()
  //   }
  // }, [location.search])
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const urlCampaignId = params.get('campaign_id')
    const prevPath = location.state?.prevPath
    const summary = location.state?.summary

    if (!prevPath && !summary) {
      if (urlCampaignId) {
        dispatch(SET_CAMPAIGN_ID_AI(urlCampaignId))
        handleGetData(urlCampaignId)
        getCampaignsSchedule()
      } else {
        const newUuid = uuidv4()
        dispatch(SET_CAMPAIGN_ID_AI(newUuid))
        navigate(`?campaign_id=${newUuid}`, { replace: true })
        getCampaignsSchedule()
      }
    } else {
      window.history.replaceState({}, '')
    }
  }, []) // Depend on location.key instead of location.search

  const getCampaignsSchedule = async () => {
    const { data } = await refetcGetCampaignSchedule()
    const schedules = data?.response
    const runInBetween = schedules.map(({ schedule_id, schedule_label }: any) => ({ schedule_id, schedule_label }))
    const scheduleLabelToFilter = 'Default Schedule'
    const filteredData = runInBetween.filter((item: any) => item.schedule_label === scheduleLabelToFilter)

    dispatch(
      SET_CAMPAIGN_SETTINGS_AI({
        isDoNotSendMessagesActive: false,
        isAvoidMessagesInOtherCampaignsActive: false,
        timingCampaign: '',
        schedule_id: filteredData[0]?.schedule_id,
      }),
    )
  }

  return (
    <>
      {loading && <LoadingOverlay isLoading={loading} text='Loading data...' />}
      {/* {loading_generate_campaign ? (
        <div className={styles.loading}>
          <LoadingBox className={'loading-box-flex-direction'} text={`Preparing your campaign...`} />
        </div>
      ) : ( */}
      <Layout style={loading_generate_campaign ? { display: 'none' } : {}}>
        <PageHeader />
        <Content>
          <Layout style={layoutStyle}>
            <Content className='customScrollContent' style={contentStyle}>
              <CustomCollapse />
            </Content>
            <Sider width='500px' style={siderStyle}>
              <FlowPreview />
            </Sider>
          </Layout>
        </Content>
      </Layout>
      {loading_generate_campaign && (
        <div className={styles.loading}>
          <LoadingBox
            className={'loading-box-flex-direction'}
            text={
              <div className={styles.prepare}>
                <span>Preparing your campaign...</span>
                <p>This may take a few minutes. Please do not close this tab until the process is finished.</p>
              </div>
            }
          />
        </div>
      )}
      {/* )} */}
    </>
  )
}

export default CreateNewCampaign
