import React, { useState, useEffect } from 'react'
import { Card, Typography, Button, Layout, Table, Space, Tooltip, Empty, Skeleton } from 'antd'
import styles from './SubscriptionSettings.module.scss'
import link_logo from 'common/assets/svg/link_logo.svg'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import dayjs from 'dayjs'
import { useLazyChangeStripePaymentMethodQuery, useLazyGetStripeInvoiceByCustomerQuery } from '../state/api/SettingsApi'
import SelectPlanModal from './Components/SelectPlanModal'
import AddMoreSeatsModal from './Components/AddMoreSeatsModal'
import { DownloadOutlined } from '@ant-design/icons'

const { Title } = Typography

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  custom: 'Custom',
  trial: 'Trial',
}

// const userData = {
//   plan: 'Premium',
//   type: 'Monthly',
//   status: 'Active',
//   renewalDate: '2024-03-01',
//   managedAccounts: 5,
//   cardNumber: '1234 5678 9012 3456',
//   expDate: '03/26',
//   cardHolderName: 'John Doe',
//   invoices: [
//     { date: '2024-02-01', name: 'Invoice Feb 2024', file: 'invoice_feb_2024.pdf' },
//     { date: '2024-01-01', name: 'Invoice Jan 2024', file: 'invoice_jan_2024.pdf' },
//   ],
// }

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '17px',
  padding: 0,
  height: 64,
  backgroundColor: 'white',
}

const contentStyle: React.CSSProperties = {
  textAlign: 'left',
  padding: '20px 0px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  minHeight: '106px',
}

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'black',
  backgroundColor: 'white',
  minHeight: '88px',
}

const layoutStyle = {
  width: '250px',
}

const columns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Invoice Number', dataIndex: 'number', key: 'number' },
  {
    title: 'File',
    dataIndex: 'invoice_pdf',
    key: 'invoice_pdf',
    render: (text: string) => (
      <Space size='middle'>
        <Tooltip title='Download Invoice'>
          <Button
            type='link'
            icon={<DownloadOutlined />}
            //  href={`/invoices/${text}`}
            href={text}
            download>
            Download
          </Button>
        </Tooltip>
      </Space>
    ),
  },
]

interface SubscriptionSettingsProps {
  data: any
  paymentData: any
  shouldHideCheckout: any
}

const SubscriptionSettings: React.FC<SubscriptionSettingsProps> = ({ data, paymentData, shouldHideCheckout }) => {
  const { currentPeriodEnd, subscription_created_at, subscription_type, plan_type, plan } = data || {}
  const renewalDate =
    subscription_created_at !== undefined && currentPeriodEnd !== undefined
      ? dayjs(currentPeriodEnd * 1000).format('MMMM DD, YYYY')
      : ''
  const [openSelectPlanModal, setOpenSelectPlanModal] = useState(false)
  // const [refetchUpgradePlan] = useLazyUpgradePlanQuery()
  const [refetchChangePaymentMethod] = useLazyChangeStripePaymentMethodQuery()
  const [refetchStripeInvoiceByCustomer, { data: invoices, isLoading }] = useLazyGetStripeInvoiceByCustomerQuery()
  const [openAddSeatsModal, setOpenAddSeatsModal] = useState(false)

  useEffect(() => {
    const getInvoiceByCustomer = async () => {
      await refetchStripeInvoiceByCustomer()
    }
    getInvoiceByCustomer()
  }, [])

  const handleNavigate = async () => {
    if (shouldHideCheckout) {
    } else {
      // navigate('/checkout')
      setOpenAddSeatsModal(true)
    }
  }

  const handleChangePayment = async () => {
    const { data } = await refetchChangePaymentMethod()
    const success_url = data?.url
    if (success_url) {
      window.open(success_url, '_self')
    } else {
      //
    }
  }

  return (
    <>
      <Card className={styles.billingWrapperCard}>
        <Title level={3}>Billing Settings</Title>

        {/* <Row gutter={[16, 16]} justify={'center'}> */}
        {/* <Col span={18}></Col> */}
        <div className={styles.cards}>
          <Card className={styles.billingCard}>
            <Layout style={layoutStyle}>
              <Header style={headerStyle}>Your Plan</Header>
              <Content style={contentStyle}>
                <div className={styles.yourPlan}>
                  <span>
                    Plan Type:{' '}
                    <span style={{ fontWeight: 600 }}>
                      {plan
                        ? plan
                        : plan_type
                        ? planMapping[plan_type]
                        : subscription_type === 'BETA'
                        ? 'Trial'
                        : subscription_type}
                    </span>
                  </span>
                  <span>
                    Status: <span style={{ fontWeight: 600 }}>Active</span>
                  </span>
                  {subscription_type === 'BETA' ? (
                    <span style={{ color: 'red' }}>
                      Trial Expires: <span style={{ fontWeight: 600 }}>{renewalDate}</span>
                    </span>
                  ) : (
                    <span>
                      Renewal Date: <span style={{ fontWeight: 600 }}>{renewalDate}</span>
                    </span>
                  )}
                </div>
              </Content>
              <Footer style={footerStyle}>
                {!shouldHideCheckout && (
                  <Button type='primary' size='large' className={styles.upgradeButton} onClick={handleNavigate}>
                    Buy Seats
                  </Button>
                )}
              </Footer>
            </Layout>
          </Card>

          {/* <Card className={styles.billingCard}>
            <Layout style={layoutStyle}>
              <Header style={headerStyle}>Managed Accounts</Header>
              <Content style={contentStyle}>
                <div className={styles.yourPlan}>
                  <span>Plan Type:</span>
                  <span>Status:</span>
                  <span>Renewal Date:</span>
                </div>
              </Content>
              <Footer style={footerStyle}>
                <Button type='primary' size='large' className={styles.getMoreButton}>
                  Get More
                </Button>
              </Footer>
            </Layout>
          </Card> */}

          {paymentData && (
            <Card className={styles.billingCard}>
              <Layout style={layoutStyle}>
                <Header style={headerStyle}>Payment Method</Header>
                <Content style={contentStyle}>
                  {paymentData?.link ? (
                    <div className={styles.yourPlan}>
                      <span>
                        Link Email: <span style={{ fontWeight: 600 }}>{paymentData.link.email}</span>
                      </span>
                      <span>
                        Link Name: <span style={{ fontWeight: 600 }}>{paymentData.link?.name}</span>
                      </span>
                      <div className={styles.linkLogo}>
                        <img src={link_logo} alt='' />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.yourPlan}>
                      <span>
                        Card Number: <span style={{ fontWeight: 600 }}>************{paymentData?.last4}</span>
                      </span>
                      <span>
                        Name on Card: <span style={{ fontWeight: 600 }}>{paymentData?.name}</span>
                      </span>
                      <span>
                        Expiration Date:{' '}
                        <span style={{ fontWeight: 600 }}>
                          {paymentData?.exp_month}/{paymentData?.exp_year}
                        </span>
                      </span>
                    </div>
                  )}
                </Content>
                <Footer style={footerStyle}>
                  <Button
                    onClick={handleChangePayment}
                    type='primary'
                    size='large'
                    className={styles.changePaymentButton}>
                    Change Payment
                  </Button>
                </Footer>
              </Layout>
            </Card>
          )}
        </div>
        {/* </Row> */}
        <Title level={3}>Invoices</Title>
        <div className={styles.invoicesTable}>
          <Table
            style={{ width: '1000px' }}
            locale={{
              emptyText: isLoading ? (
                <Skeleton
                  active={true}
                  avatar={false}
                  title={false}
                  paragraph={{ rows: 2, width: '100%' }}
                  style={{ opacity: 0.5 }}
                />
              ) : (
                <Empty />
              ),
            }}
            dataSource={invoices?.data || []}
            columns={columns}
            pagination={false}
          />
        </div>
      </Card>
      {openSelectPlanModal && (
        <SelectPlanModal
          currentPlan='Startups'
          open={openSelectPlanModal}
          onCancel={() => {
            setOpenSelectPlanModal(false)
          }}
        />
      )}
      {openAddSeatsModal && (
        <AddMoreSeatsModal
          open={openAddSeatsModal}
          onClose={() => setOpenAddSeatsModal(false)}
          defaultPaymentMethod={paymentData}
        />
      )}
    </>
  )
}

export default SubscriptionSettings
