import React, { useEffect, useState } from 'react'
import styles from './SetupTab.module.scss'
import { useAppSelector } from 'state'
import MessageSetup from './MessageSetup'
import AIResponderSetup from './AIResponderSetup'

interface SetupTabProps {
  messageSuggestions: any
  categorizer: any
  responders: any
}

const SetupTab: React.FC<SetupTabProps> = ({ messageSuggestions, categorizer, responders }) => {
  const { selected_builder_node } = useAppSelector((state) => state.CampaignBuilder)
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const [selectedSetup, setSelectedSetup] = useState<any>('')

  useEffect(() => {
    if (selected_builder_node?.id) {
      setSelectedSetup(selected_builder_node.data.type)
    } else {
      setSelectedSetup('')
    }
  }, [selected_builder_node])

  const emptyState = (
    <div className={styles.container}>
      <p>Select a Component to Setup</p>
      <span>In order to setup a component please click on it</span>
    </div>
  )

  const waitState = (
    <div className={styles.container}>
      <p>Wait</p>
      <span>Pause for the specified number of hours, days or weeks before proceeding.</span>
    </div>
  )

  const connectLeadState = truePlatforms?.includes('linkedin') ? (
    <MessageSetup messageSuggestions={messageSuggestions} isMandatory={false} />
  ) : (
    <div className={styles.container}>
      <p>Connect Lead</p>
      <span>Send a connection request to the lead.</span>
    </div>
  )

  const connectNoMessage = (
    <div className={styles.container}>
      <p>Connect Lead</p>
      <span>Send a connection request to the lead.</span>
    </div>
  )

  const followLeadState = (
    <div className={styles.container}>
      <p>Follow Lead</p>
      <span>Follow the lead's profile.</span>
    </div>
  )

  const unfollowLeadState = (
    <div className={styles.container}>
      <p>Unfollow Lead</p>
      <span>Unfollow the lead's profile.</span>
    </div>
  )

  const leadAccepted = (
    <div className={styles.container}>
      <p>Lead Accepted</p>
      <span>Indicates that the lead has accepted the connection.</span>
    </div>
  )

  const messageRepliedState = (
    <div className={styles.container}>
      <p>If Message Replied</p>
      <span>Actions to take if the lead replies to your message.</span>
    </div>
  )

  // const commentOnPostState = (
  //   <div className={styles.container}>
  //     <p>Comment on Post</p>
  //     <span>Description for Comment on Post...</span>
  //   </div>
  // )

  const likePostState = (
    <div className={styles.container}>
      <p>Like Post</p>
      <span>Like a lead's post.</span>
    </div>
  )

  const viewStoryState = (
    <div className={styles.container}>
      <p>View Story</p>
      <span>View a lead's story.</span>
    </div>
  )

  return (
    <>
      {selectedSetup === '' && emptyState}
      {selectedSetup === 'is_wait' && waitState}
      {selectedSetup === 'send_message' && <MessageSetup messageSuggestions={messageSuggestions} />}
      {selectedSetup === 'ai_responder' && <AIResponderSetup categorizer={categorizer} responders={responders} />}
      {selectedSetup === 'connect_lead_with_note' && connectLeadState}
      {selectedSetup === 'connect_lead' && connectNoMessage}
      {selectedSetup === 'follow_lead' && followLeadState}
      {selectedSetup === 'unfollow_lead' && unfollowLeadState}
      {(selectedSetup === 'lead_accepted' ||
        selectedSetup === 'lead_accepted_follow' ||
        selectedSetup === 'lead_accepted_connect') &&
        leadAccepted}
      {selectedSetup === 'is_message_replied' && messageRepliedState}
      {selectedSetup === 'comment_on_post' && <MessageSetup messageSuggestions={messageSuggestions} />}
      {selectedSetup === 'like_post' && likePostState}
      {selectedSetup === 'view_story' && viewStoryState}
    </>
  )
}

export default SetupTab
