import React from 'react'
import styles from './ProgressBar.module.scss'

interface ProgressBarProps {
  percentage: number
  status?: 'pause' | 'error' | 'paused'
  size?: 'small' | 'large' | 'xl'
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, status, size = 'large' }) => {
  let barStyle = styles['progress-bar']
  // let textStyle = styles.text

  switch (status) {
    case 'pause':
      barStyle = `${percentage < 100 && styles.pause}`
      break
    case 'paused':
      barStyle = `${percentage < 100 && styles.pause}`
      break
    case 'error':
      barStyle = `${styles.error}`
      // textStyle = `${styles.text} ${styles.errorText}`
      break
    default:
      barStyle = `${styles.inProgress} ${percentage < 100 && styles.progressBarAnimated}`
      break
  }

  const containerStyle =
    size === 'large' ? styles.containerLarge : size === 'small' ? styles.containerSmall : styles.containerXL
  const progressStyle =
    size === 'large' ? styles.progressLarge : size === 'small' ? styles.progressSmall : styles.progressXL

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={`${styles.progress} ${progressStyle}`}>
        <div className={`${styles['progress-bar']} ${barStyle}`} style={{ width: `${percentage}%` }}></div>
        {/* {status === 'inProgress' && <div className={`${styles['progress-bar']} ${styles.progressBarAnimated}`}></div>} */}
      </div>
      <span className={styles.text}>{percentage}%</span>
    </div>
  )
}

export default ProgressBar
