import React, { FC } from 'react'
import { EdgeProps, getSmoothStepPath, EdgeLabelRenderer, BaseEdge } from 'reactflow'

// Edge component for True path with green label
const TrueEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label = 'TRUE',
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            background: '#2AA58E',
            color: 'white',
            padding: '6px 10px',
            borderRadius: 5,
            fontSize: 9,
            fontStyle: 'normal',
            lineHeight: '8px',
            letterSpacing: '0.05px',
            fontWeight: 700,
            stroke: 'red',
            // pointerEvents: 'all',
          }}
          className='nodrag nopan'>
          {'TRUE'}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

// Edge component for False path with red label
const FalseEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label = 'FALSE',
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            background: '#DF667C',
            color: 'white',
            padding: '6px 10px',
            borderRadius: 5,
            fontSize: 9,
            fontStyle: 'normal',
            lineHeight: '8px',
            letterSpacing: '0.05px',
            fontWeight: 700,
          }}
          className='nodrag nopan'>
          {'FALSE'}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export { TrueEdge, FalseEdge }
