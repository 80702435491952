import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const ContactsApi: any = createApi({
  reducerPath: 'ContactsApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts?current=${params.current}&pageSize=${params.pageSize}`,
      }),
    }),

    getContactsById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/contactsbyid`,
        method: 'POST',
        body: { ids: params },
      }),
    }),

    getContact: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/${params}`,
      }),
    }),
    getContactsByCompanyId: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/contacts?current=${params.current}&pageSize=${params.pageSize}`,
        method: 'POST',
        body: { companiesId: params.company_ids, filters: params.filters },
      }),
    }),
    getExposedContacts: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/contacts?current=${params.current}&pageSize=${params.pageSize}`,
        method: 'GET',
      }),
    }),
    getTelegramContactsByCompanyId: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/telegram?current=${params.current}&pageSize=${params.pageSize}`,
        method: 'POST',
        body: { companiesId: params.company_ids },
      }),
    }),
    getTakeCredits: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/${params.contact_id}/credits?key=${
          params.value
        }&isDisplayed=${JSON.stringify(params.isDisplayed)}&where_opened=${params?.where_opened}&isCommunitiesAlready=${
          params.isCommunitiesAlready
        }`,
      }),
    }),
    getCsvExport: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/export`,
        body: { contactsId: params },
        headers: {
          'Content-Type': 'text/csv',
          'Content-Disposition': `attachment; filename="users.csv"`,
        },
      }),
    }),
    getContactsHubSpot: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot`,
        body: {
          contactsId: params.contacts,
          do_override_if_exists: params.do_override_if_exists,
          telegramCredits: params.telegramCredits,
          emailCredits: params.emailCredits,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: false,
        },
      }),
    }),
    getTracking: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/tracking?contact_id=${params.contact_id}&activity=${
          params.activity
        }${params?.linked_in ? `&linked_in=${params?.linked_in}` : ''}`,
      }),
    }),
    getAlreadyTackedCredits: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/tackedcredits`,
        body: { contacts: params.contacts },
      }),
    }),
    getForContactsList: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/contacts/list`,
        body: { company_ids: params.company_ids },
      }),
    }),
    getForCompaniesList: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/companies/list`,
        body: { contact_ids: params.contact_ids },
      }),
    }),
    getTelegramContacts: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/telegram-contacts`,
      }),
    }),
    getNotifyTracking: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/${params.contact_id}/notify?key=${params.value}`,
      }),
    }),
    getHubspotProperties: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/getProperties`,
      }),
    }),
    getJobTitle: builder.query({
      query: (params: string) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/jobTitle?search=${params}`,
      }),
    }),
    setExpose: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/contacts/expose`,
        body: {
          contact_id: params.selectedId,
          key: params.key,
        },
      }),
    }),
    exportToCsv: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/outreach/exportToCsv`,
        body: {
          contacts_for_telegram: params.telegramCredits,
          contacts_for_email: params.emailCredits,
          contactsId: params.contacts_to_export,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: params.phone,
          contactsArr: params.contacts,
        },
        headers: {
          'Content-Type': 'text/csv',
          'Content-Disposition': `attachment; filename="users.csv"`,
        },
      }),
    }),
    exportToCsvRemainingPoints: builder.mutation({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/outreach/exportToCsvRemainingPoints`,
        body: {
          contacts_for_telegram: params.telegramCredits,
          contacts_for_email: params.emailCredits,
          contactsId: params.contacts_to_export,
          email: params.emailToggle,
          telegram: params.telegramToggle,
          phone: params.phone,
          contactsArr: params.contacts,
        },
        headers: {
          'Content-Type': 'text/csv',
          'Content-Disposition': `attachment; filename="users.csv"`,
        },
      }),
    }),
  }),
})

export const {
  useLazyGetContactsQuery,
  useLazyGetContactsByIdQuery,
  useGetContactMutation,
  useGetContactsByCompanyIdMutation,
  useGetExposedContactsMutation,
  useLazyGetTelegramContactsByCompanyIdQuery,
  useLazyGetTakeCreditsQuery,
  useGetCsvExportMutation,
  useGetContactsHubSpotMutation,
  useLazyGetTrackingQuery,
  useGetAlreadyTackedCreditsMutation,
  useGetForContactsListMutation,
  useGetForCompaniesListMutation,
  useLazyGetTelegramContactsQuery,
  useLazyGetNotifyTrackingQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyGetJobTitleQuery,
  useSetExposeMutation,
  useExportToCsvMutation,
  useExportToCsvRemainingPointsMutation,
} = ContactsApi
