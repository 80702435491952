import React, { useState } from 'react'
import { Card, Divider, Tag, Tooltip } from 'antd'
import { CustomEyeButton } from './CustomEyeButton'
import TemplatePreview from './TemplatePreview'
import FlowAsImage from './FlowAsImage'

interface TemplateData {
  id: any
  name: string
  platforms: string[]
  description: string
  created_at: string
  tags: []
  image?: string
}

interface CustomTemplateCardProps {
  template: TemplateData
  onSelect: any
  isSelected: boolean
  onSelectTemplate: any
  setSelectedTemplate: any
  selectedTemplate: any
}

const CustomTemplateCard: React.FC<CustomTemplateCardProps> = ({
  template,
  onSelect,
  isSelected,
  onSelectTemplate,
  setSelectedTemplate,
  selectedTemplate,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [templatePreviewOpen, setTemplatePreviewOpen] = useState(false)

  const elipsisDescription =
    template.description?.length > 35 ? (
      <Tooltip title={template.description}>{template.description.slice(0, 35)}...</Tooltip>
    ) : (
      <span>{template.description || 'No Description'}</span>
    )
  const elipsisName =
    template.name?.length > 25 ? (
      <Tooltip title={template.name}>{template.name.slice(0, 25)?.trim()}...</Tooltip>
    ) : (
      <span>{template.name}</span>
    )
  return (
    <>
      {templatePreviewOpen && (
        <TemplatePreview
          open={templatePreviewOpen}
          onModalClose={() => setTemplatePreviewOpen(false)}
          template={template}
        />
      )}
      <Card
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'white',
          minHeight: '440px',
          maxHeight: '440px',
          marginBottom: '24px',
          height: '100%',
          width: '100%',
          border: isHovered ? '2px solid #7043ff' : '2px solid rgba(223, 225, 230, 1)', // Purple border on hover
          borderRadius: '10px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 1);',
          cursor: 'pointer',
          transform: isHovered ? 'scale(1.005)' : 'scale(1)', // Slightly enlarge on hover
          transition: 'all 0.3s ease', // Smooth transition for hover effects
        }}
        bodyStyle={{
          height: '100%',
          padding: '15px 15px 8px 15px',
          width: '100%',
        }}
        onMouseEnter={() => {
          setIsHovered(true)
          setSelectedTemplate(template)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
          setSelectedTemplate(template)
        }}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: '100%', height: '100%', flex: 0.6 }}>
            <Card
              style={{
                width: '100%',
                height: '100%',
                maxHeight: 240,
              }}
              bodyStyle={{
                padding: '0px',
                margin: '0px',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}>
                <div style={{ width: '100%', height: '240px' }}>
                  <FlowAsImage template={template} />
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              width: '95%',
              height: '100%',
              flex: 0.4,
              display: 'flex',
              marginTop: 5,
              flexDirection: 'column',
              gap: 4,
            }}>
            <span style={{ fontSize: '22px', fontWeight: 600 }}>{elipsisName}</span>
            <span style={{ fontSize: '16px', opacity: '60%', marginLeft: 2 }}>{elipsisDescription}</span>
            <Divider style={{ padding: 0, margin: 0, marginTop: 13 }} />
            <div
              style={{
                display: 'flex',
                gap: 6,
                marginTop: 5,
                width: '100%',
                height: '100%',
                filter: isHovered ? 'blur(15px)' : '',
                opacity: isHovered ? 0.5 : 1,
                transition: 'ease-out 0.1s ',
              }}>
              {template?.tags?.map((tag: string, index: number) => (
                <Tag
                  key={index}
                  style={{
                    backgroundColor: '#F2F0FF',
                    color: '#7043FF',
                    fontSize: '15px',
                    lineHeight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '42x 10px',
                    borderRadius: '8px',
                  }}
                  bordered={false}>
                  {tag}
                </Tag>
              ))}
            </div>
            <div style={{ marginTop: 10, marginBottom: 5 }}>
              <CustomEyeButton
                eyeText={'View'}
                buttonText={'Select'}
                onEyeClick={() => {
                  setTemplatePreviewOpen(true)
                }}
                onButtonClick={() => {
                  onSelectTemplate(template)
                }}
                isHovered={isHovered}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default CustomTemplateCard
