import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'

import {
  Col,
  Popconfirm,
  Row,
  Tooltip as TooltipText,
  Modal,
  message as popUpMessage,
  Form,
  Mentions,
  Button,
  Tooltip,
  Select,
} from 'antd'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import IntentSignalsPermanentPaywallMsg from '../../../../features/intent-signals/components/paywalls/IntentSignalsPermanentPaywallMsg'

import open_lock from 'common/assets/svg/open_lock.svg'
import send_message from 'common/assets/svg/send_message.svg'
import telegram_white from 'common/assets/svg/telegram_white.svg'
import notify_me from 'common/assets/svg/notify_me.svg'
import notify_me_succeed from 'common/assets/svg/notify_me_succeed.svg'
import phone_icon from 'common/assets/svg/phon.svg'
import email_icon from 'common/assets/svg/email.svg'
import telegram_icon from 'common/assets/svg/telegram.svg'
import popout_flat from 'common/assets/svg/popout-flat.svg'

import { DrawerLebels } from 'common/constants/label.constants'
import { ConfirmBox } from 'common/constants/modal.constants'
import { CommonServices } from 'common/services/common-services'

import { IContact } from 'features/contacts/interfaces/contact.interface'
import { useLazyGetTakeCreditsQuery, useLazyGetNotifyTrackingQuery } from 'features/contacts/state/api/ContactsApi'

import { CopyToClipboards } from 'common/components/copy-to-clipboards/copy-to-clipboards'
import { SET_CONTACT } from '../../state/slice/contactsSlice'
import { useGetCompanyMutation } from '../../../intent-signals/state/api/CompaniesApi'
import FancyDrawer from '../../../../common/components/fancy-drawer/fancy-drawer'
import { FancyImage } from '../FancyImage/FancyImage'
import CompanyDetails from '../../../intent-signals/components/company-details/company-details'
import { rendomContactDetailsMsg } from '../../../../common/static-data/randomizeContactDetails'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import TypedText from '../../../../common/components/TypedText/TypedText'
import LoadingDots from '../../../../common/components/LoadingDots/LoadingDots'
import { get, post } from 'common/api/axios'

import { useAuth } from 'common/hooks/useAuth.hooks'
// import ConnectPlusModal from 'common/components/ConnectPlus/ConnectPlusModal'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'

import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'
import { useLazyGetCreditsQuery, useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'
import { getMessageStatus } from 'common/utils/getMessageStatus'
import { useTelegram } from 'common/providers/TelegramProvider'
import CooldownModal from 'features/Outreach/components/CooldownModal'
import { outreach } from 'common/constants/outreach.constants'

import styles from './contact-details.module.scss'
import Communities from './Communities'
import { useAppSelector } from 'state'
import { useLazyGetSenderListQuery } from 'features/Outreach/state/api/OutreachApi'
// import ConnectPlusErrorModal from 'common/components/ConnectPlus/ConnectPlusErrorModal'
import { SET_IS_OUTREACHED, SET_IS_SENT } from 'features/Outreach/state/sendMessageSlice'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

interface IContactDetails {
  data: IContact
  communitiesData?: any
  refetch?: any
  toggleHide?: (value?: boolean) => void
}

const ContactDetails: React.FC<IContactDetails> = ({ data, toggleHide, communitiesData, refetch }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { is_send_message } = useAppSelector((state) => state.contacts)
  const [refetcGetSenderList, { data: dataSenderList, isLoading }] = useLazyGetSenderListQuery()
  const { Option } = Select
  const [outOfCredits, setOutOfCredits] = useState(false)

  const [phoneDisplayNone, setPhoneDisplayNone] = useState(true)
  const [emailDisplayNone, setEmailDisplayNone] = useState(true)
  const [telegramDisplayNone, setTelegramDisplayNone] = useState(true)
  const [isPhoneStartAnima, setIsPhoneStartAnima] = useState(false)
  const [isEmailStartAnima, setIsEmailStartAnima] = useState(false)
  const [isTelegramStartAnima, setIsTelegramStartAnima] = useState(false)
  const [IsModalErrorModalOpen, setIsModalErrorModalOpen] = useState(false)
  const [connectPlusModalOpen, setConnectPlusModalOpen] = useState(false)

  // send message flow
  const [message, setMessage] = useState('')
  const [actionsPaywallOpen, setActionsPaywallOpen] = useState(false)
  const [cooldownOpen, setCooldownOpen] = useState(false)
  const [form] = Form.useForm()
  const optionLabels = [
    // 'city',
    'email',
    // 'state',
    // 'country',
    'first_name',
    'full_name',
    'location',
    'seniority',
    'job_title',
    'company_name',
  ]

  const options = optionLabels.map((value) => ({
    value,
    label: value,
  }))

  const [messages, setMessages] = useState<any[]>([])
  const [showTextBox, setShowTextBox] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(true)

  const [loading, setLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const { userDetails } = useAuth()

  const [visibleFields, setVisibleFields] = useState<any>([])

  const [contactsData, setContactsData] = useState<any>({
    email: '',
    phone_numbers: { res: '', msg: '', msgStatus: '' },
    telegram_account: '',
    discord: '',
  })

  const [contactsErrorData, setContactsErrorData] = useState<any>({
    email: '',
    phone_numbers: { res: '', msg: '', msgStatus: '' },
    telegram_account: '',
    discord: '',
  })

  const { sendMessages, cooldown } = useTelegram()
  const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()

  const [refetchGetTakeCredits] = useLazyGetTakeCreditsQuery()
  const [refetchGetCompany, { data: company }] = useGetCompanyMutation()
  const [refetchGetNotifyTracking] = useLazyGetNotifyTrackingQuery()
  const [refetchGetCredits] = useLazyGetCreditsQuery()

  const fetchMessages = async () => {
    const response = await get(`/outreach/contact/${data?.index}`)
    setMessages(response?.data?.messages)
  }
  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])

  useEffect(() => {
    if (!data) return

    fetchMessages()
  }, [])

  const handleGetDetails = async (key: string, isCommunitiesAlready: boolean) => {
    const getIsDisplayed = (contactsData: any) => ({
      email:
        data?.email_already !== undefined ||
        (contactsData?.email?.response !== undefined && contactsData?.email?.response !== 'No email'),
      telegram:
        data?.telegram_already !== undefined ||
        (contactsData?.telegram_account?.response !== undefined &&
          contactsData?.telegram_account?.response !== 'No telegram_account'),
      phone:
        data?.phone_already !== undefined ||
        (contactsData?.phone_numbers?.response !== undefined &&
          contactsData?.phone_numbers?.response !== 'No phone_numbers'),
    })

    const result = await refetchGetTakeCredits({
      contact_id: data?.index,
      value: key,
      isDisplayed: getIsDisplayed(contactsData),
      where_opened: 'contact_pannel',
      isCommunitiesAlready,
    })

    if (result?.data?.response) {
      const updatedContactsData = { ...contactsData }

      if (key === 'email') {
        updatedContactsData.email = result?.data
      } else if (key === 'phone_numbers') {
        updatedContactsData.phone_numbers = result?.data
      } else if (key === 'telegram_account') {
        updatedContactsData.telegram_account = result?.data
      } else if (key === 'discord') {
        updatedContactsData.discord =
          result?.data?.response?.indexOf('No ') === -1
            ? result?.data?.response
            : CommonServices.getRandomElementFromArray(rendomContactDetailsMsg)
      }

      setContactsData(updatedContactsData)

      if (isCommunitiesAlready) {
        setVisibleFields([...visibleFields, 'communities', key])
      } else {
        setVisibleFields([...visibleFields, key])
      }

      // setIsTelegramStartAnima(false)
      if (!result?.data?.message) {
        try {
          if (refetch) refetch()
          await post(`/outreach/exposed`, { ...getIsDisplayed(updatedContactsData), contact_id: data?.index })
        } catch (error) {
          console.error(`Error saving to exposed_contacts table: ${error}`)
        }
      }
    } else {
      if (result?.error?.data?.msgStatus === 5) {
        setIsModalErrorModalOpen(true)
      }
      if (key === 'email') {
        setContactsErrorData({
          ...contactsData,
          email: result?.error?.data,
        })
        setMessage(result?.error?.data?.message)
      } else if (key === 'phone_numbers') {
        setContactsErrorData({
          ...contactsData,
          phone_numbers: result?.error?.data,
        })
        setMessage(result?.error?.data?.message)
      } else if (key === 'telegram_account') {
        setContactsErrorData({
          ...contactsData,
          telegram_account: result?.error?.data,
        })
        setMessage(result?.error?.data?.message)
      } else if (key === 'discord') {
        setContactsErrorData({
          ...contactsData,
          discord:
            result?.error?.data?.response?.indexOf('No ') === -1
              ? result?.error?.data.response
              : CommonServices.getRandomElementFromArray(rendomContactDetailsMsg),
        })
        setMessage(result?.error?.data?.message)
      }
    }
  }

  const notifyTracking = async (key: string) => {
    await refetchGetNotifyTracking({
      contact_id: data?.index,
      value: key,
    })
  }

  const handleShowCompany = async (id: any) => {
    dispatch(SET_CONTACT(data))
    toggleHide && toggleHide(false)
    setTimeout(async () => {
      setLoading(true)
      setShowDetails(true)
      const companyDetails = await refetchGetCompany(id)
      if (companyDetails) {
        setLoading(false)
      }
    }, 500)
  }

  const handleShowContact = async (id: any) => {
    navigate(`/contacts/${id}`)
  }

  const handleSendMessage = () => {
    post('/log', {
      action: 'Sending message dialog opened',
    })
    if (cooldown) return setCooldownOpen(true)
    if (!userDetails.addons['outreach']) return setActionsPaywallOpen(true)

    if (userDetails?.outreach?.length === 0) {
      const user = localStorage.getItem('user')
      const userData = JSON.parse(user || '{}')
      const outreach = userData?.outreach
      if (outreach.length === 0) {
        return setConnectPlusModalOpen(true)
      }
    }

    setMessage('')
    setShowTextBox(!showTextBox)
  }

  // const handleSendMessageText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   const inputValue = e.target.value
  //   if (/[\b;'"\\]/.test(inputValue)) {
  //     const text = inputValue.replace(/[\b;'"\\]/g, '')
  //     setMessage(text)
  //   } else {
  //     setMessage(inputValue)
  //   }
  // }

  function replacePlaceholders(message: string, contact: any): string {
    const regex = /@(\w+)/g
    return message.replace(regex, (match, placeholder) => {
      const option = options.find((opt) => opt.value === placeholder)
      if (option && contact.hasOwnProperty(placeholder)) {
        if (placeholder === 'email') return contact['email_already'] || '@email'

        return contact[placeholder]
      } else if (placeholder === 'first_name') {
        return contact['full_name'].split(' ')[0]
      }

      return match
    })
  }

  const handleSubmitMessage = async (values: any) => {
    if (!values.message || values.sender === undefined) return

    const message = replacePlaceholders(values.message, data)
    const sender = values.sender
    if (message.trim().length <= 0) return

    const creditsResponse = await refetchGetCredits()

    if (!creditsResponse?.data?.response?.credits?.contacts_outreached_left) return setOutOfCredits(true)
    if (!creditsResponse?.data?.response?.credits?.send_tg_message)
      return popUpMessage.error(`You're out of message credits`)

    setShowTextBox(false)
    setShowSendMessage(true)
    dispatch(SET_IS_OUTREACHED([{ ...data, contact_id: data.index }]))
    dispatch(SET_IS_SENT(true))

    const user = localStorage.getItem('user') || '{}'
    const userData = JSON.parse(user)

    const resultObject = userData?.outreach.find((item: any) => item.id === sender)

    await sendMessages(
      [
        {
          ...resultObject,
          message,
          recipient: data?.telegram_already || contactsData?.telegram_account?.response,
          contact_id: data?.index,
        },
      ],
      `${userData?.outreach?.area_code}${userData?.outreach?.number}`,
      true,
    )

    await fetchMessages()
  }

  const renderOption = (item: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>{item.area_code + item.number}</div>
  )

  return (
    <>
      {!data ? (
        "You don't have enough credits to perform this operation!"
      ) : (
        <>
          <Modal
            open={IsModalErrorModalOpen}
            onCancel={() => setIsModalErrorModalOpen(false)}
            okButtonProps={{
              className: 'hidden',
            }}
            cancelButtonProps={{
              className: 'hidden',
            }}>
            <div className='flex justify-content-center'>
              <IntentSignalsPermanentPaywallMsg message={message} setIsModalErrorModalOpen={setIsModalErrorModalOpen} />
            </div>
          </Modal>
          {data?.bio && data?.bio !== '' && (
            <div className={styles.contactDetails}>
              <p className={styles.titleSection}>{DrawerLebels.BIO}</p>
              <span className={styles.bioDescription}>{data?.bio}</span>
            </div>
          )}
          <div className={styles.contactDetails}>
            <p className={styles.titleSection}>{DrawerLebels.MAIN_CONTACT_DETAILS}</p>
            {data?.is_doxed && (
              <>
                <Row className={styles.top}>
                  <Col span={15} className={styles.details}>
                    {data?.email_already ? (
                      <>
                        <span className={styles.imgParentEmailAddress}>
                          <img src={email_icon} alt={''} />
                        </span>
                        <span className={styles.email_text}>{data?.email_already}</span>
                        {data?.email_already.indexOf('No ') === -1 && (
                          <span>
                            <CopyToClipboards data={data?.email_already} />
                          </span>
                        )}
                      </>
                    ) : visibleFields.includes('email') ? (
                      <>
                        {contactsData?.email?.msgStatus === 0 ? (
                          <>
                            <span className={styles.imgParentEmailAddress}>
                              <img src={email_icon} alt={''} />
                            </span>
                            <span className={styles.email_text}> {contactsData?.email?.response}</span>
                            <span>
                              <CopyToClipboards data={contactsData?.email?.response} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className={styles.imgParentPhone}>
                              <img src={email_icon} alt={''} />
                            </span>
                            <div className={styles.parentMsg}>
                              <div className={styles.title_msg}>
                                {'Data is currently missing, you were not charged.'}
                              </div>
                              <div className={styles.msg_body}>{contactsData?.email?.message}</div>
                              {contactsData?.email?.msgStatus === 2 ? (
                                <>
                                  {contactsData?.email?.response.map((rowData: any) => {
                                    return (
                                      <div
                                        className={styles.contacts_same_company_parent}
                                        onClick={() => {
                                          handleShowContact(rowData?.index)
                                        }}>
                                        <span>
                                          <FancyImage url={rowData?.photo_url} />
                                        </span>
                                        <span className={styles.caption_parent}>
                                          <div className={styles.header}>{rowData?.full_name}</div>
                                          <div className={styles.body}>{rowData?.job_title}</div>
                                        </span>
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <span>
                        <span className={styles.imgParentEmailAddress}>
                          <img src={email_icon} alt={''} />
                        </span>
                        {!isEmailStartAnima || contactsErrorData?.email?.msgStatus === 5 ? (
                          <span className={styles.parentEmailCaption}>Email Address</span>
                        ) : (
                          <TypedText text='Scanning Data...' typingSpeed={100} />
                        )}
                      </span>
                    )}
                  </Col>
                  <Col span={9} className={styles.right}>
                    {!visibleFields.includes('email') && !data?.email_already ? (
                      <Popconfirm
                        title={ConfirmBox.GET_DETAILS}
                        description={
                          <Col>
                            {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}

                            <div>{`This action will cost you ${userPoints?.pointsStore?.email} points. Read disclaimer:`}</div>

                            {/* This action will cost you 1 credit. Read disclaimer: */}
                            {showDisclaimer ? (
                              <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                                {ConfirmBox.DISCLAIMER}
                              </p>
                            ) : (
                              <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                                {'Disclaimer'}
                              </span>
                            )}
                          </Col>
                        }
                        onConfirm={() => {
                          // post('/log', {
                          //   action: 'confirm credit for email exposure',
                          // })
                          setIsEmailStartAnima(true)
                          const emailTimer = setTimeout(() => {
                            handleGetDetails('email', false)
                            setShowDisclaimer(false)

                            setContactsErrorData({
                              email: '',
                              phone_numbers: { res: '', msg: '', msgStatus: '' },
                              telegram_account: '',
                              discord: '',
                            })

                            clearTimeout(emailTimer)
                          }, 2000)
                        }}
                        onCancel={() => {
                          // post('/log', {
                          //   action: 'cancel credit for email exposure',
                          // })
                          setShowDisclaimer(false)
                        }}
                        okText='Get'
                        cancelText='No'>
                        {!isEmailStartAnima || contactsErrorData?.email?.msgStatus === 5 ? (
                          <p
                            className='contactTypes'
                            onClick={() => {
                              post('/log', {
                                action: 'Expose email',
                              })
                            }}>
                            <img src={open_lock} alt={''} />
                            <span>{DrawerLebels.EXPOSE}</span>
                          </p>
                        ) : (
                          <LoadingDots />
                        )}
                      </Popconfirm>
                    ) : (
                      <>
                        {contactsData?.email?.msgStatus === 0 || data?.email_already ? (
                          <></>
                        ) : (
                          <>
                            <TooltipText
                              placement='top'
                              title={'We will notify you know when this contact’s email is available.'}>
                              <p
                                className={!emailDisplayNone ? 'contactTypes display_none' : 'contactTypes'}
                                onClick={() => {
                                  setEmailDisplayNone(false)
                                  notifyTracking('email')
                                }}>
                                <img src={notify_me} alt={''} />
                                <span>{DrawerLebels.NOTIFY_ME}</span>
                              </p>
                            </TooltipText>
                            <TooltipText
                              placement='top'
                              title={'We will notify you know when this contact’s email is available.'}>
                              <p className={emailDisplayNone ? ' display_none' : ''}>
                                <img src={notify_me_succeed} alt={''} />
                              </p>
                            </TooltipText>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <Row className={styles.top}>
                  {/* ----  phone_numbers ---- */}
                  <Col span={15} className={styles.details}>
                    {data?.phone_already ? (
                      <>
                        <span className={styles.imgParentPhone}>
                          <img src={phone_icon} alt={''} />
                        </span>
                        <span>{data?.phone_already}</span>
                        {data?.phone_already.indexOf('No ') === -1 && (
                          <span>
                            <CopyToClipboards data={data?.phone_already} />
                          </span>
                        )}
                      </>
                    ) : visibleFields.includes('phone_numbers') ? (
                      <>
                        {contactsData?.phone_numbers?.msgStatus === 0 ? (
                          <>
                            <span className={styles.imgParentPhone}>
                              <img src={phone_icon} alt={''} />
                            </span>
                            <span>{contactsData?.phone_numbers?.response}</span>
                            <span>
                              <CopyToClipboards data={contactsData?.phone_numbers?.response} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className={styles.imgParentPhone}>
                              <img src={phone_icon} alt={''} />
                            </span>
                            <div className={styles.parentMsg}>
                              <div className={styles.title_msg}>
                                {'Data is currently missing, you were not charged.'}
                              </div>
                              <div className={styles.msg_body}>{contactsData?.phone_numbers?.message}</div>
                              {contactsData?.phone_numbers?.msgStatus === 2 ? (
                                <>
                                  {contactsData?.phone_numbers?.response.map((rowData: any) => {
                                    return (
                                      <div
                                        className={styles.contacts_same_company_parent}
                                        onClick={() => {
                                          handleShowContact(rowData?.index)
                                        }}>
                                        <span>
                                          <FancyImage url={rowData?.photo_url} />
                                        </span>
                                        <span className={styles.caption_parent}>
                                          <div className={styles.header}>{rowData?.full_name}</div>
                                          <div className={styles.body}>{rowData?.job_title}</div>
                                        </span>
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <span>
                        <span className={styles.imgParentPhone}>
                          <img src={phone_icon} alt={''} />
                        </span>
                        {!isPhoneStartAnima || contactsErrorData?.phone_numbers?.msgStatus === 5 ? (
                          <span className={styles.parentPhoneCaption}>Phone Number</span>
                        ) : (
                          <TypedText text='Scanning Data...' typingSpeed={100} />
                        )}
                      </span>
                    )}
                  </Col>
                  <Col span={9} className={styles.right}>
                    {!visibleFields.includes('phone_numbers') && !data?.phone_already ? (
                      <Popconfirm
                        title={ConfirmBox.GET_DETAILS}
                        description={
                          <Col>
                            <div>{`This action will cost you ${userPoints?.pointsStore?.phone} points. Read disclaimer:`}</div>

                            {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                            {showDisclaimer ? (
                              <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                                {ConfirmBox.DISCLAIMER}
                              </p>
                            ) : (
                              <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                                {'Disclaimer'}
                              </span>
                            )}
                          </Col>
                        }
                        onConfirm={() => {
                          setIsPhoneStartAnima(true)
                          const phoneTimer = setTimeout(() => {
                            handleGetDetails('phone_numbers', false)
                            setShowDisclaimer(false)
                            setContactsErrorData({
                              email: '',
                              phone_numbers: { res: '', msg: '', msgStatus: '' },
                              telegram_account: '',
                              discord: '',
                            })
                            clearTimeout(phoneTimer)
                          }, 2000)
                        }}
                        onCancel={() => {
                          setShowDisclaimer(false)
                        }}
                        okText='Get'
                        cancelText='No'>
                        {!isPhoneStartAnima || contactsErrorData?.phone_numbers?.msgStatus === 5 ? (
                          <p
                            className='contactTypes'
                            onClick={() => {
                              post('/log', {
                                action: 'Expose Phone numbers',
                              })
                            }}>
                            <img src={open_lock} alt={''} />
                            <span>{DrawerLebels.EXPOSE}</span>
                          </p>
                        ) : (
                          <LoadingDots />
                        )}
                      </Popconfirm>
                    ) : (
                      <>
                        {
                          <>
                            {contactsData?.phone_numbers?.msgStatus === 0 || data?.phone_already ? (
                              <></>
                            ) : (
                              <>
                                <TooltipText
                                  placement='top'
                                  title={'We will notify you know when this contact’s phone number is available.'}>
                                  <p
                                    className={!phoneDisplayNone ? 'contactTypes display_none' : 'contactTypes'}
                                    onClick={() => {
                                      setPhoneDisplayNone(false)
                                      notifyTracking('phone_numbers')
                                    }}>
                                    <img src={notify_me} alt={''} />
                                    <span>{DrawerLebels.NOTIFY_ME}</span>
                                  </p>
                                </TooltipText>
                                <TooltipText
                                  placement='top'
                                  title={'We will notify you know when this contact’s phone number is available.'}>
                                  <p className={phoneDisplayNone ? ' display_none' : ''}>
                                    <img src={notify_me_succeed} alt={''} />
                                  </p>
                                </TooltipText>
                              </>
                            )}
                          </>
                        }
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row className={styles.top}>
              {/* telegram_account */}
              <Col span={15} className={styles.details}>
                {data?.telegram_already ? (
                  <>
                    <span className={styles.imgParentTelegram}>
                      <img src={telegram_icon} alt={''} />
                    </span>
                    <span>
                      <a
                        className='telegramWidth'
                        href={`https://t.me/${data?.telegram_already}`}
                        target='_black'>{`https://t.me/${data?.telegram_already}`}</a>
                    </span>
                  </>
                ) : visibleFields.includes('telegram_account') ? (
                  <>
                    {contactsData?.telegram_account?.msgStatus === 0 ? (
                      <>
                        <span className={styles.imgParentTelegram}>
                          <img src={telegram_icon} alt={''} />
                        </span>
                        <span>
                          <a
                            className='telegramWidth'
                            href={`https://t.me/${contactsData?.telegram_account?.response}`}
                            target='_black'>{`https://t.me/${contactsData?.telegram_account?.response}`}</a>
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={styles.imgParentTelegram}>
                          <img src={telegram_icon} alt={''} />
                        </span>
                        <div className={styles.parentMsg}>
                          <div className={styles.title_msg}>{'Data is currently missing, you were not charged.'}</div>
                          <div className={styles.msg_body}>
                            {contactsData?.telegram_account?.message.split('<br>').map((line: any, index: any) => (
                              <React.Fragment key={index}>
                                {index > 0 && <br />}
                                {line}
                              </React.Fragment>
                            ))}
                          </div>
                          {contactsData?.telegram_account?.msgStatus === 2 ? (
                            <>
                              {contactsData?.telegram_account?.response.map((rowData: any) => {
                                return (
                                  <div
                                    className={styles.contacts_same_company_parent}
                                    onClick={() => {
                                      handleShowContact(rowData?.index)
                                    }}>
                                    <span>
                                      <FancyImage url={rowData?.photo_url} />
                                    </span>
                                    <span className={styles.caption_parent}>
                                      <div className={styles.header}>{rowData?.full_name}</div>
                                      <div className={styles.body}>{rowData?.job_title}</div>
                                    </span>
                                  </div>
                                )
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <span>
                    <span className={styles.imgParentTelegram}>
                      <img src={telegram_icon} alt={''} />
                    </span>
                    {!isTelegramStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5 ? (
                      <span className={styles.parentTelegramCaption}>Telegram Account</span>
                    ) : (
                      <TypedText text='Scanning Data...' typingSpeed={100} />
                    )}
                  </span>
                )}
              </Col>
              <Col span={9} className={styles.right}>
                {!visibleFields.includes('telegram_account') && !data?.telegram_already ? (
                  <Popconfirm
                    title={ConfirmBox.GET_DETAILS}
                    description={
                      <Col>
                        <div>{`This action will cost you ${userPoints?.pointsStore?.telegram} points. Read disclaimer:`}</div>

                        {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                        {showDisclaimer ? (
                          <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                            {ConfirmBox.DISCLAIMER}
                          </p>
                        ) : (
                          <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                            {'Disclaimer'}
                          </span>
                        )}
                      </Col>
                    }
                    onConfirm={() => {
                      post('/log', {
                        action: 'confirm credit for telegram exposure',
                      })
                      setIsTelegramStartAnima(true)
                      const telegramTimer = setTimeout(() => {
                        handleGetDetails('telegram_account', false)
                        setShowDisclaimer(false)
                        setContactsErrorData({
                          email: '',
                          phone_numbers: { res: '', msg: '', msgStatus: '' },
                          telegram_account: '',
                          discord: '',
                        })
                        clearTimeout(telegramTimer)
                      }, 2000)
                    }}
                    onCancel={() => {
                      post('/log', {
                        action: 'cancel credit for telegram exposure',
                      })
                      setShowDisclaimer(false)
                    }}
                    okText='Get'
                    cancelText='No'>
                    {!isTelegramStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5 ? (
                      <p
                        className='contactTypes'
                        onClick={() => {
                          post('/log', {
                            action: 'Expose telegram',
                          })
                        }}>
                        <img src={open_lock} alt={''} />
                        <span>{DrawerLebels.EXPOSE}</span>
                      </p>
                    ) : (
                      <LoadingDots />
                    )}
                  </Popconfirm>
                ) : (
                  <>
                    {contactsData?.telegram_account?.msgStatus === 0 || data?.telegram_already ? (
                      <div>
                        {!showTextBox && showSendMessage && (
                          <Button
                            loading={is_send_message}
                            className='contactTypes'
                            style={{ padding: '0px 12px', margin: '0px' }}
                            onClick={handleSendMessage}>
                            {!is_send_message && <img src={send_message} alt='' />}
                            <span>{DrawerLebels.SEND_MESSAGE}</span>
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <TooltipText
                          placement='top'
                          title={'We will notify you know when this contact’s telegram is available.'}>
                          <p
                            className={!telegramDisplayNone ? 'contactTypes display_none' : 'contactTypes'}
                            onClick={() => {
                              setTelegramDisplayNone(false)
                              notifyTracking('telegram_account')
                            }}>
                            <img src={notify_me} alt={''} />
                            <span>{DrawerLebels.NOTIFY_ME}</span>
                          </p>
                        </TooltipText>
                        <TooltipText
                          placement='top'
                          title={'We will notify you know when this contact’s telegram is available.'}>
                          <p className={telegramDisplayNone ? 'display_none' : ''}>
                            <img src={notify_me_succeed} alt={''} />
                          </p>
                        </TooltipText>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div className={styles.contactDetails}>
            <p className={styles.titleSection}>{DrawerLebels.COMPANY}</p>
            <div className={styles.rowData}>
              {data?.company_s3_logo && (
                <img
                  src={data?.company_s3_logo}
                  alt=''
                  onClick={() => {
                    handleShowCompany(data?.company_id)
                  }}
                />
              )}
              <span className={styles.parent_company}>
                <span
                  className='linkTo'
                  onClick={() => {
                    handleShowCompany(data?.company_id)
                  }}>
                  {data?.company_name}
                </span>
                <span>
                  <TooltipText placement='topLeft' title={CommonServices.generateStringFromArr(data?.vertical)}>
                    <span className={'textTooltipContact'}>{CommonServices.generateStringFromArr(data?.vertical)}</span>
                  </TooltipText>
                </span>
              </span>
            </div>
          </div>

          {/* Communities */}
          <Communities
            data={data}
            communitiesData={communitiesData}
            visibleFields={visibleFields}
            setIsTelegramStartAnima={setIsTelegramStartAnima}
            isTelegramStartAnima={isTelegramStartAnima}
            handleGetDetails={handleGetDetails}
            setContactsErrorData={setContactsErrorData}
            contactsErrorData={contactsErrorData}
            contactsData={contactsData}
            notifyTracking={notifyTracking}
            telegramDisplayNone={telegramDisplayNone}
            setTelegramDisplayNone={setTelegramDisplayNone}
          />

          {/* Outreach */}
          <div className={styles.fixedOutreach}>
            <div className={styles.contactDetails}>
              <p className={styles.titleSection} style={{ margin: messages.length === 0 ? '0px' : '0px 0px 16px 0px' }}>
                {DrawerLebels.OUTREACH}
              </p>
              <span style={{ fontSize: '12px', fontWeight: '500' }}>
                {!(contactsData?.telegram_account?.msgStatus === 0 || data?.telegram_already)
                  ? "Expose Contact's Telegram to Message"
                  : !showTextBox
                  ? messages.length === 0 && 'You Can Now Message Contact'
                  : "Message Contact's Telegram"}
              </span>
              {showTextBox && (
                <div style={{ marginTop: '20px', marginBottom: '16px' }}>
                  <div className={styles.sendMessageContainer}>
                    <div className={styles.sendMessage}>
                      <Form
                        id='messageForm'
                        name='messageForm'
                        form={form}
                        layout='horizontal'
                        style={{ width: '100%' }}
                        onFinish={handleSubmitMessage}>
                        <Form.Item name='sender'>
                          <Select
                            // style={{ width: '100%', height: '40px' }}
                            className={styles.select}
                            placeholder='Send from...'
                            loading={isLoading}
                            onFocus={async () => {
                              await refetcGetSenderList()
                            }}>
                            {dataSenderList?.response.map((item: any) => (
                              <Option key={item.id} value={item.id} disabled={item.cooldown}>
                                {renderOption(item)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name='message'>
                          <Mentions
                            className={`${styles.inputText}`}
                            autoSize={{ minRows: 4, maxRows: 5 }}
                            maxLength={5000}
                            style={{ padding: '15px 0px 0px 5px' }}
                            placeholder='You can use @ to ref user data here'
                            options={options}
                          />
                        </Form.Item>
                      </Form>
                      <div className={styles.buttons}>
                        <span onClick={handleSendMessage} style={{ color: '#d9d9d9' }}>
                          {'Cancel'}
                        </span>

                        <Button
                          form='messageForm'
                          key='submit'
                          htmlType='submit'
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '0px',
                          }}>
                          {'Send'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {messages.length === 0 ? (
                <>
                  {!(contactsData?.telegram_account?.msgStatus === 0 || data?.telegram_already) && (
                    <Popconfirm
                      title={ConfirmBox.GET_DETAILS}
                      description={
                        <Col>
                          <div>{`This action will cost you ${userPoints?.pointsStore?.telegram} points. Read disclaimer:`}</div>

                          {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                          {showDisclaimer ? (
                            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                              {ConfirmBox.DISCLAIMER}
                            </p>
                          ) : (
                            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                              {'Disclaimer'}
                            </span>
                          )}
                        </Col>
                      }
                      onConfirm={() => {
                        setIsTelegramStartAnima(true)
                        const telegramTimer = setTimeout(() => {
                          handleGetDetails('telegram_account', false)
                          setShowDisclaimer(false)
                          setContactsErrorData({
                            email: '',
                            phone_numbers: { res: '', msg: '', msgStatus: '' },
                            telegram_account: '',
                            discord: '',
                          })
                          clearTimeout(telegramTimer)
                        }, 2000)
                      }}
                      onCancel={() => {
                        setShowDisclaimer(false)
                      }}
                      okText='Get'
                      cancelText='No'>
                      {(!isTelegramStartAnima || contactsErrorData?.telegram_account?.msgStatus === 5) && (
                        <p
                          className='contactTypes'
                          style={{
                            padding: '0px 12px',
                            margin: '-20px 0px 0px 0px',
                            backgroundColor: '#7043ff',
                          }}>
                          <img style={{ filter: 'brightness(0) invert(1)' }} src={open_lock} alt='' />
                          <span style={{ color: 'white', fontWeight: 'bold' }}>{DrawerLebels.EXPOSE}</span>
                        </p>
                      )}
                    </Popconfirm>
                  )}
                  {(contactsData?.telegram_account?.msgStatus === 0 || data?.telegram_already) && !showTextBox && (
                    <Button
                      className='contactTypes'
                      style={{
                        padding: '0px 12px',
                        margin: '-20px 0px 0px 0px',
                        backgroundColor: '#7043ff',
                      }}
                      loading={is_send_message}
                      onClick={handleSendMessage}>
                      {!is_send_message && <img src={telegram_white} alt='' />}
                      <span style={{ color: 'white', fontWeight: '600' }}>{DrawerLebels.SEND_MESSAGE}</span>
                    </Button>
                  )}
                </>
              ) : (
                <div className={styles.rowData}>
                  <div className={styles.sentMessages}>
                    <div style={{ width: '30px', height: '30px', position: 'absolute', right: '20px', top: '20px' }}>
                      {(data?.telegram_already || contactsData?.telegram_account?.response) && (
                        <a
                          href={`https://t.me/${data?.telegram_already || contactsData?.telegram_account?.response}`}
                          target='_black'
                          style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                          {/* <img src={popout_flat} style={{ borderRadius: '0', width: '30px', height: '30px' }} alt='' /> */}
                          <Tooltip
                            overlayInnerStyle={{
                              color: 'black',
                              backgroundColor: 'white',
                            }}
                            title='Continue in Telegram'>
                            <img
                              src={popout_flat}
                              style={{ borderRadius: '0', width: '30px', height: '30px', marginRight: '0px' }}
                              alt=''
                            />
                            {''}
                          </Tooltip>
                          {/* <span style={{ color: '#7043fe' }}>{DrawerLebels.SEND_MESSAGE}</span> */}
                        </a>
                      )}
                    </div>
                    {messages &&
                      messages?.map((message, index) => (
                        <div className={styles.messageContainer} key={index}>
                          <p>{message.message}</p>
                          <div className={styles.metaInfo}>
                            <span>{new Date(message.createdAt).toLocaleString()}</span>
                            <Tooltip
                              placement='topRight'
                              title={message.user_telegram || message.user_telegram_id}
                              arrow={true}>
                              <span>{getMessageStatus(message)}</span>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <FancyDrawer
        onClose={() => {
          toggleHide && toggleHide(true)
          setShowDetails(false)
          window.location.pathname.indexOf('companies') === -1 &&
            navigate(
              `/${
                window.location.pathname.indexOf('exposedContacts') !== -1 ? 'exposedContacts' : 'contacts'
              }${search}`,
            )
        }}
        open={showDetails}
        customClass={'companyDetails'}
        title={
          !loading && (
            <div className={'drawerTitle'}>
              <FancyImage url={company?.s3_logo} iscompany={true} />
              <p>
                {company?.name}
                <a href={company?.url} target='_blanck'>
                  {company?.url}
                </a>
              </p>
            </div>
          )
        }>
        {!loading ? (
          <CompanyDetails
            data={company}
            toggleHide={(value: any) => {
              setShowDetails(value)
            }}
            showCompany={handleShowCompany}
          />
        ) : (
          <LoadingBox />
        )}
      </FancyDrawer>

      {connectPlusModalOpen && (
        <OutreachConnection open={connectPlusModalOpen} onClose={() => setConnectPlusModalOpen(false)} />
      )}
      {/* {connectPlusModalOpen &&
        (userDetails?.subscription_type === 'PREMIUM' ? (
          <ConnectPlusErrorModal open={connectPlusModalOpen} onClose={() => setConnectPlusModalOpen(false)} />
        ) : (
          <ConnectPlusModal open={connectPlusModalOpen} onClose={() => setConnectPlusModalOpen(false)} />
        ))} */}

      <Modal
        open={actionsPaywallOpen}
        onCancel={() => setActionsPaywallOpen(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywall
            addon={'Telegram messaging'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          location='exposedContacts'
          type='limit'
          caption={'contacts outreached'}
          isSubCaption={false}
        />
      )}

      {cooldownOpen && <CooldownModal open={cooldownOpen} onClose={() => setCooldownOpen(false)} />}
    </>
  )
}

export default ContactDetails
