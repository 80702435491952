import { useEffect, useState } from 'react'
import classNames from 'classnames'

import email from 'common/assets/svg/email_icon_purple.svg'
import phone from 'common/assets/svg/phone_icon_purple.svg'
import telegram from 'common/assets/svg/telegram_icon_purple.svg'
import new_message from '../../common/assets/svg/new-message.svg'
import success_green from '../../common/assets/svg/success_green.svg'
import failed_red from '../../common/assets/svg/failed_red.svg'
import { Tooltip } from 'antd'
import { get } from 'common/api/axios'
import BottomMenu from './components/BottomMenu'
import ContactsPage from 'features/contacts/contacts'
import MessagesStatus from './components/MessagesStatus'
import { getMessageStatus } from 'common/utils/getMessageStatus'

import styles from './ExposedContacts.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_IS_OUTREACHED, SET_IS_SENT } from './state/sendMessageSlice'
import { SET_DELETE_CONTACTS_SELECTED_ROWS } from 'features/contacts/state/slice/contactsSlice'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import SuccessAnimation from './Animations/SuccessAnimation'
import FailureAnimation from './Animations/FailureAnimation'
import SpinnerAnimation from './Animations/SpinnerAnimation'

const ExposedContacts = () => {
  const [messages, setMessages] = useState([])
  const [counts, setCounts] = useState([])
  const [selectedContacts, setSelectedContacts] = useState<any>([])
  const { is_outreached, is_sent } = useAppSelector((state) => state.outreached)
  const [contactsLoadingStatus, setContactsLoadingStatus] = useLocalStorage('loading_status_outreach', [])

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (is_outreached && is_sent === true) {
      dispatch(SET_IS_OUTREACHED([]))
      dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))
      dispatch(SET_IS_SENT(false))

      // Save loading state in localStorage
      const filterTelegram = is_outreached.filter((item) => item?.telegram_account.length > 0)
      const contactsLoadingStatus = filterTelegram.map((item) => ({
        contact_id: item.contact_id,
        isLoading: true,
        status: '',
      }))
      const loadingStatusTemp = localStorage.getItem('loading_status_outreach') || '[]'
      let loadingStatus = JSON.parse(loadingStatusTemp)

      contactsLoadingStatus.forEach((contact) => {
        const index = loadingStatus.findIndex((item: any) => item.contact_id === contact.contact_id)
        if (index !== -1) {
          loadingStatus[index] = contact
        } else {
          loadingStatus = [...loadingStatus, contact]
        }
      })
      setContactsLoadingStatus(loadingStatus)
    }
  }, [is_sent, contactsLoadingStatus])

  const handleSelectedContacts = (contacts: any) => {
    setSelectedContacts(contacts)
  }

  const handleCounts = (counts: any) => {
    setCounts(counts)
  }

  const fetchMessages = async () => {
    try {
      const response = await get('/outreach/user')

      setMessages(response?.data?.messages)
    } catch (error) {
      console.error(`Error fetching user messages ${error}`)
    }
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  const getLastMessage = (item: any) =>
    messages
      .filter((message: any) => message?.contact_telegram === item?.telegram_account)
      .sort((a: any, b: any) => new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime())[0]

  const getMessageCount = (item: any) =>
    messages.reduce(
      (count, message: any) => (message?.contact_telegram === item?.telegram_account ? count + 1 : count),
      0,
    )

  const isRepliedColumn = {
    title: '',
    dataIndex: 'replied',
    width: 150,
    render: (text: boolean, item: any) => {
      return (
        <>
          {getMessageCount(item) ? (
            <div className={styles.newMessage}>
              <Tooltip placement='topRight' title={item?.user_telegram_id} arrow={true}>
                <img src={new_message} alt='' />
                <span>{getMessageStatus(getLastMessage(item))}</span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </>
      )
    },
  }

  const exposedChannelsColumn = {
    title: 'Channels',
    dataIndex: 'channels',
    width: 150,
    render: (text: boolean, item: any) => (
      <div className={styles.channelsContainer}>
        <div className={classNames(styles.iconWrapper, !!item.email ? '' : styles.disabled)}>
          <img src={email} alt='' />
        </div>
        <div className={classNames(styles.iconWrapper, !!item.phone_numbers && !!item.phone ? '' : styles.disabled)}>
          <img src={phone} alt='' />
        </div>
        <div
          className={classNames(styles.iconWrapper, !!item.telegram_account && !!item.telegram ? '' : styles.disabled)}>
          <img src={telegram} style={{ marginRight: '6px' }} alt='' />
        </div>
      </div>
    ),
  }

  const outreachedColumn = {
    title: (
      <Tooltip
        placement='top'
        title={
          <div style={{ margin: '10px' }}>
            {`✅ Sent: Your message has been successfully sent to the recipient.`}
            <br />
            {`🔄 Replied: The recipient has replied to your message.`}
            <br />
            {`❌ Failed: Unfortunately, the message delivery has failed.`}
          </div>
        }>
        {'Outreached'}
      </Tooltip>
    ),
    dataIndex: 'outreached',
    key: 'outreached',
    width: 100,
    render: (text: boolean, item: any, index: number) => {
      const contacts = JSON.parse(localStorage.getItem('loading_status_outreach') || '[]')
      const selectedItem = contacts.filter((contact: any) => contact.contact_id === item.contact_id)
      if (selectedItem.length > 0) {
        const isLoading = selectedItem?.[0]?.isLoading
        const status = selectedItem?.[0]?.status
        const shouldRenderAnimation = isLoading === false && status !== ''
        if (shouldRenderAnimation) {
          const updatedContacts = contacts.filter((contact: any) => contact.contact_id !== item.contact_id)
          setTimeout(() => {
            localStorage.setItem('loading_status_outreach', JSON.stringify(updatedContacts))
          }, 250 + index)
        }
        return (
          <>
            {isLoading === true ? (
              <div className={styles.outreached}>
                <div className={styles.loaderWrapper}>
                  <SpinnerAnimation />
                </div>
              </div>
            ) : status === true ? (
              <div className={styles.outreached}>
                <div className={styles.loaderWrapper}>
                  <SuccessAnimation />
                </div>
              </div>
            ) : (
              <div className={styles.outreached}>
                <div className={styles.loaderWrapper}>
                  <FailureAnimation />
                </div>
              </div>
            )}
          </>
        )
      }

      if (getMessageStatus(getLastMessage(item)) === 'Not Sent') {
        return (
          <div className={styles.outreached}>
            <p>-</p>
          </div>
        )
      } else if (
        getMessageStatus(getLastMessage(item)) === 'Sent' ||
        getMessageStatus(getLastMessage(item)) === 'Replied'
      ) {
        return (
          <div className={styles.outreached}>
            <img src={success_green} alt='' />
            {/* <SuccessAnimation anim={0} /> */}
          </div>
        )
      } else {
        return (
          <div className={styles.outreached}>
            <img src={failed_red} alt='' />
            {/* <SuccessAnimation anim={1} /> */}
          </div>
        )
      }
    },
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ContactsPage
        isFromOutreach={true}
        filterBarValue={<MessagesStatus refetch={fetchMessages} messages={messages} counts={counts} />}
        bottomMenuButton={<BottomMenu selectedContacts={selectedContacts} />}
        additionalColumns={[outreachedColumn, exposedChannelsColumn, isRepliedColumn]}
        handleSelectedContacts={handleSelectedContacts}
        setCounts={handleCounts}
      />
    </div>
  )
}

export default ExposedContacts
